
import { GroupModel } from '$store/models';
import { useSelector } from 'react-redux';
import { RootState } from '$store/rootReducer';
import { groupSelector } from '$store/groups';

export const useGroupInfo = (groupId: number): GroupModel => {
  const groupInfo = useSelector((state: RootState) =>
    groupSelector(state, groupId),
  );
  return groupInfo as GroupModel;
};
