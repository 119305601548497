import './SegmentedControl.sass';

import React, { useState, useCallback, useEffect, FC } from 'react';

import { classNames } from '$utils';
import { SegmentedControlProps } from '$uikit/SegmentedControl/SegmentedControl.interface';

function getSelectedIndex(defaultValue, options) {
  if (!defaultValue) {
    return 0;
  }

  for (let i = 0; i < options.length; i++) {
    if (defaultValue === options[i].value) {
      return i;
    }
  }

  return 0;
}

export const SegmentedControl: FC<SegmentedControlProps> = (props) => {
  const {
    options,
    defaultValue,
    onChange = null,
  } = props;

  const [selected, setSelected] = useState<any>(getSelectedIndex(defaultValue, options));

  const handleClick = useCallback((value) => () => {
    setSelected(value);
    onChange && onChange(options[value].value);
  }, [setSelected, options, onChange]);

  return (
    <div className="SegmentedControl">
      <div className="SegmentedControl__in">
        {options.map(({ label, value, counter = null }, i) => {
          return (
            <div
              key={value}
              className={classNames({
                SegmentedControl__item: true,
                selected: selected === i,
              })}
              onClick={handleClick(i)}
            >
              {label}
              {!!counter && <span>{counter}</span>}
            </div>
          )
        })}
        <div
          className="SegmentedControl__indicator"
          style={{
            width: `${100 / options.length}%`,
            transform: `translateX(${100 * selected}%)`
          }}
        />
      </div>
    </div>
  );
};
