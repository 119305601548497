import { API_URL_PRODUCTION, API_URL_STAGING } from '../shared/constants';
import { resetBaseApiUrl } from '../api';

export function setStorageItem(name: string, value: string) {
  try {
    localStorage.setItem(name, value);
  } catch (e) {}
}

export function getStorageItem(name: string): string | null | undefined {
  try {
    return localStorage.getItem(name);
  } catch (e) {}
}

export const getToken = () => {
  const raw = ((localStorage.getItem('token')) || '').split(',');
  return {
    userId: +raw[0],
    token: raw[1],
  };
};

export const setToken = (userId: number, token: string) => {
  localStorage.setItem('token', `${userId},${token}`);
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const getApiUrl = (): string | null => {
  const apiDomain = localStorage.getItem('apiUrl');
  return apiDomain ?? API_URL_PRODUCTION;
};

export const setRegistrationToken = (token: string) => {
  localStorage.setItem('registration_token', token);
};

export const removeRegistrationToken = () => {
  localStorage.removeItem('registration_token');
};

export const getRegistrationToken = (): string | null => {
  return localStorage.getItem('registration_token');
};

export const setApiUrl = (isProduction: boolean): void => {
  const apiUrl = isProduction ? API_URL_PRODUCTION : API_URL_STAGING;
  localStorage.setItem('apiUrl', apiUrl);
  resetBaseApiUrl();
};
