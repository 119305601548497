import './AnimeItem.sass';

import React, { FC } from 'react';

import { AnimeItemProps } from './interface';
import { ModalLink } from '$shared/components';

export const AnimeItem: FC<AnimeItemProps> = ({ anime }) => {
  return (
    <ModalLink name={`anime${anime.id}`}>
      <div className="AnimeItem">
        <div
          className="AnimeItem__poster"
          style={{ backgroundImage: `url(${anime.photos[0]})` }}
        />
        <div className="AnimeItem__cont">
          <div className="AnimeItem__title">{anime.title}</div>
          <div className="AnimeItem__description">{anime.description}</div>
        </div>
      </div>
    </ModalLink>
  );
};
