import './Placeholder.sass';

import React, { FC } from 'react';
import { PlaceholderProps } from './Placeholder.interface';
import { classNames } from '$utils';

export const Placeholder: FC<PlaceholderProps> = (props) => {
  const {
    title = '',
    icon = null,
    actions = null,
    children,
    centered = false,
  } = props;

  return (
    <div className={classNames({
      Placeholder: true,
      centered,
    })}>
      {icon && <div className="Placeholder__icon">{icon}</div>}
      {title && <div className="Placeholder__title">{title}</div>}
      <div className="Placeholder__text">{children}</div>
      {actions && <div className="Placeholder__actions">{actions}</div>}
    </div>
  );
};
