import './RatingBlock.sass';

import React, { FC } from 'react';
import { Link } from 'react-router5';

import { classNames, getRatingColor, getRatingNextLevelValue } from '$utils';
import { useUserInfo } from '$hooks';
import { RatingBlockProps } from './RatingBlock.interface';
import { RATING } from '$shared/constants/pages';

export const RatingBlock: FC<RatingBlockProps> = ({ userId, isMyLabel = false }) => {
  const user = useUserInfo(userId);

  return (
    <>
      <div className="RatingBlock">
        <Link className="RatingBlock__link" routeName={RATING}>
          <div className="RatingBlock__info">
            <div className="RatingBlock__info__label">{isMyLabel ? 'Ваш рейтинг' : 'Рейтинг'}</div>
            <div className={classNames({
              RatingBlock__info__count: true,
              RatingColor: true,
              [getRatingColor(user.rating)]: true,
            })}>
              {` ${user.rating < 0 ? '-' : '+'} `}
              {Math.abs(user.rating)}
            </div>
          </div>
          <div className="RatingBlock__progress">
            <div
              className={classNames({
                RatingBlock__progress__indicator: true,
                RatingBackground: true,
                [getRatingColor(user.rating)]: true,
              })}
              style={{
                width: Math.max(0, (user.rating / getRatingNextLevelValue(user.rating) * 100)) + '%'
              }}
            />
          </div>
          <div className="RatingBlock__level_info">
            До следующего уровня — {getRatingNextLevelValue(user.rating) - user.rating}
          </div>
        </Link>
      </div>
    </>
  );
}
