import './NewGroup.sass';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Form, FormItem, Header, Input, ScrollView } from '$uikit';
import { gram } from '$utils';
import { BottomBar } from '$uikit';
import { useDispatch } from 'react-redux';
import { groupsActions } from '$store/groups';
import { MAX_GROUP_DESCRIPTION, MAX_GROUP_NAME } from '$shared/constants/groups';

export const NewGroup: FC = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleName = useCallback(({ target }) => {
    setName(target.value.substr(0, MAX_GROUP_NAME));
  }, [setName]);

  const handleDescription = useCallback(({ target }) => {
    setDescription(target.value.substr(0, MAX_GROUP_DESCRIPTION));
  }, [setDescription]);

  const nameLabel = useMemo(() => {
    const avail = MAX_GROUP_NAME - name.length;
    return `${gram(avail, ['остался', 'осталось', 'осталось'], true)} ${gram(avail, ['символ', 'символа', 'символов'])}`;
  }, [name]);

  const descriptionLabel = useMemo(() => {
    const avail = MAX_GROUP_DESCRIPTION - description.length;
    return `${gram(avail, ['остался', 'осталось', 'осталось'], true)} ${gram(avail, ['символ', 'символа', 'символов'])}`;
  }, [description]);

  const handleCreate = useCallback(() => {
    dispatch(groupsActions.createGroup({ name, description }));
  }, [name, description, dispatch]);

  return (
    <>
      <Header showCloseButton>Новое сообщество</Header>
      <ScrollView>
        <Form>
          <FormItem title="Название" description={nameLabel}>
            <Input
              placeholder="Введите название.."
              value={name}
              onChange={handleName}
              maxLength={MAX_GROUP_NAME}
            />
          </FormItem>
          <FormItem title="Описание" description={descriptionLabel}>
            <Input
              placeholder="Введите описание.."
              multiline
              value={description}
              onChange={handleDescription}
              maxLength={MAX_GROUP_DESCRIPTION}
            />
          </FormItem>
        </Form>
      </ScrollView>
      <BottomBar>
        <Button stretched onClick={handleCreate}>Создать</Button>
      </BottomBar>
    </>
  );
};
