import './Birthdays.sass';

import { FC, useCallback, useEffect, useMemo } from 'react';
import * as fns from 'date-fns';

import { Button, Header, InlineHeader, Placeholder, ScrollView } from '$uikit';
import { isBackNavigation } from '$navigation/router';
import { useDispatch, useSelector } from 'react-redux';
import { followersActions, followersSelector } from '$store/followers';
import { Loader } from '$shared/components';
import { format, ily, parse } from '$utils';
import { mainSelector } from '$store/main';
import { BirthdayCell } from '$core/Birthdays/BirthdayCell/BirthdayCell';

export const Birthdays: FC = () => {
  const isBack = isBackNavigation();
  const dispatch = useDispatch();

  const {
    isBirthdaysLoading,
    isBirthdaysFailed,
    birthdays,
  } = useSelector(followersSelector);
  const { users } = useSelector(mainSelector);

  useEffect(() => {
    if (!isBack) {
      dispatch(followersActions.loadBirthdays());
    }
  }, []);

  const handleRetry = useCallback(() => {
    dispatch(followersActions.loadBirthdays());
  }, [dispatch]);

  const sections = useMemo(() => {
    let todaySection: number[] = [];
    let tomorrowSection: number[] = [];
    let weekSection: number[] = [];
    let monthsSections: { [index: number]: number[] } = {};

    const nowDate = new Date();
    const now = parse(`${nowDate.getDate()}.${nowDate.getMonth() + 1}`, 'dd.MM', new Date());
    const daysInYear = ily(nowDate.getFullYear()) ? 366 : 365;
    let prepared = birthdays
      .map((friendId) => {
        const user = users[friendId] ;
        if (!user || !user.bDate) {
          return null;
        }

        let matches = user.bDate.match(/^(\d+).(\d+)/);
        if (!matches) {
          return null;
        }

        const date = fns.parse(`${matches[1]}.${matches[2]}`, 'dd.MM', new Date());
        if (!fns.isValid(date)) {
          return null;
        }

        const rawDiff = fns.differenceInDays(date, now);
        let diff = rawDiff;
        if (diff < 0) {
          diff = daysInYear - Math.abs(diff);
        }

        return {
          rawDiff,
          diff,
          friendId,
          date,
        };
      })
      .filter((item) => !!item)
      .sort((a, b) => a!.diff > b!.diff ? 1 : -1)
      //.slice(0, visibleCount);

    for (let item of prepared) {
      const { friendId, diff, date, rawDiff } = item!;

      if (diff === 0) {
        todaySection.push(friendId);
      } else if (diff === 1) {
        tomorrowSection.push(friendId);
      } else if (diff > 0 && diff <= 7) {
        weekSection.push(friendId);
      } else {
        let key: string;
        if (rawDiff > 0) {
          key = `cur_year_${date.getMonth()}`;
        } else {
          key = `next_year_${date.getMonth()}`;
        }

        if (!monthsSections[key]) {
          monthsSections[key] = [];
        }
        monthsSections[key].push(friendId);
      }
    }

    let monthsSectionsResult: { title: string, key: string, friendIds: number[], sortIndex: number }[] = [];
    const nextYear = nowDate.getFullYear() + 1;
    for (let key in monthsSections) {
      const keyExp = key.split('_');
      const month = +keyExp[2];
      const date = parse(`${month + 1}`, 'MM', new Date());
      let monthName = format(date, 'LLLL');
      let sortIndex = month;
      if (keyExp[0] === 'next') {
        monthName += ` ${nextYear}`;
        sortIndex += 100;
      }

      monthsSectionsResult.push({
        key,
        sortIndex,
        title: monthName.substr(0, 1).toUpperCase() + monthName.substr(1),
        friendIds: monthsSections[key],
      });
    }

    monthsSectionsResult.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1)

    return {
      todaySection,
      tomorrowSection,
      weekSection,
      monthsSections: monthsSectionsResult,
    };
  }, [birthdays]);

  function renderSection(title: string, ids: number[], showButton = false) {
    if (!ids.length) {
      return null;
    }

    return (
      <div className="Birthdays__section">
        <InlineHeader>{title}</InlineHeader>
        <div>
          {ids.map((id) => <BirthdayCell userId={id} key={id} showButton={showButton} />)}
        </div>
      </div>
    )
  }

  function renderContent() {
    if (isBirthdaysLoading) {
      return <Loader isWrapped />;
    }

    if (isBirthdaysFailed) {
      return (
        <Placeholder
          title="Ошибка"
          actions={<Button size="small" onClick={handleRetry}>Попробовать снова</Button>}
        >
          Произошла ошибка, попробуйте позже
        </Placeholder>
      );
    }

    if (!birthdays.length) {
      return (
        <Placeholder
          centered
          title="Пока ничего нет"
        >
          Здесь будут отображаться дни рождения ваших подписок.
        </Placeholder>
      );
    }

    return (
      <>
        {renderSection('Сегодня', sections.todaySection, true)}
        {renderSection('Завтра', sections.tomorrowSection)}
        {renderSection('На неделе', sections.weekSection)}
        {sections.monthsSections.map((item) => renderSection(item.title, item.friendIds))}
      </>
    );
  }

  return (
    <>
      <Header showBackButton>Дни рождения</Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
