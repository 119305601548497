import { API_URL_PRODUCTION } from 'shared/constants';
import { baseUrl } from 'api';
import * as firebaseMessaging from 'firebase/messaging';
import amplitude from 'amplitude-js';

export const chunk = (input: any[], size: number) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

export function shuffle(a: any[]) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function isProduction() {
  return baseUrl === API_URL_PRODUCTION;
}

export function genderText(gender: number, variants: string[]) {
  // gender 2 is female
  return +gender === 2 ? variants[0] : variants[1];
}

export function classNames(...args) {
  let result: any[] = [];

  // @ts-ignore
  [].concat(args).forEach(function (item) {
    if (!item) {
      return;
    }
    switch (typeof item === 'undefined' ? 'undefined' : typeof item) {
      case 'string':
        result.push(item);
        break;
      case 'object':
        Object.keys(item).forEach(function (key) {
          if (item[key]) {
            result.push(key);
          }
        });
        break;
      default:
        result.push('' + item);
    }
  });

  return result.join(' ');
}

export function ily(yr) {
  return !((yr % 4) || (!(yr % 100) && (yr % 400)));
}

function roundNumber(input, decimals = 2) {
  const roundTo = Math.pow(10, decimals);
  return Math.round(input * roundTo) / roundTo;
}

export function formatBigNumber(input) {
  if (input < 1000) {
    return input;
  } else if (input < 1000000) {
    return `${roundNumber(input / 1000, 1)}K`;
  } else {
    return `${roundNumber(input / 1000000, 1)}KK`;
  }
}

export function gram(number, variants, skipNumber = false): string {
  const cases = [2, 0, 1, 1, 1, 2];
  let res = (variants[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5]]);
  if (!skipNumber) {
    res = number+' '+res;
  }
  return res;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  return `${phone}`.length >= 8;
}

export function canvasToBlob(canvas, quality): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', quality);
  });
}

export function scrollToBottom(elemId) {
  const elem = document.getElementById(elemId);
  if (!elem) {
    return;
  }

  elem.scrollTop = elem.scrollHeight - elem.offsetHeight;
}

export function scrollToBottomWithCheck(elemId) {
  const elem = document.getElementById(elemId);
  if (!elem) {
    return;
  }

  const endScroll = elem.scrollHeight - elem.offsetHeight;
  const curScroll = elem.scrollTop;

  if (endScroll - curScroll < 300) {
    scrollToBottom(elemId);
  }
}

export function isDesktop() {
  return window.innerWidth >= 1000;
}

export function isIOS() {
  return !!(navigator.platform && /iPad|iPhone|iPod/i.test(navigator.platform));
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export const amplitudeEvent = (action: string, params: any) => {
  try {
    amplitude.getInstance().logEvent(action, params);
  } catch (e) {
    console.error('amplitudeEvent error', e);
  }
};

export function statReachGoal(eventName: string) {
  amplitudeEvent(eventName, {});
}

export function isNative(version = 0) {
  // @ts-ignore
  if (!window.isNative) {
    return false;
  }

  // @ts-ignore
  return window.nativeVersion >= version;
}

export function isNotificationsSupported() {
  if (isNative(2) || isIOS()) {
    return true;
  }

  if (!window.Notification) {
    return false;
  }

  return firebaseMessaging.isSupported();
}

export function getRatingColor(karma) {
  if (karma >= 10000) {
    return 'red';
  } else if (karma >= 5000) {
    return 'purple';
  } else if (karma >= 2500) {
    return 'orange';
  } else if (karma >= 1000) {
    return 'green';
  } else {
    return 'cyan';
  }
}

export function getRatingNextLevelValue(karma) {
  if (karma >= 10000) {
    return 10000;
  } else if (karma >= 5000) {
    return 10000;
  } else if (karma >= 2500) {
    return 5000;
  } else if (karma >= 1000) {
    return 2500;
  } else {
    return 1000;
  }
}

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if ('standalone' in navigator || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

export function isPWA() {
  return ['standalone', 'twa'].indexOf(getPWADisplayMode()) > -1;
}

export * from './date';
export * from './color';
export * from './asyncStorage';
export * from './texts';
export * from './device';
export * from './anime';
export * from './attach';
export * from './elems';
