import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ResolveAction, ResolverState, SetNotFoundAction, SetResultAction } from './interface';
import { RootState } from '$store/rootReducer';

const initialState: ResolverState = {
  results: {},
  isFailed: {},
  isNotFound: {},
};

export const { actions, reducer } = createSlice({
  name: 'resolver',
  initialState,
  reducers: {
    resolve(state, action: ResolveAction) {
      const { mode, domain } = action.payload;
      delete state.isFailed[`${mode}_${domain}`];
      delete state.isNotFound[`${mode}_${domain}`];
    },
    setResult(state, action: SetResultAction) {
      const { mode, domain, result } = action.payload;
      state.results[`${mode}_${domain}`] = result;
    },
    setNotFound(state, action: SetNotFoundAction) {
      const { mode, domain } = action.payload;
      state.isNotFound[`${mode}_${domain}`] = true;
    },
    setFailed(state, action: SetNotFoundAction) {
      const { mode, domain } = action.payload;
      state.isFailed[`${mode}_${domain}`] = true;
    },
  },
});

export { reducer as resolverReducer, actions as resolverActions };

export const resolverSelector = (state: RootState) => state.resolver;
