import './Promo.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, InlineHeader, ScrollView } from '$uikit';
import { IcAdd28, IcChevron16, IcDiscover28, IcUserPlus28, IcUsers28 } from '$assets';
import { isNative, statReachGoal } from '$utils';
import { mainActions, mainSelector } from '$store/main';
import { useDispatch, useSelector } from 'react-redux';
import { Auth, Loader, Post, UserCell } from '$shared/components';
import { Link } from 'react-router5';
import { SEARCH } from '$shared/constants/pages';

export const Promo: FC = () => {
  const dispatch = useDispatch();
  const { isMainPageLoading, mainPagePosts, mainPageGroups } = useSelector(mainSelector);
  const [isAuthFromShown, setAuthFormShown] = useState(false);

  useEffect(() => {
    statReachGoal('main_page');

    if (!isNative()) {
      dispatch(mainActions.loadMainPageData());
    }
  }, []);

  const handleShowAuthFrom = useCallback(() => {
    setAuthFormShown(true);
  }, []);

  function renderContent() {
    if (isMainPageLoading) {
      return (
        <div className="Main__explore__loader">
          <Loader />
        </div>
      );
    }

    return (
      <div className="Main__explore">
        <InlineHeader>Группы</InlineHeader>
        {mainPageGroups.map((item) => (
          <UserCell
            key={`${item}`}
            objectId={item}
            isFollowed={false}
            isGroup
          />
        ))}
        <Link className="Search__more_btn" routeName={SEARCH} routeParams={{ tab: 'groups' }}>
          Показать больше
          <IcChevron16 />
        </Link>
        <InlineHeader>Последние записи</InlineHeader>
        <div>
          {mainPagePosts.map((item) => (
            <Post
              key={`${item}`}
              postId={item}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <ScrollView>
        <div className="Promo__header">
          <div className="Promo__header__circle top" />
          <div className="Promo__header__circle bottom" />
          <div className="Promo__header__name">Anime.Fans</div>
          <div className="Promo__header__title">
            Социальная сеть для любителей аниме
          </div>
          <div className="Promo__header__action">
            {isAuthFromShown ? (
              <Auth />
            ) : (
              <Button
                icon={<IcAdd28 />}
                onClick={handleShowAuthFrom}
              >Создать страницу</Button>
            )}
          </div>
        </div>
        <div className="Promo__features">
          <div className="Promo__features__item">
            <div className="Promo__features__item__icon">
              <IcUsers28 />
            </div>
            <div className="Promo__features__item__title">
              Сообщества
            </div>
            <div className="Promo__features__item__caption">
              Создавайте сообщества по любимым аниме или присоединяйтесь к уже существующим.
            </div>
          </div>
          <div className="Promo__features__item">
            <div className="Promo__features__item__icon">
              <IcUserPlus28 />
            </div>
            <div className="Promo__features__item__title">
              Находите друзей
            </div>
            <div className="Promo__features__item__caption">
              Найдите друзей, которым нравится аниме и общайтесь на сайте с помощью быстрых сообщений.
            </div>
          </div>
          <div className="Promo__features__item">
            <div className="Promo__features__item__icon">
              <IcDiscover28 />
            </div>
            <div className="Promo__features__item__title">
              Аниме
            </div>
            <div className="Promo__features__item__caption">
              Смотрите аниме без рекламы, создавайте списки аниме и делитесь ими с друзьями.
            </div>
          </div>
        </div>
        {renderContent()}
      </ScrollView>
    </>
  );
};
