import { createSlice } from '@reduxjs/toolkit';

import { ToastActivity, ToastState, SetToastAction } from './interface';

const initialState: ToastState = {
  label: '',
  activity: null,
};

export const { reducer, actions } = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastLoading(state, action: SetToastAction) {
      state.activity = ToastActivity.LOADING;
      state.label = action.payload || 'Загрузка..';
    },
    setToastSuccess(state, action: SetToastAction) {
      state.activity = ToastActivity.SUCCESS;
      state.label = action.payload || 'Успешно!';
    },
    setToastFail(state, action: SetToastAction) {
      state.activity = ToastActivity.FAIL;

      if (action.payload) {
        state.label = typeof action.payload === 'string' ? action.payload : JSON.stringify(action.payload);
      } else {
        state.label = 'Произошла ошибка';
      }
    },
    hideToast(state) {
      state.label = '';
      state.activity = null;
    },
  },
});
