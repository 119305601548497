import './BirthdayCell.sass';

import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router5';

import { BirthdayCellProps } from '$core/Birthdays/BirthdayCell/BirthdayCell.interface';
import { useUserInfo } from '$hooks';
import { format, getUserAge, gram, parse } from '$utils';
import { Online, Verified } from '$shared/components';
import { CHAT, PROFILE, RESOLVE } from '$shared/constants/pages';
import { Button } from '$uikit';
import { IcGift28, IcMessage28 } from '$assets';
import { editGroupActions } from '$store/editGroup';
import { followersActions } from '$store/followers';
import router from '$navigation/router';

export const BirthdayCell: FC<BirthdayCellProps> = ({ userId, showButton }) => {
  const user = useUserInfo(userId);

  const description = useMemo(() => {
    let components: string[] = [];

    if (!user.bDate) {
      return '';
    }

    let matches: any = user.bDate.match(/^(\d+).(\d+).(\d+)/);
    matches = matches ? matches.slice(1).map((item) => +item).filter(item => item > 0) : [];
    if (matches.length >= 3) {
      const date = parse(`${matches[0]}.${matches[1]}.${matches[2]}`, 'dd.MM.yyyy', new Date());
      components.push(format(date, `d MMMM yyyy`));

      const age = getUserAge(date.getTime()) + 1;
      components.push(gram(age, ['год', 'года', 'лет']));
    } else {
      matches = user.bDate.match(/^(\d+).(\d+)/);
      const date = parse(`${matches![1]}.${matches![2]}`, 'dd.MM', new Date());
      components.push(format(date, `d MMMM`));
    }

    return components.join(' · ');
  }, [user.bDate]);

  const handleClick = useCallback((e) => {
    if (e.target.closest('.BirthdayCell__btn')) {
      e.preventDefault();
      router.navigate(CHAT, { peerId: userId });
      return false;
    }
  }, [userId]);

  return (
    <Link
      className="BirthdayCell"
      routeName={user.domain ? RESOLVE : PROFILE}
      routeParams={user.domain ? { domain: user.domain } : { id: user.id }}
      onClick={handleClick}
    >
      <div
        className="BirthdayCell__photo"
        style={{ backgroundImage: `url(${user.photo})` }}
      >
        <Online userId={userId} inPhoto />
      </div>
      <div className="BirthdayCell__cont">
        <div className="BirthdayCell__name_wrap">
          <div className="BirthdayCell__name">{user.firstName} {user.lastName}</div>
          <Verified objectId={userId} />
        </div>
        <div className="BirthdayCell__caption">{description}</div>
      </div>
      {showButton && (
        <div className="BirthdayCell__btn">
          <Button
            size="small"
            icon={<IcGift28 />}
          />
        </div>
      )}
    </Link>
  );
};
