import './Attachments.sass';

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Collage, InlineVideo, Post } from '$shared/components';
import { LinkAttach } from '$shared/components/LinkAttach/LinkAttach';
import { postsSelector } from '$store/posts';

export function Attachments({ attachments, photosWidth = 300, parentObject = 'default', canExpand = false }) {
  const { posts } = useSelector(postsSelector);

  if (!attachments || !attachments.length) {
    return null;
  }

  const link = useMemo(() => {
    for (let item of attachments) {
      if (item.type === 'link') {
        return item.link;
      }
    }

    return null;
  }, [attachments]);

  const media = useMemo(() => {
    return attachments.filter((item) => item.type === 'photo' || item.type === 'video');
  }, [attachments]);

  function renderMedia() {
    if (!media.length) {
      return null;
    }

    if (media.length === 1 && media[0].type === 'video') {
      const video = media[0].video;
      return (
        <InlineVideo
          videoId={video.videoId}
          poster={video.photoMedium}
          source={video.source}
          parentObject={parentObject}
        />
      );
    }

    return (
      <Collage
        items={media}
        maxWidth={photosWidth}
      />
    );
  }

  if (canExpand && media.length === 0 && link && link.type === 'post' && posts[link.object.id]) {
    return (
      <div className="Post__repost">
        <div className="LinkAttach__type">Запись на стене</div>
        <Post
          postId={link.object.id}
        />
      </div>
    );
  }

  return (
    <div className="Attachments">
      {renderMedia()}
      {link && <LinkAttach link={link} mini={media.length > 0} parentObject={parentObject} />}
    </div>
  );
}
