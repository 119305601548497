import './CommentForm.sass';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { incline } from 'lvovich';
import { useDispatch, useSelector } from 'react-redux';

import { IcClose16, IcGallery28, IcSend28 } from '../../../assets';
import { RootState } from '$store/rootReducer';
import { createPostFormSelector, postsActions } from '../../../store/posts';
import AttachPickerManager from '../AttachPickerManager/AttachPickerManager';
import { AttachPickerManagerRef } from '../AttachPickerManager/AttachPickerManager.interface';
import { useCurrentUserInfo, useDesktop, useEvent, useGroupInfo, usePost, useUserInfo } from '$hooks';
import { ActionSheet } from '$uikit';

const inputPadding = 8;
const inputLineHeight = 24;
const inputMinHeight = inputLineHeight + inputPadding * 2;
const inputMaxLines = 3;

export default function CommentForm({ parentId }) {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(inputMinHeight);
  const form = useSelector((state: RootState) => createPostFormSelector(state, `comment_${parentId}`));
  const attachManager = useRef<AttachPickerManagerRef | null>(null);
  const [photoInputKey, setPhotoInputKey] = useState(0);
  const sendBtn = useRef<HTMLDivElement | null>(null);
  const isDesktop = useDesktop();
  const [bottom, setBottom] = useState(0);
  const replyToUser = useUserInfo(form.replyTo?.publisherId ?? 0);
  const curUser = useCurrentUserInfo();
  const [isMenuShown, setMenuShown] = useState(false);

  const post = usePost(parentId);
  const postGroup = useGroupInfo(-post.ownerId);

  const isCanReplyFromGroup = useMemo(() => {
    if (!post || post.level !== 0) {
      return false;
    }

    if (!postGroup || !postGroup.isModer) {
      return false;
    }

    return true;
  }, [post, postGroup]);

  const handleOnChange = useCallback((event) => {
    event.target.style.height = '0px';

    const newHeight = Math.min(
      Math.max(inputMinHeight, event.target.scrollHeight),
      inputLineHeight * inputMaxLines + inputPadding * 2
    );

    event.target.style.height = `${newHeight}px`;
    setHeight(newHeight);

    dispatch(postsActions.updateFormText({ formId: `comment_${parentId}`, text: event.target.value }));
  }, [dispatch, parentId, setHeight]);

  const handleSend = useCallback((e) => {
    try {
      e && e.preventDefault();
    } catch (err) {}
    dispatch(postsActions.publishComment(parentId));
  }, [dispatch, parentId]);

  // @ts-ignore
  if (window.isNative) {
    useEvent('touchstart', handleSend, sendBtn);
  } else {
    useEvent('touchstart', handleSend, sendBtn);
    useEvent('mousedown', handleSend, sendBtn);
  }

  const handlePhotoChange = useCallback(({ target }) => {
    attachManager.current?.uploadImages(target.files);
    setPhotoInputKey(Date.now());
  }, [attachManager, setPhotoInputKey]);

  const handleScroll = useCallback(({ target }) => {
    if (target.classList.contains('Modal__cont')) {
      setBottom(target.scrollTop);
    }
  }, [setBottom]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    const el = document.querySelector('.Modal__cont');
    if (!el) {
      return;
    }

    el.addEventListener('scroll', handleScroll, true);
    return () => el.removeEventListener('scroll', handleScroll, true);
  }, [isDesktop, parentId]);

  const replyToLabel = useMemo(() => {
    if (form.replyTo) {
      const dativeName = incline({ first: replyToUser?.firstName ?? '', last: replyToUser?.lastName ?? '' }, 'dative');
      return `Ответ ${dativeName.first} ${dativeName.last}`;
    }

    return null;
  }, [form.replyTo, replyToUser]);

  const placeholder = useMemo(() => {
    if (replyToLabel) {
      return replyToLabel;
    }

    return 'Ваш комментарий..';
  }, [replyToLabel]);

  const handleResetReply = useCallback(() => {
    dispatch(postsActions.replyComment({ parentId, comment: null }));
  }, [dispatch, parentId]);

  const handleMenu = useCallback((value) => {
    setMenuShown(false);

    dispatch(postsActions.setSender({ formId: `comment_${parentId}`, sender: value }))
  }, [dispatch, setMenuShown, parentId]);

  const handleShowMenu = useCallback(() => {
    setMenuShown(true);
  }, [setMenuShown]);

  return (
    <>
      {isMenuShown && (
        <ActionSheet
          isOpened
          options={[
            { label: `От имени сообщества`, value: 'group' },
            { label: `От своего имени`, value: 'myself' }
          ]}
          onChange={handleMenu}
          selected={form.sender || 'myself'}
        />
      )}
      <div className="CommentForm" style={{ transform: `translateY(${bottom}px) translateZ(0)` }}>
        {replyToLabel && (
          <div className="CommentForm__reply_to" onClick={handleResetReply}>
            {replyToLabel}
            <div className="CommentForm__reply_to__cancel">
              <IcClose16 />
            </div>
          </div>
        )}
        <AttachPickerManager
          mini
          ref={attachManager}
          pickerId={`comment_${parentId}`}
        />
        <div className="CommentForm__content">
          <div className="CommentForm__button">
            <IcGallery28 />
            <input
              key={photoInputKey}
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              multiple
            />
          </div>
          {isCanReplyFromGroup && (
            <div className="CommentForm__sender" onClick={handleShowMenu}>
              <div className="CommentForm__sender__photo" style={{ backgroundImage: `url(${form.sender === 'group' ? postGroup?.photo : curUser.photo})` }} />
            </div>
          )}
          <div className="CommentForm__input_wrap">
          <textarea
            placeholder={placeholder}
            style={{ height }}
            onChange={handleOnChange}
            value={form.text}
            id="comment_form"
          />
          </div>
          <div className="CommentForm__button" ref={sendBtn}>
            <IcSend28 />
          </div>
        </div>
      </div>
      <div className="CommentForm__helper" />
    </>
  );
}
