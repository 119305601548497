import './Invite.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import copyToClipboard from 'copy-text-to-clipboard';
import { useDispatch } from 'react-redux';

import { Button, Header, InlineHeader, Placeholder, ScrollView } from '$uikit';
import { useCurrentUserInfo, useDesktop } from '$hooks';
import { Loader, Metatags } from '$shared/components';
import { toastActions } from '$store/toast';
import { Link } from 'react-router5';
import { RATING } from '$shared/constants/pages';
import { Api } from '$api';

export const Invite: FC = () => {
  const user = useCurrentUserInfo();
  const dispatch = useDispatch();
  const isDesktop = useDesktop();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [data, setData] = useState<{ code: string; invites: number } | null>(null);

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get<any, any>('/invite/info')
      .then((data) => {
        setData({
          code: data.code,
          invites: data.invites,
        });
        setLoadingStatus('');
      })
      .catch((e) => {
        setLoadingStatus('failed');
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handleCopy = useCallback(() => {
    copyToClipboard(data?.code ?? '');
    dispatch(toastActions.setToastSuccess('Скопировано!'))
  }, []);

  if (loadingStatus === 'failed') {
    return (
      <Placeholder
        actions={<Button size="small" onClick={loadData}>Попробовать еще раз</Button>}
      >
        Произошла ошибка, попробуйте позже
      </Placeholder>
    );
  }

  if (loadingStatus === 'loading' || !data) {
    return <Loader isWrapped />;
  }

  return (
    <>
      <Metatags title="Пригласить друга" />
      <Header showBackButton={!isDesktop}>Пригласить друзей</Header>
      <ScrollView>
        <div className="Invite__link_wrap">
          <div className="Invite__link_title">Вы можете пригласить {data.invites} друзей</div>
          <div className="Invite__link_description">
            Для обеспечения качества нашего сообщества, мы внесли ограничения на приглашения, чтобы недопускать попадания людей не связанных с аниме. Пожалуйста, делитесь своим кодом только с друзьями, которым действительно нравится аниме и которым вы доверяете.
          </div>
          <div className="Invite__link_label">Ваша код:</div>
          <div className="Invite__link" onClick={handleCopy}>{data.code}</div>
          <Button onClick={handleCopy} size="small">Скопировать</Button>
        </div>
      </ScrollView>
    </>
  );
};
