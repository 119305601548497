import { all, takeLatest, call, put, retry, select, takeEvery } from 'redux-saga/effects';
import * as loadImage from 'blueimp-load-image';

import { profileActions, profileInfoSelector } from './index';
import {
  ChangeDomainAction,
  CheckDomainAction,
  EditProfileAction,
  FollowAction,
  LoadMorePostsAction,
  LoadProfileAction,
  SendInviteAction,
  SetStatusAction,
  UploadPhotoAction
} from './interface';
import { Api } from '$api';
import { mainActions, mainSelector } from '../main';
import { postsActions } from '../posts';
import { toastActions } from '$store/toast';
import { goBack } from '$navigation/helper';
import { canvasToBlob, statReachGoal, validatePhone } from '$utils';
import { RootState } from '$store/rootReducer';
import { groupsActions } from '$store/groups';
import { authSelector } from '$store/auth';
import { batchActions } from '$store';
import { followersActions } from '$store/followers';

function* loadProfileWorker(action: LoadProfileAction) {
  try {
    const profileId = action.payload;

    const data = yield retry(Infinity, 3000, Api.get, `/profile/${profileId}`);
    yield call(handleProfileResult, profileId, data);
  } catch(e) {}
}

export function* handleProfileResult(profileId: number, data: any) {
  yield put(
    batchActions(
      mainActions.setUsers(data.users),
      mainActions.setGroups(data.groups),
      postsActions.setPosts(data.posts),
      postsActions.setList({
        listId: String(profileId),
        items: data.posts.map((post) => post.id),
      }),
      profileActions.setProfile({
        userId: profileId,
        postsCount: +data.posts_count,
        isFollowFromMe: data.is_follow_from_me,
        isFollowToMe: data.is_follow_to_me,
        postsNextFrom: data.postsNextFrom,
        scheduledCount: data.scheduledCount,
        animeListsCount: data.animeListsCount,
      }),
    )
  )
}

function* followWorker(action: FollowAction) {
  const userId = action.payload;
  try {
    yield put(profileActions.setFollowStatus({ userId, isFollowed: true }));
    const resp = yield call(Api.post, `/follow/${userId}/add`);
    yield put(
      batchActions(
        mainActions.setUser(resp.user),
        followersActions.setFollowing({
          [userId]: true,
        }),
      ),
    );
    yield call(statReachGoal, 'follow');
  } catch (e) {
    yield put(profileActions.setFollowStatus({ userId, isFollowed: false }));
  }
}

function* unfollowWorker(action: FollowAction) {
  const userId = action.payload;
  try {
    yield put(profileActions.setFollowStatus({ userId, isFollowed: false }));
    const resp = yield call(Api.post, `/follow/${userId}/del`);
    yield put(
      batchActions(
        mainActions.setUser(resp.user),
        followersActions.setFollowing({
          [userId]: false,
        }),
      ),
    );
  } catch (e) {
    yield put(profileActions.setFollowStatus({ userId, isFollowed: true }));
  }
}

function* editProfileWorker(action: EditProfileAction) {
  try {
    yield put(toastActions.setToastLoading());

    const {
      firstName,
      lastName,
      sex,
      birthday,
      countryId,
      cityId,
    } = action.payload;

    const bDateExp = birthday.split('.');
    const bDay = +bDateExp[0] || 0;
    const bMonth = +bDateExp[1] || 0;
    const bYear = +bDateExp[2] || 0;

    const { user } = yield call(Api.post, '/profile/edit', {
      firstName,
      lastName,
      sex,
      bDay,
      bMonth,
      bYear,
      countryId,
      cityId,
    });
    yield put(mainActions.setUser(user));
    yield put(toastActions.setToastSuccess());
    yield call(goBack);
  } catch(e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* setStatusWorker(action: SetStatusAction) {
  try {
    const { text, isPublish, groupId } = action.payload;

    const endpoint = groupId > 0 ? `/group/${groupId}/status` : '/profile/status';
    yield put(toastActions.setToastLoading());
    const resp = yield call(Api.post, endpoint, {
      status: text,
      publish: isPublish,
    });
    if (groupId > 0) {
      yield put(mainActions.setGroup(resp.group));
      yield put(groupsActions.loadGroupInfo(groupId));
    } else {
      yield put(mainActions.setUser(resp.user));
      yield put(profileActions.loadProfile(resp.user.id));
    }
    yield put(toastActions.hideToast());
    yield call(goBack);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* sendInviteWorker(action: SendInviteAction) {
  try {
    const phone = action.payload.phone.trim();

    if (!validatePhone(phone)) {
      yield put(toastActions.setToastFail('Введите корректный номер телефона'));
      return;
    }

    yield put(toastActions.setToastLoading());
    const resp = yield call(Api.post, '/invite', {
      phoneCode: +action.payload.code,
      phone: phone.replace(/[\s()\-]/g, ''),
    });
    yield put(mainActions.setUser(resp.user));
    yield put(toastActions.setToastSuccess());
    action.payload.onDone();
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadMorePostsWorker(action: LoadMorePostsAction) {
  try {
    const userId = action.payload;
    const { postsNextFrom } = yield select((state: RootState) => profileInfoSelector(state, userId));
    const data = yield call(Api.post, `/profile/${userId}/posts`, {
      startFrom: +postsNextFrom,
    });

    yield put(mainActions.setUsers(data.users));
    yield put(postsActions.setPosts(data.posts));
    yield put(postsActions.pushToList({
      listId: String(userId),
      items: data.posts.map((post) => post.id),
    }));

    yield put(profileActions.setLoadMorePosts({
      userId,
      nextFrom: data.nextFrom,
    }));
  } catch (e) {}
}

function* uploadPhotoWorker(action: UploadPhotoAction) {
  try {
    const file = action.payload;
    const { currentUserId } = yield select(authSelector);

    if (file.type.substr(0, 6) !== 'image/' || file.type === 'image/gif') {
      return yield put(toastActions.setToastFail('Это не фото — нужно фото'));
    }

    yield put(toastActions.setToastLoading());

    const img = yield call(loadImage, file, {
      maxWidth: 1200,
      maxHeight: 1200,
      meta: true,
      canvas: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      orientation: true,
    });

    const quality = 0.8;
    const blob = yield call(canvasToBlob, img.image, quality);

    const form = new FormData();
    form.append('file', blob);
    const data = yield call(Api.post, `/profile/photo`, form);
    yield put(mainActions.setUser(data.user));
    yield put(toastActions.setToastSuccess());
    yield put(profileActions.loadProfile(currentUserId));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* checkDomainWorker(action: CheckDomainAction) {
  try {
    yield call(Api.post, '/domain/check', {
      domain: action.payload.domain,
    });
    action.payload.onDone();
  } catch (e) {
    action.payload.onFail(e.message);
  }
}

function* changeDomainWorker(action: ChangeDomainAction) {
  try {
    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, '/profile/domain', {
      domain: action.payload,
    });
    data.user.domain = action.payload;
    yield put(mainActions.setUser(data.user));
    yield call(goBack);
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* profileSaga() {
  yield all([
    takeEvery(profileActions.loadProfile, loadProfileWorker),
    takeLatest(profileActions.follow, followWorker),
    takeLatest(profileActions.unfollow, unfollowWorker),
    takeLatest(profileActions.editProfile, editProfileWorker),
    takeLatest(profileActions.setStatus, setStatusWorker),
    takeLatest(profileActions.sendInvite, sendInviteWorker),
    takeLatest(profileActions.loadMorePosts, loadMorePostsWorker),
    takeLatest(profileActions.uploadPhoto, uploadPhotoWorker),
    takeLatest(profileActions.checkDomain, checkDomainWorker),
    takeLatest(profileActions.changeDomain, changeDomainWorker),
  ]);
}
