import './Input.sass';

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { InputProps } from './Input.interface';

const INPUT_HEIGHT = 48;
const TEXTAREA_HEIGHT = 96;

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    multiline = false,
    lineHeight=  24,
    padding = 12,
    maxLines = 8,
    minHeight,
    ...otherProps
  } = props;

  const minHeightRes = props.minHeight || (multiline ? TEXTAREA_HEIGHT : INPUT_HEIGHT);

  const input = useRef(null);
  const [height, setHeight] = useState(minHeightRes);

  let preparedProps = {
    ...otherProps,
    ref: input,
    className: 'Input',
    style: {
      height,
      minHeight: minHeightRes,
      lineHeight: `${lineHeight}px`,
      paddingTop: padding,
      paddingBottom: padding,
    }
  };

  /* @ts-ignore */
  useImperativeHandle(ref, () => ({
    focus() {
      /* @ts-ignore */
      input.current.focus();
    },
    resetHeight() {
      setHeight(minHeightRes);
    },
  }));

  const handleOnChange = useCallback((event) => {
    event.target.style.height = '0px';

    const newHeight = Math.min(
      Math.max(minHeightRes, event.target.scrollHeight),
      lineHeight * maxLines + padding * 2
    );

    event.target.style.height = `${newHeight}px`;
    setHeight(newHeight);

    if (otherProps.onChange) {
      otherProps.onChange(event);
    }
  }, [setHeight, otherProps]);

  useEffect(() => {
    //multiline && input.current && handleOnChange({ target: input.current });
  }, [input])

  if (multiline) {
    /* @ts-ignore */
    preparedProps.onChange = handleOnChange;
  }

  return multiline ? <textarea {...preparedProps} /> : <input {...preparedProps} />;
});
