import { all, takeLatest, put, retry, select, call } from 'redux-saga/effects';
import { inviteActions, inviteSelector } from '$store/invite/index';
import { Api } from '$api';
import { batchActions } from '$store';
import { mainActions } from '$store/main';

function* loadSuggestionsWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.post, '/invite/suggestions', {
      startFrom: 0,
    });
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        inviteActions.setSuggestions({
          suggestions: data.suggestions,
          nextFrom: data.nextFrom,
        }),
      ),
    );
  } catch (e) {}
}

function* loadMoreSuggestionsWorker() {
  try {
    const { suggestionsNextFrom } = yield select(inviteSelector);
    const data = yield call(Api.post, '/invite/suggestions', {
      startFrom: suggestionsNextFrom,
    });
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        inviteActions.setLoadedMoreSuggestions({
          suggestions: data.suggestions,
          nextFrom: data.nextFrom,
        }),
      ),
    );
  } catch (e) {}
}

export function* inviteSaga() {
  yield all([
    takeLatest(inviteActions.loadSuggestions, loadSuggestionsWorker),
    takeLatest(inviteActions.loadMoreSuggestions, loadMoreSuggestionsWorker),
  ]);
}
