import './ExternalVideo.sass';

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExternalVideoProps } from '$shared/components/ExternalVideo/ExternalVideo.interface';
import { IcPlay28 } from '$assets';
import { useVisibleElement } from '$hooks';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions, mainSelector } from '$store/main';

export const ExternalVideo: FC<ExternalVideoProps> = ({ link, parentObject }) => {
  const dispatch = useDispatch();
  const { playingVideo } = useSelector(mainSelector);
  const [isPlaying, setPlaying] = useState(false);

  const elem = useRef(null);
  const { visible } = useVisibleElement(elem);

  useEffect(() => {
    if (!visible && isPlaying) {
      setPlaying(false);
    }
  }, [visible]);

  useEffect(() => {
    if (isPlaying && playingVideo !== parentObject) {
      setPlaying(false);
    }
  }, [playingVideo]);

  const prettyPlatform = useMemo(() => {
    if (link.object.platform === 'youtube') {
      return 'YouTube';
    } else if (link.object.platform === 'tiktok') {
      return 'TikTok';
    } else {
      return link.object.platform.substr(0, 1).toUpperCase() + link.object.platform.substr(1);
    }
  }, [link.object.platform]);

  const handlePlay = useCallback(() => {
    setPlaying(true);
    dispatch(mainActions.setPlayingVideo(parentObject));
  }, [setPlaying, dispatch, link.object.id]);

  const description = useMemo(() => {
    let result = link.object.description;
    if (result > 180) {
      result = `${result.substr(0, 168)}...`;
    }

    return result;
  }, [link.object.description]);

  return (
    <div className="ExternalVideo" ref={elem}>
      <div className="ExternalVideo__iframe_wrap">
        {!isPlaying && (
          <>
            <div className="ExternalVideo__iframe" style={{ backgroundImage: `url(${link.object.imageUrl})` }} onClick={handlePlay}>
              <div className="ExternalVideo__play_btn">
                <IcPlay28 />
              </div>
            </div>
            <div className="ExternalVideo__platform">{prettyPlatform}</div>
          </>
        )}
        {isPlaying && (
          <iframe allowFullScreen src={`${link.object.videoUrl}?autoplay=1&showinfo=0`} className="ExternalVideo__iframe" />
        )}
      </div>
      <div className="ExternalVideo__info">
        <a href={link.object.originalLink} target="_blank" className="ExternalVideo__title">{link.object.title}</a>
        <div className="ExternalVideo__description">{description}</div>
      </div>
    </div>
  );
};
