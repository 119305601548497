import './Settings.sass';

import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CellButton, Header, ScrollView } from '$uikit';
import { IcSignOut28 } from '$assets';
import { authActions, authSelector } from '$store/auth';
import { useCurrentUserInfo, useTranslator } from '$hooks';
import AuthRequired from '$core/AuthRequired/AuthRequired';

export const Settings: FC = () => {
  const dispatch = useDispatch();
  const  t = useTranslator();
  const user = useCurrentUserInfo();
  const { isLogged } = useSelector(authSelector);

  const handleLogout = useCallback(() => {
    if (confirm(t('settings_logout_confirm'))) {
      dispatch(authActions.logout());
    }
  }, [dispatch, t]);

  const handleLegal = useCallback(() => {
    alert('По всем вопросам связанным с нарушением авторских прав пишите на email: legal@anime.fans');
  }, []);

  if (!isLogged) {
    return <AuthRequired />;
  }

  return (
    <>
      <Header showBackButton>{t('settings_title')}</Header>
      <ScrollView>
        <div className="Settings">
          <CellButton indicator={user.domain || `id${user.id}`} modal="domain">{t('settings_username')}</CellButton>
          <CellButton href="/terms.html">{t('settings_terms')}</CellButton>
          <CellButton href="/privacy.html">{t('settings_privacy')}</CellButton>
          <CellButton onClick={handleLegal}>{t('settings_legal')}</CellButton>
          <CellButton icon={<IcSignOut28 />} onClick={handleLogout}>{t('settings_logout')}</CellButton>
        </div>
      </ScrollView>
    </>
  );
};
