import { createSlice } from '@reduxjs/toolkit';
import {
  AnimeState,
  DeleteListAction,
  EditAnimeAction,
  LoadAnimeInfoAction,
  LoadEditDataAction,
  LoadListAction,
  LoadListsAction,
  LoadListsLoadedAction,
  LoadMoreEndAction,
  ResetEditDataAction,
  SaveAnimeListAction,
  SaveProgressAction,
  SearchAction,
  SetAnimeInfoAction,
  SetEditDataAction,
  SetExploreAction,
  SetListLoadedAction,
  SetNextFromAction,
  SetSearchResultAction,
  SetSelectedSeasonAction,
  SharePostAction,
  ToggleLikeAction,
  UpdateAnimeInfoAction
} from '$store/anime/interface';
import { RootState } from '$store/rootReducer';
import { AnimeModel, VideoModel } from '$store/models';

const initialState: AnimeState = {
  isLoading: true,
  edit: {},
  list: [],
  recent: [],
  likes: [],
  suggestions: [],
  animeInfo: {},
  searchQuery: '',
  searchResult: [],
  isSearching: false,
  nextFrom: '',
  isLoadingMore: false,
  searchNextFrom: '',
  isSearchLoadingMore: false,
  isListsLoading: true,
  isListsFailed: false,
  lists: [],
  isListLoading: true,
  isListFailed: false,
  listInfo: null,
};

export const { actions, reducer } = createSlice({
  name: 'anime',
  initialState,
  reducers: {
    loadExplore(state) {
      state.isLoading = true;
    },
    setExplore(state, action: SetExploreAction) {
      state.isLoading = false;
      state.recent = action.payload.recent;
      state.likes = action.payload.likes;
      state.suggestions = action.payload.suggestions;

      if (!state.list.length) {
        state.list = action.payload.list;
        state.nextFrom = action.payload.nextFrom;
      }
    },
    setEditData(state, action: SetEditDataAction) {
      const { id, updated } = action.payload;

      state.edit[id] = {
        ...(state.edit[id] || {}),
        ...updated,
      };
    },
    createAnime() {},
    editAnime(_, action: EditAnimeAction) {},
    loadEditData(state, action: LoadEditDataAction) {
      const animeId = action.payload;
      state.edit[animeId] = {
        ...(state.edit[animeId] || {}),
        isLoading: true,
      };
    },
    resetEditData(state, action: ResetEditDataAction) {
      delete state.edit[action.payload];
    },
    loadAnimeInfo(_, action: LoadAnimeInfoAction) {},
    setAnimeInfo(state, action: SetAnimeInfoAction) {
      const { animeId, info, seasons, videos, seasonSelected, translations, progress } = action.payload;

      const videosMap: { [index: number]: VideoModel } = {};
      for (let video of videos) {
        videosMap[video.id] = video;
      }

      state.animeInfo[animeId] = {
        info,
        seasons,
        videos: videosMap,
        seasonSelected: seasonSelected,
        translations,
        translationSelected: state.animeInfo[animeId]?.translationSelected ?? translations[0]?.id,
        progress,
        state: action.payload.state,
      };
    },
    updateAnimeInfo(state, action: UpdateAnimeInfoAction) {
      const { animeId, updated } = action.payload;

      if (state.animeInfo[animeId]) {
        state.animeInfo[animeId].info = {
          ...state.animeInfo[animeId].info,
          ...updated,
        };
      }
    },
    setSelectedSeason(state, action: SetSelectedSeasonAction) {
      const { animeId, season } = action.payload;
      state.animeInfo[animeId].seasonSelected = season;
    },
    saveProgress(state, action: SaveProgressAction) {
      const { animeId, episodeId, duration } = action.payload;

      state.animeInfo[animeId].progress[episodeId] = duration;
    },
    search(state, action: SearchAction) {
      state.searchQuery = action.payload;
      state.isSearching = true;
    },
    setSearchResult(state, action: SetSearchResultAction) {
      state.searchResult = action.payload;
      state.isSearching = false;
      state.isLoadingMore = false;
    },
    setNextFrom(state, action: SetNextFromAction) {
      state.nextFrom = action.payload;
    },
    setSearchNextFrom(state, action: SetNextFromAction) {
      state.searchNextFrom = action.payload;
    },
    toggleLike(_, action: ToggleLikeAction) {},
    sharePost(_, __: SharePostAction) {},
    loadMore(state) {
      state.isLoadingMore = false;
    },
    loadMoreEnd(state, action: LoadMoreEndAction) {
      const exist: { [key: number]: boolean } = {};
      for (let item of state.list) {
        exist[item.id] = true;
      }

      const add: AnimeModel[] = [];
      for (let item of action.payload) {
        if (!exist[item.id]) {
          add.push(item);
        }
      }

      state.list = [
        ...state.list,
        ...add,
      ];
      state.isLoadingMore = false;
    },
    searchLoadMoreEnd(state, action: LoadMoreEndAction) {
      const exist: { [key: number]: boolean } = {};
      for (let item of state.searchResult) {
        exist[item.id] = true;
      }

      const add: AnimeModel[] = [];
      for (let item of action.payload) {
        if (!exist[item.id]) {
          add.push(item);
        }
      }

      state.searchResult = [
        ...state.searchResult,
        ...add,
      ];
      state.isLoadingMore = false;
    },
    loadLists(state, _: LoadListsAction) {
      state.isListsLoading = true;
      state.isListsFailed = false;
    },
    loadListsFailed(state) {
      state.isListsLoading = false;
      state.isListsFailed = true;
    },
    setListsLoaded(state, action: LoadListsLoadedAction) {
      state.isListsLoading = false;
      state.lists = action.payload;
    },
    saveAnimeList(_, __: SaveAnimeListAction) {},
    loadList(state, action: LoadListAction) {
      state.isListLoading = true;
      state.isListFailed = false;

      const listId = action.payload;
      for (let item of state.lists) {
        if (item.id === listId) {
          state.listInfo = item;
          break;
        }
      }
    },
    setListLoaded(state, action: SetListLoadedAction) {
      state.isListLoading = false;
      state.listInfo = action.payload;
    },
    loadListFailed(state) {
      state.isListLoading = false;
      state.isListFailed = true;
    },
    deleteList(_, __: DeleteListAction) {},
  },
});

export { reducer as animeReducer, actions as animeActions };

export const animeSelector = (state: RootState) => state.anime;
