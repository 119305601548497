import { useCallback, useEffect, useState } from 'react';
import { isDesktop } from '$utils';

export function useDesktop() {
  const [desktop, setDesktop] = useState(isDesktop());

  // const handleResize = useCallback(() => {
  //   const newVal = isDesktop();
  //
  //   if (newVal !== desktop) {
  //     setDesktop(newVal);
  //   }
  // }, [desktop, setDesktop]);
  //
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [desktop]);

  return desktop;
}
