import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select, { default as ReactSelect } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, authSelector } from '../../../store/auth';
import { promiseCities, promiseCountries } from '../../../services/selectDataFetcher';
import { Button, Form, FormItem, ScrollView } from '$uikit';
import { BottomBar } from '$uikit';
import { IcDropdown16 } from '$assets';
import { useTranslator } from '$hooks';

export default function Step2({ setStep }) {
  const {
    registrationForm: {
      country,
      city,
    }
  } = useSelector(authSelector);
  const dispatch = useDispatch();
  const t = useTranslator();

  const [isCitiesLoading, setCitiesLoading] = useState(false);
  const [cities, setCities] = useState<any[]>([]);
  const searchTimer = useRef<any | null>(null);

  useEffect(() => {
    return () => {
      searchTimer.current && clearTimeout(searchTimer.current);
    };
  }, []);

  const handleCountryChange = useCallback((value) => {
    dispatch(authActions.updateRegistraionForm({
      country: value,
      city: null,
    }));
    handleCitiesInputChange('', { action: 'input-change' }, value);
  }, [dispatch]);

  const handleCityChange = useCallback((value) => {
    dispatch(authActions.updateRegistraionForm({
      city: value,
    }));
  }, [dispatch]);

  const handleCitiesInputChange = useCallback((query, { action }, newCountry = null) => {
    if (action !== 'input-change') {
      return;
    }

    searchTimer.current && clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setCitiesLoading(true);
      promiseCities(query, newCountry?.value ?? country!.value).then((options) => {
        setCities(options);
        setCitiesLoading(false);
      });
    }, newCountry ? 0 : 1000);
  }, [setCitiesLoading, promiseCities, country, searchTimer]);

  const handleNext = useCallback(() => {
    setStep(3);
  }, [setStep]);

  const handlePrev = useCallback(() => {
    setStep(1);
  }, [setStep]);

  return (
    <>
      <ScrollView>
        <div className="Registration__title">
          {t('reg_step2_title')}
        </div>
        <Form>
          <FormItem title={t('reg_country')}>
            <AsyncSelect
              classNamePrefix="Select"
              value={country}
              onChange={handleCountryChange}
              cacheOptions
              defaultOptions
              loadOptions={promiseCountries}
              placeholder={t('reg_select_country')}
              loadingMessage={() => t('loading')}
              noOptionsMessage={() => t('reg_select_empty')}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
          {!!country && (
            <FormItem title={t('reg_city')}>
              <Select
                classNamePrefix="Select"
                defaultMenuIsOpen={!city}
                isSearchable
                value={city}
                onChange={handleCityChange}
                options={cities}
                onInputChange={handleCitiesInputChange}
                isLoading={isCitiesLoading}
                placeholder={t('reg_select_city')}
                loadingMessage={() => t('loading')}
                noOptionsMessage={() => t('reg_select_empty')}
                components={{
                  DropdownIndicator: () => <IcDropdown16 />,
                }}
              />
            </FormItem>
          )}
        </Form>
      </ScrollView>
      <BottomBar>
        <Button onClick={handlePrev} mode="secondary" stretched>{t('back')}</Button>
        <Button onClick={handleNext} stretched>{t('continue')}</Button>
      </BottomBar>
    </>
  )
}
