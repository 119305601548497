import './AttachPickerManager.sass';

import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import LinkifyIt from 'linkify-it';

import { AttachPickerManagerProps, AttachPickerManagerRef } from './AttachPickerManager.interface';
import { useDispatch, useSelector } from 'react-redux';
import { attachActions, attachPhotosSelector, attachSelector, attachTimerSelector } from '../../../store/attach';
import { RootState } from '$store/rootReducer';
import Photos from '$shared/components/AttachPickerManager/Photos';
import { classNames } from '$utils';
import Timer from './Timer';
import Link from './Link';
import { roundToNearestMinutes } from 'date-fns';

export default forwardRef<AttachPickerManagerRef, AttachPickerManagerProps>((props, ref) => {
  const { pickerId, mini = false } = props;

  const photos = useSelector((state: RootState) => attachPhotosSelector(state, pickerId));
  const timer = useSelector((state: RootState) => attachTimerSelector(state, pickerId));
  const linkTimer = useRef<any>(0);
  const { linkFailed } = useSelector(attachSelector);

  useEffect(() => {
    return () => clearTimeout(linkTimer.current);
  }, []);

  const dispatch = useDispatch();
  const linkify = useMemo(() => {
    return LinkifyIt();
  }, []);

  useImperativeHandle(ref, () => ({
    uploadImages(files: File[]) {
      dispatch(attachActions.uploadPhotos({
        pickerId,
        files,
      }));
    },
    uploadVideo(file: File) {
      dispatch(attachActions.uploadVideo({
        pickerId,
        file,
      }));
    },
    findLinks(text: string) {
      clearTimeout(linkTimer.current);

      const links = linkify.match(text);
      if (links) {
        for (let link of links) {
          const url = new URL(link.raw);
          if (
            url.hostname === 'anime.fans'
            || url.hostname === 'localhost'
            || url.hostname === 'youtu.be'
            || url.hostname === 'youtube.com'
            || url.hostname === 'www.youtube.com'
          ) {
            const link = links[0].raw;
            if (linkFailed[link]) {
              continue;
            }

            linkTimer.current = setTimeout(() => {
              dispatch(attachActions.loadLink({
                pickerId,
                link,
              }))
            }, 1000);
            break;
          }
        }
      }
    },
    toggleTimer() {
      if (timer) {
        dispatch(attachActions.setTimer({
          pickerId,
          time: null,
        }));
      } else {
        let time = Date.now() + 3600 * 1000;
        time = roundToNearestMinutes(time, {
          nearestTo: 5,
        }).getTime();

        dispatch(attachActions.setTimer({
          pickerId,
          time,
        }));
      }
    },
  }));

  return (
    <div className={classNames({
      AttachPickerManager: true,
      mini,
    })}>
      <Timer pickerId={pickerId} />
      <Photos
        pickerId={pickerId}
        photos={photos}
        mini={mini}
      />
      <Link pickerId={pickerId} mini={mini} />
    </div>
  );
});
