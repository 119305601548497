import { useUserInfo } from '$hooks/useUserInfo';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInDays from 'date-fns/differenceInDays';

export function useLastActivity(userId) {
  const user = useUserInfo(userId);
  if (!user || user.lastUpdate === null || user.lastUpdate === undefined) {
    return {
      online: false,
      seenRecently: false,
    };
  }

  const lastActivityDate = new Date(user.lastUpdate * 1000);
  const lastActivityInMinutes = differenceInMinutes(new Date(), lastActivityDate);

  if (lastActivityInMinutes <= 15) {
    return {
      online: true,
      seenRecently: false,
    };
  }

  const lastActivityInDays = differenceInDays(new Date(), lastActivityDate);

  if (lastActivityInDays < 1) {
    return {
      online: false,
      seenRecently: true,
    };
  }

  return {
    online: false,
    seenRecently: false,
  };
}
