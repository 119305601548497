import { combineReducers } from '@reduxjs/toolkit';

import { reducer as auth } from './auth';
import { reducer as toast } from './toast';
import { reducer as main } from './main';
import { reducer as profile } from './profile';
import { reducer as posts } from './posts';
import { reducer as attach } from './attach';
import { reducer as feed } from './feed';
import { reducer as anime } from './anime';
import { reducer as search } from './search';
import { reducer as followers } from './followers';
import { reducer as groups } from './groups';
import { reducer as im } from './im';
import { reducer as realtime } from './realtime';
import { reducer as blacklist } from './blacklist';
import { reducer as notify } from './notify';
import { reducer as invite } from './invite';
import { reducer as editGroup } from './editGroup';
import { reducer as resolver } from './resolver';
import { reducer as likes } from './likes';

export const rootReducer = combineReducers({
  auth,
  toast,
  main,
  profile,
  posts,
  attach,
  feed,
  anime,
  search,
  followers,
  groups,
  im,
  realtime,
  blacklist,
  notify,
  invite,
  editGroup,
  resolver,
  likes,
});

export type RootState = ReturnType<typeof rootReducer>;
