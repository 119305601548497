import { differenceInHours, differenceInYears, format, formatDistanceToNow } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export function useDate(ts) {
  const date = new Date(ts * 1000);
  let dateStr;
  if (differenceInHours(new Date(), date) < 24) {
    dateStr = formatDistanceToNow(
      date,
      {
        locale: ruLocale,
        addSuffix: true,
      }
    );
  } else if (differenceInYears(new Date(), date) === 0) {
    dateStr = `${format(date, 'dd MMM', { locale: ruLocale })} в ${format(date, 'HH:mm')}`;
  } else {
    dateStr = `${format(date, 'dd MMM yyyy', { locale: ruLocale })} в ${format(date, 'HH:mm')}`;
  }

  return dateStr;
}
