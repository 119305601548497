import './Comment.sass';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router5';

import { CommentProps } from './Comment.interface';
import { useCurrentUserInfo, useDate, useGroupInfo, usePost, usePosts, useUserInfo } from '$hooks';
import { GROUP, PROFILE } from '$shared/constants/pages';
import { Attachments, Linkify, Loader, Online, Verified } from '$shared/components';
import { IcDropdown16, IcLike24, IcMore24 } from '$assets';
import { classNames, gram, rnbr } from '$utils';
import { postsActions, postsSelector } from '$store/posts';
import { useDispatch, useSelector } from 'react-redux';
import { ActionSheet, Button } from '$uikit';
import { incline } from 'lvovich';
import { blacklistActions, blacklistSelector } from '$store/blacklist';

export const Comment: FC<CommentProps> = ({ rootPostId, commentId, isFullPage = false }) => {
  const comment = usePost(commentId);
  const author = useUserInfo(comment.publisherId);
  const date = useDate(comment.createdAt);
  const dispatch = useDispatch();
  const [isMenuShown, setMenuShown] = useState(false);
  const curUser = useCurrentUserInfo();
  const post = usePost(comment.parentId);
  const replyToUser = useUserInfo(comment.replyTo?.ownerId ?? 0);
  const replyToGroup = useGroupInfo(Math.abs(comment.replyTo?.ownerId ?? 0));
  const postsState = useSelector(postsSelector);
  const repliesState = postsState.replies[commentId] || {};
  const replies = usePosts(`comments_${commentId}`);
  const group = useGroupInfo(-comment.ownerId);
  const rootPost = usePost(rootPostId);
  const postGroup = useGroupInfo(rootPost ? -rootPost.ownerId : 0);
  const { groupBan, banFromMe } = useSelector(blacklistSelector);

  const isAuthor = comment.publisherId === curUser?.id;
  const isPostAuthor = post.ownerId == curUser?.id;
  let canDelete = isAuthor || isPostAuthor;
  if (rootPost && rootPost.ownerId < 0 && postGroup && postGroup.isModer) {
    canDelete = true;
  }

  const authorInfo = useMemo(() => {
    if (comment.ownerId < 0) {
      return {
        name: group.name,
        photo: group.photo,
        route: GROUP,
      };
    } else {
      return {
        name: `${author.firstName} ${author.lastName}`,
        photo: author.photo,
        route: PROFILE,
      };
    }
  }, [group, author]);

  const text = useMemo(() => {
    let isTextMinimized = false;
    let text;
    if (!isFullPage && comment.text.length > 220) {
      isTextMinimized = true;
      text = comment.text.substr(0, 200) + '...';
    } else {
      text = comment.text;
    }

    return {
      value: text,
      isTextMinimized
    }
  }, [comment.text, isFullPage]);

  const handleClick = useCallback((event) => {
    event.stopPropagation();

    const target = event.target;
    if (target.closest('.Comment__more')) {
      setMenuShown(true);
      return;
    }

    if (target.closest('a') || target.closest('.Button') || target.closest('.Comment__like') || target.closest('.ActionSheet') || (target.closest('._Comment__replies') && comment.level === 1)) {
      return;
    }

    if (curUser.id === comment.ownerId && comment.level > 1) {
      //return;
    }

    dispatch(postsActions.replyComment({ parentId: rootPostId, comment }));
    document.getElementById('comment_form')!.focus();
  }, [dispatch, comment.id, isFullPage, setMenuShown, curUser.id, comment.ownerId, comment.level]);

  const handleLike = useCallback(() => {
    dispatch(postsActions.toggleLike(commentId));
  }, [dispatch, commentId]);

  const handleMenu = useCallback((value) => {
    setMenuShown(false);

    if (value === 'delete') {
      dispatch(postsActions.deletePost(commentId));
    }
  }, [dispatch, commentId, setMenuShown]);

  const handleRestore = useCallback(() => {
    dispatch(postsActions.restorePost(commentId));
  }, [dispatch, commentId]);

  const handleBlockAuthor = useCallback(() => {
    if (rootPost?.ownerId < 0) {
      dispatch(blacklistActions.groupBan({
        userId: comment.ownerId,
        groupId: rootPost?.ownerId < 0 ? -rootPost?.ownerId : 0,
      }));
    } else {
      dispatch(blacklistActions.ban(comment.ownerId));
    }
  }, [rootPost, comment]);

  const handleUnblockAuthor = useCallback(() => {
    if (rootPost?.ownerId < 0) {
      dispatch(blacklistActions.groupUnban({
        userId: comment.ownerId,
        groupId: rootPost?.ownerId < 0 ? -rootPost?.ownerId : 0,
      }));
    } else {
      dispatch(blacklistActions.unban(comment.ownerId));
    }
  }, [rootPost, comment]);

  const isBanned = useMemo(() => {
    if (rootPost?.ownerId < 0) {
      const groupId = -rootPost?.ownerId;
      if (!groupBan[groupId]) {
        return false;
      }

      return groupBan[groupId]![comment.ownerId];
    } else {
      return banFromMe[comment.ownerId];
    }
  }, [rootPost, banFromMe, groupBan, comment]);

  const replyToLabel = useMemo(() => {
    if (comment.replyTo) {
      if (comment.replyTo.ownerId < 0) {
        const dativeName = incline({ first: replyToGroup?.name ?? '' }, 'dative');
        return dativeName.first;
      } else {
        const dativeName = incline({ first: replyToUser?.firstName ?? '', last: replyToUser?.lastName ?? '' }, 'dative');
        return `${dativeName.first} ${dativeName.last}`;
      }
    }

    return null;
  }, [comment.replyTo, replyToUser]);

  const loadReplies = useCallback(() => {
    dispatch(postsActions.loadReplies({
      postId: comment.id,
    }));
  }, [dispatch, comment.id]);

  const hideReplies = useCallback(() => {
    dispatch(postsActions.toggleReplies({
      postId: comment.id,
      isShown: false,
    }));
  }, [dispatch, comment]);

  const showReplies = useCallback(() => {
    if (replies.length > 0) {
      dispatch(postsActions.toggleReplies({
        postId: comment.id,
        isShown: true,
      }));
    } else {
      dispatch(postsActions.loadReplies({
        postId: comment.id,
      }));
    }
  }, [dispatch, comment, replies]);

  function renderReplies() {
    if (comment.comments === 0) {
      return null;
    }

    return (
      <div className="_Comment__replies">
        {!repliesState.isLoading && !repliesState.isShown && (
          <div className="Comment__replies_button" onClick={showReplies}>
            <IcDropdown16 />
            {gram(comment.comments, ['ответ', 'ответа', 'ответов'])}
          </div>
        )}
        {repliesState.isShown && replies.map((reply) => <Comment key={reply} rootPostId={rootPostId} commentId={reply} />)}
        <div className="Comment__replies_actions">
          {!repliesState.isLoading && repliesState.isShown && repliesState.nextFrom ? (
            <div className="Comment__replies_button" onClick={loadReplies}>
              <IcDropdown16 />
              Показать еще
            </div>
          ) : <div />}
          {repliesState.isShown && replies.length > 0 && (
            <div className="Comment__replies_button hide" onClick={hideReplies}>
              Скрыть
              <IcDropdown16 />
            </div>
          )}
        </div>
        {repliesState.isLoading && (
          <div className="Comment__replies_loading">
            <div className="Comment__replies_loading__in">
              <Loader />
            </div>
          </div>
        )}
      </div>
    );
  }

  if (comment.isDeleted) {
    return (
      <div className="Comment Comment__deleted">
        <div className="Comment__deleted__label">Комментарий удалён</div>
        <div className="Comment__deleted__actions">
          {!isAuthor && comment.ownerId > 0 && (
            isBanned ?
              <Button mode="secondary" size="small" onClick={handleUnblockAuthor}>Разблокировать автора</Button>
              :
              <Button mode="secondary" size="small" onClick={handleBlockAuthor}>Заблокировать автора</Button>
          )}
          <Button mode="secondary" size="small" onClick={handleRestore}>Восстановить</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="Comment" onClick={handleClick}>
      {isMenuShown && (
        <ActionSheet
          isOpened
          options={[
            { label: `Удалить`, value: 'delete' }
          ]}
          onChange={handleMenu}
          selected={0}
        />
      )}
      <Link
        routeName={authorInfo.route}
        routeParams={{ id: Math.abs(comment.ownerId) }}
        className="Comment__photo"
        style={{ backgroundImage: `url(${authorInfo.photo})` }}
      >
        <Online userId={comment.ownerId} inPhoto />
      </Link>
      <div className="Comment__content">
        <div className="Comment__name_wrap">
          <Link routeName={authorInfo.route} routeParams={{ id: Math.abs(comment.ownerId) }}>
            <div className="Comment__name">{authorInfo.name}</div>
          </Link>
          <Verified objectId={comment.ownerId} />
          {comment.replyTo && (
            <div className="Comment__name__reply">
              →&nbsp;
              <Link
                className="Comment__name__reply__user"
                routeName={comment.replyTo.ownerId > 0 ? PROFILE : GROUP}
                routeParams={{ id: Math.abs(comment.replyTo.ownerId) }}
              >{replyToLabel}</Link>
            </div>
          )}
          {canDelete && <div className="Comment__more">
            <IcMore24 />
          </div>}
        </div>
        {text.value.length > 0 && <div className="Comment__text">
          <Linkify>
            {rnbr(text.value)}
            {text.isTextMinimized && <div className="Post__text__expand">Показать все</div>}
          </Linkify>
        </div>}
        <Attachments attachments={comment.attachments} parentObject={`comment_${commentId}`} />
        <div className="Comment__footer">
          <div className="Comment__footer__group">
            <div className="Comment__date">{date}</div>
            <div className="Comment__reply">Ответить</div>
          </div>
          <div
            className={classNames({
              Comment__like: true,
              liked: comment.isLiked,
            })}
            onClick={handleLike}
          >
            <div className="Comment__like_icon">
              <IcLike24 />
            </div>
            {comment.likes > 0 && <div className="Comment__like__count">{comment.likes}</div>}
          </div>
        </div>
        {renderReplies()}
      </div>
    </div>
  )
};
