import axios from 'axios';
import { API_URL_PRODUCTION } from '$shared/constants';

export const promiseCountries = inputValue =>
  new Promise<any[]>((resolve, reject) => {
    axios.get(`${API_URL_PRODUCTION}/database/countries`)
      .then((resp) => {
        let options =  resp.data.map((item) => ({
          value: +item.id,
          label: item.title,
        }));
        resolve(options);
      })
      .catch(() => {
        reject();
      })
  });

export const promiseCities = (query, country: number) =>
  new Promise<any[]>((resolve, reject) => {
    axios.get(`${API_URL_PRODUCTION}/database/cities`, {
      params: { countryId: country, query },
  })
  .then((resp) => {
      let options =  resp.data.map((item) => ({
        value: +item.id,
        label: item.title,
        countryId: +item.country_id,
      }));
      resolve(options);
    })
      .catch(() => {
        reject();
      })
  });
