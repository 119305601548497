import axios from 'axios';

import { API_URL_PRODUCTION } from 'shared/constants';
import { getToken, getApiUrl, getRegistrationToken, removeToken, removeRegistrationToken } from 'utils/asyncStorage';
import { getLanguage } from '$translation';

export const Api = axios.create({
  baseURL: API_URL_PRODUCTION,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': getLanguage(),
    'Version': 15,
    'Platform': 'web',
  },
});

export let baseUrl: string | null = null;

export const resetBaseApiUrl = () => {
  baseUrl = null;
};

Api.interceptors.request.use(
  (config) => {
    let { token } = getToken();
    if (!token) {
      token = getRegistrationToken() ?? '';
    }

    if (!baseUrl) {
      baseUrl = (getApiUrl()) ?? API_URL_PRODUCTION;
    }

    return {
      ...config,
      baseURL: baseUrl,
      headers: {
        ...config.headers,
        Authorization: token || '',
        //Version: VERSION,
      },
    };
  },
  (error) => {
    console.warn('error', error);
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  (response) => {
    if (['document', 'text'].indexOf(response.config.responseType as string) > -1) {
      return response.data;
    }

    return response.status === 200
      ? Promise.resolve(response.data)
      : Promise.reject(response.data);
  },
  (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject({
        message: 'Ошибка сети',
        isNetworkError: true,
      });
    } else if (error.response.data?.code === 'access_token') {
      removeToken();
      removeRegistrationToken();
      location.href = '/auth_required';
    }

    return Promise.reject(error.response ? error.response.data : error);
  },
);
