import './PlayerContent.sass';

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGesture } from 'react-use-gesture';

import { PlayerContentProps } from './PlayerContent.interface';
import { IcFullscreen24, IcFullscreenExit24, IcPause48, IcPlay48, IcSkipNext48, IcSkipPrevious48 } from '$assets';
import { Loader } from '$shared/components';
import { classNames } from '$utils';

export const PlayerContent: FC<PlayerContentProps> = (props) => {
  const { video, initialTime, onNextEpisode, canGoNext, onProgress } = props;

  const [isAutoPlayExecuted, setAutoPlayExecuted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isPlaying, setPlaying] = useState(false);
  const [isControlsShown, setControlsShown] = useState(true);
  const [keepControlsIncr, setKeepControlsIncr] = useState(0);
  const [progress, setProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isProgressChanging, setProgressChanging] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);

  const wrapRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const handlePlay = useCallback(() => {
    videoRef.current?.play();
  }, [videoRef]);

  const handleFullscreenChange = useCallback((event: Event) => {
    setFullscreen(!!document.fullscreenElement);
  }, []);

  const handleTogglePlay = useCallback((e) => {
    e.preventDefault();
    if (isPlaying) {
      setPlaying(false);
    } else {
      handlePlay();
    }
  }, [isPlaying, handlePlay]);

  useEffect(() => {
    let timer: any = 0;
    if (videoRef.current) {
      timer = setTimeout(() => {
        onProgress(Math.floor(videoRef.current?.currentTime ?? 0), true);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isPlaying]);

  const handleSkip = useCallback((direction: string) => (event) => {
    if (!videoRef.current) {
      return;
    }

    if (event) {
      event.preventDefault();
    }

    let newProgress: number;
    if (direction === 'next') {
      newProgress = Math.min(videoRef.current.currentTime + 10, duration);
    } else {
      newProgress = Math.max(0, videoRef.current.currentTime - 10);
    }

    videoRef.current.currentTime = newProgress;
    setProgress(newProgress);
    setKeepControlsIncr(keepControlsIncr + 1);
  }, [videoRef, duration, keepControlsIncr]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const evType = event.code || event.key;
    if (evType === 'Space') {
      handleTogglePlay(event);
    } else if (evType === 'ArrowRight') {
      handleSkip('next')(event);
    } else if (evType === 'ArrowLeft') {
      handleSkip('prev')(event);
    } else if (evType === 'KeyF') {
      if (!document.fullscreenElement) {
        toggleFullscreen();
      }
    }
  }, [handleTogglePlay, videoRef, duration, handleSkip]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleChangeProgress = useCallback((x: number) => {
    if (!progressRef.current) {
      return;
    }

    const isRotated = isFullscreen && window.screen && screen.orientation.type.substr(0, 8) === 'portrait';
    const pWidth = progressRef.current.offsetWidth;

    let pLeft: number;
    if (isRotated) {
      pLeft = progressRef.current.getBoundingClientRect().top - progressRef.current.offsetHeight;
    } else {
      pLeft = progressRef.current.getBoundingClientRect().left;
    }

    const percent = Math.min(pWidth, Math.max(0, x - pLeft)) / pWidth;
    const newProgress = percent * duration;
    videoRef.current!.currentTime = newProgress;
    setProgress(newProgress);
    setKeepControlsIncr(keepControlsIncr + 1);
  }, [progressRef.current, keepControlsIncr, duration, isFullscreen]);

  const progressBind = useGesture({
    onMouseUp: ({ event }) => {
      if (isProgressChanging) {
        event.preventDefault();
        setProgressChanging(false);
      }
    },
    onMove: ({ event, xy }) => {
      if (!isProgressChanging) {
        return;
      }
      event.preventDefault();
      handleChangeProgress(xy[0]);
    },
  }, {
    eventOptions: { passive: false },
    enabled: !!progressRef.current && duration > 0 && !!videoRef.current && isProgressChanging,
    domTarget: window,
  });

  const handleDownProgress = useCallback((e) => {
    e.preventDefault();
    const isRotated = isFullscreen && window.screen && screen.orientation.type.substr(0, 8) === 'portrait';
    if (e.changedTouches && e.changedTouches.length > 0) {
      if (isRotated) {
        const bottom = window.innerHeight - e.changedTouches[0].clientY;
        console.log(bottom)
        handleChangeProgress(bottom);
      } else {
        handleChangeProgress(e.changedTouches[0].clientX);
      }
    } else if (e.clientX) {
      if (isRotated) {
        const bottom = window.innerHeight - e.clientY;
        console.log(bottom)
        handleChangeProgress(bottom);
      } else {
        handleChangeProgress(isRotated ? e.clientY : e.clientX);
      }
    }
    setProgressChanging(true);
  }, [handleChangeProgress, isFullscreen]);

  useEffect(() => {
    if (isPlaying && !isLoading) {
      videoRef.current?.play()
        .catch((err) => {
          console.log('err', err.message);
          setPlaying(false);
          videoRef.current?.pause();
        });
    } else if (!isPlaying) {
      videoRef.current?.pause();
    }
  }, [isPlaying, videoRef.current, isLoading]);

  useEffect(() => {
    setControlsShown(true);
    setKeepControlsIncr(keepControlsIncr + 1);
  }, [isLoading, isPlaying]);

  useEffect(() => {
    let timer: any = 0;
    if (isControlsShown && isPlaying && !isLoading) {
      timer = setTimeout(() => {
        setControlsShown(false);
      }, 4000);
    }

    return () => clearTimeout(timer);
  }, [isControlsShown, keepControlsIncr]);

  const handleLoadedMetadata = useCallback((e) => {
    console.log('e', e.target.width, e.target.height);
    e.target.currentTime = initialTime;
    setDuration(e.target.duration);
  }, []);

  const handleToggleControls = useCallback((e) => {
    e.preventDefault();
    setControlsShown(!isControlsShown);
  }, [isControlsShown]);

  const handleLoadStart = useCallback(() => {
    setLoading(true);
  }, []);

  const handleCanPlay = useCallback(() => {
    setLoading(false);
    if (!isAutoPlayExecuted) {
      setPlaying(true);
      setAutoPlayExecuted(true);
    }
  }, [isAutoPlayExecuted]);

  const handleOnPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const handleOnPause = useCallback((e) => {
    setPlaying(false);
  }, []);

  const handleError = useCallback((err) => {
    console.log('error 2', err);
  }, []);

  const handleProgress = useCallback(({ target }) => {
    setProgress(Math.floor(target.currentTime));

    if (target.duration) {
      for (let i = 0; i < target.buffered.length; i++) {
        if (target.buffered.start(target.buffered.length - 1 - i) < target.currentTime) {
          setBuffered(target.buffered.end(target.buffered.length - 1 - i));
          break;
        }
      }
    }

    onProgress(Math.floor(target.currentTime));
  }, [onProgress]);

  const progressPercent = useMemo(() => {
    if (!duration) {
      return 0;
    }

    return Math.min(100, Math.floor(progress / duration * 100));
  }, [progress, duration]);

  const bufferedPercent = useMemo(() => {
    if (!duration) {
      return 0;
    }

    return Math.min(100, Math.floor(buffered / duration * 100));
  }, [buffered, duration]);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      if (wrapRef.current) {
        if (wrapRef.current.requestFullscreen) {
          wrapRef.current.requestFullscreen();
          // @ts-ignore
        } else if (wrapRef.current.webkitRequestFullScreen) {
          // @ts-ignore
          wrapRef.current.webkitRequestFullScreen();
          // @ts-ignore
        } else if (wrapRef.current.mozRequestFullScreen) {
          // @ts-ignore
          wrapRef.current.mozRequestFullScreen();
          // @ts-ignore
        } else if (wrapRef.current.requestFullScreen) {
          // @ts-ignore
          wrapRef.current.requestFullScreen();
        }
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
      } else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
        // @ts-ignore
      } else if (document.mozExitFullscreen) {
        // @ts-ignore
        document.mozExitFullscreen();
        // @ts-ignore
      } else if (document.exitFullScreen) {
        // @ts-ignore
        document.exitFullScreen();
      }
    }
  }, [wrapRef.current]);

  const handleNextEpisode = useCallback(() => {
    onNextEpisode();
  }, [onNextEpisode]);

  function renderControl() {
    if (isLoading) {
      return <Loader />;
    }

    return isPlaying ? <IcPause48 /> : <IcPlay48 />;
  }

  return (
    <div ref={wrapRef}>
      <div
        className={classNames({
          PlayerContent: true,
          isPlaying,
          isLoading,
          isControlsShown,
          isFullscreen,
        })}
      >
        <div className="PlayerContent__cont">
          <div className="PlayerContent__press_overlay" onClick={handleToggleControls} />

          <div className="PlayerContent__skip_control next" onClick={handleSkip('next')}>
            <IcSkipNext48 />
          </div>

          <div className="PlayerContent__skip_control prev" onClick={handleSkip('prev')}>
            <IcSkipPrevious48 />
          </div>

          <div className="PlayerContent__play_control" onClick={handleTogglePlay}>
            <div className="PlayerContent__play_control__in">
              {renderControl()}
            </div>
          </div>
          <div className="PlayerContent__footer">
            <div
              className="PlayerContent__progress"
              {...progressBind()}
              onMouseDown={handleDownProgress}
              onTouchStart={handleDownProgress}
              ref={progressRef}
            >
              <div className="PlayerContent__progress__in_wrap">
                <div className="PlayerContent__progress__in">
                  <div className="PlayerContent__progress__buffered" style={{ width: `${bufferedPercent}%` }} />
                  <div className="PlayerContent__progress__current" style={{ width: `${progressPercent}%` }}>
                    <div className="PlayerContent__progress__current_indicator" />
                  </div>
                </div>
              </div>
            </div>
            <div className="PlayerContent__fullscreen" onClick={toggleFullscreen}>
              {isFullscreen ? <IcFullscreenExit24 /> : <IcFullscreen24 />}
            </div>
          </div>
          {canGoNext && duration > 0 && (duration - progress < 4 * 60) && (
            <div className="PlayerContent__next_button" onClick={handleNextEpisode}>
              Следующая серия
            </div>
          )}
        </div>
        <video
          poster={video.poster}
          ref={videoRef}
          className="PlayerContent__video"
          controls={false}
          onLoadedMetadata={handleLoadedMetadata}
          preload="auto"
          onCanPlay={handleCanPlay}
          onLoadStart={handleLoadStart}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onError={handleError}
          onProgress={handleProgress}
          width="100%"
          height="auto"
        >
          {video.source.map((item) => (
            <source key={item.path} src={item.path} type="video/mp4" />
          ))}
        </video>
      </div>
    </div>
  );
};
