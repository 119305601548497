import './Tabbar.sass';

import React from 'react';
import { classNames } from 'utils';
import { Badge } from '$uikit/Badge/Badge';

export function Tabbar({ children }) {
  return (
    <div className="Tabbar">
      <div className="Tabbar__cont">
        {children}
      </div>
    </div>
  );
}

export function TabbarItem({ children, label, isActive, onClick, counter = 0, badge = false }) {
  return (
    <div
      className={classNames({
        Tabbar__item: true,
        active: isActive,
      })}
      onClick={onClick}
    >
      <div className="Tabbar__icon">
        {children}
        {counter > 0 && (
          <div className="Tabbar__counter">
            <Badge count={counter} />
          </div>
        )}
        {badge && (
          <div className="Tabbar__badge" />
        )}
      </div>
      <div className="Tabbar__label">{label}</div>
    </div>
  );
}
