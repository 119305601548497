import './Checkbox.sass';

import React, { FC } from 'react';
import { CheckboxProps } from '$uikit/Checkbox/Checkbox.interface';
import { IcCheckbox24 } from '$assets';
import { classNames } from '$utils';

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { checked, children, onClick, disabled } = props;

  return (
    <div
      className={classNames({
        Checkbox: true,
        checked,
        disabled,
      })}
      onClick={onClick}
    >
      <div className="Checkbox__indicator">
        <IcCheckbox24 />
      </div>
      <div className="Checkbox__label">{children}</div>
    </div>
  );
};
