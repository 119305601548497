import { all, takeLatest, put, retry } from 'redux-saga/effects';

import { mainActions } from '$store/main/index';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { batchActions } from '$store';
import { postsActions } from '$store/posts';

function* loadMainPageDataWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.get, '/main/page');
    yield put(
      batchActions(
        mainActions.setGroups(data.groups),
        postsActions.setPosts(data.posts),
        mainActions.setMainPageData({
          groups: Object.keys(data.groups).map((item) => +item),
          posts: data.posts.map((item) => item.id),
        }),
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* mainSaga() {
  yield all([
    takeLatest(mainActions.loadMainPageData, loadMainPageDataWorker),
  ]);
}
