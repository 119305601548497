import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { profileInfoSelector } from '../store/profile';
import { ProfileInfo } from '../store/profile/interface';

export const useProfileInfo = (profileId: number): ProfileInfo => {
  return useSelector((state: RootState) =>
    profileInfoSelector(state, profileId),
  );
};
