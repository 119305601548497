import './Tabs.sass';

import React, { FC, useCallback, useMemo, useRef } from 'react';
import { TabsItemProps, TabsProps } from '$uikit/Tabs/Tabs.interface';
import { classNames, formatBigNumber } from '$utils';
import { HorizontalScroll } from '$uikit/HorizontalScroll/HorizontalScroll';

export const TabsItem: FC<TabsItemProps> = (props) => {
  const { children, selected = false, onClick = undefined, counter = 0, value } = props;

  return (
    <div
      className={classNames({
        Tabs__item: true,
        selected,
        [`_${value}`]: true,
      })}
      onClick={onClick}
    >
      {children}
      {!!counter && <span>{formatBigNumber(counter)}</span>}
    </div>
  );
};

export const Tabs: FC<TabsProps> = (props) => {
  const { children, selected, onChange, skipMargin = false } = props;
  const scrollRef = useRef<HTMLDivElement>(null);

  useMemo(() => {
    if (!scrollRef.current) {
      return;
    }

    const selectedEl = scrollRef.current.querySelector(`._${selected}`) as HTMLDivElement;
    if (!selectedEl) {
      return;
    }

    const center = selectedEl.offsetLeft - scrollRef.current.offsetWidth / 2 + selectedEl.offsetWidth / 2;
    scrollRef.current.scrollLeft = center;
  }, [selected, scrollRef.current]);

  const handleClick = useCallback((tab) => () => {
    onChange(tab);
  }, [onChange]);

  return (
    <HorizontalScroll ref={scrollRef}>
      <div className={classNames({
        Tabs: true,
        skipMargin,
      })}>
        {React.Children.map(children, (item) => {
          if (!React.isValidElement(item)) {
            return;
          }

          return React.cloneElement(item, {
            selected: item.props.value === selected,
            onClick: handleClick(item.props.value),
          });
        })}
      </div>
    </HorizontalScroll>
  );
};
