import './Feed.sass';

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRoute } from 'react-router5';

import { authSelector } from '$store/auth';
import AuthRequired from '../AuthRequired/AuthRequired';
import { CreatePostForm, Metatags, ModalLink } from 'shared/components';
import { useCurrentUserInfo, useDesktop, useTranslator } from '$hooks';
import { Button, Header, Placeholder, PullToRefresh, ScrollView, TabsItem, Tabs } from '$uikit';
import { feedActions, feedSelector } from '$store/feed';
import { Loader, Post } from '$shared/components';
import { IcSearch28, IcSuccess72, IcUserPlus28 } from '$assets';
import { ANIME_LISTS, FEED, FIND_FRIENDS, INVITE, PROFILE, RESOLVE, SEARCH, VIDEOS } from '$shared/constants/pages';
import router, { isBackNavigation } from '$navigation/router';
import { FeedNotification } from '$core/Feed/FeedNotification/FeedNotification';

export function Feed() {
  const { isLogged } = useSelector(authSelector);
  const { feed, isLoading, isLoadingMore, nextFrom, notifications } = useSelector(feedSelector);
  const user = useCurrentUserInfo();
  const dispatch = useDispatch();
  const isDesktop = useDesktop();
  const t = useTranslator();
  const { route } = useRoute();
  const activeTab = route.params?.section ?? 'following';
  const isSuggestions = activeTab === 'suggestions';
  const isBack = isBackNavigation();

  useEffect(() => {
    if (isLogged && !isBack) {
      dispatch(feedActions.loadFeed({
        section: activeTab,
      }));
    }
  }, [isSuggestions]);

  const handleTabChange = useCallback((newTab) => {
    const params: any = {};
    if (newTab !== 'following')  {
      params.section = newTab;
    }
    router.navigate(FEED, params);
    dispatch(feedActions.loadFeed({
      section: newTab,
    }));
  }, [dispatch]);

  const handleRefresh = useCallback(() => {
    dispatch(feedActions.loadFeed({
      section: activeTab,
    }));
  }, [dispatch, isSuggestions]);

  if (!isLogged) {
    return <AuthRequired />;
  }

  function renderWelcome() {
    if (isSuggestions || isLoading || activeTab !== 'following') {
      return null;
    }

    return (
      <div className="Welcome">
        <div className="Welcome__title">Добро пожаловать</div>
        <div className="Welcome__message">
          <div>
            <b>Anime.Fans</b> — новая социальная сеть для любителей аниме, здесь вы сможете завести новых друзей, создавать сообщества по интересам и смотреть аниме без рекламы.
          </div>
          <div style={{ marginTop: 12 }}>
            Социальная сеть открылась совсем недавно, поэтому зарегистировано не так много людей, приглашайте своих друзей, чем больше людей узнают о сайте, тем будет веселее!
            <div style={{ marginTop: 12, display: 'flex' }}>
              <Link routeName={INVITE} style={{ textDecoration: 'none' }}>
                <Button icon={<IcUserPlus28 width={16} height={16} />} size="small">Пригласить друзей</Button>
              </Link>
            </div>
          </div>
          <div style={{ marginTop: 24 }}>
            <b>А пока можете:</b>
            <ul>
              <li>
                <Link routeName={VIDEOS}>Смотреть аниме;</Link>
              </li>
              <li>
                <Link routeName={FIND_FRIENDS}>Найти друзей;</Link>
              </li>
              <li>
                <ModalLink name="group">Создать свое сообщество;</ModalLink>
              </li>
              <li>
                <Link routeName={SEARCH}>Подписаться на сообщества или людей;</Link>
              </li>
              <li>
                <Link routeName={ANIME_LISTS} routeParams={{ ownerId: user.id }}>Создать списки аниме</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function renderContent() {
    if (!feed.length) {
      if (isLoading) {
        return <Loader isWrapped />;
      }

      if (activeTab === 'liked') {
        return (
          <Placeholder centered title={t('feed_likes_empty_title')}>
            {t('feed_likes_empty_caption')}
          </Placeholder>
        );
      }

      return (
        <>
          {renderWelcome()}
          <Placeholder
            centered
            title={t('feed_onboarding_title')}
            actions={(
              <Link routeName={FIND_FRIENDS}>
                <Button size="small">{t('find_friends_btn')}</Button>
              </Link>
            )}
          >{t('feed_onboarding_caption')}</Placeholder>
        </>
      );
    }

    return (
      <div className="Feed__items">
        {feed.map((item, i) => {

          let cont = <></>;
          if (item.type === 'post') {
            cont = <Post key={item.id} postId={item.object} showFollowBtn={item.isSuggestion} />;
          } else {
            cont = <>Not supported {item.type}</>;
          }

          return (
            <div key={item.id}>
              {!isSuggestions && (i > 0 && item.isSuggestion && !feed[i - 1].isSuggestion) && (
                <Placeholder
                  icon={<IcSuccess72 />}
                  title={t('feed_suggestions_title')}
                >
                  {t('feed_suggestions_caption')}
                </Placeholder>
              )}
              {!isSuggestions && (i === 0 && item.isSuggestion) && (
                <>
                  {renderWelcome()}
                  <Placeholder
                    title={t('feed_onboarding_title')}
                    actions={(
                      <Link routeName={FIND_FRIENDS}>
                        <Button size="small">{t('find_friends_btn')}</Button>
                      </Link>
                    )}
                  >{t('feed_onboarding_caption')}</Placeholder>
                  <div className="Feed__separator" />
                  <Placeholder
                    icon={<IcSuccess72 />}
                    title={t('feed_suggestions_title')}
                  >
                    {t('feed_suggestions_caption')}
                  </Placeholder>
                </>
              )}
              {cont}
            </div>
          );
        })}
        {activeTab !== 'liked' && (
          <Placeholder
            title={t('feed_empty_title')}
            actions={(
              <Link routeName={SEARCH}>
                <Button size="small">{t('feed_show_peoples')}</Button>
              </Link>
            )}
          >
            {t('feed_empty_caption')}
          </Placeholder>
        )}
      </div>
    );
  }

  function renderNotifications() {
    if (!notifications.length) {
      return null;
    }

    return notifications.map((notification) => <FeedNotification key={notification.id} notification={notification} />);
  }

  return (
    <>
      <Metatags title={t('feed_title')} />
      <Header
        leftContent={(
          <Link
            className="Header__back"
            routeName={user.domain ? RESOLVE : PROFILE}
            routeParams={user.domain ? { domain: user.domain } : { id: user!.id }}
          >
            <div className="Feed__my_profile_btn" style={{ backgroundImage: `url(${user.photo})` }} />
          </Link>
        )}
        rightIcon={<IcSearch28 />}
        rightLink={SEARCH}
      >
        {isDesktop ? t('feed_title') : <div className="Feed_logo_img" />}
      </Header>
      <ScrollView
        canLoadMore={!isLoading && !isLoadingMore && !!nextFrom}
        onLoadMore={() => dispatch(feedActions.loadMore({ section: activeTab }))}
      >
        <PullToRefresh
          isRefreshing={isLoading}
          onRefresh={handleRefresh}
        >
          <div className="Feed__tabs">
            <Tabs selected={activeTab} onChange={handleTabChange} skipMargin>
              <TabsItem value="following">Подписки</TabsItem>
              <TabsItem value="suggestions">Рекомендации</TabsItem>
              <TabsItem value="liked">Понравилось</TabsItem>
            </Tabs>
          </div>
          {renderNotifications()}
          <CreatePostForm ownerId={user!.id} />
          {renderContent()}
        </PullToRefresh>
      </ScrollView>
    </>
  );
}
