import { all, retry, put, takeLatest, call, select } from 'redux-saga/effects';

import { followersActions, followersForUserSelector } from '$store/followers/index';
import { Api } from '$api';
import { LoadAction, LoadMoreAction } from '$store/followers/interface';
import { mainActions } from '$store/main';
import { RootState } from '$store/rootReducer';
import { FollowAction } from '$store/groups/interface';
import { profileActions } from '$store/profile';
import { groupsActions } from '$store/groups';
import { batchActions } from '$store';
import router from '$navigation/router';
import { toastActions } from '$store/toast';

function* loadWorker(action: LoadAction) {
  try {
    const userId = action.payload;
    const query = router.getState().params?.query ?? '';
    const resp = yield retry(Infinity, 3000, Api.post, `/followers/${userId}`, {
      query: query.trim(),
    });
    yield put(
      batchActions(
        mainActions.setUsers(resp.users),
        mainActions.setGroups(resp.groups),
        followersActions.setResult({
          userId,
          inbox: resp.inbox,
          outbox: resp.outbox,
          inboxNextFrom: resp.inboxNextFrom,
          outboxNextFrom: resp.outboxNextFrom,
        }),
        followersActions.setFollowing(resp.followed),
      ),
    );
  } catch (e) {}
}

function* loadMoreWorker(action: LoadMoreAction) {
  try {
    const { userId, tab } = action.payload;
    const { inboxNextFrom, outboxNextFrom } = yield select((state: RootState) => followersForUserSelector(state, userId));
    const nextFrom = tab === 'inbox' ? inboxNextFrom : outboxNextFrom;
    const query = router.getState().params?.query ?? '';

    const resp = yield call(Api.post, `/followers/${userId}/more`, {
      startFrom: +nextFrom,
      type: tab,
      query,
    });

    yield put(
      batchActions(
        mainActions.setUsers(resp.users),
        mainActions.setGroups(resp.groups),
        followersActions.setMoreResult({
          userId,
          tab,
          items: resp.items,
          nextFrom: resp.nextFrom,
        }),
        followersActions.setFollowing(resp.followed),
      ),
    );
  } catch (e) {}
}

function* followWorker(action: FollowAction) {
  if (action.payload > 0) {
    yield put(profileActions.follow(action.payload));
  } else {
    yield put(groupsActions.follow(-action.payload));
  }
}

function* unfollowWorker(action: FollowAction) {
  if (action.payload > 0) {
    yield put(profileActions.unfollow(action.payload));
  } else {
    yield put(groupsActions.unfollow(-action.payload));
  }
}

function* loadBirthdaysWorker() {
  try {
    const data = yield call(Api.get, '/followers/birthdays');
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        followersActions.setBirthdaysLoaded({
          userIds: Object.keys(data.users).map((item) => +item),
        }),
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
    yield put(followersActions.setBirthdaysFailed());
  }
}

export function* followersSaga() {
  yield all([
    takeLatest(followersActions.load, loadWorker),
    takeLatest(followersActions.loadMore, loadMoreWorker),
    takeLatest(followersActions.follow, followWorker),
    takeLatest(followersActions.unfollow, unfollowWorker),
    takeLatest(followersActions.loadBirthdays, loadBirthdaysWorker),
  ]);
}
