import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  CreateGroupAction,
  GroupsState,
  LoadGroupInfoAction,
  SetGroupFailed,
  SetGroupInfoAction,
  SetGroupsAction,
  FollowAction,
  UnfollowAction,
  SetFollowStatus,
  SaveGroupAction,
  UploadPhotoAction,
  SetLoadMorePostsAction,
} from '$store/groups/interface';
import { RootState } from '$store/rootReducer';
import { selectMain } from '$store/main';
import { LoadMorePostsAction } from '$store/profile/interface';

const initialState: GroupsState = {
  groups: [],
  managed: [],
  isGroupsLoading: true,
  groupsInfo: {},
};

export const { actions, reducer } = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    createGroup(_, action: CreateGroupAction) {},
    saveGroup(_, action: SaveGroupAction) {},
    loadGroups(state) {
      state.isGroupsLoading = true;
    },
    setGroups(state, action: SetGroupsAction) {
      state.isGroupsLoading = false;
      state.groups = action.payload.groups;
      state.managed = action.payload.managed;
    },
    loadGroupInfo(state, action: LoadGroupInfoAction) {
      const groupId = action.payload;

      state.groupsInfo[groupId] = {
        ...(state.groupsInfo[groupId] || {}),
        isLoading: true,
        isFailed: false,
      };
    },
    loadMorePosts(state, action: LoadMorePostsAction) {
      state.groupsInfo[action.payload].isPostsLoadingMore = true;
    },
    setLoadMorePosts(state, action: SetLoadMorePostsAction) {
      const { groupId, nextFrom } = action.payload;

      if (state.groupsInfo[groupId]) {
        state.groupsInfo[groupId].isPostsLoadingMore = false;
        state.groupsInfo[groupId].postsNextFrom = nextFrom;
      }
    },
    setGroupInfo(state, action: SetGroupInfoAction) {
      const { groupId, isFollowed, canPost, nextFrom, scheduledCount } = action.payload;
      state.groupsInfo[groupId] = {
        ...(state.groupsInfo[groupId] || {}),
        isLoading: false,
        isFailed: false,
        isFollowed,
        canPost,
        postsNextFrom: nextFrom,
        scheduledCount: scheduledCount,
      };
    },
    setGroupFailed(state, action: SetGroupFailed) {
      const { groupId, reason } = action.payload;
      state.groupsInfo[groupId] = {
        ...(state.groupsInfo[groupId] || {}),
        isFailed: true,
        isLoading: false,
        failedReason: reason,
      };
    },
    follow(_, action: FollowAction) {},
    unfollow(_, action: UnfollowAction) {},
    setFollowStatus(state, action: SetFollowStatus) {
      const { groupId, isFollowed } = action.payload;

      state.groupsInfo[groupId] = {
        ...(state.groupsInfo[groupId] || {}),
        isFollowed: isFollowed,
      };
    },
    uploadPhoto(_, action: UploadPhotoAction) {},
  },
});

export { reducer as groupsReducer, actions as groupsActions };

export const groupsSelector = (state: RootState) => state.groups;

export const groupSelector = createSelector(
  selectMain,
  (_, groupId: number) => groupId,
  (main, groupId) => {
    return main.groups[groupId];
  },
);

export const groupInfoSelector = createSelector(
  groupsSelector,
  (_, groupId: number) => groupId,
  (profile, groupId) => {
    return profile.groupsInfo[groupId];
  },
);
