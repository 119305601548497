import './UserCell.sass';

import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router5';

import { UserCellProps } from '$shared/components/UserCell/UserCell.interface';
import { useCurrentUserInfo, useGroupInfo, useUserInfo } from '$hooks';
import { gram } from '$utils';
import { GROUP, PROFILE, RESOLVE } from '$shared/constants/pages';
import { Online, Verified } from '$shared/components';
import { Button } from '$uikit';
import { IcAdd28, IcClose28, IcDone28 } from '$assets';
import { useDispatch, useSelector } from 'react-redux';
import { followersActions, followersSelector } from '$store/followers';
import { editGroupActions } from '$store/editGroup';

export const UserCell: FC<UserCellProps> = (props) => {
  const {
    objectId,
    isGroup = false,
    isFollowBtnShown= true,
    isPromote = false,
    targetId = 0,
    skipButton = false,
  } = props;
  const dispatch = useDispatch();
  const user = useUserInfo(objectId) || {};
  const group = useGroupInfo(objectId) || {};
  const curUser = useCurrentUserInfo();
  const { following } = useSelector(followersSelector);

  const isFollowed = useMemo(() => {
    if (isGroup) {
      return following[-objectId];
    } else {
      return following[objectId];
    }
  }, [following, objectId, isGroup]);

  const info = useMemo(() => {
    if (isGroup) {
      return {
        name: group.name,
        followers: group.followers,
        photo: group.photo,
        route: group.domain ? RESOLVE : GROUP,
        routeParams: group.domain ? { domain: group.domain } : { id: group.id }
      };
    } else {
      return {
        name: `${user.firstName} ${user.lastName}`,
        followers: user.followers,
        photo: user.photo,
        route: user.domain ? RESOLVE : PROFILE,
        routeParams: user.domain ? { domain: user.domain } : { id: user.id }
      };
    }
  }, [user, group, isGroup]);

  const handleClick = useCallback((e) => {
    if (e.target.closest('.UserCell__follow')) {
      e.preventDefault();

      if (isPromote) {
        dispatch(editGroupActions.promoteAdmin({
          groupId: -targetId,
          userId: objectId,
        }));
      } else {
        if (!isFollowed) {
          dispatch(followersActions.follow(isGroup ? -objectId : objectId));
        } else {
          dispatch(followersActions.unfollow(isGroup ? -objectId : objectId));
        }
      }

      return false;
    }
  }, [isFollowed, dispatch, objectId, isGroup]);

  return (
    <Link
      className="UserCell"
      routeName={info.route}
      routeParams={info.routeParams}
      onClick={handleClick}
    >
      <div
        className="UserCell__photo"
        style={{ backgroundImage: `url(${info.photo})` }}
      >
        {!isGroup && <Online userId={objectId} inPhoto />}
      </div>
      <div className="UserCell__cont">
        <div className="UserCell__name_wrap">
          <div className="UserCell__name">{info.name}</div>
          <Verified objectId={objectId} />
        </div>
        <div className="UserCell__followers">{gram(info.followers, ['подписчик', 'подписчика', 'подписчиков'])}</div>
      </div>
      {!skipButton && (isFollowBtnShown || isPromote) && (curUser.id !== objectId || isGroup) && (
        <div className="UserCell__follow">
          <Button
            icon={isPromote ? <IcDone28 /> : (isFollowed ? <IcClose28 /> : <IcAdd28 />)}
            size="small"
            mode={isFollowed ? 'secondary' : 'default'}
          />
        </div>
      )}
    </Link>
  );
};
