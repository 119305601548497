import './CellButton.sass';

import React, { FC } from 'react';
import { Link } from 'react-router5';

import { ModalLink } from '$shared/components';
import { CellButtonProps } from '$uikit/CellButton/CellButton.interface';

export const CellButton: FC<CellButtonProps> = (props) => {

  const {
    children,
    icon = null,
    onClick = null,
    modal = null,
    route = null,
    indicator = null,
    routeParams = null,
    href = null,
  } = props;

  let Component: any;
  let params: any = {};
  if (modal) {
    Component = ModalLink;
    params.name = modal;
  } else if (route) {
    Component = Link;
    params.routeName = route;
    params.routeParams = routeParams;
  } else if (href) {
    Component = 'a';
    params.href = href;
    params.target = '_blank';
  } else {
    Component = 'div';
  }

  return (
    <Component className="CellButton" onClick={onClick} {...params}>
      <div className="CellButton__in">
        <div className="CellButton__text">{children}</div>
        {indicator && (
          <div className="CellButton__indicator">
            {indicator}
          </div>
        )}
        {icon && (
          <div className="CellButton__icon">
            {icon}
          </div>
        )}
      </div>
    </Component>
  );
};
