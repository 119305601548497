import { useSelector } from 'react-redux';

import { UserModel } from 'store/models';
import { currentUserSelector, userSelector } from 'store/main';
import { RootState } from 'store/rootReducer';

export const useCurrentUserInfo = (): UserModel => {
  /* @ts-ignore */
  return useSelector(currentUserSelector);
};

export const useUserInfo = (userId: number): UserModel => {
  const userInfo = useSelector((state: RootState) =>
    userSelector(state, userId),
  );
  return userInfo as UserModel;
};
