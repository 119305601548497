import './Header.sass'

import React, { useCallback, FC, useContext } from 'react';
import { Link, useRoute } from 'react-router5';

import { IcBack28, IcClose28 } from '$assets';
import { HeaderProps } from '$uikit/Header/interface';
import { goBack, hideModal } from '$navigation/helper';
import { useDesktop } from '$hooks';
import { ModalLink } from '$shared/components';
import { ModalContext } from '$shared/context';
import { classNames } from '$utils';

export const Header: FC<HeaderProps> = (props) => {
  const {
    showBackButton = false,
    showCloseButton = false,
    children,
    leftContent = null,
    rightIcon = null,
    rightOnClick = null,
    rightLink = null,
    rightModal = null,
    routeName = null,
    routeParams = {},
    isSearch = false,
    isSecondaryBg = false,
    isTransparent = false,
  } = props;
  const { route } = useRoute();

  const handleBack = useCallback(() => {
    if (route.params?.w && false) {
      hideModal();
    } else {
      goBack();
    }
  }, [route]);
  const isDesktop = useDesktop();
  const isModal = useContext(ModalContext);

  function renderBack() {
    if (leftContent) {
      return leftContent;
    }

    if (!showBackButton && !showCloseButton) {
      return null;
    }

    return (
      <div className="Header__back" onClick={handleBack}>
        {(showCloseButton || (isDesktop && isModal)) ? <IcClose28 /> : <IcBack28 />}
      </div>
    );
  }

  function renderRightButton() {
    if (!rightIcon) {
      return null;
    }

    let Component: any = 'div';
    let params: any = {};
    if (rightLink) {
      Component = Link;
      params.routeName = rightLink;
    } else if (rightModal) {
      Component = ModalLink;
      params.name = rightModal;
    }

    return (
      <Component className="Header__right" onClick={rightOnClick} {...params}>
        {rightIcon}
      </Component>
    )
  }

  function renderTitle() {
    if (routeName) {
      return (
        <Link
          routeName={routeName}
          routeParams={routeParams}
          className="Header__title"
        >
          {children}
        </Link>
      );
    } else {
      return (
        <div className="Header__title">
          {children}
        </div>
      );
    }
  }

  const backButton = renderBack();

  return (
    <div className={classNames({
      Header: true,
      isSearch,
      hasBack: backButton,
      isSecondaryBg,
      isTransparent,
    })}>
      {backButton}
      {renderTitle()}
      {renderRightButton()}
    </div>
  );
}
