import router from '$navigation/router';
import { CHAT } from '$shared/constants/pages';
import { store } from '$store';
import { imActions } from '$store/im';

export function startVisibilityWatcher() {
  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      return;
    }

    const routerState = router.getState();
    if (routerState.name === CHAT) {
      store.dispatch(
        imActions.readHistory(+routerState.params.peerId)
      );
    }
  }, false);
}
