import { all, takeLatest, put, call } from 'redux-saga/effects';

import { Api } from '$api';
import { batchActions } from '$store';
import { mainActions } from '$store/main';
import { likesActions } from '$store/likes/index';
import { toastActions } from '$store/toast';
import { LoadLikesAction, LoadMoreLikesAction } from '$store/likes/interface';
import { followersActions } from '$store/followers';

function* loadLikesWorker(action: LoadLikesAction) {
  try {
    const { objectId, objectType } = action.payload;

    const data = yield call(Api.post, `/likes/${objectId}/${objectType}/get`, {});
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        likesActions.setLikesLoaded({
          likes: data.likes,
          nextFrom: data.nextFrom,
        }),
        followersActions.setFollowing(data.followed),
      ),
    );
  } catch (e) {
    yield put(
      batchActions(
        toastActions.setToastFail(e.message),
        likesActions.setLikesFailed(),
      ),
    );
  }
}

function* loadMoreLikesWorker(action: LoadMoreLikesAction) {
  try {
    const { objectId, objectType, nextFrom } = action.payload;

    const data = yield call(Api.post, `/likes/${objectId}/${objectType}/get`, {
      startFrom: nextFrom,
    });
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        likesActions.setLoadMoreLoaded({
          likes: data.likes,
          nextFrom: data.nextFrom,
        }),
        followersActions.setFollowing(data.followed),
      ),
    );
  } catch (e) {
    yield put(likesActions.setLoadMoreFailed());
  }
}

export function* likesSaga() {
  yield all([
    takeLatest(likesActions.loadLikes, loadLikesWorker),
    takeLatest(likesActions.loadMoreLikes, loadMoreLikesWorker),
  ]);
}
