import './Search.sass';

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRoute, useRouter } from 'react-router5';
import ReactSelect from 'react-select';

import { SearchProps } from '$core/Search/Search.interface';
import {
  Button,
  Form,
  FormItem,
  Header,
  InlineHeader,
  Placeholder, ScrollView,
  SearchInput,
  Tabs,
  TabsItem,
} from '$uikit';
import { IcChevron16, IcDropdown16, IcFilters28 } from '$assets';
import { searchActions, searchSelector } from '$store/search';
import { UserCell } from '$shared/components/UserCell/UserCell';
import { Loader, Metatags } from '$shared/components';
import { useDesktop, useTranslator } from '$hooks';
import { SEARCH } from '$shared/constants/pages';
import { SearchResultModel } from '$store/models';
import { promiseCities } from '../../services/selectDataFetcher';
import { isBackNavigation } from '$navigation/router';

export const Search: FC<SearchProps> = () => {
  const dispatch = useDispatch();
  const { query, result, isLoading, nextFrom, isLoadingMore } = useSelector(searchSelector);
  const isDesktop = useDesktop();
  const router = useRouter();
  const { route } = useRoute();
  const activeTab = route.params.tab || (query.trim().length > 0 ? 'all' : 'explore');
  const t = useTranslator();
  const SortOptions = useMemo(() => {
    return [
      { value: 'popular', label: t('search_sort_popular') },
      { value: 'new', label: t('search_sort_new') },
      { value: 'online', label: t('search_sort_online') }
    ];
  }, [t]);

  const [isFiltersShown, setFiltersShown] = useState(false);
  const [sort, setSort] = useState(SortOptions[0]);
  const [city, setCity] = useState<any>(null);
  const searchTimer = useRef<any | null>(null);
  const [cities, setCities] = useState<any[]>([]);
  const [isCitiesLoading, setCitiesLoading] = useState(false);
  const isBack = isBackNavigation();

  useEffect(() => {
    if (activeTab === 'peoples') {
      handleCitiesInputChange('', { action: 'input-change' }, true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!isBack) {
      dispatch(searchActions.search({ query, isFast: true, tab: activeTab, sort: sort.value, city: city?.value ?? 0 }));
    }
  }, [sort, city, activeTab]);

  const handleSearchChange = useCallback((query) => {
    dispatch(searchActions.search({ query, isFast: query.length === 0, tab: activeTab, sort: sort.value, city: city?.value ?? 0 }));
  }, [dispatch, activeTab]);

  const handleTabChange = useCallback((newTab) => {
    const params: any = {};
    if (newTab !== 'all') {
      params.tab = newTab;
    }
    router.navigate(SEARCH, params, { replace: true });
    dispatch(searchActions.search({ query, isFast: true, tab: newTab, sort: sort.value, city: city?.value ?? 0 }));
  }, [router, query, sort, city]);

  const exploreData = useMemo(() => {
    const peoples: SearchResultModel[] = [];
    const groups: SearchResultModel[] = [];

    for (let item of result) {
      if (item.type === 'group') {
        groups.push(item);
      } else {
        peoples.push(item);
      }
    }

    return {
      peoples: peoples.slice(0, 5),
      groups: groups.slice(0, 5),
    }
  }, [activeTab, result]);

  const handleCitiesInputChange = useCallback((query, { action }, isFast = false) => {
    if (action !== 'input-change') {
      return;
    }

    searchTimer.current && clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setCitiesLoading(true);
      promiseCities(query, 0).then((options) => {
        setCities(options);
        setCitiesLoading(false);
      });
    }, isFast ? 0 : 1000);
  }, [setCitiesLoading, promiseCities, searchTimer]);

  const handleFiltersToggle = useCallback(() => {
    setFiltersShown(!isFiltersShown);
  }, [isFiltersShown, setFiltersShown]);

  const handleSortChange = useCallback((value) => {
    setSort(value);
  }, [setSort]);

  const handleCityChange = useCallback((value) => {
    setCity(value);
  }, [setCity]);

  function renderFilters() {
    if (!isFiltersShown || activeTab !== 'peoples') {
      return null;
    }

    return (
      <div className="Anime__filters">
        <Form>
          <FormItem title={t('search_filters_sort')}>
            <ReactSelect
              classNamePrefix="Select"
              placeholder={t('search_filters_select_sort')}
              menuPlacement="bottom"
              isSearchable={false}
              value={sort}
              onChange={handleSortChange}
              options={SortOptions}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
          <FormItem title={t('search_filters_city')}>
            <ReactSelect
              classNamePrefix="Select"
              placeholder={t('search_filters_select_city')}
              isSearchable
              isClearable
              value={city}
              onChange={handleCityChange}
              options={cities}
              onInputChange={handleCitiesInputChange}
              isLoading={isCitiesLoading}
              loadingMessage={() => t('loading')}
              noOptionsMessage={() => t('reg_select_empty')}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
        </Form>
      </div>
    );
  }

  function renderContent() {
    if (!result.length) {
      if (isLoading) {
        return <Loader isWrapped />;
      }

      return (
        <Placeholder>{t('search_empty')}</Placeholder>
      );
    }

    if (activeTab === 'explore') {
      return (
        <>
          <InlineHeader>{t('search_section_peoples')}</InlineHeader>
          {exploreData.peoples.map((item) => (
            <UserCell
              key={`${item.type}_${item.objectId}`}
              objectId={item.objectId}
              isFollowed={item.isFollowed}
            />
          ))}
          <Link className="Search__more_btn" routeName={SEARCH} routeParams={{ tab: 'peoples' }} routeOptions={{ replace: true }}>
            {t('show_more')}
            <IcChevron16 />
          </Link>
          <InlineHeader>{t('search_section_groups')}</InlineHeader>
          {exploreData.groups.map((item) => (
            <UserCell
              key={`${item.type}_${item.objectId}`}
              objectId={item.objectId}
              isFollowed={item.isFollowed}
              isGroup
            />
          ))}
          <Link className="Search__more_btn" routeName={SEARCH} routeParams={{ tab: 'groups' }} routeOptions={{ replace: true }}>
            {t('show_more')}
            <IcChevron16 />
          </Link>
        </>
      );
    }

    return result.map((item) => (
      <UserCell
        key={`${item.type}_${item.objectId}`}
        objectId={item.objectId}
        isFollowed={item.isFollowed}
        isGroup={item.type === 'group'}
      />
    ));
  }

  function renderPlaceholder() {
    if (activeTab === 'peoples') {
      return t('search_peoples_placeholder');
    } else if (activeTab === 'groups') {
      return t('search_groups_placeholder');
    } else {
      return t('search_all_placeholder');
    }
  }

  return (
    <>
      <Metatags
        title={t('search_meta_title')}
        description={t('search_meta_description')}
      />
      <Header showBackButton={!isDesktop} isSearch>
        <SearchInput
          placeholder={renderPlaceholder()}
          query={query}
          onChange={handleSearchChange}
          rightButtons={activeTab === 'peoples' && (
            <Button
              icon={<IcFilters28 />}
              mode={isFiltersShown ? 'light' : 'secondary'}
              onClick={handleFiltersToggle}
            />
          )}
        />
      </Header>
      <ScrollView
        onLoadMore={() => dispatch(searchActions.loadMore({ tab: activeTab, sort: sort.value, city: city?.value ?? 0 }))}
        canLoadMore={!isLoading && !!nextFrom && !isLoadingMore && activeTab !== 'explore'}
      >
        <div className="Search">
          <div className="Search__tabs">
            <Tabs selected={activeTab === 'explore' ? 'all' : activeTab} onChange={handleTabChange}>
              <TabsItem value="all">{t('search_tab_all')}</TabsItem>
              <TabsItem value="peoples">{t('search_tab_peoples')}</TabsItem>
              <TabsItem value="groups">{t('search_tab_groups')}</TabsItem>
            </Tabs>
          </div>
          {renderFilters()}
          {renderContent()}
        </div>
      </ScrollView>
    </>
  );
};
