import './CreatePostForm.sass';

import React, { useMemo } from 'react';
import { Input } from 'uikit';
import { useCurrentUserInfo, useGroupInfo } from '$hooks';
import { ModalLink } from '$shared/components';

export function CreatePostForm({ ownerId }) {
  const user = useCurrentUserInfo();
  const group = useGroupInfo(-ownerId);

  const info = useMemo(() => {
    if (ownerId < 0) {
      return {
        placeholder: 'Новая запись',
        photo: group.photo,
        route: `new_post${ownerId}`,
      };
    } else {
      return {
        placeholder: 'Что у вас нового?',
        photo: user.photo,
        route: 'post',
      };
    }
  }, [user, group, ownerId]);

  return (
    <ModalLink name={info.route}>
      <div className="CreatePostForm">
        <div
          className="CreatePostForm__photo"
          style={{ backgroundImage: `url(${info.photo})` }}
        />
        <Input placeholder={info.placeholder} />
      </div>
    </ModalLink>
  );
}
