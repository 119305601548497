import './Profile.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRoute, Link } from 'react-router5';
import { useDispatch, useSelector } from 'react-redux';
import copyToClipboard from 'copy-text-to-clipboard';

import { profileActions, profileSelector } from '../../store/profile';
import { useUserInfo, useProfileInfo, usePosts, useCurrentUserInfo, useLoadMore, useDesktop } from 'hooks';
import { CreatePostForm, DownloadAppBlock, Metatags, RatingBlock, Verified } from '$shared/components';
import { ActionSheet, Button, CellButton, Header, Placeholder, PullToRefresh, ScrollView } from '$uikit';
import { format, formatBigNumber, genderText, gram, parse } from '$utils';
import { IcAdd28, IcChevron16, IcMessage28, IcDone28, IcStatusSending16, IcCopy28, IcList28 } from '$assets';
import { Online, Loader, Post, ModalLink } from '$shared/components';
import { ActionSheetPropsOption } from '$uikit/ActionSheet/ActionSheet.interface';
import { ANIME_LISTS, CHAT } from '$shared/constants/pages';
import { ProfileProps } from './Profile.interface';
import { toastActions } from '$store/toast';

export const Profile: FC<ProfileProps> = (props) => {
  const dispatch = useDispatch();
  const { route } = useRoute();
  const profileId = props.profileId ? props.profileId : +route.params.id;
  const posts = usePosts(String(profileId));
  const curUser = useCurrentUserInfo();
  const profileInfo = useProfileInfo(profileId);
  const isMyProfile = profileId === curUser!.id;
  const isDesktop = useDesktop();
  const [isPhotoActionsShown, setPhotoActionsShown] = useState(false);

  const {
    isLoading,
  } = useSelector(profileSelector);

  useEffect(() => {
    dispatch(profileActions.loadProfile(profileId));
  }, [profileId]);

  const user = useUserInfo(profileId);

  const handleFollow = useCallback(() => {
    dispatch(profileActions.follow(profileId));
  }, [dispatch, profileId]);

  const handleUnfollow = useCallback(() => {
    dispatch(profileActions.unfollow(profileId));
  }, [dispatch, profileId]);

  const info = useMemo(() => {
    let items: { label: string, value: string }[] = [];

    if (!user) {
      return items;
    }

    if (user.bDate) {
      let matches = user.bDate.match(/(\d+).(\d+).(\d+)/) || [];
      let dateStr;
      if (+matches[1] > 0 && +matches[2] > 0 && +matches[3] > 0) {
        const date = parse(user.bDate, 'dd.MM.yyyy', new Date());
        dateStr = format(date, 'dd MMMM yyyy');
      } else if (+matches[1] > 0 && +matches[2] > 0) {
        const date = parse(`${matches[1]}.${matches[2]}`, 'dd.MM', new Date());
        dateStr = format(date, 'dd MMMM');
      } else {
        dateStr = '';
      }

      if (dateStr) {
        items.push({
          label: 'Дата рождения',
          value: dateStr,
        });
      }
    }

    if (user.countryName) {
      items.push({
        label: 'Страна',
        value: user.countryName,
      });
    }

    if (user.cityName) {
      items.push({
        label: 'Город',
        value: user.cityName,
      });
    }

    return items;
  }, [user]);

  const handlePhotoSelect = useCallback(({ target }) => {
    setPhotoActionsShown(false);
    dispatch(profileActions.uploadPhoto(target.files[0] ));
  }, [setPhotoActionsShown]);

  const photoActions = useMemo(() => {
    const items: ActionSheetPropsOption[]  = [
      {
        label: 'Изменить фото',
        value: 'changePhoto',
        disabled: true,
        children: (
          <input
            type="file"
            className="Profile__upload_ava_input"
            onChange={handlePhotoSelect}
          />
        )
      },
    ];

    return items;
  }, [handlePhotoSelect]);

  const handleCopyLink = useCallback(() => {
    copyToClipboard(`https://anime.fans/${user.domain ? user.domain : `id${profileId}`}`);
    dispatch(toastActions.setToastSuccess('Ссылка скопирована!'));
  }, [profileId, dispatch]);

  const handlePhotoActions = useCallback(() => {
    setPhotoActionsShown(false);
  }, [setPhotoActionsShown]);

  const handlePhotoClick = useCallback(() => {
    if (isMyProfile) {
      setPhotoActionsShown(true);
    }
  }, [isMyProfile, setPhotoActionsShown]);

  const metaDescription = useMemo(() => {
    const result: string[] = [];

    if (!user) {
      return '';
    }

    if (user.status) {
      result.push(`Статус: ${user.status}`);
    }

    if (user.countryName) {
      result.push(`Страна: ${user.countryName}`);
    }

    if (user.cityName) {
      result.push(`Город: ${user.cityName}`);
    }

    if (user.followers > 0) {
      result.push(`Подписчики: ${user.followers}`);
    }

    if (profileInfo.postsCount > 0) {
      result.push(`Записи: ${profileInfo.postsCount}`);
    }

    if (user.followed > 0) {
      result.push(`${genderText(user.sex, ['Подписан', 'Подписана'])}: ${user.followed}`);
    }

    return result.filter(item => item.length > 0).join('. ');
  }, [user, profileInfo]);

  const handleRefresh = useCallback(() => {
    dispatch(profileActions.loadProfile(profileId));
  }, [dispatch, profileId]);

  if (isLoading && profileInfo.isEmpty) {
    return (
      <Loader isWrapped />
    );
  }

  function renderPosts() {
    if (posts.length === 0) {
      return <Placeholder>Нет записей</Placeholder>;
    }

    return (
      <div>
        {posts.map((post) => <Post key={post} postId={post} />)}
      </div>
    );
  }

  function renderStatus() {
    if (isMyProfile) {
      return (
        <ModalLink
          className="Profile__common_info__status"
          name="status"
        >
          <IcMessage28 className="Profile__common_info__status__icon" />
          {user.status || 'Изменить статус'}
          <IcChevron16 className="Profile__common_info__status__chevron" />
        </ModalLink>
      );
    }

    if (!user.status) {
      return null;
    }

    return (
      <div className="Profile__common_info__status">
        <IcMessage28 className="Profile__common_info__status__icon" />
        {user.status}
      </div>
    );
  }

  function renderContent() {
    return (
      <div className="Profile">
        <div className="Profile__info_block">
          <div className="Profile__common_info">
            <div
              className="Profile__common_info__photo"
              style={{ backgroundImage: `url(${user.photo})` }}
              onClick={handlePhotoClick}
            />
            <div className="Profile__common_info__cont">
              <div className="Profile__common_info__name_wrap">
                <div className="Profile__common_info__name">
                  {user.firstName} {user.lastName}
                </div>
                <Verified objectId={profileId} large />
              </div>
              <Online
                userId={profileId}
                extended
              />
              {renderStatus()}
            </div>
          </div>
          <div className="Profile__info">
            {info.map((item) => (
              <div className="Profile__info__item" key={item.label}>
                <div className="Profile__info__item__label">{item.label}:</div>
                <div className="Profile__info__item__value">{item.value}</div>
              </div>
            ))}
          </div>
          {!isMyProfile && <div className="Profile__follow_wrap">
            {profileInfo.isFollowFromMe ? (
              <>
                <Button
                  icon={<IcDone28 />}
                  mode="secondary"
                  onClick={handleUnfollow}
                >Вы подписаны</Button>
                <Link routeName={CHAT} routeParams={{ peerId: profileId }}>
                  <Button style={{ marginTop: 12 }}>
                    Написать сообщение
                  </Button>
                </Link>
              </>
            ) : (
              <Button
                icon={<IcAdd28 />}
                onClick={handleFollow}
              >{profileInfo.isFollowToMe ? 'Подписаться в ответ' : 'Подписаться'}</Button>
            )}
          </div>}
          <div className="Profile__counters">
            <div className="Profile__counters__item">
              <div className="Profile__counters__item_count">{formatBigNumber(profileInfo.postsCount)}</div>
              <div className="Profile__counters__item_label">{gram(profileInfo.postsCount || 0, ['публикация', 'публикации', 'публикаций'], true)}</div>
            </div>
            <ModalLink name={`followers${profileId}`} className="Profile__counters__item">
              <div className="Profile__counters__item_count">{formatBigNumber(user.followers)}</div>
              <div className="Profile__counters__item_label">{gram(user.followers, ['подписчик', 'подписчика', 'подписчиков'], true)}</div>
            </ModalLink>
            <ModalLink name={`followed${profileId}`} className="Profile__counters__item">
              <div className="Profile__counters__item_count">{formatBigNumber(user.followed)}</div>
              <div className="Profile__counters__item_label">{gram(user.followed, ['подписка', 'подписки', 'подписок'], true)}</div>
            </ModalLink>
          </div>
          <div className="Profile__buttons">
            {(profileInfo.animeListsCount > 0 || isMyProfile) && (
              <CellButton icon={<IcList28 />} route={ANIME_LISTS} routeParams={{ ownerId: profileId }}>Списки аниме <span>{profileInfo.animeListsCount}</span></CellButton>
            )}
          </div>
        </div>
        {isMyProfile && <CreatePostForm ownerId={profileId} />}
        {profileInfo.scheduledCount > 0 && (
          <ModalLink className="Group__scheduled_posts" name={`scheduled${profileId}`}>
            <IcStatusSending16 width={28} height={28} />
            {gram(profileInfo.scheduledCount, ['запланирована', 'запланированы', 'запланировано'])}
          </ModalLink>
        )}
        {renderPosts()}
      </div>
    )
  }

  return (
    <>
      <Metatags
        title={`${user?.firstName ?? ''} ${user?.lastName ?? ''} (@${(user?.domain ?? '') || 'id' + user?.id}) | Anime.Fans`}
        description={metaDescription}
        image={user?.photo ?? ''}
        canonical={`https://anime.fans/${user?.domain ? user.domain : `id${user?.id}`}`}
      />
      {!isDesktop && <Header showBackButton isSecondaryBg isTransparent />}
      <ScrollView
        onLoadMore={() => dispatch(profileActions.loadMorePosts(profileId))}
        canLoadMore={!isLoading && !profileInfo.isPostsLoadingMore && !!profileInfo.postsNextFrom}
      >
        <div className="Header__helper isSecondaryBg" />
        <PullToRefresh
          isRefreshing={isLoading}
          onRefresh={handleRefresh}
        >
          <DownloadAppBlock />
          {renderContent()}
        </PullToRefresh>
      </ScrollView>
      <ActionSheet
        isOpened={isPhotoActionsShown}
        options={photoActions}
        onChange={handlePhotoActions}
      />
    </>
  );
};
