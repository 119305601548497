import './History.sass';

import React, { FC, useMemo } from 'react';
import { HistoryProps } from '$core/Chat/History/History.interface';
import { Placeholder, ScrollView } from '$uikit';
import { useSelector } from 'react-redux';
import { RootState } from '$store/rootReducer';
import { imHistorySelector } from '$store/im';
import { useChatInfo, useDialog, useTranslator } from '$hooks';
import { Loader } from '$shared/components';
import { Message } from '$core/Chat/Message/Message';
import { format, formatDate } from '$utils';

type PreparedHistoryItem = { type: string; object: any; key: any };

const isNeedGroup = (message: PreparedHistoryItem, otherMessage: PreparedHistoryItem) => {
  let isGrouping = false;

  if (message.type !== otherMessage.type || message.type !== 'message') {
    return false;
  }

  if (otherMessage.object.isInbox === message.object.isInbox) {
    isGrouping = true;
  }

  return isGrouping;
};

export const History: FC<HistoryProps> = ({ peerId }) => {
  const historyRaw = useSelector((state: RootState) => imHistorySelector(state, peerId));
  const info = useChatInfo(peerId);
  const t = useTranslator();

  const history = useMemo(() => {
    let historyCopy = Object.values(historyRaw);
    historyCopy.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);

    const result: PreparedHistoryItem[] = [];

    let lastDate = '';
    for (let message of historyCopy) {
      const createdAt = message.createdAt * 1000;
      const date = format(createdAt, 'dd MMMM yyyy');
      if (date !== lastDate) {
        result.push({
          type: 'date',
          key: date,
          object: createdAt,
        });
        lastDate = date;
      }

      result.push({
        type: 'message',
        key: message.id,
        object: message,
      });
    }

    return result;
  }, [historyRaw]);

  function renderContent() {
    if (info.isLoading && !history.length) {
      return <Loader isWrapped />;
    }

    if (!history.length) {
      return (
        <Placeholder centered>
          {t('history_empty')}
        </Placeholder>
      );
    }

    return history.map((item, index) => {
      const isGroupingBefore = index > 0 && isNeedGroup(item, history[index - 1]);
      const isGroupingAfter =
        index < history.length - 1 && isNeedGroup(item, history[index + 1]);

      if (item.type === 'date') {
        return (
          <div className="History__date" key={item.key}>
            {formatDate(new Date(item.object), t)}
          </div>
        );
      } else {
        return (
          <Message
            key={item.key}
            message={item.object}
            peerId={peerId}
            isGroupingBefore={isGroupingBefore}
            isGroupingAfter={isGroupingAfter}
          />
        );
      }
    });
  }

  return (
    <ScrollView id="chat_history">
      <div className="History">
        {renderContent()}
      </div>
    </ScrollView>
  );
};
