import './CreateAnimeList.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AnimeItemProps, CreateAnimeListProps } from './CreateAnimeList.interface';
import { BottomBar, Button, Checkbox, Form, FormItem, Header, Input, Placeholder, ScrollView } from '$uikit';
import { IcAdd28, IcClose16, IcClose28 } from '$assets';
import { Api } from '$api';
import { mainActions } from '$store/main';
import { AnimeModel } from '$store/models';
import { Loader } from '$shared/components';
import { useAnime } from '$hooks';
import { animeActions } from '$store/anime';
import { toastActions } from '$store/toast';

const AnimeItem: FC<AnimeItemProps> = ({ animeId, isAdded, btnClick }) => {
  const anime = useAnime(animeId);

  return (
    <div className="CreateAnimeList__item">
      <div className="CreateAnimeList__item__name">{anime.title}</div>
      <div className="CreateAnimeList__item__btn">
        <Button
          onClick={btnClick}
          size="small"
          icon={isAdded ? <IcClose28 /> : <IcAdd28 />}
          mode={isAdded ? 'secondary' : 'default'}
        />
      </div>
    </div>
  );
};

export const CreateAnimeList: FC<CreateAnimeListProps> = ({ listId = 0 }) => {
  const isEdit = listId > 0;
  const dispatch = useDispatch();

  const [isLoaded, setLoaded] = useState(false);
  const [isFailed, setFailed] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPrivate, setPrivate] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [animeIds, setAnimeIds] = useState<number[]>([]);
  const [foundAnime, setFoundAnime] = useState<number[]>([]);

  const preparedQuery = useMemo(() => {
    return query.trim();
  }, [query]);

  const handleLoadInfo = useCallback(() => {
    setFailed(false);
    Api.post(`/anime/list/${listId}`)
      .then((resp) => {
        setLoaded(true);

        const data = resp as any;
        dispatch(mainActions.setAnimes(data.anime));
        setTitle(data.list.title);
        setDescription(data.list.description);
        setPrivate(data.list.privacy === 1);
        setAnimeIds(data.list.animeIds);
      })
      .catch(() => {
        setFailed(true);
      });
  }, [listId]);

  useEffect(() => {
    if (listId > 0) {
      handleLoadInfo();
    }
  }, [listId, handleLoadInfo]);

  useEffect(() => {
    let timer: any = 0;

    const curPrepared = preparedQuery;
    if (curPrepared.length > 0) {
      setSearching(true);

      timer = setTimeout(() => {
        Api.post('/anime/search', {
          query: curPrepared,
          showMeta: true,
        })
          .then((resp) => {
            if (query !== curPrepared) {
              return;
            }
            setSearching(false);

            const anime: { [index: number]: AnimeModel } = {};
            const ids: number[] = [];

            // @ts-ignore
            for (let item of resp.list) {
              if (ids.length >= 8) {
                break;
              }
              anime[item.id] = item;
              ids.push(item.id);
            }

            dispatch(mainActions.setAnimes(anime))
            setFoundAnime(ids);
          });
      }, 600);
    }

    return () => clearTimeout(timer);
  }, [query]);

  const handleTitleChange = useCallback(({ target }) => {
    setTitle(target.value);
  }, []);

  const handleDescriptionChange = useCallback(({ target }) => {
    setDescription(target.value);
  }, []);

  const handleTogglePrivate = useCallback(() => {
    setPrivate(!isPrivate);
  }, [isPrivate]);

  const handleSearchChange = useCallback(({ target }) => {
    setQuery(target.value);
    //dispatch(animeActions.search(target.value));
  }, [dispatch]);

  const handleCancelSearch = useCallback(() => {
    setQuery('');
  }, []);

  const handleClick = useCallback((animeId: number) => () => {
    const index = animeIds.indexOf(animeId);
    const idsCopy = [...animeIds];
    if (animeIds.indexOf(animeId) > -1) {
      idsCopy.splice(index, 1);
    } else {
      idsCopy.push(animeId);
    }
    setAnimeIds(idsCopy);
  }, [animeIds]);

  const handleSave = useCallback(() => {
    if (title.trim().length < 5) {
      dispatch(toastActions.setToastFail('Введите название не короче 5 символов'));
    }

    if (!animeIds.length) {
      dispatch(toastActions.setToastFail('Добавьте аниме в список'));
    }

    dispatch(animeActions.saveAnimeList({
      listId,
      title: title.trim(),
      description: description.trim(),
      privacy: isPrivate ? 1 : 0,
      animeIds,
    }))
  }, [dispatch, listId, title, description, isPrivate, animeIds]);

  function renderAnime() {
    if (preparedQuery.length > 0) {
      if (isSearching) {
        return  <Loader isWrapped />;
      }

      if (!foundAnime.length) {
        return (
          <Placeholder>
            По вашему запросу ничего не найдено
          </Placeholder>
        );
      }

      return foundAnime.map((animeId) => (
        <AnimeItem
          animeId={animeId}
          isAdded={animeIds.indexOf(animeId) > -1}
          btnClick={handleClick(animeId)}
        />
      ));
    }

    if (!animeIds.length) {
      return (
        <Placeholder>
          Начните вводить название аниме для поиска. Вы можете добавить до 100 аниме.
        </Placeholder>
      );
    }

    return animeIds.map((animeId) => (
      <AnimeItem
        animeId={animeId}
        isAdded={true}
        btnClick={handleClick(animeId)}
      />
    ));
  }

  function renderContent() {
    if (isEdit) {
      if (isFailed) {
        return (
          <Placeholder
            title="Произошла ошибка"
            actions={<Button size="small" onClick={handleLoadInfo}>Попробовать снова</Button>}
            centered
          >
            Попробуйте еще раз позже
          </Placeholder>
        );
      }

      if (!isLoaded) {
        return <Loader isWrapped />;
      }
    }

    return (
      <>
        <Form>
          <FormItem title="Название">
            <Input
              placeholder="Введите название"
              value={title}
              onChange={handleTitleChange}
            />
          </FormItem>
          <FormItem title="Описание">
            <Input
              placeholder="Введите описание"
              multiline
              maxLength={500}
              value={description}
              onChange={handleDescriptionChange}
            />
          </FormItem>
          <FormItem title="Приватность" description="Сделайте список приватным, чтобы другие пользователи не видели его.">
            <Checkbox checked={isPrivate} onClick={handleTogglePrivate}>Сделать приватным</Checkbox>
          </FormItem>
          <FormItem title="Аниме">
            <div className="Anime__search">
              <div className="Anime__search__input">
                <Input
                  placeholder="Поиск аниме"
                  onChange={handleSearchChange}
                  value={query}
                />
                {query.trim().length > 0 && (
                  <div className="Anime__search__cancel" onClick={handleCancelSearch}>
                    <IcClose16 />
                  </div>
                )}
              </div>
            </div>
            <div className="CreateAnimeList__search">
              {renderAnime()}
            </div>
          </FormItem>
        </Form>
        <BottomBar fixed>
          <Button stretched onClick={handleSave}>Сохранить</Button>
        </BottomBar>
      </>
    );
  }

  return (
    <>
      <Header showCloseButton>
        {isEdit ? 'Ред. список' : 'Новый список'}
      </Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
