import './Button.sass';

import React, { FC } from 'react';

import * as utils from 'utils';
import { ButtonProps } from './Button.interface';

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    mode = 'default',
    disabled = false,
    icon = null,
    onClick,
    onTouchStart,
    size = 'normal',
    href = null,
    target = '',
    stretched = false,
    style = {},
    className = '',
  } = props;

  const Tag = href ? 'a' : 'div';

  const params = {
    className: utils.classNames({
      Button: true,
      disabled,
      [mode]: true,
      [size]: true,
      stretched,
      [className]: !!className,
    }),
    onClick,
    onTouchStart,
    style,
  };

  if (href) {
    /* @ts-ignore */
    params.href = href;
    /* @ts-ignore */
    params.target = target;
  }

  return (
    /* @ts-ignore */
    <Tag {...params}>
      {!!icon && <div className="Button__icon">{icon}</div>}
      {!!children && <div className="Button__text">
        {children}
      </div>}
    </Tag>
  )
}
