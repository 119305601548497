import './AuthRequired.sass';

import React from 'react';

import { Auth } from '$shared/components';

export default function AuthRequired() {
  return (
    <div className="AuthRequired">
      <div className="AuthRequired__title">Необходима авторизация</div>
      <div className="AuthRequired__caption">Для просмотра данного раздела войдите на сайт</div>
      <div className="AuthRequired__form">
        <Auth />
      </div>
    </div>
  );
}
