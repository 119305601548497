import './ViewComment.sass';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header, ScrollView } from 'uikit';
import { usePost } from 'hooks';
import { postsActions, postsSelector } from 'store/posts';
import { Comment, Loader } from '$shared/components';

export function ViewComment({ commentId }) {
  const dispatch = useDispatch();
  const comment = usePost(commentId);
  const { isPostLoading } = useSelector(postsSelector);

  useEffect(() => {
    dispatch(postsActions.loadPost(commentId))
  }, [dispatch]);

  function renderContent() {
    if (isPostLoading[commentId] && !comment || !comment) {
      return (
        <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Loader />
        </div>
      )
    }

    return (
      <Comment commentId={commentId} isFullPage rootPostId={comment.parentId} />
    );
  }

  return (
    <div className="ViewPost">
      <Header showBackButton>Комментарий</Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </div>
  );
}
