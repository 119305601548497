import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { editGroupActions, editGroupSelector } from '$store/editGroup/index';
import { Api } from '$api';
import { LoadAdminsAction, PromoteAdminAction, RemoveAdminAction } from '$store/editGroup/interface';
import { batchActions } from '$store';
import { mainActions } from '$store/main';
import { toastActions } from '$store/toast';
import { goBack } from '$navigation/helper';

function* loadAdminsWorker(action: LoadAdminsAction) {
  try {
    const groupId = action.payload;
    const data = yield call(Api.get, `/group/${groupId}/admins`);

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        editGroupActions.loadAdminsDone(data.admins),
      ),
    );
  } catch (e) {
    yield put(
      batchActions(
        editGroupActions.loadAdminsFailed(),
        toastActions.setToastFail(e.message),
      ),
    );
  }
}

function* promoteAdminWorker(action: PromoteAdminAction) {
  try {
    const { groupId, userId } = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/group/${groupId}/add_admin`, {
      userId,
    });
    yield put(toastActions.setToastSuccess());
    yield call(goBack);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* removeAdminWorker(action: RemoveAdminAction) {
  try {
    const { groupId, userId } = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/group/${groupId}/del_admin`, {
      userId,
    });
    yield put(toastActions.setToastSuccess());

    const { admins } = yield select(editGroupSelector);
    const adminsCopy = [...admins];
    for (let i = 0; i < adminsCopy.length; i++) {
      if (adminsCopy[i].userId === userId) {
        adminsCopy.splice(i, 1);
        break;
      }
    }

    yield put(editGroupActions.setAdmins(adminsCopy));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* editGroupSaga() {
  yield all([
    takeLatest(editGroupActions.loadAdmins, loadAdminsWorker),
    takeLatest(editGroupActions.promoteAdmin, promoteAdminWorker),
    takeLatest(editGroupActions.removeAdmin, removeAdminWorker),
  ]);
}
