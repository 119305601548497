import { store } from '$store';
import { mainActions } from '$store/main';
import { Api } from '$api';
import { getDeviceId } from '$utils';
import router from '$navigation/router';
import { CHAT, PROFILE } from '$shared/constants/pages';

export function sendNativeEvent(action: string, body: any = {}) {
  // @ts-ignore
  window.ReactNativeWebView.postMessage(JSON.stringify({
    action,
    body,
  }));
}

export function listenNativeEvents() {

  // window for ios
  document.addEventListener('message', (e) => {
    try {
      // @ts-ignore
      const data = JSON.parse(e.data);

      switch (data.action) {
        case 'push_notification': {
          //
          break;
        }

        case 'tap_push_notification': {
          const pushData = data.body.notification.request.content.data;
          if (pushData.type === 'follow') {
            router.navigate(PROFILE, { id: +pushData.userId });
          } else if (pushData.type === 'like') {
            router.navigate(PROFILE, { id: +pushData.userId });
          } else if (pushData.type === 'message') {
            router.navigate(CHAT, { peerId: +pushData.peerId });
          }
          break;
        }

        case 'push_token': {
          const token = data.body;
          const device = getDeviceId();
          Api.post('/account/push_token', {
            device,
            token,
            platform: 'android',
          })
            .catch((err) => console.log('err', err));

          store.dispatch(mainActions.setNotifyEnabled(true));
          break;
        }
      }
    } catch (e) {}
  }, false);
}
