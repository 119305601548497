import './Rating.sass';

import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CellButton, Form, FormItem, FormItemColumn, Header, InlineHeader, ScrollView } from '$uikit';
import { RatingBlock } from '$shared/components';
import { useCurrentUserInfo } from '$hooks';
import { authSelector } from '$store/auth';
import AuthRequired from '$core/AuthRequired/AuthRequired';
import { birthdayToAge } from '$utils';
import { INVITE, PROFILE } from '$shared/constants/pages';

export const Rating: FC = () => {
  const user = useCurrentUserInfo();
  const { isLogged } = useSelector(authSelector);

  if (!isLogged) {
    return (
      <AuthRequired />
    );
  }

  const hasBirthday = useMemo(() => {
    return birthdayToAge(user.bDate) > 0;
  }, [user.bDate]);

  return (
    <>
      <Header showBackButton>Рейтинг</Header>
      <ScrollView>
        <div className="Rating__info">
          <div className="Rating__description">
            <b>Рейтинг</b> — социальный показатель, который помогает нам определить порядок участников при поиске и в списках подписчиков. Он зависит от таких параметров, как заполненность страницы и количества подписчиков.
          </div>
          <div className="Rating__block_wrap">
            <RatingBlock userId={user.id} isMyLabel />
          </div>
        </div>
        <InlineHeader>Как получить?</InlineHeader>
        <div className="Rating__give_buttons">
          <CellButton indicator="+25" route={INVITE}>Пригласите друга</CellButton>
          {!user.photo && <CellButton indicator="+15" route={PROFILE} routeParams={{ id: user.id }}>Загрузите фото</CellButton>}
          {!user.cityId && <CellButton indicator="+10" modal="edit">Укажите ваш город</CellButton>}
          {!hasBirthday && <CellButton indicator="+10" modal="edit">Укажите дату рождения</CellButton>}
          {!user.status && <CellButton indicator="+5" modal="status">Задайте статус</CellButton>}
        </div>
        <InlineHeader>Цвета</InlineHeader>
        <div className="Rating__levels">
          <Form>
            <FormItemColumn>
              <FormItem title="От 0 до 999">
                <div className="Rating__level RatingBackground cyan" />
              </FormItem>
              <FormItem title="От 1000 до 2499">
                <div className="Rating__level RatingBackground green" />
              </FormItem>
            </FormItemColumn>
            <FormItemColumn>
              <FormItem title="От 2500 до 4999">
                <div className="Rating__level RatingBackground orange" />
              </FormItem>
              <FormItem title="От 5000 до 9999">
                <div className="Rating__level RatingBackground purple" />
              </FormItem>
            </FormItemColumn>
            <FormItemColumn>
              <FormItem title="От 10000">
                <div className="Rating__level RatingBackground red" />
              </FormItem>
              <FormItem />
            </FormItemColumn>
          </Form>
        </div>
      </ScrollView>
    </>
  );
};
