import './Select.sass';
import React, { FC } from 'react';
import { IcDropdown16 } from '$assets';

export const Select: FC<React.SelectHTMLAttributes<HTMLSelectElement>> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <div className="Select">
      <select {...otherProps}>
        {children}
      </select>
      <div className="Select__chevron">
        <IcDropdown16 />
      </div>
    </div>
  );
};
