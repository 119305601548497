import { all, takeLatest, put, call, select, fork } from 'redux-saga/effects';

import { authActions, authSelector } from './index';
import { toastActions } from '../toast';
import { Api } from 'api';
import {
  getDeviceId,
  getRegistrationToken,
  getStorageItem,
  getToken, isNative,
  removeRegistrationToken,
  removeToken,
  setRegistrationToken,
  setToken, statReachGoal,
  validatePhone,
} from '$utils';
import router from 'navigation/router';
import { RegistrationUploadPhotoAction, SendAuthCodeAction } from './interface';
import { mainActions } from '../main';
import { REGISTRATION } from '$shared/constants/pages';
import { realtimeActions } from '$store/realtime';
import { batchActions, store } from '$store';
import { startVisibilityWatcher } from '../../services/visibility';
import { listenNativeEvents } from '$services';
import { postsActions } from '$store/posts';

function* initWorker() {
  try {
    const tokenInfo = yield call(getToken);
    if (tokenInfo.userId > 0) {
      const data = yield call(Api.get, '/main/init');

      yield put(
        batchActions(
          mainActions.setUser(data.user),
          authActions.setLogged(data.user.id),
          mainActions.setNewDialogs(data.newDialogs),
          mainActions.setHasNotify(data.hasNotify),
        ),
      );

      //if (location.pathname === '/' || location.pathname === '/registration') {
      // if (location.pathname === '/') {
      //   router.navigate('feed', {}, { replace: true });
      // }

      yield put(realtimeActions.listen(tokenInfo.token));
      yield call(startVisibilityWatcher);
      yield call(statReachGoal, 'user_visit');

      if (isNative(2)) {
        yield call(listenNativeEvents);
      }
    } else {
      // if (location.pathname === '/registration') {
      //   location.href = '/';
      // } else {
        yield put(authActions.setLoading(false));
     // }
    }
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
    // if (location.pathname === '/registration') {
    //   location.href = '/';
    // } else {
      yield put(authActions.setLoading(false));
    //}
  }

  yield fork(listenRouterChange);
}

function* listenRouterChange() {
  /* @ts-ignore */
  router.addListener((from, to) => {
    store.dispatch(postsActions.sendSeenPosts());
  });
}

function* goToCaptchaWorker() {
  try {
    const { authForm } = yield select(authSelector);
    const phone = authForm.phone.replace(/[^0-9]/g, '').trim();

    if (!phone.length) {
      yield put(toastActions.setToastFail('Введите номер телефона'));
      return;
    }

    if (!validatePhone(phone)) {
      yield put(toastActions.setToastFail('Введите корректный номер телефона'));
      return;
    }

    //yield put(authActions.setAuthFormStep('captcha'));
    yield put(authActions.sendAuthCode(''));
  } catch (e) {}
}

function* sendAuthCodeWorker(action: SendAuthCodeAction) {
  try {
    const { authForm } = yield select(authSelector);

    if (!confirm(`+${authForm.phoneCode} ${authForm.phone.replace(/\_/g, '')} — это правильный номер?`)) {
      return;
    }

    const phone = authForm.phone.replace(/[^0-9]/g, '').trim();
    yield put(toastActions.setToastLoading());
    yield call(Api.post, '/auth', {
      phoneCode: +authForm.phoneCode,
      phone,
      captcha: action.payload,
    });
    yield put(toastActions.hideToast());
    yield put(authActions.setAuthFormStep('code'));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
    yield put(authActions.setAuthFormStep('phone'));
  }
}

function* validateCodeWorker() {
  try {
    const { authForm } = yield select(authSelector);
    const code = authForm.code.trim();
    const phone = authForm.phone.replace(/[^0-9]/g, '').trim();

    if (code.length < 4) {
      yield put(toastActions.setToastFail('Введите код'));
      return;
    }

    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, '/auth/check_code', {
      phoneCode: +authForm.phoneCode,
      phone,
      code,
      device: getDeviceId(),
    });
    yield put(toastActions.hideToast());
    yield call(statReachGoal, 'sms_code_success_validate');

    if (data.is_new) {
      yield call(setRegistrationToken, data.access_token);
      router.navigate(REGISTRATION);
    } else {
      yield call(setToken, +data.user_id, data.access_token);
      location.href = '/feed';
    }
  } catch (e) {
    yield call(statReachGoal, 'sms_code_fail_validate')
    yield put(toastActions.setToastFail(e.message));
  }
}

function* createAccountWorker() {
  try {
    const { registrationForm } = yield select(authSelector);
    const firstName = registrationForm.firstName.trim();
    const lastName = registrationForm.lastName.trim();
    const sex = registrationForm.sex;
    const bDate = registrationForm.bDate || '';
    const countryId = registrationForm.country?.value ?? 0;
    const cityId = registrationForm.city?.value ?? 0;
    const photoId = registrationForm.photoId;
    const code = registrationForm.code.trim();

    const bDateExp = bDate.split('.');
    const bDay = +bDateExp[0] || 0;
    const bMonth = +bDateExp[1] || 0;
    const bYear = +bDateExp[2] || 0;

    yield put(toastActions.setToastLoading());

    let ref: any = getStorageItem('ref');
    if (ref) {
      try {
        ref = parseInt(ref, 16);
      } catch (e) {
        ref = 0;
      }
    }

    const data = yield call(Api.post, '/registration', {
      first_name: firstName,
      last_name: lastName,
      sex,
      country_id: countryId,
      city_id: cityId,
      photo_id: photoId,
      bday: bDay,
      bmonth: bMonth,
      byear: bYear,
      device: getDeviceId(),
      ref,
      code,
    }, {
      headers: {
        Authorization: yield call(getRegistrationToken),
      }
    });

    const utm = getStorageItem('utm');
    if (utm) {
      yield call(statReachGoal, `utm_${utm}`);
    }

    if (ref) {
      yield call(statReachGoal, `register_by_ref`);
    }

    try {
      // @ts-ignore
      if (window.ttq) {
        // @ts-ignore
        window.ttq.instance('C5M3OD51T98720E04SQG').track('CompleteRegistration');
      }
    } catch (e) {}

    yield call(statReachGoal, 'registration');
    yield call(removeRegistrationToken);
    yield call(setToken, +data.user_id, data.access_token);
    //yield put(authActions.init());

    setTimeout(() => {
      location.href = '/feed';
    }, 1000);
  } catch(e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* registrationUploadPhotoWorker(action: RegistrationUploadPhotoAction) {
  try {
    const form = new FormData();
    form.append('file', action.payload);

    const data = yield call(Api.post, '/registration/photo', form, {
      headers: {
        Authorization: yield call(getRegistrationToken),
      }
    });

    yield put(authActions.updateRegistraionForm({
      isPhotoUploading: false,
      photoId: +data.photo_id,
    }));
  } catch(e) {
    yield put(toastActions.setToastFail(e.message));
    yield put(authActions.updateRegistraionForm({
      isPhotoUploading: false,
      photo: null,
    }));
  }
}

function* logoutWorker() {
  try {
    yield call(removeToken);
    yield call(removeRegistrationToken);
    location.href = '/';
  } catch (e) {}
}

export function* authSaga() {
  yield all([
    takeLatest(authActions.init, initWorker),
    takeLatest(authActions.sendAuthCode, sendAuthCodeWorker),
    takeLatest(authActions.validateCode, validateCodeWorker),
    takeLatest(authActions.createAccount, createAccountWorker),
    takeLatest(authActions.registrationUploadPhoto, registrationUploadPhotoWorker),
    takeLatest(authActions.logout, logoutWorker),
    takeLatest(authActions.goToCaptcha, goToCaptchaWorker),
  ]);
}
