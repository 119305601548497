import './index.sass';

import 'core-js/es';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router5';
import { Provider as StoreProvider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';
import amplitude from 'amplitude-js';

import App from './core/App';
import { store } from '$store';
import { TranslatorProvider } from '$translation';
import router from 'navigation/router';
import { isNative, setStorageItem, statReachGoal } from '$utils';
import { Api } from '$api';


const firebaseConfig = {
  apiKey: "AIzaSyBX-vu5Fhaz1mhC4x59JZ-hMX2et8V8oa8",
  authDomain: "anime-fans-311314.firebaseapp.com",
  projectId: "anime-fans-311314",
  storageBucket: "anime-fans-311314.appspot.com",
  messagingSenderId: "657166526180",
  appId: "1:657166526180:web:f11aabb4c49d8ff57beef1",
  measurementId: "G-X3SDXPMV02"
};

// Initialize Firebase
try {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
} catch (e) {}

amplitude.getInstance().init('17f16c65d752f9a8a60b0a680de57b41', null, {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
});

const queryParams = new URLSearchParams(window.location.search || '');

const ref = queryParams.get('ref');
if (ref) {
  setStorageItem('ref', `${ref}`);
  statReachGoal('visit_by_ref');
}

if (queryParams.get('code')) {
  // @ts-ignore
  window.VkAuthCode = queryParams.get('code');
} else {
  const hashParams = new URLSearchParams('?' + String(window.location.hash || '').substr(1));
  if (hashParams.get('access_token')) {
    // @ts-ignore
    window.VkAuthAccessToken = hashParams.get('access_token');
  }
}

if (queryParams.get('utm')) {
  statReachGoal('from_utm');
  setStorageItem('utm', queryParams.get('utm')!);
} else if (location.search && location.search.substr(0, 5) === '?utm_') {
  statReachGoal('from_utm');

  const exp = location.search.substr(5).split('=');
  setStorageItem('utm', exp[0]);
}

window.onerror = (event, source, lineno, colno, error) => {
  axios.post('https://api.anime.fans/error', {
    error: JSON.stringify({
      event,
      source,
      lineno,
      colno,
      error,
      errorMsg: error?.message,
      url: location.href,
    }),
  });
};

// window.onerror = (error) => {
//   document.body.innerHTML = `
// <div class="FatalError">
//     <div class="FatalError__title">Произошла ошибка, перезапустите приложение</div>
//     <div class="FatalError__caption">${JSON.stringify(error)}</div>
// </div>
// `;
// };

router.start();

const handleError = (error, info) => {
  Api.post('/error', {
    message: error?.message ?? 'Empty',
    stack: error?.stack ?? 'Unknown',
    componentStack: info?.componentStack ?? 'Unknown',
    url: location.href,
  });
};

const FallbackComponent = ({ error }) => (
  <div className="FatalError">
    <div className="FatalError__title">Произошла ошибка, перезапустите приложение</div>
    <div className="FatalError__caption">{JSON.stringify(error.message)}</div>
    {!isNative() && <div className="FatalError__btn" onClick={() => location.reload()}>Попробовать снова</div>}
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      onError={handleError}
      FallbackComponent={FallbackComponent}
    >
      <RouterProvider router={router}>
        <TranslatorProvider>
          <StoreProvider {...{ store }}>
            <App />
          </StoreProvider>
        </TranslatorProvider>
      </RouterProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
