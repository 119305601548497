import './FindFriends.sass';

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CellButton, Header, InlineHeader, Placeholder, ScrollView } from '$uikit';
import { IcUserPlus28, IcSearch28 } from '$assets';
import { INVITE, SEARCH } from '$shared/constants/pages';
import { inviteActions, inviteSelector } from '$store/invite';
import { Loader, Metatags } from '$shared/components';
import { FindFriendItem } from '$core/FindFriends/FindFriendItem/FindFriendItem';
import { isBackNavigation } from '$navigation/router';
import { useTranslator } from '$hooks';

export const FindFriends: FC = () => {
  const dispatch = useDispatch();
  const { suggestions, isSuggestionsLoading, suggestionsNextFrom, isSuggestionsLoadingMore } = useSelector(inviteSelector);
  const isBack = isBackNavigation();
  const t = useTranslator();

  useEffect(() => {
    if (!isBack) {
      dispatch(inviteActions.loadSuggestions());
    }
  }, []);

  function renderSuggestions() {
    if (isSuggestionsLoading && !suggestions.length) {
      return <Loader isWrapped />;
    }

    if (!suggestions.length) {
      return (
        <Placeholder>
          {t('find_friends_empty')}
        </Placeholder>
      );
    }

    return suggestions.map((item) => <FindFriendItem key={item.userId} item={item} />);
  }

  return (
    <>
      <Metatags title={t('find_friends_title')} />
      <Header showBackButton>{t('find_friends_title')}</Header>
      <ScrollView
        onLoadMore={() => dispatch(inviteActions.loadMoreSuggestions())}
        canLoadMore={!isSuggestionsLoading && !!suggestionsNextFrom && !isSuggestionsLoadingMore}
      >
        <div className="FindFriends__methods">
          <CellButton icon={<IcSearch28 />} route={SEARCH} routeParams={{ tab: 'peoples' }}>{t('find_friends_search')}</CellButton>
          <CellButton icon={<IcUserPlus28 />} route={INVITE}>{t('find_friends_invite')}</CellButton>
        </div>
        <div className="FindFriends__suggestions">
          <InlineHeader>{t('find_friends_suggestions')}</InlineHeader>
          {renderSuggestions()}
        </div>
      </ScrollView>
    </>
  );
};
