import './AnimeLists.sass';

import React, { FC, useCallback, useEffect } from 'react';
import { Link, useRoute } from 'react-router5';
import copyToClipboard from 'copy-text-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import { useCurrentUserInfo, useUserInfo } from '$hooks';
import { Loader, Metatags } from '$shared/components';
import { Button, Header, HorizontalScroll, InlineHeader, Placeholder, ScrollView } from '$uikit';
import { animeActions, animeSelector } from '$store/anime';
import { isBackNavigation } from '$navigation/router';
import { showModal } from '$navigation/helper';
import { AnimeCard } from '$core/Anime/AnimeCard/AnimeCard';
import { mainSelector } from '$store/main';
import { IcAdd28, IcChevron16, IcKey28 } from '$assets';
import { ANIME_LIST, PROFILE, RESOLVE } from '$shared/constants/pages';
import { toastActions } from '$store/toast';

export const AnimeLists: FC = () => {
  const { route } = useRoute();
  const ownerId = +route.params.ownerId;
  const dispatch = useDispatch();
  const isBack = isBackNavigation();

  const curUser = useCurrentUserInfo();
  const user = useUserInfo(ownerId);
  const isOwner = curUser?.id == ownerId;
  const { isListsLoading, isListsFailed, lists } = useSelector(animeSelector);
  const { anime } = useSelector(mainSelector);

  useEffect(() => {
    if (!isBack) {
      dispatch(animeActions.loadLists(ownerId));
    }
  }, []);

  const handleRetry = useCallback(() => {
    dispatch(animeActions.loadLists(ownerId));
  }, [dispatch, ownerId]);

  const handleCreate = useCallback(() => {
    showModal('new_list');
  }, []);

  const handleShare = useCallback(() => {
    /* @ts-ignore */
    if (navigator.canShare) {
      navigator.share({
        url: location.href,
        title: `Списки аниме | ${user?.firstName} ${user?.lastName}`,
        text: `Посмотрите мои списки аниме на anime.fans`,
      })
    } else {
      copyToClipboard(location.href);
      dispatch(toastActions.setToastSuccess('Ссылка скопирована!'));
    }
  }, []);

  function renderLists() {
    if (!lists.length) {
      return (
        <Placeholder
          centered
          title="Списков нет"
          actions={isOwner && <Button size="small" onClick={handleCreate}>Добавить список</Button>}
        >
          {isOwner ? 'Добавьте свой первый список' : 'Ниодного списка еще не добавленно'}
        </Placeholder>
      );
    }

    return lists.map((list) => (
      <div key={list.id} className="AnimeLists__item">
        <Link routeName={ANIME_LIST} routeParams={{ listId: list.id }} className="AnimeLists__item__header">
          <div className="AnimeLists__item__title_wrap">
            <InlineHeader>
              {list.title}
              {list.privacy === 1 && <IcKey28 />}
            </InlineHeader>
            <div className="AnimeLists__item_show_all">
              Перейти
              <IcChevron16 />
            </div>
          </div>
          <div className="AnimeLists__item__description">{list.description}</div>
        </Link>
        <div className="Anime__cards">
          <HorizontalScroll>
            {list.animeIds.map((animeId) => <AnimeCard key={animeId} item={{ anime: anime[animeId] }} />)}
          </HorizontalScroll>
        </div>
      </div>
    ));
  }

  function renderContent() {
    if (isListsLoading) {
      return (
        <Loader isWrapped />
      );
    }

    if (isListsFailed) {
      return (
        <Placeholder
          title="Произошла ошибка"
          actions={<Button size="small" onClick={handleRetry}>Попробовать снова</Button>}
          centered
        >
          Попробуйте еще раз позже
        </Placeholder>
      );
    }

    return (
      <>
        {(!isOwner || lists.length > 0) && (
          <Link className="AnimeLists" routeName={user.domain ? RESOLVE : PROFILE} routeParams={user.domain ? { domain: user.domain } : { id: user.id }}>
            <div className="AnimeLists__photo" style={{ backgroundImage: `url(${user.photo})` }} />
            <div className="AnimeLists__label">Автор списков</div>
            <div className="AnimeLists__name">{user.firstName} {user.lastName}</div>
          </Link>
        )}
        {lists.length > 0 && (
          <div className="AnimeLists__share">
            <Button onClick={handleShare}>Поделиться списками</Button>
          </div>
        )}
        {renderLists()}
      </>
    );
  }

  return (
    <>
      <Metatags
        title={`Списки аниме | ${user?.firstName} ${user?.lastName}`}
        description="Посмотрите мои списки аниме"
      />
      <Header
        showBackButton
        rightIcon={isOwner && <IcAdd28 />}
        rightOnClick={isOwner && handleCreate}
      >
        {isOwner && 'Списки аниме'}
      </Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
