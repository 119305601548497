import './GroupAdminsItem.sass';

import React, { FC, useCallback } from 'react';
import { GroupAdminsItemProps } from '$core/GroupAdmins/GroupAdminsItem/GroupAdminsItem.interface';
import { Link } from 'react-router5';
import { Online } from '$shared/components';
import { useUserInfo } from '$hooks';
import { PROFILE } from '$shared/constants/pages';
import { useDispatch } from 'react-redux';
import { editGroupActions } from '$store/editGroup';

export const GroupAdminsItem: FC<GroupAdminsItemProps> = ({ userId, groupId }) => {
  const dispatch = useDispatch();
  const user = useUserInfo(userId);

  const handleClick = useCallback((e) => {
    if (e.target.closest('.GroupAdminsItem__action')) {
      e.preventDefault();

      dispatch(editGroupActions.removeAdmin({
        groupId,
        userId,
      }));
      return false;
    }
  }, [dispatch, userId]);

  return (
    <Link
      className="GroupAdminsItem"
      routeName={PROFILE}
      routeParams={{ id: userId }}
      onClick={handleClick}
    >
      <div
        className="GroupAdminsItem__photo"
        style={{ backgroundImage: `url(${user.photo})` }}
      />
      <div className="GroupAdminsItem__cont">
        <div className="GroupAdminsItem__name_wrap">
          <div className="GroupAdminsItem__name">{user.firstName} {user.lastName}</div>
          <Online userId={userId} />
        </div>
      </div>
      <div className="GroupAdminsItem__action">
        Удалить
      </div>
    </Link>
  );
};
