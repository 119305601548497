import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  MainState, SetAnimesAction,
  SetGroupAction,
  SetGroupsAction, SetHasNotifyAction, SetMainPageDataAction,
  SetNewDialogsAction, SetNotifyEnabledAction, SetPlayingVideoAction, SetPopupAction,
  SetUserAction,
  SetUsersAction
} from './interface';
import { RootState } from 'store/rootReducer';
import { isNative } from '$utils';

const initialState: MainState = {
  users: {},
  groups: {},
  anime: {},
  newDialogs: 0,
  hasNotify: false,
  isNotifyEnabled: !isNative() && window.Notification && Notification.permission === 'granted',
  playingVideo: '',
  isMainPageLoading: true,
  mainPagePosts: [],
  mainPageGroups: [],
  popup: null,
};

export const { actions, reducer } = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setUsers(state, action: SetUsersAction) {
      for (let userId in action.payload) {
        let user = action.payload[userId];
        state.users[user.id] = user;
      }
    },
    setUser(state, action: SetUserAction) {
      const userId = action.payload.id!;
      state.users[userId] = {
        ...(state.users[userId] || {}),
        ...action.payload,
      };
    },
    setGroups(state, action: SetGroupsAction) {
      for (let groupId in action.payload) {
        let group = action.payload[groupId];
        state.groups[group.id] = group;
      }
    },
    setGroup(state, action: SetGroupAction) {
      const groupId = action.payload.id!;
      state.groups[groupId] = {
        ...(state.groups[groupId] || {}),
        ...action.payload,
      };
    },
    setNewDialogs(state, action: SetNewDialogsAction) {
      state.newDialogs = action.payload;
    },
    setHasNotify(state, action: SetHasNotifyAction) {
      state.hasNotify = action.payload;
    },
    setNotifyEnabled(state, action: SetNotifyEnabledAction) {
      state.isNotifyEnabled = action.payload;
    },
    setPlayingVideo(state, action: SetPlayingVideoAction) {
      state.playingVideo = action.payload;
    },
    loadMainPageData() {},
    setMainPageData(state, action: SetMainPageDataAction) {
      state.isMainPageLoading = false;
      state.mainPageGroups = action.payload.groups;
      state.mainPagePosts = action.payload.posts;
    },
    setAnimes(state, action: SetAnimesAction) {
      for (let animeId in action.payload) {
        let anime = action.payload[animeId];
        state.anime[anime.id] = anime;
      }
    },
    setPopup(state, action: SetPopupAction) {
      state.popup = action.payload;
    },
  },
});

export { reducer as mainReducer, actions as mainActions };

export const mainSelector = (state: RootState) => state.main;

export const selectMain = (state: RootState): ReturnType<typeof reducer> =>
  state.main;

export const userSelector = createSelector(
  selectMain,
  (_,  userId: number) => userId,
  (main, userId) => {
    return main.users[userId];
  },
);

export const currentUserSelector = (state: RootState) => {
  return state.auth.currentUserId ? state.main.users[state.auth.currentUserId] : {};
};

export const animeInfoSelector = createSelector(
  selectMain,
  (_, animeId: number) => animeId,
  (main, animeId) => {
    return main.anime[animeId];
  },
);
