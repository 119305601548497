import React, { useRef, useEffect } from 'react';

export function useEvent(eventName, handler, element) {
  // Create a ref that stores handler
  const savedHandler = useRef<any>(null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler, savedHandler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element.current && element.current.addEventListener;
      if (!isSupported || !element.current) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current && savedHandler.current(event);

      // Add event listener
      element.current && element.current.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.current && element.current.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element, element.current] // Re-run if eventName or element changes
  );
};
