import './VideoPlayer.sass';

import React, { FC, useCallback, useRef } from 'react';
import { VideoPlayerProps } from './VideoPlayer.interface';
import { useDispatch } from 'react-redux';
import { animeActions } from '$store/anime';
import { useAnimeFull } from '$hooks';
import { PlayerContent } from '$shared/components/VideoPlayer/PlayerContent/PlayerContent';
import { showModal } from '$navigation/helper';

export const VideoPlayer: FC<VideoPlayerProps> = (props) => {
  const { video, animeId, episodeId, season, episode, translationId, nextParams } = props;

  const dispatch = useDispatch();
  const lastProgressSave = useRef(0);
  const anime = useAnimeFull(animeId);
  const lastProgress = useRef(anime.progress[episodeId] || 0);

  const handleSave = useCallback(() => {
    dispatch(animeActions.saveProgress({
      animeId,
      episodeId,
      duration: lastProgress.current,
      season,
      episode,
      translationId,
    }));
    lastProgressSave.current = Date.now();
  }, [animeId, episodeId, lastProgress, season, episode, translationId, lastProgressSave]);

  const handleProgress = useCallback((time: number, forceSave: boolean = false) => {
    if (time < 10) {
      return;
    }

    lastProgress.current = time;

    const now = Date.now();
    if (now - lastProgressSave.current > 15 * 1000 || forceSave) {
      lastProgressSave.current = now;
      handleSave();
    }
  }, [dispatch, handleSave, animeId, episodeId, lastProgressSave, lastProgress, season, episode, translationId]);

  const handleNext = useCallback(() => {
    if (nextParams) {
      showModal(`play${animeId}_${nextParams.translation}_${nextParams.season}_${nextParams.number}`, true);
    }
  }, [nextParams]);

  return (
    <div className="VideoPlayer__wrap">
      <div className="VideoPlayer__fixed">
        <div className="VideoPlayer">
          <PlayerContent
            initialTime={anime.progress[episodeId] || 0}
            video={video}
            onNextEpisode={handleNext}
            canGoNext={!!nextParams}
            onProgress={handleProgress}
          />
          {/*<video*/}
          {/*  className="VideoPlayer__video"*/}
          {/*  autoPlay*/}
          {/*  poster={video.poster}*/}
          {/*  controls*/}
          {/*  ref={videoRef}*/}
          {/*  onProgress={handleProgress}*/}
          {/*  onPlay={handlePlay}*/}
          {/*  onPause={handlePause}*/}
          {/*>*/}
          {/*  {video.source.map((item) => (*/}
          {/*    <source key={item.path} src={item.path} type="video/mp4" />*/}
          {/*  ))}*/}
          {/*</video>*/}
        </div>
      </div>
      {/*<div className="VideoPlayer" />*/}
    </div>
  );
};
