import './ActionSheet.sass';

import React, { FC, useCallback } from 'react';

import { ActionSheetProps } from './ActionSheet.interface';
import { classNames } from '$utils';
import { IcClose28 } from '$assets';

export const ActionSheet: FC<ActionSheetProps> = ({ isOpened, options, onChange, selected }) => {
  const handleOption = useCallback((option) => () => {
    if (option.disabled) {
      return;
    }
    onChange(option.value);
  }, [onChange]);

  const handleClose = useCallback(() => {
    onChange(selected);
  }, [onChange, selected]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className="ActionSheet">
      <div className="ActionSheet__options">
        {options.map((option) => (
          <div
            className={classNames({
              ActionSheet__option: true,
              selected: option.value === selected,
            })}
            key={option.value}
            onClick={handleOption(option)}
          >
            {option.label}
            {option.children || null}
          </div>
        ))}
      </div>
      <div className="ActionSheet__close" onClick={handleClose}>
        <IcClose28 />
      </div>
    </div>
  );
};
