import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';

import {
  BanAction,
  BlacklistState,
  GroupBanAction,
  SetBanFromMeAction,
  SetBanToMeAction,
  SetGroupBanAction,
  UnbanAction
} from './interface';

const initialState: BlacklistState = {
  banFromMe: {},
  banToMe: {},
  groupBan: {},
};

export const { actions, reducer } = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    setBanFromMe(state, action: SetBanFromMeAction) {
      const { userId, isBanned } = action.payload;

      if (isBanned) {
        state.banFromMe[userId] = true;
      } else {
        delete state.banFromMe[userId];
      }
    },
    setBanToMe(state, action: SetBanToMeAction) {
      const { userId, isBanned } = action.payload;

      if (isBanned) {
        state.banToMe[userId] = true;
      } else {
        delete state.banToMe[userId];
      }
    },
    setGroupBan(state, action: SetGroupBanAction) {
      const { userId, groupId, isBanned } = action.payload;

      if (!state.groupBan[groupId]) {
        state.groupBan[groupId] = {};
      }

      if (isBanned) {
        state.groupBan[groupId]![userId] = true;
      } else {
        delete state.groupBan[groupId]![userId];
      }
    },
    ban(_, __: BanAction) {},
    unban(_, __: UnbanAction) {},
    groupBan(_, __: GroupBanAction) {},
    groupUnban(_, __: GroupBanAction) {},
  },
});

export { reducer as blacklistReducer, actions as blacklistActions };

export const blacklistSelector = (state: RootState) => state.blacklist;

export const blacklistHasBanFromMeSelector = createSelector(
  blacklistSelector,
  (_, userId: number) => userId,
  (blacklist, userId) => {
    return blacklist.banFromMe[userId];
  },
);

export const blacklistHasBanToMeSelector = createSelector(
  blacklistSelector,
  (_, userId: number) => userId,
  (blacklist, userId) => {
    return blacklist.banToMe[userId];
  },
);
