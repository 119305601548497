import './PhotoView.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGesture } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import copyToClipboard from 'copy-text-to-clipboard';
import { saveAs } from 'file-saver';

import { goBack } from '$navigation/helper';
import { PhotoViewProps } from '$core/PhotoView/PhotoView.interface';
import { postsActions, postsSelector } from '$store/posts';
import { Loader } from '$shared/components';
import { ActionSheet, Header } from '$uikit';
import { IcMore24 } from '$assets';
import { classNames, isDesktop, isNative } from '$utils';
import { toastActions } from '$store/toast';
import { sendNativeEvent } from '$services';
import { imActions, imSelector } from '$store/im';
import { AttachItem } from '$store/models';

function calcSlidePosition(index) {
  const slideWidth = isDesktop() ? Math.min(600, window.innerWidth) : window.innerWidth;
  return -index * (slideWidth + 16);
}

export const PhotoView: FC<PhotoViewProps> = ({ object, selectedId }) => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(-1);
  const { posts } = useSelector(postsSelector);
  const { history } = useSelector(imSelector);
  const [isControlsShown, setControlsShown] = useState(true);
  const [isMenuShown, setMenuShown] = useState(false);

  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));
  const [{ opacity }, opacityApi] = useSpring(() => ({ opacity: 1 }));

  useEffect(() => {
    const matches = object.match(/^([a-z]+)(\d+)$/);
    if (matches) {
      const type = matches[1];
      const objectId = +matches[2];
      if (type === 'post') {
        if (!posts[objectId]) {
          dispatch(postsActions.loadPost(objectId));
        }
      } else if (type === 'history') {
        dispatch(imActions.loadChat(objectId));
      }
    }
  }, [object]);

  const handleClose = useCallback(() => {
    goBack();
  }, []);

  const handleChange = useCallback((newIndex) => {
    setIndex(newIndex);
  }, [setIndex]);

  const photos = useMemo(() => {
    const matches = object.match(/^([a-z]+)(\d+)$/);
    if (!matches) {
      return [];
    }

    const type = matches[1];
    const objectId = +matches[2];
    if (type === 'post') {
      const post = posts[objectId];
      if (!post) {
        return [];
      }

      return (post.attachments || [])
        .filter((item) => item.type === 'photo')
        .map((item) => ({ id: item.photo?.photoId ?? 0, src: item.photo?.photoLarge ?? '' }));
    } else if (type === 'history') {
      const messages = history[objectId] || {};
      let historyCopy = Object.values(messages);
      historyCopy
        .filter((item) => !!item.attachments && item.attachments.length > 0)
        .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);

      const result: AttachItem[] = [];
      for (let message of historyCopy) {
        const filtered = message.attachments!.filter((item) => item.type === 'photo');
        if (filtered.length > 0) {
          result.push(...filtered);
        }
      }

      return result.map((item) => ({ id: item.photo?.photoId ?? 0, src: item.photo?.photoLarge ?? '' }));
    } else {
      return [];
    }
  }, [object, posts, history]);

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [mx, my], tap }) => {
        if (tap) {
          setControlsShown(!isControlsShown);
          return;
        }

        if (down) {
          api.start({ x: mx + calcSlidePosition(index), y: down ? my : 0, immediate: down });

          const y = Math.abs(my);
          opacityApi.start({ opacity: 1 - y / window.innerHeight, immediate: true });
        } else {
          const yDiff = Math.abs(my);
          if (yDiff > 120) {
            api.start({ x: calcSlidePosition(index), y: (my > 0 ? 1 : -1) * window.innerHeight, immediate: false });
            opacityApi.start({ opacity: 0, immediate: false });
            setTimeout(() => {
              handleClose();
            }, 200);
            return;
          }

          const diff = Math.abs(mx);
          if (diff < 60) {
            api.start({ x: calcSlidePosition(index), y: 0, immediate: false });
            opacityApi.start({ opacity: 1, immediate: false });
            return;
          }

          let newIndex;
          if (mx > 0) {
            newIndex = Math.max(0, index - 1);
          } else {
            newIndex = Math.min(photos.length - 1, index + 1);
          }
          api.start({ x: calcSlidePosition(newIndex), y: 0, immediate: false });
          opacityApi.start({ opacity: 1, immediate: false });
          setIndex(newIndex);
        }
      },
    }, {
      drag: {
        axis: 'lock',
        filterTaps: true,
        eventOptions: {
          passive: false,
        },
      }
    });

  useEffect(() => {
    let foundIndex = 0;
    for (let i = 0; i < photos.length; i++) {
      if (photos[i].id === selectedId) {
        foundIndex = i;
        break;
      }
    }

    if (foundIndex !== index && photos.length > 0) {
      setIndex(foundIndex);
      api.start({ x: calcSlidePosition(foundIndex), y: 0, immediate: true });
    }
  }, [photos]);

  const handleActions = useCallback((action) => {
    setMenuShown(false);

    if (action === 'copy_link') {
      copyToClipboard(location.href);
      dispatch(toastActions.setToastSuccess('Скопировано!'));
    } else if (action === 'download') {
      const exp = (photos[index]?.src ?? '').split('/');
      if (isNative(4)) {
        sendNativeEvent('download', {
          src: photos[index]?.src ?? '',
          name: exp[exp.length - 1],
        });
      } else {
        saveAs(photos[index]?.src ?? '', exp[exp.length - 1]);
      }
    }
  }, [photos, index]);

  const handleMore = useCallback(() => {
    setMenuShown(true);
  }, []);

  const actions = useMemo(() => {
    const matches = object.match(/^([a-z]+)(\d+)$/);
    if (!matches) {
      return [];
    }

    const result = [
      {
        label: 'Скачать',
        value: 'download',
      },
      {
        label: 'Открыть оригинал',
        value: 'open',
        disabled: true,
        children: (
          <a href={photos[index]?.src ?? ''} target="_blank" className="PhotoView__open">Open</a>
        ),
      }
    ];

    if (matches[1] !== 'history') {
      result.push({
        label: 'Копировать ссылку',
        value: 'copy_link'
      });
    }

    return result;
  }, [object]);

  if (index === -1 || !photos.length) {
    return <Loader isWrapped />;
  }

  return (
    <div className={classNames({
      PhotoView: true,
      hideControls: !isControlsShown,
    })}>
      <animated.div className="PhotoView__bg" style={{ opacity }} />
      <div className="PhotoView__content">
        <Header
          showCloseButton
          rightIcon={<IcMore24 />}
          rightOnClick={handleMore}
        >
          {photos.length <= 1 ? 'Просмотр фото' : `${index + 1}/${photos.length}`}
        </Header>
        <animated.div className="PhotoView__container" {...bind()} style={{ y }}>
          <animated.div className="PhotoView__photos" style={{ x }}>
            {photos.map((photo) => (
              <div
                key={photo.id}
                className="PhotoView__photo__container"
              >
                <div
                  className="PhotoView__photo"
                  style={{ backgroundImage: `url(${photo.src})` }}
                />
              </div>
            ))}
          </animated.div>
        </animated.div>
      </div>
      <ActionSheet
        isOpened={isMenuShown}
        options={actions}
        onChange={handleActions}
        />
    </div>
  );
};
