import './Auth.sass';

import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { authActions, authSelector } from '$store/auth';
import { Button, Form, FormItem, Input } from '$uikit';
import { Loader, PhoneInput } from '$shared/components';
import { statReachGoal } from '$utils';
import { useTranslator } from '$hooks';

export const Auth: FC = () => {
  const [authType, setAuthType] = useState('none');
  const dispatch = useDispatch();
  const t = useTranslator();
  const { authForm } = useSelector(authSelector);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const handleSendCode = useCallback(() => {
    dispatch(authActions.goToCaptcha());

    try {
      // @ts-ignore
      if (window.ttq) {
        // @ts-ignore
        window.ttq.instance('C496S2QUEPR55IURLMO0').track('ClickButton');
      }
    } catch (e) {}
  }, [dispatch, captchaRef]);

  const handleValidateCode = useCallback(() => {
    dispatch(authActions.validateCode());
  }, [dispatch]);

  const handleChangePhoneCode = useCallback(({ target }) => {
    dispatch(authActions.setAuthFormPhoneCode(target.value));
  }, [dispatch]);

  const handleChangePhone = useCallback(({ target }) => {
    dispatch(authActions.setAuthFormPhone(target.value));
  }, [dispatch]);

  const handleChangeCode = useCallback(({ target }) => {
    dispatch(authActions.setAuthFormCode(target.value));
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(authActions.setAuthFormStep('phone'));
  }, [dispatch]);

  const onChangeRecaptcha = useCallback((res) => {
    dispatch(authActions.sendAuthCode(res));
  }, [dispatch]);

  const handleVkAuth = useCallback(() => {
    statReachGoal('press_vk_auth');
  }, []);

  const handleSmsAuth = useCallback(() => {
    setAuthType('sms');
    statReachGoal('press_sms_auth');
  }, []);

  const vkAuthUrl = useMemo(() => {
    let result = 'https://oauth.vk.com/authorize?';
    const params = {
      client_id: 7955247,
      display: 'page',
      redirect_uri: `${location.protocol}//${location.host}/vkauth`,
      scope: '65536',
      response_type: 'token',
      v: '5.131',
    };

    const paramsArr = [];
    for (let k in params) {
      // @ts-ignore
      paramsArr.push(`${k}=${encodeURIComponent(params[k])}`);
    }

    return result + paramsArr.join('&');
  }, []);

  function renderAuthForm() {
    if (authType === 'none') {
      return (
        <div className="Main__auth_methods">
          <Button href={vkAuthUrl} className="vk" onClick={handleVkAuth}>{t('auth_vk')}</Button>
          {/*<Button mode="secondary" onClick={handleSmsAuth}>{t('auth_sms')}</Button>*/}
        </div>
      );
    }

    if (authForm.step === 'code') {
      return (
        <Form>
          <FormItem title={t('auth_sms_code_title')} description={(
            <div className="Auth__phone_sent_label">
              <span>{t('auth_sms_code_description', { phone: `+${authForm.phoneCode} ${authForm.phone.replace(/\\_/g, '')}` })}</span>
              <div className="Auth__phone_sent__change" onClick={handleBack}>{t('auth_change_number')}</div>
            </div>
          )}>
            <Input
              placeholder={t('auth_enter_code')}
              value={authForm.code}
              onChange={handleChangeCode}
            />
          </FormItem>
          <FormItem>
            <Button onClick={handleValidateCode}>{t('continue')}</Button>
            <Button onClick={handleBack} mode="secondary" style={{ marginTop: '12px' }}>{t('back')}</Button>
          </FormItem>
        </Form>
      );
    } else if (authForm.step === 'captcha') {
      return (
        <Form>
          <FormItem title="Вы не робот?">
            <div className="Main__captcha_wrap">
              <div className="Main__captcha_loader">
                <Loader />
              </div>
              <div className="Main__captcha__cont">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6LcI27EaAAAAAO8jdV2UKV046hDRQ9mdMLcmrNJE"
                  onChange={onChangeRecaptcha}
                  theme="dark"
                />
              </div>
            </div>
          </FormItem>
          <FormItem>
            <Button onClick={handleBack} mode="secondary">{t('back')}</Button>
          </FormItem>
        </Form>
      );
    }

    return (
      <Form>
        <FormItem title={t('auth_title')}>
          <PhoneInput
            code={authForm.phoneCode}
            value={authForm.phone}
            onChange={handleChangePhone}
            onChangeCode={handleChangePhoneCode}
          />
        </FormItem>
        <FormItem>
          <div className="Main__buttons_row">
            <Button onClick={handleSendCode}>{t('auth_button')}</Button>
            <Button mode="secondary" onClick={() => setAuthType('none')}>{t('cancel')}</Button>
          </div>
        </FormItem>
      </Form>
    );
  }

  return (
    <div className="Auth">
      {renderAuthForm()}
      {authType === 'none' && (
        <div className="Main__privacy">
          {t('auth_terms_pref')} <a href="https://anime.fans/terms.html" target="_blank">{t('auth_terms')}</a>{` ${t('and')} `}<a href="https://anime.fans/privacy.html" target="_blank">{t('auth_privacy')}</a>
        </div>
      )}
    </div>
  );
};
