import { FC } from 'react';
import { Placeholder } from '$uikit';

export const PaymentSuccess: FC = () => {
  return (
    <Placeholder
      title="Успешный платеж"
      centered
    >
      Закройте это окно, чтобы продолжить
    </Placeholder>
  )
};
