import {  createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';
import { LikesState, LoadLikesAction, LoadMoreLikesAction, SetLikesLoadedAction } from '$store/likes/interface';

const initialState: LikesState = {
  isLoading: true,
  isFailed: false,
  likes: [],
  nextFrom: null,
};

export const { actions, reducer } = createSlice({
  name: 'likes',
  initialState,
  reducers: {
    loadLikes(state, _: LoadLikesAction) {
      state.isLoading = true;
      state.isFailed = false;
      state.nextFrom = null;
      state.likes = [];
    },
    setLikesLoaded(state, action: SetLikesLoadedAction) {
      const { nextFrom, likes } = action.payload;

      state.isLoading = false;
      state.nextFrom = nextFrom;
      state.likes = likes;
    },
    setLikesFailed(state) {
      state.isLoading = false;
      state.isFailed = true;
    },
    loadMoreLikes(state, _: LoadMoreLikesAction) {
      state.isLoading = true;
    },
    setLoadMoreLoaded(state, action: SetLikesLoadedAction) {
      const { nextFrom, likes } = action.payload;

      const exist: { [index: number]: boolean } = {};
      for (const item of state.likes) {
        exist[item] = true;
      }

      const filtered: number[] = [];
      for (const item of likes) {
        if (!exist[item]) {
          filtered.push(item);
        }
      }

      state.isLoading = false;
      state.nextFrom = nextFrom;
      state.likes = [
        ...state.likes,
        ...filtered,
      ];
    },
    setLoadMoreFailed(state) {
      state.isLoading = false;
    },
  },
});

export { reducer as likesReducer, actions as likesActions };

export const likesSelector = (state: RootState) => state.likes;
