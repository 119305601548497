import './Registration.sass';

import React, { useEffect, useMemo, useState } from 'react';

import { getRegistrationToken, statReachGoal } from '$utils';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { Header } from '$uikit';
import { useTranslator } from '$hooks';

export function Registration() {
  const [step, setStep] = useState(1);
  const t = useTranslator();

  useEffect(() => {
    if (!getRegistrationToken()) {
      location.href = '/';
    } else {
      statReachGoal('register_page');
    }
  }, []);

  const title = useMemo(() => {
    if (step === 1) {
      return t('reg_step1_title');
    } else if (step === 2) {
      return t('reg_step2_title');
    } else {
      return t('reg_step3_title');
    }
  }, [step, t]);

  function renderContent() {
    if (step === 1) {
      return <Step1 setStep={setStep} />
    } else if (step === 2) {
      return <Step2 setStep={setStep} />;
    } else if (step === 3) {
      return <Step3 setStep={setStep} />;
    }
  }

  return (
    <>
      <Header />
      {renderContent()}
    </>
  );
}
