import s from './DownloadAppBlock.module.sass';
import { FC } from 'react';
import { Button } from '$uikit';
import { Link } from 'react-router5';
import { MAIN } from '$shared/constants/pages';
import { useSelector } from 'react-redux';
import { authSelector } from '$store/auth';

export const DownloadAppBlock: FC = () => {
  const { isLogged } = useSelector(authSelector);

  if (isLogged) {
    return null;
  }

  return (
    <Link className={s.wrap} routeName={MAIN}>
      <div className={s.cont}>
        Скачивайте приложение Anime.Fans
      </div>
      <div className={s.btn}>
        <Button mode="light">Скачать</Button>
      </div>
    </Link>
  );
};
