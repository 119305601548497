import './Chat.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from 'react-router5';

import { ActionSheet, Header } from '$uikit';
import { History } from '$core/Chat/History/History';
import { SendForm } from '$core/Chat/SendForm/SendForm';
import { imActions } from '$store/im';
import { useTranslator, useUserInfo } from '$hooks';
import { IcMore24 } from '$assets';
import { scrollToBottom } from '$utils';
import { ActionSheetPropsOption } from '$uikit/ActionSheet/ActionSheet.interface';
import { RootState } from '$store/rootReducer';
import { blacklistActions, blacklistHasBanFromMeSelector } from '$store/blacklist';
import { PROFILE, RESOLVE } from '$shared/constants/pages';
import { Metatags, Online, Verified } from '$shared/components';

export const Chat: FC = () => {
  const { route } = useRoute();
  const peerId = +route.params.peerId;
  const dispatch = useDispatch();
  const t = useTranslator();
  const peer = useUserInfo(peerId);
  const [isActionShow, setActionsShown] = useState(false);
  const isBannedFromMe = useSelector((root: RootState) => blacklistHasBanFromMeSelector(root, peerId));

  useEffect(() => {
    dispatch(imActions.loadChat(peerId));
    scrollToBottom('chat_history');
  }, []);

  const name = useMemo(() => {
    if (!peer) {
      return 'Загрузка..';
    }

    return (
      <>
        <div className="Chat__header_name_wrap">
          <div className="Chat__header_name">{peer.firstName} {peer.lastName}</div>
          <Verified objectId={peer.id} />
        </div>
        <div className="Chat__header_online">
          <Online userId={peer.id} extended />
        </div>
      </>
    );
  }, [peer]);

  const handleActions = useCallback(() => {
    setActionsShown(true);
  }, []);

  const handleAction = useCallback((item) => {
    setActionsShown(false);

    if (item === 'ban') {
      dispatch(blacklistActions.ban(peerId));
    } else if (item === 'unban') {
      dispatch(blacklistActions.unban(peerId));
    } else if (item === 'delete') {
      dispatch(imActions.deleteDialog(peerId));
    }
  }, [dispatch, peerId]);

  const actions = useMemo(() => {
    const items: ActionSheetPropsOption[] = [];

    if (isBannedFromMe) {
      items.push({ label: t('chat_unblock'), value: 'unban' });
    } else {
      items.push({ label: t('chat_block'), value: 'ban' });
    }

    items.push({ label: t('chat_delete'), value: 'delete' });

    return items;
  }, [isBannedFromMe, t]);

  return (
    <>
      <Metatags title={peer ? `${peer.firstName} ${peer.lastName} | ${t('chat_title')}` : t('chat_title')} />
      <Header
        showBackButton
        rightIcon={<IcMore24 />}
        rightOnClick={handleActions}
        routeName={peer?.domain ? RESOLVE : PROFILE}
        routeParams={peer?.domain ? { domain: peer.domain } : { id: peerId }}
      >
        {name}
      </Header>
      <History peerId={peerId} />
      <SendForm peerId={peerId} />
      <ActionSheet
        isOpened={isActionShow}
        options={actions}
        onChange={handleAction}
      />
    </>
  );
};
