import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResolverProps } from './Resolver.interface';
import { Loader } from '$shared/components';
import { resolverActions, resolverSelector } from '$store/resolver';
import { Button, Placeholder } from '$uikit';
import { Profile } from '$core/Profile/Profile';
import { Group } from '$core/Group/Group';
import { NotFound } from '$core/NotFound/NotFound';

export const Resolver: FC<ResolverProps> = ({ mode = 'address', domain }) => {
  const dispatch = useDispatch();
  const { results, isFailed, isNotFound } = useSelector(resolverSelector);
  const key = `${mode}_${domain}`;
  const info = results[key];

  useEffect(() => {
    dispatch(resolverActions.resolve({
      mode,
      domain,
    }));
  }, [domain]);

  const handleRetry = useCallback(() => {
    dispatch(resolverActions.resolve({
      mode,
      domain,
    }));
  }, [dispatch, mode, domain]);

  if (isNotFound[key]) {
    return <NotFound />;
  }

  if (isFailed[key]) {
    return (
      <Placeholder
        centered
        title="Неудалось загрузить страницу"
        actions={<Button onClick={handleRetry} size="small">Попробовать еще</Button>}
      >
        Произошла ошибка либо страница еще не создана, попробуйте позже
      </Placeholder>
    )
  }

  if (!info) {
    return <Loader isWrapped />;
  }

  switch (info.type) {
    case 'user':
      return <Profile profileId={info.id} />;
    case 'group':
      return <Group groupId={info.id} />;
    default:
      return <NotFound />;
  }
};
