import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toastActions } from 'store/toast';
import { USER_NAME_REGEX } from 'shared/constants';
import Birthday from 'shared/components/Birthday/Birthday';
import { authActions, authSelector } from '../../../store/auth';
import { Button, Form, FormItem, Input, ScrollView, Select } from '$uikit';
import { BottomBar } from '$uikit';
import { useTranslator } from '$hooks';

export default function Step1({ setStep }) {
  const dispatch = useDispatch();
  const t = useTranslator();

  const {
    registrationForm: {
      firstName,
      lastName,
      sex,
      bDate,
      code,
    }
  } = useSelector(authSelector);

  const handleChangeFirstName = useCallback(({ target }) => {
    dispatch(authActions.updateRegistraionForm({
      firstName: target.value,
    }));
  }, []);

  const handleChangeLastName = useCallback(({ target }) => {
    dispatch(authActions.updateRegistraionForm({
      lastName: target.value,
    }));
  }, []);

  const handleChangeCode = useCallback(({ target }) => {
    dispatch(authActions.updateRegistraionForm({
      code: target.value,
    }));
  }, []);

  const handleSexChange = useCallback(({ target }) => {
    dispatch(authActions.updateRegistraionForm({
      sex: +target.value,
    }));
  }, []);

  const handleBirthday = useCallback((newBirthday) => {
    dispatch(authActions.updateRegistraionForm({
      bDate: newBirthday,
    }));
  }, []);

  const handleFirstStep = useCallback(() => {
    if (firstName.trim().length < 2) {
      return dispatch(toastActions.setToastFail(t('reg_name_short')));
    }

    if (firstName.trim().length > 16) {
      return dispatch(toastActions.setToastFail(t('reg_name_long')));
    }

    if (!firstName.trim().match(USER_NAME_REGEX)) {
      return dispatch(toastActions.setToastFail(t('reg_name_wrong')));
    }

    if (lastName.trim().length < 2) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_short')));
    }

    // if (code.trim().length < 2) {
    //   return dispatch(toastActions.setToastFail(t('reg_code_required_error')));
    // }

    if (lastName.trim().length > 16) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_long')));
    }

    if (!lastName.trim().match(USER_NAME_REGEX)) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_wrong')));
    }

    if (!sex) {
      return dispatch(toastActions.setToastFail(t('reg_select_gender')));
    }

    dispatch(authActions.createAccount());
    //setStep(2);
  }, [setStep, dispatch, firstName, lastName, sex, code, t]);

  const handleCancel = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <>
      <ScrollView>
        <div className="Registration__title">
          {t('reg_step1_title')}
        </div>
        <Form>
          <FormItem title={t('reg_invite_code')} description={t('reg_invite_code_description')}>
            <Input
              value={code}
              onChange={handleChangeCode}
              placeholder={t('reg_enter_invite_code')}
            />
          </FormItem>
          <FormItem title={t('reg_name')}>
            <Input
              value={firstName}
              onChange={handleChangeFirstName}
              placeholder={t('reg_enter_name')}
            />
          </FormItem>
          <FormItem title={t('reg_last_name')}>
            <Input
              value={lastName}
              onChange={handleChangeLastName}
              placeholder={t('reg_enter_last_name')}
            />
          </FormItem>
          <FormItem title={t('reg_gender')}>
            <Select onChange={handleSexChange} value={sex}>
              <option value={0}>{t('reg_gender_not_selected')}</option>
              <option value={1}>{t('reg_gender_female')}</option>
              <option value={2}>{t('reg_gender_male')}</option>
            </Select>
          </FormItem>
          <FormItem title={t('reg_birthday')}>
            <Birthday birthday={bDate} onChange={handleBirthday} />
          </FormItem>
        </Form>
      </ScrollView>
      <BottomBar>
        <Button onClick={handleCancel} stretched mode="secondary">{t('cancel')}</Button>
        <Button onClick={handleFirstStep} stretched>{t('continue')}</Button>
      </BottomBar>
    </>
  );
}
