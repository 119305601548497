import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '$store/rootReducer';
import {
  FollowersState,
  LoadAction,
  LoadMoreAction, SetBirthdaysLoadedAction, SetFollowingAction,
  SetMoreResultAction,
  SetResultAction
} from '$store/followers/interface';
import { FollowersResultModel } from '$store/models';
import { FollowAction, UnfollowAction } from '$store/groups/interface';

const initialState: FollowersState = {
  isLoading: {},
  isInboxLoadingMore: {},
  isOutboxLoadingMore: {},
  inbox: {},
  outbox: {},
  inboxNextFrom: {},
  outboxNextFrom: {},
  following: {},
  birthdays: [],
  isBirthdaysLoading: true,
  isBirthdaysFailed: false,
};

export const { actions, reducer } = createSlice({
  name: 'followers',
  initialState,
  reducers: {
    load(state, action: LoadAction) {
      state.isLoading[action.payload] = true;
    },
    setResult(state, action: SetResultAction) {
      const { userId, inbox, outbox, inboxNextFrom, outboxNextFrom } = action.payload;

      delete state.isLoading[userId];
      state.inbox[userId] = inbox;
      state.outbox[userId] = outbox;
      state.inboxNextFrom[userId] = inboxNextFrom;
      state.outboxNextFrom[userId] = outboxNextFrom;
    },
    loadMore(state, action: LoadMoreAction) {
      const { userId, tab } = action.payload;

      if (tab === 'inbox') {
        state.isInboxLoadingMore[userId] = true;
      } else {
        state.isOutboxLoadingMore[userId] = true;
      }
    },
    setMoreResult(state, action: SetMoreResultAction) {
      const { userId, tab, items, nextFrom } = action.payload;

      let oldItems: FollowersResultModel[];
      if (tab === 'inbox') {
        state.inboxNextFrom[userId] = nextFrom;
        oldItems = state.inbox[userId] || [];
        delete state.isInboxLoadingMore[userId];
      } else {
        state.outboxNextFrom[userId] = nextFrom;
        oldItems = state.outbox[userId] || [];
        delete state.isOutboxLoadingMore[userId];
      }

      let existMap = {};
      for (let item of oldItems) {
        existMap[`${item.type}_${item.objectId}`] = true;
      }

      const resultItems = [...oldItems].concat(
        items.filter((item) => !existMap[`${item.type}_${item.objectId}`])
      );

      if (tab === 'inbox') {
        state.inbox[userId] = resultItems;
      } else {
        state.outbox[userId] = resultItems;
      }
    },
    setFollowing(state, action: SetFollowingAction) {
      state.following = {
        ...state.following,
        ...action.payload,
      };
    },
    follow(_, __: FollowAction) {},
    unfollow(_, __: UnfollowAction) {},
    loadBirthdays(state) {
      state.isBirthdaysLoading = true;
    },
    setBirthdaysLoaded(state, action: SetBirthdaysLoadedAction) {
      state.isBirthdaysLoading = false;
      state.birthdays = action.payload.userIds;
    },
    setBirthdaysFailed(state) {
      state.isBirthdaysLoading = false;
      state.isBirthdaysFailed = true;
    },
  }
});

export { reducer as followersReducer, actions as followersActions };

export const followersSelector = (state: RootState) => state.followers;

export const followersForUserSelector = createSelector(
  followersSelector,
  (_, userId: number) => userId,
  (followers, userId) => {
    return {
      isLoading: followers.isLoading[userId] || false,
      inbox: followers.inbox[userId] || [],
      outbox: followers.outbox[userId] || [],
      inboxNextFrom: followers.inboxNextFrom[userId] || null,
      outboxNextFrom: followers.outboxNextFrom[userId] || null,
      isInboxLoadingMore: followers.isInboxLoadingMore[userId] || null,
      isOutboxLoadingMore: followers.isOutboxLoadingMore[userId] || null,
    };
  },
);
