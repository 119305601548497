import { store } from '$store';
import { feedActions } from '$store/feed';
import { statReachGoal } from '$utils';

let deferredPrompt: any = null;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js')
    .catch(function() {});
}

export function requestAddToHomeScreen() {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        store.dispatch(feedActions.hideNotification('home_screen'));
        statReachGoal('add_to_home_screen');
      } else {
        alert('Неудалось добавить');
      }
      deferredPrompt = null;
    });
  } else {
    alert('Неудалось добавить, обновите страницу и попробуйте снова');
  }
}
