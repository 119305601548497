import { all, call, cancel, delay, fork, put, select, takeLatest, retry } from 'redux-saga/effects';

import { searchActions, searchSelector } from '$store/search/index';
import { Api } from '$api';
import { mainActions } from '$store/main';
import { LoadMoreAction, SearchAction } from '$store/search/interface';
import { followersActions } from '$store/followers';
import { batchActions } from '$store';

function* searchTask(isFast: boolean | undefined, tab: string, sort: string, city: number) {
  try {
    if (!isFast) {
      yield delay(500);
    } else {
      yield put(searchActions.reset());
    }

    const { query }  = yield select(searchSelector);
    const data = yield retry(Infinity, 3000, Api.post, '/search', {
      query: query.trim(),
      startFrom: 0,
      tab,
      sort,
      city,
    });

    const following: { [index: number]: boolean } = {};
    for (let item of data.items) {
      if (item.isFollowed) {
        following[item.type === 'group' ? -item.objectId : item.objectId] = item.isFollowed;
      }
    }

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        mainActions.setGroups(data.groups),
        searchActions.setResult({
          nextFrom: data.nextFrom,
          items: data.items,
        }),
        followersActions.setFollowing(following),
      ),
    );
  } catch (e) {}
}

let searchTaskInst
function* searchWorker(action: SearchAction) {
  if (searchTaskInst) {
    yield cancel(searchTaskInst);
    searchTaskInst = null
  }
  const { isFast, tab, sort, city } = action.payload;
  searchTaskInst = yield fork(searchTask, isFast, tab, sort, city);
}

function* loadMoreWorker(action: LoadMoreAction) {
  try {
    const { tab, sort, city } = action.payload;
    const { query, nextFrom } = yield select(searchSelector);
    const data = yield call(Api.post, '/search', {
      query: query.trim(),
      startFrom: +nextFrom,
      tab,
      sort,
      city,
    });

    const newState = yield select(searchSelector);
    if (newState.query.trim() != query.trim() || newState.nextFrom !== nextFrom) {
      return;
    }

    const exist = {};
    for (let item of newState.result) {
      exist[`${item.type}_${item.objectId}`] = true;
    }

    const following: { [index: number]: boolean } = {};
    for (let item of data.items) {
      if (item.isFollowed) {
        following[item.type === 'group' ? -item.objectId : item.objectId] = item.isFollowed;
      }
    }

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        mainActions.setGroups(data.groups),
        searchActions.setResult({
          nextFrom: data.nextFrom,
          items: newState.result
            .concat(
              data.items.filter((item) => !exist[`${item.type}_${item.objectId}`])
            ),
        }),
        followersActions.setFollowing(following),
      )
    );
  } catch (e) {}
}

export function* searchSaga() {
  yield all([
    takeLatest(searchActions.search, searchWorker),
    takeLatest(searchActions.loadMore, loadMoreWorker),
  ]);
}
