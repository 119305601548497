import { all, takeLatest, call, put, select, retry, delay, fork, cancel } from 'redux-saga/effects';
import { toastActions } from '$store/toast';
import { animeActions, animeSelector } from '$store/anime/index';

import { RootState } from '$store/rootReducer';
import { Api } from '$api';
import { attachActions, attachItemsSelector } from '$store/attach';
import { goBack, showModal } from '$navigation/helper';
import {
  DeleteListAction,
  EditAnimeAction,
  LoadAnimeInfoAction,
  LoadEditDataAction,
  LoadListAction,
  LoadListsAction,
  SaveAnimeListAction,
  SaveProgressAction,
  SharePostAction,
} from '$store/anime/interface';
import { ToggleLikeAction } from '$store/posts/interface';
import { authSelector } from '$store/auth';
import { AttachLink } from '$store/models';
import { batchActions } from '$store';
import router from '$navigation/router';
import { mainActions } from '$store/main';
import { ANIME_LISTS } from '$shared/constants/pages';

function* createAnimeWorker() {
  try {
    yield put(toastActions.setToastLoading('Сохранение..'));
    const data = yield select(({ anime }: RootState) => anime.edit[0] || {});
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `edit_anime`));
    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    const { anime_id } = yield call(Api.post, '/anime/create', {
      title: data.title,
      orig_title: data.origTitle,
      description: data.description,
      attachments,
      genres: data.genres,
      themes: data.themes,
      years: data.years,
      age_restriction: +data.ageRestriction,
    });

    yield put(toastActions.hideToast());
    yield put(animeActions.resetEditData(0));

    showModal(`edit_anime${anime_id}`);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadEditDataWorker(action: LoadEditDataAction) {
  try {
    const animeId = action.payload;
    const data = yield call(Api.get, `/anime/edit_data/${animeId}`);
    yield put(animeActions.setEditData({
      id: animeId,
      updated: {
        ...data,
        id: animeId,
        isLoading: false,
      },
    }));
    yield put(attachActions.initFromAttachments({
      pickerId: 'edit_anime',
      attachments: data.photos,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* editAnimeWorker(action: EditAnimeAction) {
  try {
    const animeId = action.payload;
    yield put(toastActions.setToastLoading('Сохранение..'));
    const data = yield select(({ anime }: RootState) => anime.edit[animeId] || {});
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `edit_anime`));
    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    yield call(Api.post, `/anime/edit/${animeId}`, {
      title: data.title,
      orig_title: data.origTitle,
      description: data.description,
      attachments,
      genres: data.genres,
      themes: data.themes,
      years: data.years,
      age_restriction: +data.ageRestriction,
      available: +data.available,
    });

    yield put(toastActions.hideToast());
    yield put(animeActions.resetEditData(animeId));
    yield call(goBack);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadExploreWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.get, '/anime');
    yield put(animeActions.setExplore({
      list: data.list,
      recent: data.recent,
      likes: data.likes,
      suggestions: data.suggestions,
      nextFrom: data.nextFrom,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadAnimeInfoWorker(action: LoadAnimeInfoAction) {
  try {
    const { animeId, season } = action.payload;

    const data = yield call(Api.get, `/anime/${animeId}`);
    yield put(animeActions.setAnimeInfo({
      animeId,
      info: data.info,
      seasons: data.seasons,
      videos: data.videos,
      seasonSelected: season,
      translations: data.translations,
      progress: data.progress,
      state: data.state,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* saveProgressWorker(action: SaveProgressAction) {
  try {
    const { isLogged } = yield select(authSelector);
    if (!isLogged) {
      return;
    }

    const { animeId, episodeId, duration, season, episode, translationId } = action.payload;
    yield call(Api.post, `/anime/progress/${animeId}/${episodeId}`, {
      duration,
      season,
      episode,
      translation_id: translationId,
    });
  } catch (e) {
    console.log('cant save progress', e);
  }
}

function* searchTask() {
  try {
    yield delay(500);
    const params = router.getState().params;
    const genre = params.genre || null;
    const theme = params.theme || null;

    const { searchQuery }  = yield select(animeSelector);
    const query = searchQuery.trim();
    if (!query.length && !genre && !theme) {
      yield put(animeActions.setSearchResult([]));
      return;
    }

    const data = yield call(Api.post, '/anime/search', {
      query,
      genre,
      theme,
    });
    yield put(
      batchActions(
        animeActions.setSearchResult(data.list),
        animeActions.setSearchNextFrom(data.nextFrom),
      )
    );
  } catch (e) {}
}

let searchTaskInst
function* searchWorker() {
  if (searchTaskInst) {
    yield cancel(searchTaskInst);
    searchTaskInst = null
  }
  searchTaskInst = yield fork(searchTask)
}

function* toggleLikeWorker(action: ToggleLikeAction) {
  const animeId = action.payload;
  const anime = yield select(({ anime }: RootState) => anime.animeInfo[animeId] || {});
  const isAddLike = !anime.info.isLiked;

  try {
    yield put(animeActions.updateAnimeInfo({
      animeId,
      updated: {
        isLiked: isAddLike,
        likes: isAddLike ? anime.info.likes + 1 : Math.max(0, anime.info.likes - 1),
      },
    }));

    const data = yield call(Api.post, `/likes/${animeId}/anime/${isAddLike ? 'add' : 'del'}`);
    yield put(animeActions.updateAnimeInfo({
      animeId,
      updated: {
        likes: +data.likes_count,
      },
    }));
  } catch (e) {
    if (e.message === 'already_liked') {
      return;
    }

    yield put(toastActions.setToastFail(e.message));
    yield put(animeActions.updateAnimeInfo({
      animeId,
      updated: {
        isLiked: anime.info.isLiked,
        likes: anime.info.likes,
      },
    }));
  }
}

function* sharePostWorker(action: SharePostAction) {
  try {
    const { currentUserId } = yield select(authSelector);
    const anime = action.payload;

    const attach: AttachLink = {
      type: 'anime',
      object: anime,
    };

    yield call(showModal, 'post');
    yield put(
      batchActions(
        attachActions.clear(`post_${currentUserId}`),
        attachActions.setLink({
          pickerId: `post_${currentUserId}`,
          link: attach,
        })
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadMoreWorker() {
  try {
    const { nextFrom, searchNextFrom, searchQuery } = yield select(animeSelector);
    const isSearch = searchQuery.trim().length > 0;
    const data = yield call(Api.post, '/anime/search', {
      query: isSearch ? searchQuery : '',
      startFrom: isSearch ? searchNextFrom : nextFrom,
    });

    if (isSearch) {
      yield put(
        batchActions(
          animeActions.searchLoadMoreEnd(data.list),
          animeActions.setSearchNextFrom(data.nextFrom),
        ),
      );
    } else {
      yield put(
        batchActions(
          animeActions.loadMoreEnd(data.list),
          animeActions.setNextFrom(data.nextFrom),
        ),
      );
    }
  } catch (e) {

  }
}

function* loadListsWorker(action: LoadListsAction) {
  try {
    const ownerId = action.payload;
    const data = yield call(Api.get, `/anime/lists/${ownerId}`);
    yield put(
      batchActions(
        mainActions.setUser(data.owner),
        mainActions.setAnimes(data.anime),
        animeActions.setListsLoaded(data.lists),
      ),
    );
  } catch (e) {
    yield put(animeActions.loadListsFailed());
  }
}

function* saveAnimeListWorker(action: SaveAnimeListAction) {
  try {
    const {
      listId,
      title,
      description,
      privacy,
      animeIds,
    } = action.payload;

    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, listId > 0 ? `/anime/list/${listId}/edit` : '/anime/list/create', {
      title,
      description,
      privacy,
      animeIds,
    });

    if (listId > 0) {
      yield call(goBack);

      const { listInfo } = yield select(animeSelector);
      if (listInfo && listInfo.id === listId) {
        const newInfo = {
          ...listInfo,
          title,
          description,
          privacy,
          animeIds,
        };
        yield put(animeActions.setListLoaded(newInfo));
      }
    } else {
      const { currentUserId } = yield select(authSelector);
      // @ts-ignore
      yield call(router.navigate, ANIME_LISTS, { ownerId: currentUserId }, {
        replace: true,
      });
      // data.listId
      yield put(animeActions.loadLists(currentUserId));
    }
    yield put(toastActions.hideToast());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadListWorker(action: LoadListAction) {
  try {
    const listId = action.payload;
    const data = yield call(Api.get, `/anime/list/${listId}`);
    yield put(
      batchActions(
        mainActions.setUser(data.owner),
        mainActions.setAnimes(data.anime),
        animeActions.setListLoaded(data.list),
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
    yield put(animeActions.loadListFailed());
  }
}

function* deleteListWorker(action: DeleteListAction) {
  try {
    if (!confirm('Вы действительно хотите удалить список? Отменить действие будет невозможно!')) {
      return;
    }

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/anime/list/${action.payload}/delete`);
    yield call(goBack);
    const { currentUserId } = yield select(authSelector);
    yield put(animeActions.loadLists(currentUserId));
    yield put(toastActions.hideToast());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* animeSaga() {
  yield all([
    takeLatest(animeActions.createAnime, createAnimeWorker),
    takeLatest(animeActions.loadEditData, loadEditDataWorker),
    takeLatest(animeActions.editAnime, editAnimeWorker),
    takeLatest(animeActions.loadExplore, loadExploreWorker),
    takeLatest(animeActions.loadAnimeInfo, loadAnimeInfoWorker),
    takeLatest(animeActions.saveProgress, saveProgressWorker),
    takeLatest(animeActions.search, searchWorker),
    takeLatest(animeActions.toggleLike, toggleLikeWorker),
    takeLatest(animeActions.sharePost, sharePostWorker),
    takeLatest(animeActions.loadMore, loadMoreWorker),
    takeLatest(animeActions.loadLists, loadListsWorker),
    takeLatest(animeActions.saveAnimeList, saveAnimeListWorker),
    takeLatest(animeActions.loadList, loadListWorker),
    takeLatest(animeActions.deleteList, deleteListWorker),
  ]);
}
