import './Post.sass';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router5';

import { ActionSheet, Button } from 'uikit';
import { IcAdd28, IcClose28, IcLike24, IcMore24, IcPin24, IcShare24, IcViews24 } from 'assets';
import { useDate, useUserInfo, usePost, useGroupInfo, useVisibleElement } from 'hooks';
import { postsActions } from 'store/posts';
import { Linkify, Attachments, ShareSheet, Online, Verified, Metatags } from '$shared/components';
import { GROUP, PROFILE, RESOLVE } from 'shared/constants/pages';
import { IcComment24 } from '$assets';
import { showModal } from '$navigation/helper';
import { AttachLink } from '$store/models';
import { gram, rnbr } from '$utils';
import { profileActions } from '$store/profile';
import { followersActions, followersSelector } from '$store/followers';
import { mainActions } from '$store/main';

export function Post({ postId, isFullPage = false, showFollowBtn = false }) {
  const dispatch = useDispatch();
  const post = usePost(postId);
  const user = useUserInfo(post.publisherId);
  const group = useGroupInfo(-post.ownerId);
  const date = useDate(post.createdAt);
  const [isMenuShown, setMenuShown] = useState(false);
  const canDelete = post.canDelete || group?.isModer;
  const postRef = useRef<HTMLDivElement>(null);
  const { following } = useSelector(followersSelector);

  const { visible: isVisible } = useVisibleElement(postRef);
  const isFollowed = following[post.ownerId];

  useEffect(() => {
    if (isVisible && postRef.current) {
      dispatch(postsActions.readPost(postId));
    }
  }, [isVisible]);

  const handleLike = useCallback(() => {
    dispatch(postsActions.toggleLike(postId));
  }, [dispatch, postId]);

  const isScheduled = useMemo(() => {
    return post.createdAt * 1000 - Date.now() > 10 * 1000; // ten sec
  }, [post.createdAt]);

  const authorInfo = useMemo(() => {
    if (post.ownerId < 0) {
      return {
        name: group.name,
        photo: group.photo,
        route: group.domain ? RESOLVE : GROUP,
        routeParams: group.domain ? { domain: group.domain } : { id: group.id },
      };
    } else {
      return {
        name: `${user.firstName} ${user.lastName}`,
        photo: user.photo,
        route: user.domain ? RESOLVE : PROFILE,
        routeParams: user.domain ? { domain: user.domain } : { id: user.id },
      };
    }
  }, [post.ownerId, group, user]);

  const text = useMemo(() => {
    let isTextMinimized = false;
    let text;
    if (!isFullPage && post.text.length > 220) {
      isTextMinimized = true;
      text = post.text.substr(0, 200) + '...';
    } else {
      text = post.text;
    }

    return {
      value: text,
      isTextMinimized
    }
  }, [post.text, isFullPage]);

  const actions = useMemo(() => {
    const now = Date.now() / 1000;
    const result: { label: string; value: string }[] = [];

    if (now - post.createdAt < 86400 && canDelete) {
      result.push({ label: `Редактировать`, value: 'edit' });
    }

    if (canDelete && !post.pinned) {
      result.push({ label: `Закрепить`, value: 'pin' });
    }

    if (canDelete && post.pinned) {
      result.push({ label: `Открепить`, value: 'unpin' });
    }

    if (canDelete) {
      result.push({ label: `Удалить`, value: 'delete' });
    }

    return result;
  }, [post.createdAt, canDelete, post.pinned]);

  const handleClick = useCallback((e) => {
    const target = e.target;
    if (target.closest('.Post__repost')) {
      e.stopPropagation();
    }

    if (target.closest('.Post__likes')) {
      showModal(`likes_post${postId}`);
      return;
    }

    if (target.closest('.Post__header__more')) {
      setMenuShown(true);
      return;
    }

    if (target.closest('.Post__header__follow')) {
      if (isFollowed) {
        dispatch(followersActions.unfollow(post.ownerId));
      } else {
        dispatch(followersActions.follow(post.ownerId));
      }
      return;
    }

    if (target.closest('.Collage__item')) {
      const el = target.closest('.Collage__item');
      const photoId = el.dataset.id;

      if (el.classList.contains('video')) {
        showModal(`video+post${postId}+${photoId}`);
      } else {
        showModal(`photos+post${postId}+${photoId}`);
      }
      return;
    }

    if (isFullPage || target.closest('.ExternalVideo') || target.closest('.InlineVideo') || target.closest('a')
      || target.closest('.link') || target.closest('.Button')
      || target.closest('.ActionSheet')) {
      return;
    }

    showModal(`post${post.id}`);
  }, [dispatch, isFullPage, post.id, setMenuShown, post.ownerId, isFollowed]);

  const handleComment = useCallback(() => {
    showModal(`post${post.id}`);
  }, [post.id]);

  const handleMenu = useCallback((value) => {
    setMenuShown(false);

    if (value === 'delete') {
      dispatch(postsActions.deletePost(postId));
    } else if (value === 'edit') {
      showModal(`edit_post${postId}`);
    } else if (value === 'pin') {
      dispatch(postsActions.pinPost(postId));
    } else if (value === 'unpin') {
      dispatch(postsActions.unpinPost(postId));
    }
  }, [dispatch, postId, setMenuShown]);

  const handleRestore = useCallback(() => {
    dispatch(postsActions.restorePost(postId));
  }, [dispatch, postId]);

  const handleFollow = useCallback(() => {
    dispatch(profileActions.follow(post.ownerId));
  }, [dispatch]);

  const handleRepost = useCallback(() => {
    const link: AttachLink = {
      type: 'post',
      object: post,
    };

    if (post.ownerId > 0) {
      link.extra = user;
    } else {
      link.extra = group;
    }

    dispatch(mainActions.setPopup(
      <ShareSheet title="Поделиться записью" link={link} />
    ));
  }, [dispatch, post, user, group]);

  const handlePublish = useCallback(() => {
    dispatch(postsActions.publishScheduled(postId));
  }, [postId, dispatch]);

  if (post.isDeleted) {
    return (
      <div className="Post Post__deleted">
        <div className="Post__deleted__label">
          Запись удалена
        </div>
        <div className="Post__deleted__actions">
          <Button mode="secondary" size="small" onClick={handleRestore}>Восстановить</Button>
        </div>
      </div>
    );
  }

  function renderFooter() {
    if (!isScheduled) {
      return (
        <>
          <div className="Post__footer__actions">
            <Button
              size="small"
              mode={post.isLiked ? 'red' : 'secondary'}
              icon={<IcLike24 />}
              onClick={handleLike}
            />
            {!isFullPage && <Button
              size="small"
              mode="secondary"
              icon={<IcComment24 />}
              onClick={handleComment}
            >
              {post.comments > 0 ? post.comments : null}
            </Button>}
            <Button
              size="small"
              mode="secondary"
              icon={<IcShare24 />}
              onClick={handleRepost}
            >
              {post.shares > 0 ? post.shares : null}
            </Button>
          </div>
          {post.views > 0 ? (
            <div className="Post__views">
              <div className="Post__views__icon">
                <IcViews24 />
              </div>
              {post.views}
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div className="Post__publish_now_button">
          <Button size="small" onClick={handlePublish}>Опубликовать сейчас</Button>
        </div>
      );
    }
  }

  return (
    <article className="Post" onClick={handleClick} ref={postRef}>
      {isFullPage && (
        <Metatags
          title={`${authorInfo.name}: ${text.value.substring(0, 30)}`}
          description={text.value.substring(0, 300)}
          canonical={`https://anime.fans/post${post.id}`}
        />
      )}
      {isMenuShown && (
        <ActionSheet
          isOpened
          options={actions}
          onChange={handleMenu}
          selected={0}
        />
      )}
      <div className="Post__header">
        <Link routeName={authorInfo.route} routeParams={authorInfo.routeParams}>
          <div
            className="Post__header__photo"
            style={{ backgroundImage: `url(${authorInfo.photo})` }}
          >
            <Online userId={post.ownerId} inPhoto />
          </div>
        </Link>
        <Link routeName={authorInfo.route} routeParams={authorInfo.routeParams}>
          <div className="Post__header__content">
            <div className="Post__header__name_wrap">
              <div className="Post__header__name">{authorInfo.name}</div>
              <Verified objectId={post.ownerId} />
            </div>
            <div className="Post__header__date_wrap">
              <div className="Post__header__date">{date}</div>
              {post.pinned && (
                <div className="Post__header__pinned">
                  <IcPin24 />
                </div>
              )}
            </div>
          </div>
        </Link>
        {showFollowBtn && (
          <div className="Post__header__follow">
            <Button
              icon={isFollowed ? <IcClose28 /> : <IcAdd28 />}
              size="small"
              mode={isFollowed ? 'secondary' : 'default'}
             />
          </div>
        )}
        {actions.length > 0 && <div className="Post__header__more">
          <IcMore24 />
        </div>}
      </div>
      {text.value.length > 0 && <div className="Post__text">
        <Linkify>
          {rnbr(text.value)}
          {text.isTextMinimized && <div className="Post__text__expand">Показать все</div>}
        </Linkify>
      </div>}
      <Attachments attachments={post.attachments} parentObject={`post_${postId}`} canExpand />
      {post.likes > 0 && (
        <div className="Post__likes">{gram(post.likes, ['лайк', 'лайка', 'лайков'])}</div>
      )}
      <div className="Post__footer">
        {renderFooter()}
      </div>
    </article>
  );
}
