import './InlineVideo.sass';

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InlineVideoProps } from './InlineVideo.interface';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions, mainSelector } from '$store/main';
import { useVisibleElement } from '$hooks';
import { IcPlay28 } from '$assets';

export const InlineVideo: FC<InlineVideoProps> = (props) => {
  const {
    parentObject,
    poster,
    source,
  } = props;

  const dispatch = useDispatch();
  const { playingVideo } = useSelector(mainSelector);
  const [isPlaying, setPlaying] = useState(false);

  const elem = useRef(null);
  const { visible } = useVisibleElement(elem);

  useEffect(() => {
    if (!visible && isPlaying) {
      setPlaying(false);
    }
  }, [visible]);

  useEffect(() => {
    if (isPlaying && playingVideo !== parentObject) {
      setPlaying(false);
    }
  }, [playingVideo]);

  const handlePlay = useCallback(() => {
    setPlaying(true);
    dispatch(mainActions.setPlayingVideo(parentObject));
  }, [setPlaying, dispatch]);

  const src = useMemo(() => {
    try {
      const parsed = JSON.parse(source);
      return parsed[0].path;
    } catch (e) {
      return '';
    }
  }, [source]);

  return (
    <div className="InlineVideo" ref={elem}>
      {!isPlaying && (
        <div className="InlineVideo__info" style={{ backgroundImage: `url(${poster})` }} onClick={handlePlay}>
          <div className="ExternalVideo__play_btn">
            <IcPlay28 />
          </div>
        </div>
      )}
      {isPlaying && (
        <video
          autoPlay
          src={src}
          poster={poster}
          controls
        />
      )}
    </div>
  );
};
