import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  AttachBodyItem,
  AttachState, ClearAction, InitFromAttachmentsAction, LoadLinkAction,
  PushPhotosAction, RemoveLinkAction,
  RemovePhotoAction,
  SetLinkAction,
  SetLoadedLinkAction,
  SetTimerAction,
  UpdatePhotoAction,
  UploadPhotosAction, UploadVideoAction
} from './interface';
import { RootState } from 'store/rootReducer';
import { AttachItem } from '$store/models';

const initialState: AttachState = {
  photos: {},
  links: {},
  deleteLinks: {},
  linkLoading: {},
  timers: {},
  linkFailed: {},
};

export const { actions, reducer } = createSlice({
  name: 'attach',
  initialState,
  reducers: {
    uploadPhotos(_, action: UploadPhotosAction) {},
    uploadVideo(_, action: UploadVideoAction) {},
    pushPhotos(state, action: PushPhotosAction) {
      const { pickerId, photos } = action.payload;

      state.photos[pickerId] = [
        ...(state.photos[pickerId] || []),
        ...photos,
      ];
    },
    removePhoto(state, action: RemovePhotoAction) {
      const { pickerId, photoId } = action.payload;

      if (state.photos[pickerId]) {
        let newPhotos = [...state.photos[pickerId]];
        for (let i = 0; i < newPhotos.length; i++) {
          if (newPhotos[i].photoId === photoId) {
            newPhotos.splice(i, 1);
            break;
          }
        }
        state.photos[pickerId] = newPhotos;
      }
    },
    updatePhoto(state, action: UpdatePhotoAction) {
      const { pickerId, photoId, updated } = action.payload;

      if (state.photos[pickerId]) {
        let newPhotos = [...state.photos[pickerId]];
        for (let i = 0; i < newPhotos.length; i++) {
          if (newPhotos[i].photoId === photoId) {
            newPhotos[i] = {
              ...newPhotos[i],
              ...updated,
            };
            break;
          }
        }
        state.photos[pickerId] = newPhotos;
      }
    },
    clear(state, action: ClearAction) {
      delete state.photos[action.payload];
      delete state.links[action.payload];
      delete state.timers[action.payload];
    },
    initFromAttachments(state, action: InitFromAttachmentsAction) {
      const { pickerId, attachments } = action.payload;

      console.log('here', attachments);
      state.photos[pickerId] = attachments.map((item: AttachItem) => {
        if (item.type === 'photo') {
          const photo = item.photo!;
          return {
            photoId: photo.photoId,
            photoMedium: photo.photoMedium,
            photoLarge: photo.photoLarge,
            width: photo.width,
            height: photo.height,
          };
        } else {
          const video = item.video!;
          return {
            isVideo: true,
            photoId: video.videoId,
            photoMedium: video.photoMedium,
            photoLarge: video.photoLarge,
            width: video.width,
            height: video.height,
          };
        }
      });
    },
    loadLink(state, action: LoadLinkAction) {
      const { pickerId } = action.payload;

      if (!state.links[pickerId]) {
        state.linkLoading[pickerId] = true;
      }
    },
    setLink(state, action: SetLinkAction) {
      const { pickerId, link } = action.payload;

      state.links[pickerId] = link;
    },
    setLoadedLink(state, action: SetLoadedLinkAction) {
      delete state.linkLoading[action.payload];
    },
    setLinkFailed(state, action: SetLoadedLinkAction) {
      state.linkFailed[action.payload] = true;
    },
    removeLink(state, action: RemoveLinkAction) {
      delete state.links[action.payload];
      state.deleteLinks[action.payload] = true;
    },
    setTimer(state, action: SetTimerAction) {
      const { pickerId, time } = action.payload;

      if (time) {
        state.timers[pickerId] = time;
      } else {
        delete state.timers[pickerId];
      }
    }
  },
});

export { reducer as attachReducer, actions as attachActions };

export const attachSelector = (state: RootState) => state.attach;

export const attachPhotosSelector = createSelector(
  attachSelector,
  (_, pickerId: string) => pickerId,
  (attach, pickerId) => {
    return attach.photos[pickerId] || [];
  },
);

export const attachLinksSelector = createSelector(
  attachSelector,
  (_, pickerId: string) => pickerId,
  (attach, pickerId) => {
    return attach.links[pickerId];
  },
);

export const attachTimerSelector = createSelector(
  attachSelector,
  (_, pickerId: string) => pickerId,
  (attach, pickerId) => {
    return attach.timers[pickerId];
  },
);

export const attachItemsSelector = createSelector(
  attachSelector,
  (_, pickerId: string) => pickerId,
  (attach, pickerId) => {
    const photos = attach.photos[pickerId] || [];
    const link = attach.links[pickerId];

    let result: AttachBodyItem[] = [];
    for (let photo of photos) {
      if (photo.photoId < 0) {
        return 'photo_uploading';
      }

      if (photo.isVideo) {
        result.push({
          type: 'video',
          video: {
            videoId: photo.photoId,
          },
        });
      } else {
        result.push({
          type: 'photo',
          photo: {
            photoId: photo.photoId,
          },
        });
      }
    }

    if (link) {
      result.push({
        type: 'link',
        link: `${link.type}${link.object.id}`,
      });
    }

    return result;
  },
);
