import { getMessaging, getToken } from 'firebase/messaging';

import { Api } from '$api';
import { store } from '$store';
import { mainActions } from '$store/main';
import { getDeviceId, isNative, isNotificationsSupported } from '$utils';
import { sendNativeEvent } from './native';

export function initPush() {
  if (isNative(2)) {
    sendNativeEvent('update_push_token');
    return;
  }

  if (!isNotificationsSupported()) {
    return;
  }

  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      subscribe();
    } else {
      console.log('need permissions')
    }
  }
}

export function subscribe() {
  const messaging = getMessaging();
  getToken(messaging, { vapidKey: 'BOjV86peW_vCTD89YUBswd4ZqLgBhG8h4sLADjLLlHsQeKC-b6jH-3L8T6ZRIBU7vSU8A8XKHRF9gAPEiskJtf0' })
    .then((token) => {
      const device = getDeviceId();

      Api.post('/account/push_token', {
        device,
        token,
        platform: 'web',
      })
        .catch((err) => console.log('err', err));

      store.dispatch(mainActions.setNotifyEnabled(true));
    })
    .catch((err) => {
      console.log('subscribe err', err);
    });
}
