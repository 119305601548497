import './Navbar.sass';

import React, { FC, useCallback } from 'react';
import { Link, useRoute } from 'react-router5';
import { useSelector } from 'react-redux';

import { NavbarItemProps, NavbarProps } from '$shared/components/Navbar/Navbar.constant';
import {
  FEED,
  FIND_FRIENDS,
  GROUPS,
  IM,
  NOTIFY,
  OTHER,
  PROFILE,
  RESOLVE,
  SEARCH,
  VIDEOS
} from '$shared/constants/pages';
import { useCurrentUserInfo, useTranslator } from '$hooks';
import { classNames } from '$utils';
import { ModalLink } from '$shared/components';
import {
  IcDiscover28,
  IcHome28,
  IcMenu28,
  IcMessage28,
  IcNotifications28,
  IcUser28,
  IcUserPlus28,
  IcUsers28,
  IcSearch28,
} from '$assets';
import { mainSelector } from '$store/main';
import { Badge } from '$uikit';
import { feedActions } from '$store/feed';

const NavbarItem: FC<NavbarItemProps> = (props) => {
  const {
    children,
    routeName,
    routeParams,
    isActive = false,
    isModal = false,
    icon,
    counter = 0,
    badge = false,
  } = props;

  const handleClick = useCallback(() => {
    if (isActive) {
      const scrollNode = document.querySelector('.ScrollView');
      if (scrollNode) {
        scrollNode.scrollTop = 0;
      }
    }
  }, [isActive]);

  let Component = Link;
  if (isModal) {
    Component = ModalLink;
  }

  return (
    <Component
      className={classNames({
        NavbarItem: true,
        active: isActive,
      })}
      routeName={routeName}
      routeParams={routeParams}
      onClick={handleClick}
    >
      <div className="NavbarItem__icon">
        {icon}
      </div>
      <div className="NavbarItem__label">
        {children}
      </div>
      {counter > 0 && (
        <div className="NavbarItem__counter">
          <Badge count={counter} />
        </div>
      )}
      {badge && (
        <div className="NavbarItem__badge" />
      )}
    </Component>
  );
};

export const Navbar: FC<NavbarProps> = ({ activeTab }) => {
  const user = useCurrentUserInfo();
  const { route } = useRoute();
  const { newDialogs, hasNotify } = useSelector(mainSelector);
  const t = useTranslator();

  return (
    <div className="Navbar">
      <Link routeName={FEED} className="Navbar__logo">
        <div className="Feed_logo_img" />
      </Link>
      <NavbarItem
        routeName={user.domain ? RESOLVE : PROFILE}
        routeParams={user.domain ? { domain: user.domain } : { id: user.id }}
        isActive={route.name === PROFILE && route.params?.id === user.id}
        icon={<IcUser28 />}
      >{t('navbar_my_page')}</NavbarItem>
      <NavbarItem
        routeName={FEED}
        isActive={route.name === FEED}
        icon={<IcHome28 />}
      >{t('navbar_news')}</NavbarItem>
      <NavbarItem
        routeName={VIDEOS}
        isActive={route.name === VIDEOS}
        icon={<IcDiscover28 />}
      >{t('navbar_anime')}</NavbarItem>
      <NavbarItem
        routeName={IM}
        isActive={route.name === IM}
        icon={<IcMessage28 />}
        counter={newDialogs}
      >{t('navbar_im')}</NavbarItem>
      <NavbarItem
        routeName={NOTIFY}
        isActive={route.name === NOTIFY}
        icon={<IcNotifications28 />}
        badge={hasNotify}
      >{t('navbar_notify')}</NavbarItem>
      <NavbarItem
        routeName={GROUPS}
        isActive={route.name === GROUPS}
        icon={<IcUsers28 />}
      >{t('navbar_groups')}</NavbarItem>
      <NavbarItem
        routeName={SEARCH}
        isActive={route.name === SEARCH}
        icon={<IcSearch28 />}
      >{t('navbar_search')}</NavbarItem>
      <NavbarItem
        routeName={FIND_FRIENDS}
        isActive={route.name === FIND_FRIENDS}
        icon={<IcUserPlus28 />}
      >{t('navbar_find_friends')}</NavbarItem>
      <NavbarItem
        routeName={OTHER}
        isActive={route.name === OTHER}
        icon={<IcMenu28 />}
      >{t('navbar_more')}</NavbarItem>
    </div>
  );
};
