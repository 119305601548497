import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';
import {
  ChatSetFormTextAction, DeleteDialogAction, DeleteMessageAction,
  ImState,
  LoadChatAction,
  ReadHistoryAction,
  RemoveDialogAction,
  RetrySendAction,
  SendMessageAction,
  SetChatFailedAction,
  SetDialogsAction,
  SetHistoryAction,
  SetMessageAction,
  SetWriteIdsAction,
} from '$store/im/interface';
import { DialogModel } from '$store/models';

const initialState: ImState = {
  isDialogsLoading: true,
  dialogs: {},
  history: {},
  info: {},
  isWriteLoading: true,
  writeIds: [],
};

export const { actions, reducer } = createSlice({
  name: 'im',
  initialState,
  reducers: {
    loadDialogs(state) {
      state.isDialogsLoading = true;
    },
    setDialogs(state, action: SetDialogsAction) {
      const { dialogs, messages } = action.payload;

      const history = { ...state.history };
      const dialogsMap: { [index: number]: DialogModel } = {};
      for (let dialog of dialogs) {
        const message = messages[dialog.lastMsgId];
        dialogsMap[dialog.peerId] = dialog;

        if (dialog.lastMsgId > 0 && message) {
          history[dialog.peerId] = {
            ...(history[dialog.peerId] || {}),
            [message.id]: message,
          };
        }
      }

      state.dialogs = {
        ...state.dialogs,
        ...dialogsMap,
      };
      state.history = history;
      state.isDialogsLoading = false;
    },
    loadChat(state, action: LoadChatAction) {
      const peerId = action.payload;

      state.info[peerId] = {
        ...(state.info[peerId] || {}),
        isLoading: true,
        isFailed: false,
      };
    },
    chatSetFormText(state, action: ChatSetFormTextAction) {
      const { peerId, text } = action.payload;

      state.info[peerId] = {
        ...(state.info[peerId] || {}),
        text,
      };
    },
    sendMessage(_, action: SendMessageAction) {},
    setHistory(state, action: SetHistoryAction) {
      const { peerId, history } = action.payload;

      state.history[peerId] = {
        ...(state.history[peerId] || {}),
        ...history,
      };
      state.info[peerId] = {
        ...(state.info[peerId] || {}),
        isLoading: false,
      };
    },
    setChatFailed(state, action: SetChatFailedAction) {
      const { peerId, reason } = action.payload;

      state.info[peerId] = {
        ...(state.info[peerId] || {}),
        isLoading: false,
        isFailed: true,
        failReason: reason,
      };
    },
    setMessage(state, action: SetMessageAction) {
      const { peerId, message, messageId } = action.payload;

      const messages = {
        ...(state.history[peerId] || {}),
        [message.id]: message,
      };

      if (messageId !== message.id) {
        delete messages[messageId];
      }

      state.history[peerId] = messages;
    },
    retrySend(_, __: RetrySendAction) {},
    readHistory(_, __: ReadHistoryAction) {},
    loadWrite(state) {
      state.isWriteLoading = true;
    },
    setWriteIds(state, action: SetWriteIdsAction) {
      state.isWriteLoading = false;
      state.writeIds = action.payload;
    },
    deleteDialog(_, __: DeleteDialogAction) {},
    removeDialog(state, action: RemoveDialogAction) {
      delete state.dialogs[action.payload];
      delete state.history[action.payload];
    },
    deleteMessage(_, __: DeleteMessageAction) {},
    removeMessage(state, action: DeleteMessageAction) {
      const { id, peerId } = action.payload;
      if (state.history[peerId]) {
        delete state.history[peerId][id];
      }
    },
  },
});

export { reducer as imReducer, actions as imActions };

export const imSelector = (state: RootState) => state.im;

export const imHistorySelector = createSelector(
  imSelector,
  (_, peerId: number) => peerId,
  (im, peerId) => {
    return im.history[peerId] || {};
  },
);

export const imChatInfoSelector = createSelector(
  imSelector,
  (_, peerId: number) => peerId,
  (im, peerId) => {
    return im.info[peerId] || { isLoading: true };
  },
);
