import React, { useCallback, useState } from 'react';
import * as loadImage from 'blueimp-load-image';
import { useDispatch, useSelector } from 'react-redux';

import { toastActions } from '../../../store/toast';
import { authActions, authSelector } from '../../../store/auth';
import { Loader } from '$shared/components';
import { IcAdd28 } from '$assets';
import { BottomBar, ScrollView } from '$uikit';
import { Button } from '$uikit';
import { useTranslator } from '$hooks';

export default function Step3({ setStep }) {
  const dispatch = useDispatch();
  const t = useTranslator();

  const {
    registrationForm: {
      photo,
      isPhotoUploading,
    }
  } = useSelector(authSelector);

  const [key, setKey] = useState(0);

  const handleChange = useCallback(async ({ target: { files } }) => {
    const file = files[0];

    setKey(Date.now());

    if (file.type.substr(0, 6) !== 'image/' || file.type === 'image/gif') {
      return dispatch(toastActions.setToastFail(t('bad_photo_err')));
    }

    dispatch(authActions.updateRegistraionForm({
      isPhotoUploading: true,
    }));

    try {
      const data = await loadImage(file, {
        maxWidth: 1200,
        maxHeight: 1200,
        meta: true,
        canvas: true,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
        orientation: true,
      });

      const quality = 0.8;
      const base64 = data.image.toDataURL('image/jpeg', quality);
      dispatch(authActions.updateRegistraionForm({
        photo: base64,
      }));

      data.image.toBlob((blob) => {
        dispatch(authActions.registrationUploadPhoto(blob));
      }, 'image/jpeg', quality);
    } catch (e) {
      dispatch(toastActions.setToastFail(e.message));
      dispatch(authActions.updateRegistraionForm({
        isPhotoUploading: false,
        photo: null,
        photoId: 0,
      }));
    }
  }, [dispatch, t]);

  const handleDelete = useCallback(() => {
    dispatch(authActions.updateRegistraionForm({
      isPhotoUploading: false,
      photo: null,
      photoId: 0,
    }));
  }, []);

  const handlePrev = useCallback(() => {
    setStep(2);
  }, [setStep]);

  const handleNext = useCallback(() => {
    if (isPhotoUploading) {
      return dispatch(toastActions.setToastFail(t('photo_uploading_err')));
    }

    dispatch(authActions.createAccount());
  }, [dispatch, t, isPhotoUploading]);

  return (
    <>
      <ScrollView>
        <div className="Registration__title">
          {t('reg_step3_title')}
        </div>
        <div className="Registration__step3">
          <div
            className="Registration__photo_wrap"
            style={{ backgroundImage: `url(${photo})` }}
          >
            {isPhotoUploading && (
              <div className="Registration__photo__loader">
                <Loader />
              </div>
            )}
            {!photo && !isPhotoUploading && (
              <div
                className="Registration__photo__loader add_icon"
              >
                <IcAdd28 />
              </div>
            )}
            <input
              key={key}
              type="file"
              onChange={handleChange}
              accept="image/*"
            />
          </div>
          <div className="Registration__photo__remove_wrap">
            {photo && <Button mode="secondary" size="small" onClick={handleDelete}>{t('reg_delete_photo')}</Button>}
          </div>
        </div>
      </ScrollView>
      <BottomBar>
        <Button mode="secondary" onClick={handlePrev} stretched>{t('back')}</Button>
        <Button onClick={handleNext} stretched>{t('done')}</Button>
      </BottomBar>
    </>
  );
}
