import React, { createContext, useState, useCallback, useEffect, FC } from 'react';

import i18n, { Scope, TranslateOptions } from 'i18n-js';

import { FALLBACK_LANGUAGE, LanguageTags } from 'shared/constants';
import { pluralizeForRussian } from './helper';
import translations from './translations.js';

let LangDetected: string | null = null;
export function getLanguage() {
  if (LangDetected !== null) {
    return LangDetected;
  }

  let languageTag = i18n.currentLocale().substr(0, 2).toLowerCase();
  if (navigator.languages && navigator.languages.length > 0) {
    const avail = Object.keys(LanguageTags);
    for (let item of navigator.languages) {
      const prepared = item.substr(0, 2).toLowerCase();
      if (avail.indexOf(prepared) > -1) {
        languageTag = prepared;
        break;
      }
    }
  } else if (navigator.language) {
    languageTag = navigator.language.substr(0, 2).toLowerCase();
  }

  const available = Object.keys(LanguageTags);

  if (available.indexOf(languageTag) < 0) {
    languageTag = LanguageTags.ru;
  }

  LangDetected = languageTag;

  return languageTag;
}

export type TranslatorFunc = (scope: Scope, options?: TranslateOptions) => string;

export const TranslatorContext = createContext(
  (scope: Scope, options?: TranslateOptions) => i18n.t(scope, options),
);

export const TranslatorProvider: FC = ({ children }) => {
  const [hasLangChanged, setHasLangChanged] = useState(false);

  const t: TranslatorFunc = useCallback((scope, options?) => i18n.t(scope, options), [
    hasLangChanged,
  ]);

  const setI18nConfig = useCallback(() => {
    const languageTag = getLanguage();

    let keys = translations[languageTag];
    if (languageTag !== LanguageTags.ru) {
      keys = {
        ...translations[LanguageTags.ru],
        ...keys,
      };
    }

    i18n.translations = {
      [languageTag]: keys,
    };
    i18n.locale = languageTag;

    setHasLangChanged((prev) => !prev);
  }, []);

  useEffect(() => {
    setI18nConfig();
    addEventListener('change', setI18nConfig);
    return () => removeEventListener('change', setI18nConfig);
  }, []);

  return <TranslatorContext.Provider value={t}>{children}</TranslatorContext.Provider>;
};

i18n.pluralization.ru = pluralizeForRussian;
