import './SearchInput.sass';
import React, { FC, useCallback, useMemo } from 'react';
import { SearchInputProps } from '$uikit/SearchInput/SearchInput.interface';
import { Input } from '$uikit/index';
import { IcClose16 } from '$assets';

export const SearchInput: FC<SearchInputProps> = (props) => {
  const {
    query,
    onChange,
    rightButtons = null,
    placeholder,
  } = props;

  const queryPrepared = useMemo(() => {
    return query.trim();
  }, [query]);

  const handleChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className="SearchInput">
      <div className="SearchInput__input">
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          value={query}
        />
        {queryPrepared.length > 0 && (
          <div className="SearchInput__cancel" onClick={handleClear}>
            <IcClose16 />
          </div>
        )}
      </div>
      {rightButtons && (
        <div className="SearchInput__buttons">
          {rightButtons}
        </div>
      )}
    </div>
  );
};
