
const initialHeight = window.innerHeight;

let LastId = 0;
let Intervals = {};
export function watchOpen(callback) {
  let id = ++LastId;

  Intervals[id] = setInterval(() => {
    if (initialHeight - window.innerHeight > 150) {
      callback();
      clearInterval(Intervals[id]);
    }
  }, 50);

  return id;
}

export function stopWatch(id) {
  clearInterval(Intervals[id]);
}
