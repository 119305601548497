import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  ProfileState,
  LoadProfileAction,
  SetProfileAction,
  FollowAction,
  UnfollowAction,
  SetFollowStatus,
  EditProfileAction,
  SetStatusAction,
  SendInviteAction,
  LoadMorePostsAction,
  SetLoadMorePostsAction,
  UploadPhotoAction,
  CheckDomainAction,
  ChangeDomainAction
} from './interface';
import { RootState } from 'store/rootReducer';

const initialState: ProfileState = {
  userId: 0,
  isLoading: true,
  profileInfo: {},
};

export const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    loadProfile(state, action: LoadProfileAction) {
      state.userId = action.payload;
      state.isLoading = true;
    },
    setProfile(state, action: SetProfileAction) {
      state.userId = action.payload.userId;
      state.isLoading = false;

      state.profileInfo[action.payload.userId] = {
        isFollowFromMe: action.payload.isFollowFromMe,
        isFollowToMe: action.payload.isFollowToMe,
        postsCount: action.payload.postsCount,
        postsNextFrom: action.payload.postsNextFrom,
        isPostsLoadingMore: false,
        scheduledCount: action.payload.scheduledCount,
        animeListsCount: action.payload.animeListsCount,
      };
    },
    follow(_, action: FollowAction) {},
    unfollow(_, action: UnfollowAction) {},
    setFollowStatus(state, action: SetFollowStatus) {
      const { userId, isFollowed } = action.payload;

      state.profileInfo[userId] = {
        ...(state.profileInfo[userId] || {}),
        isFollowFromMe: isFollowed,
      };
    },
    editProfile(_, action: EditProfileAction) {},
    setStatus(_, action: SetStatusAction) {},
    sendInvite(_, action: SendInviteAction) {},
    loadMorePosts(state, action: LoadMorePostsAction) {
      state.profileInfo[action.payload].isPostsLoadingMore = true;
    },
    setLoadMorePosts(state, action: SetLoadMorePostsAction) {
      const { userId, nextFrom } = action.payload;

      if (state.profileInfo[userId]) {
        state.profileInfo[userId].isPostsLoadingMore = false;
        state.profileInfo[userId].postsNextFrom = nextFrom;
      }
    },
    uploadPhoto(_, action: UploadPhotoAction) {},
    checkDomain(_, action: CheckDomainAction) {},
    changeDomain(_, action: ChangeDomainAction) {},
  },
});

export { reducer as profileReducer, actions as profileActions };

export const profileSelector = (state: RootState) => state.profile;

export const profileInfoSelector = createSelector(
  profileSelector,
  (_, profileId: number) => profileId,
  (profile, profileId) => {
    return profile.profileInfo[profileId] || { isEmpty: true };
  },
);
