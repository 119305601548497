import './ViewPost.sass';

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header, ScrollView } from 'uikit';
import { useGroupInfo, useLoadMore, usePost, usePosts, useUserInfo } from 'hooks';
import { postsActions, postsSelector } from 'store/posts';
import { Loader, Metatags, Post } from '$shared/components';
import { format, gram, parseLinkData } from '$utils';
import CommentForm from '../../shared/components/CommentForm/CommentForm';
import { Comment } from '$shared/components';

export function ViewPost({ postId }) {
  const dispatch = useDispatch();
  const post = usePost(postId);
  const { isPostLoading, isCommentsLoadMore, commentsNextFrom } = useSelector(postsSelector);
  const comments = usePosts(`comments_${post?.id}`);
  const user = useUserInfo(post?.ownerId ?? 0);
  const group = useGroupInfo(-post?.ownerId ?? 0);

  useLoadMore({
    handler: () => dispatch(postsActions.loadMoreComments(postId)),
    enabled: !isPostLoading[postId] && !!post && !isCommentsLoadMore[postId] && !!commentsNextFrom[postId],
  });

  useEffect(() => {
    dispatch(postsActions.loadPost(postId))
  }, [dispatch]);

  const metaTitle = useMemo(() => {
    if (!post) {
      return '';
    }
    const date = format(post.createdAt * 1000, 'd MMMM yyyy в H:i');
    if (post.ownerId < 0) {
      return `Запись на стене сообщества от ${date} | ${group?.name} | Anime.Fans`;
    }  else {
      return `Запись на стене от ${date} | ${user?.firstName} ${user?.lastName} | Anime.Fans`;
    }
  }, [post, user, group]);

  const metaDescription = useMemo(() => {
    if (!post) {
      return '';
    }

    let result = [post.text];

    if (post.attachments) {
      const photos = post.attachments.filter((item) => item.type === 'photo');
      if (photos.length > 0) {
        result.push(gram(photos.length, ['фотография', 'фотографии', 'фотографий']));
      }

      const link = post.attachments.filter((item) => item.type === 'link');
      if (link.length > 0) {
        const linkData = parseLinkData(link[0].link!);
        result.push(`${linkData.type}: ${linkData.title} → ${linkData.description}. ${linkData.buttonText}`);
      }
    }

    return result.filter(item => item.length > 0).join('. ');
  }, [post]);


  function renderContent() {
    if (isPostLoading[postId] && !post || !post) {
      return (
        <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Loader />
        </div>
      )
    }

    return (
      <>
        <ScrollView>
          <div className="ViewPost">
            <Post
              postId={postId}
              isFullPage
            />
            <div className="ViewPost__comments">
              {post.comments > 0 ? gram(
                post.comments,
                post.level === 0 ? ['комментарий', 'комментария', 'комментариев'] : ['ответ', 'ответа', 'ответов']
              ) : 'Нет комментариев'}
            </div>
            <div>
              {comments.map((commentId) => <Comment key={commentId} rootPostId={postId} commentId={commentId} />)}
            </div>
          </div>
        </ScrollView>
        <CommentForm parentId={post.id} />
      </>
    )
  }

  return (
    <>
      <Metatags
        title={metaTitle}
        description={metaDescription}
      />
      <Header showBackButton>{post?.level === 0 ? 'Запись' : 'Комментарий'}</Header>
      {renderContent()}
    </>
  );
}
