import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '$store/rootReducer';
import {
  attachActions,
  attachTimerSelector
} from '$store/attach';
import { IcClose16 } from '$assets';
import { useCallback } from 'react';
import { classNames, format, parse } from '$utils';
import { Input } from '$uikit';
import { roundToNearestMinutes } from 'date-fns';

export default function Timer({ pickerId }) {
  const timer = useSelector((state: RootState) => attachTimerSelector(state, pickerId));
  const dispatch = useDispatch();

  const handleRemove = useCallback(() => {
    dispatch(attachActions.setTimer({
      pickerId,
      time: null,
    }));
  }, [dispatch, pickerId]);

  const onChange = useCallback((e) => {
    let time = new Date(new Date(e.target.value).toISOString()).getTime();
    time = Math.max(time, Date.now() + 10 * 60 * 1000);
    time = roundToNearestMinutes(time, {
      nearestTo: 5,
    }).getTime();

    dispatch(attachActions.setTimer({
      pickerId,
      time,
    }));
  }, [dispatch]);

  const value = useMemo(() => {
    if (!timer) {
      return '';
    }

    return format(timer, 'yyyy-MM-dd', new Date()) + 'T' + format(timer, 'hh:mm', new Date());
  }, [timer]);

  if (!timer) {
    return null;
  }

  return (
    <div className={classNames({
      AttachPickerManager__link: true,
    })}>
      <div className="AttachPickerManager__link_header">
        <div className="AttachPickerManager__link_header_title">
          Таймер
        </div>
        <div className="AttachPickerManager__link_header_remove" onClick={handleRemove}>
          <IcClose16 />
        </div>
      </div>
      <div className="AttachPickerManager__timer">
        <Input
          type="datetime-local"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
}
