import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '$store/rootReducer';
import { attachActions, attachLinksSelector, attachPhotosSelector, attachSelector } from '$store/attach';
import { LinkAttach } from '$shared/components/LinkAttach/LinkAttach';
import { IcClose16 } from '$assets';
import { useCallback } from 'react';
import { Loader } from '$shared/components';
import { classNames } from '$utils';

export default function Link({ pickerId, mini = false }) {
  const link = useSelector((state: RootState) => attachLinksSelector(state, pickerId));
  const photos = useSelector((state: RootState) => attachPhotosSelector(state, pickerId));
  const dispatch = useDispatch();
  const { linkLoading } = useSelector(attachSelector);

  const handleRemove = useCallback(() => {
    dispatch(attachActions.removeLink(pickerId));
  }, [dispatch, pickerId]);

  if (!link) {
    if (linkLoading[pickerId]) {
      return (
        <div className="AttachPickerManager__link_loader">
          <Loader />
        </div>
      );
    }

    return null;
  }

  return (
    <div className={classNames({
      AttachPickerManager__link: true,
      mini,
    })}>
      <div className="AttachPickerManager__link_header">
        <div className="AttachPickerManager__link_header_title">
          Ссылка
        </div>
        <div className="AttachPickerManager__link_header_remove" onClick={handleRemove}>
          <IcClose16 />
        </div>
      </div>
      <LinkAttach link={link} mini={photos.length > 0} parentObject={`picker_${pickerId}`} />
    </div>
  );
}
