import React, { FC } from 'react';
import { ModalLinkProps } from '$shared/components/ModalLink/ModalLink.interface';
import { useRoute, Link } from 'react-router5';

export const ModalLink: FC<ModalLinkProps> = (props) => {
  const { route } = useRoute();

  const { name, children, className, routeOptions } = props;

  return (
    <Link
      className={className}
      routeName={route.name}
      routeOptions={routeOptions}
      routeParams={{
        ...route.params,
        w: name,
      }}
    >
      {children}
    </Link>
  );
};
