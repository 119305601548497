import './EditGroup.sass';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { gram } from '$utils';
import { groupsActions } from '$store/groups';
import { Button, CellButton, Checkbox, Form, FormItem, Header, Input, ScrollView } from '$uikit';
import { BottomBar } from '$uikit';
import { MAX_GROUP_DESCRIPTION, MAX_GROUP_LINK, MAX_GROUP_NAME } from '$shared/constants/groups';
import { useGroupInfo } from '$hooks';
import { EditGroupProps } from './EditGroup.interface';
import { IcStar28 } from '$assets';

export const EditGroup: FC<EditGroupProps> = ({ groupId }) => {
  const group = useGroupInfo(groupId);
  const dispatch = useDispatch();
  const [name, setName] = useState(group?.name ?? '');
  const [description, setDescription] = useState(group?.description ?? '');
  const [link, setLink] = useState(group?.link ?? '');
  const [isAdultOnly, setAdultOnly] = useState(group?.adultOnly ?? false);

  const handleName = useCallback(({ target }) => {
    setName(target.value.substr(0, MAX_GROUP_NAME));
  }, [setName]);

  const handleDescription = useCallback(({ target }) => {
    setDescription(target.value.substr(0, MAX_GROUP_DESCRIPTION));
  }, [setDescription]);

  const handleLink = useCallback(({ target }) => {
    setLink(target.value.substr(0, MAX_GROUP_LINK));
  }, [setLink]);

  const nameLabel = useMemo(() => {
    const avail = MAX_GROUP_NAME - name.length;
    return `${gram(avail, ['остался', 'осталось', 'осталось'], true)} ${gram(avail, ['символ', 'символа', 'символов'])}`;
  }, [name]);

  const descriptionLabel = useMemo(() => {
    const avail = MAX_GROUP_DESCRIPTION - description.length;
    return `${gram(avail, ['остался', 'осталось', 'осталось'], true)} ${gram(avail, ['символ', 'символа', 'символов'])}`;
  }, [description]);

  const handleSave = useCallback(() => {
    dispatch(groupsActions.saveGroup({ groupId, name, description, link, isAdultOnly }));
  }, [groupId, name, description, link, dispatch, isAdultOnly]);

  const handleToggleAdultOnly = useCallback(() => {
    setAdultOnly(!isAdultOnly);
  }, [isAdultOnly]);

  return (
    <>
      <Header showCloseButton>Ред. сообщество</Header>
      <ScrollView>
        <Form>
          <FormItem title="Название" description={nameLabel}>
            <Input
              placeholder="Введите название.."
              value={name}
              onChange={handleName}
              maxLength={MAX_GROUP_NAME}
            />
          </FormItem>
          <FormItem title="Описание" description={descriptionLabel}>
            <Input
              placeholder="Введите описание.."
              multiline
              value={description}
              onChange={handleDescription}
              maxLength={MAX_GROUP_DESCRIPTION}
            />
          </FormItem>
          <FormItem title="Ссылка" description="Укажите ссылку на ваш сайт">
            <Input
              placeholder="Введите ссылку"
              value={link}
              onChange={handleLink}
              maxLength={MAX_GROUP_LINK}
            />
          </FormItem>
          <FormItem title="Возрастное ограничение">
            <Checkbox checked={isAdultOnly} onClick={handleToggleAdultOnly} disabled={group?.forceAdult}>
              Только 18+
            </Checkbox>
          </FormItem>
        </Form>
        <CellButton
          icon={<IcStar28 />}
          modal={`group_admins${groupId}`}
        >
          Администраторы
        </CellButton>
      </ScrollView>
      <BottomBar fixed>
        <Button stretched onClick={handleSave}>Сохранить</Button>
      </BottomBar>
    </>
  );
};
