import './InlineHeader.sass';

import React, { FC } from 'react';
import { InlineHeaderProps } from './InlineHeader.interface';

export const InlineHeader: FC<InlineHeaderProps> = ({
  children,
  counter = null,
  caption = null,
}) => {
  return (
    <div className="InlineHeader">
      <div className="InlineHeader__title">
        {children}
        {!!counter && <div className="InlineHeader__counter">{counter}</div>}
      </div>
      {!!caption && <div className="InlineHeader__caption">{caption}</div>}
    </div>
  )
}
