import './EditAnime.sass';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, CellButton, Form, FormItem, Header, Input, ScrollView } from '$uikit';
import AsyncSelect from 'react-select/async';
import Select  from 'react-select';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { BottomBar } from '$uikit';
import { API_URL_PRODUCTION } from '$shared/constants';
import { IcAdd28, IcEdit28, IcGallery28 } from '$assets';
import { AttachPickerManagerRef } from '$shared/components/AttachPickerManager/AttachPickerManager.interface';
import AttachPickerManager from '$shared/components/AttachPickerManager/AttachPickerManager';
import { RootState } from '$store/rootReducer';
import { animeActions } from '$store/anime';

let Genres = {};
let Themes = {};

export function EditAnime({ animeId, isEdit = true }) {
  const attachManager = useRef<AttachPickerManagerRef | null>(null);
  const [photoInputKey, setPhotoInputKey] = useState(0);
  const dispatch = useDispatch();

  const data = useSelector(({ anime }: RootState) => anime.edit[animeId] || { isLoading: animeId !== 0 });

  useEffect(() => {
    if (animeId > 0 && !data.id) {
      dispatch(animeActions.loadEditData(animeId));
    }
  }, [animeId]);

  const handlePhotoChange = useCallback(({ target }) => {
    attachManager.current?.uploadImages(target.files);
    setPhotoInputKey(Date.now());
  }, [attachManager, setPhotoInputKey]);

  const handleInputChange = useCallback((field) => ({ target }) => {
    dispatch(animeActions.setEditData({
      id: animeId,
      updated: { [field]: target.value },
    }));
  }, [dispatch, animeId]);

  const handleSelectChange = useCallback((field) => (newValueRaw) => {
    let newValue: string;
    if (newValueRaw[0] && newValueRaw !== undefined) {
      newValue = newValueRaw.map((item) => item.value).join(',');
    } else {
      newValue = newValueRaw.value ? `${newValueRaw.value}` : '';
    }
    dispatch(animeActions.setEditData({
      id: animeId,
      updated: { [field]: newValue },
    }));
  }, [dispatch, animeId]);

  const handleSave = useCallback(() => {
    if (animeId > 0) {
      dispatch(animeActions.editAnime(animeId));
    } else {
      dispatch(animeActions.createAnime());
    }
  }, [dispatch, animeId]);

  const promiseGenres = inputValue =>
    new Promise<any[]>((resolve, reject) => {
      axios.get(`${API_URL_PRODUCTION}/anime/genres`)
        .then((resp) => {
          let options = resp.data.map((item) => ({
            value: +item.id,
            label: item.title,
          }));

          for (let option of options) {
            Genres[option.value] = option;
          }

          resolve(options);
        })
        .catch(() => {
          reject();
        })
    });

  const promiseThemes = inputValue =>
    new Promise<any[]>((resolve, reject) => {
      axios.get(`${API_URL_PRODUCTION}/anime/themes`)
        .then((resp) => {
          let options =  resp.data.map((item) => ({
            value: +item.id,
            label: item.title,
          }));

          for (let option of options) {
            Themes[option.value] = option;
          }

          resolve(options);
        })
        .catch(() => {
          reject();
        })
    });

  const yearOptions = useMemo(() => {
    const curYear = new Date().getFullYear();
    let result: {value: number; label: string}[] = [];
    for (let i = curYear; i >= 2000; i--) {
      result.push({
        value: i,
        label: `${i}`
      });
    }
    return result;
  }, []);

  const ageRestrictionValue = useMemo(() => {
    if (!data.ageRestriction) {
      return null;
    }

    return { value: data.ageRestriction, label: `${data.ageRestriction}+` };
  }, [data.ageRestriction]);

  const genresValue = useMemo(() => {
    if (!data.genres) {
      return null;
    }

    let sep = data.genres.split(',').map((item) => +item).filter((item) => !!item);
    let result: any[] = [];
    for (let genre of sep) {
      result.push({ value: genre, label: Genres[genre]?.label ?? '' });
    }

    return result;
  }, [data.genres, Genres]);

  const themesValue = useMemo(() => {
    if (!data.themes) {
      return null;
    }

    let sep = data.themes.split(',').map((item) => +item).filter((item) => !!item);
    let result: any[] = [];
    for (let theme of sep) {
      result.push({ value: theme, label: Themes[theme]?.label ?? '' });
    }

    return result;
  }, [data.themes, Themes]);

  const yearsValue = useMemo(() => {
    if (!data.years) {
      return null;
    }

    let sep = data.years.split(',').map((item) => +item).filter((item) => !!item);
    let result: any[] = [];
    for (let year of sep) {
      result.push({ value: year, label: year });
    }

    return result;
  }, [data.years]);

  return (
    <div className="EditAnime__wrap">
      <Header showCloseButton>{isEdit ? 'Ред. аниме' : 'Новое аниме'}</Header>
      <ScrollView>
        <Form>
          <FormItem title="Название">
            <Input
              maxLength={200}
              value={data.title}
              onChange={handleInputChange('title')}
            />
          </FormItem>
          <FormItem title="Оригинальное название">
            <Input
              maxLength={200}
              value={data.origTitle}
              onChange={handleInputChange('origTitle')}
            />
          </FormItem>
          <FormItem title="Описание">
            <Input
              multiline
              maxLength={3000}
              value={data.description}
              onChange={handleInputChange('description')}
            />
          </FormItem>
          <FormItem title="Возрастные ограничения">
            <Select
              classNamePrefix="Select"
              placeholder="Выберите ограничение"
              defaultOptions
              onChange={handleSelectChange('ageRestriction')}
              options={[
                {
                  value: 13,
                  label: '13+'
                },
                {
                  value: 16,
                  label: '16+'
                },
                {
                  value: 18,
                  label: '18+'
                }
              ]}
              menuPlacement="top"
              value={ageRestrictionValue}
            />
          </FormItem>
          <FormItem title="Жанры">
            <AsyncSelect
              classNamePrefix="Select"
              placeholder="Выберите жанр"
              cacheOptions
              defaultOptions
              isMulti
              loadOptions={promiseGenres}
              menuPlacement="top"
              value={genresValue}
              onChange={handleSelectChange('genres')}
            />
          </FormItem>
          <FormItem title="Темы">
            <AsyncSelect
              classNamePrefix="Select"
              placeholder="Выберите тему"
              cacheOptions
              defaultOptions
              isMulti
              loadOptions={promiseThemes}
              menuPlacement="top"
              value={themesValue}
              onChange={handleSelectChange('themes')}
            />
          </FormItem>
          <FormItem title="Года выпуска">
            <Select
              classNamePrefix="Select"
              placeholder="Выберите год"
              defaultOptions
              isMulti
              options={yearOptions}
              menuPlacement="top"
              value={yearsValue}
              onChange={handleSelectChange('years')}
            />
          </FormItem>
          <FormItem title="Постеры">
            <div>
              <AttachPickerManager
                mini
                ref={attachManager}
                pickerId={`edit_anime`}
              />
              <div className="EditAnime__photo_button">
                <Button
                  size="small"
                  mode="secondary"
                  icon={<IcGallery28 />}
                >Добавить</Button>
                <input
                  key={photoInputKey}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  multiple
                />
              </div>
            </div>
          </FormItem>
          <FormItem title="Переводы">
            {(data.translations || []).map((item) => (
              <CellButton key={item.id} icon={<IcEdit28 />} modal={`edit_anime_translation${item.id}`}>
                [{item.lang.toUpperCase()}] {item.name}
              </CellButton>
            ))}
            <CellButton icon={<IcAdd28 />}>
              Добавить
            </CellButton>
          </FormItem>
        </Form>
      </ScrollView>
      <br />
      <BottomBar fixed>
        <Button stretched onClick={handleSave}>Сохранить</Button>
      </BottomBar>
    </div>
  );
}
