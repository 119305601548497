import './Groups.sass';

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import * as _ from 'lodash';
import { useRoute } from 'react-router5';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Placeholder, ScrollView, Tabs, TabsItem } from '$uikit';
import { useDesktop, useTranslator } from '$hooks';
import { IcAdd28 } from '$assets';
import { groupsActions, groupsSelector } from '$store/groups';
import { Loader, Metatags, UserCell } from '$shared/components';
import router from '$navigation/router';
import { GROUPS } from '$shared/constants/pages';

export const Groups: FC = () => {
  const { route } = useRoute();
  const isDesktop = useDesktop();
  const dispatch = useDispatch();
  const t = useTranslator();
  const { groups, isGroupsLoading, managed } = useSelector(groupsSelector);
  const section = route.params.section || 'all';

  useEffect(() => {
    dispatch(groupsActions.loadGroups());
  }, []);

  const handleTabChange = useCallback((section) => {
    if (section !== 'all') {
      router.navigate(GROUPS, { section }, { replace: true });
    } else {
      router.navigate(GROUPS, {}, { replace: true });
    }
  }, []);

  const items = useMemo(() => {
    let result = [...groups];
    if (section === 'managed') {
      const managedMap = _.invert(managed);
      result = result.filter((groupId) => !!managedMap[groupId]);
    }

    return result.sort((a, b) => a > b ? -1 : 1);
  }, [section, groups, managed]);

  function renderContent() {
    if (isGroupsLoading && !groups.length) {
      return (
        <Loader isWrapped />
      );
    }

    return (
      <>
        <Tabs selected={section} onChange={handleTabChange}>
          <TabsItem value="all" counter={groups.length}>{t('groups_tab_all')}</TabsItem>
          <TabsItem value="managed" counter={managed.length}>{t('groups_tab_manage')}</TabsItem>
        </Tabs>
        {renderItems()}
      </>
    );
  }

  function renderItems() {
    if (!items.length) {
      return (
        <Placeholder centered>
          {t('groups_empty')}
        </Placeholder>
      );
    }

    return (
      <div className="Groups__list">
        {items.map((group) => (
          <UserCell
            key={group}
            objectId={group}
            isGroup
            isFollowBtnShown={false}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <Metatags title={t('groups_title')} />
      <Header
        showBackButton={!isDesktop}
        rightIcon={<IcAdd28 />}
        rightModal="group"
      >{t('groups_title')}</Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
