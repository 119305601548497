import './Followers.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRoute } from 'react-router5';
import { useDispatch, useSelector } from 'react-redux';

import { FollowersProps } from '$core/Followers/Followers.interface';
import { Header, Placeholder, ScrollView, SearchInput, TabsItem, Tabs } from '$uikit';
import { useGroupInfo, useUserInfo } from '$hooks';
import { showModal } from '$navigation/helper';
import { followersActions, followersForUserSelector } from '$store/followers';
import { RootState } from '$store/rootReducer';
import { Loader, Metatags } from '$shared/components';
import { UserCell } from '$shared/components/UserCell/UserCell';
import { formatBigNumber, gram } from '$utils';
import { editGroupSelector } from '$store/editGroup';
import router, { isBackNavigation } from '$navigation/router';

export const Followers: FC<FollowersProps> = ({ userId, isOutbox = false }) => {
  const user = useUserInfo(userId);
  const group = useGroupInfo(-userId);
  const [tab, setTab] = useState(isOutbox ? 'outbox' : 'inbox');
  const dispatch = useDispatch();
  const {
    isLoading,
    inbox,
    outbox,
    inboxNextFrom,
    outboxNextFrom,
    isInboxLoadingMore,
    isOutboxLoadingMore,
  } = useSelector((state: RootState) => followersForUserSelector(state, userId));
  const { admins } = useSelector(editGroupSelector);
  const { route } = useRoute();
  const isPromote = !!route.params?.promote && userId < 0;
  const isBack = isBackNavigation();
  const [query, setQuery] = useState(route.params?.query ?? '');

  useEffect(() => {
    if (!isBack) {
      dispatch(followersActions.load(userId));
    }
  }, []);

  useEffect(() => {
    const params: any = { ...route.params };
    if (query && query.length > 0) {
      if (params.query === query) {
        return;
      }

      params.query = query;
    } else {
      delete params.query;
    }

    router.navigate(route.name, params, { replace: true, urlParamsEncoding: false });

    let timer = setTimeout(() => {
      dispatch(followersActions.load(userId));
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const handleChange = useCallback((newTab) => {
    if (newTab === 'inbox') {
      showModal(`followers${userId}`, true);
    } else {
      showModal(`followed${userId}`, true);
    }
    setTab(newTab);
  }, [setTab]);

  const adminsMap = useMemo(() => {
    const adminsMap = {};
    for (let admin of admins) {
      adminsMap[admin.userId] = true;
    }
    return adminsMap;
  }, [admins]);

  const items = useMemo(() => {
    return tab === 'inbox' ? inbox : outbox;
  }, [tab, inbox, outbox]);

  const isLoadingMore = useMemo(() => {
    return tab === 'inbox' ? isInboxLoadingMore : isOutboxLoadingMore;
  }, [tab, isInboxLoadingMore, isOutboxLoadingMore]);

  const nextFrom = useMemo(() => {
    return tab === 'inbox' ? inboxNextFrom : outboxNextFrom;
  }, [tab, inboxNextFrom, outboxNextFrom]);

  const title = useMemo(() => {
    if (userId < 0) {
      return formatBigNumber(group?.followers ?? 0) + ' ' + gram(group?.followers ?? 0, ['подписчик', 'подписчика', 'подписчиков'], true);
    }  else {
      return `${user?.firstName} ${user?.lastName}`;
    }
  }, [userId, user, group]);

  const metaTitle = useMemo(() => {
    if (userId < 0) {
      return `${group?.name} | Подписчики | Anime.Fans`;
    }  else {
      return `${user?.firstName} ${user?.lastName} | Подписчики | Anime.Fans`;
    }
  }, [userId, user, group]);

  const metaDescription = useMemo(() => {
    if (userId < 0) {
      return formatBigNumber(group?.followers ?? 0) + ' ' + gram(group?.followers ?? 0, ['подписчик', 'подписчика', 'подписчиков'], true);
    }  else {
      return formatBigNumber(user?.followers ?? 0) + ' ' + gram(user?.followers ?? 0, ['подписчик', 'подписчика', 'подписчиков'], true);
    }
  }, [userId, user, group]);

  const handleSearchChange = useCallback((newQuery) => {
    setQuery(newQuery);
  }, []);

  function renderContent() {
    if (!items.length) {
      if (isLoading) {
        return (
          <Loader isWrapped />
        );
      }

      return (
        <Placeholder>Никого нет</Placeholder>
      );
    }

    return items.map((item) => (
      <UserCell
        key={`${item.type}_${item.objectId}`}
        objectId={item.objectId}
        isFollowed={false}
        isGroup={item.type === 'group'}
        isPromote={isPromote}
        targetId={userId}
        skipButton={isPromote && adminsMap[item.objectId]}
      />
    ));
  }

  return (
    <>
      <Metatags
        title={metaTitle}
        description={metaDescription}
      />
      <Header showBackButton>{title}</Header>
      <ScrollView
        onLoadMore={() => dispatch(followersActions.loadMore({ userId, tab }))}
        canLoadMore={!isLoading && !isLoadingMore && !!nextFrom}
      >
        <SearchInput
          query={query}
          onChange={handleSearchChange}
          placeholder="Поиск"
        />
        {(!isLoading || items.length > 0) && userId > 0 && (
          <div className="Followers__tabs_wrap">
            <Tabs selected={tab} onChange={handleChange}>
              <TabsItem value="inbox" counter={user?.followers ?? 0}>Подписчики</TabsItem>
              <TabsItem value="outbox" counter={user?.followed ?? 0}>Подписки</TabsItem>
            </Tabs>
          </div>
        )}
        {renderContent()}
      </ScrollView>
    </>
  );
};
