import './EpisodeItem.sass';

import React, { FC } from 'react';

import { EpisodeItemProps } from './interface';
import { useAnimeFull } from '$hooks';
import { classNames } from '$utils';
import { IcChevron16 } from '$assets';
import { ModalLink } from '$shared/components';

export const EpisodeItem: FC<EpisodeItemProps> = ({ animeId, episode, isSelected, isReplaceLocation }) => {
  const anime = useAnimeFull(animeId);
  const video = anime.videos[episode.videoId];

  return (
    <ModalLink
      name={`play${animeId}_${anime.translationSelected}_${episode.season}_${episode.number}`}
      routeOptions={{ replace: isReplaceLocation }}
    >
      <div className={classNames({
        EpisodeItem: true,
        selected: isSelected,
      })}>
        <div
          className="EpisodeItem__poster"
          style={{ backgroundImage: `url(${video.poster})` }}
        />
        <div className="EpisodeItem__title">Серия {episode.number} {episode.isFiller ? <sup>F</sup> : ''}</div>
        {!isReplaceLocation && <div className="EpisodeItem__arrow">
          <IcChevron16 />
        </div>}
      </div>
    </ModalLink>
  );
};
