import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  AuthState, RegistrationUploadPhotoAction, SendAuthCodeAction,
  SetAuthCodeAction,
  SetAuthPhoneAction, SetAuthPhoneCodeAction,
  SetAuthStepAction,
  SetLoadingAction, SetLoggedAction, UpdateRegistrationFormAction
} from './interface';
import { RootState } from 'store/rootReducer';

const initialState: AuthState = {
  currentUserId: 0,
  isLoading: true,
  isLogged: false,
  authForm: {
    step: 'phone',
    phoneCode: 7,
    phone: '',
    code: '',
  },
  registrationForm: {
    firstName: '',
    lastName: '',
    sex: 0,
    bDate: '',
    country: null,
    city: null,
    isPhotoUploading: false,
    photo: null,
    photoId: 0,
    code: '',
  }
};

export const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    init(state) {
      state.isLoading = true;
    },
    setLoading(state, action: SetLoadingAction) {
      state.isLoading = action.payload;
    },
    goToCaptcha() {},
    sendAuthCode(_, action: SendAuthCodeAction) {},
    validateCode() {},
    setAuthFormStep(state, action: SetAuthStepAction) {
      state.authForm.code = '';
      state.authForm.step = action.payload;
    },
    setAuthFormPhoneCode(state, action: SetAuthPhoneCodeAction) {
      state.authForm.phoneCode = action.payload;
    },
    setAuthFormPhone(state, action: SetAuthPhoneAction) {
      state.authForm.phone = action.payload;
    },
    setAuthFormCode(state, action: SetAuthCodeAction) {
      state.authForm.code = action.payload;
    },
    updateRegistraionForm(state, action: UpdateRegistrationFormAction) {
      state.registrationForm = {
        ...state.registrationForm,
        ...action.payload,
      };
    },
    createAccount() {},
    registrationUploadPhoto(_, action: RegistrationUploadPhotoAction) {},
    setLogged(state, action: SetLoggedAction) {
      state.isLoading = false;
      state.isLogged = true;
      state.currentUserId = action.payload;
    },
    logout() {},
  },
});

export { reducer as authReducer, actions as authActions };

export const authSelector = (state: RootState) => state.auth;

export const authIsLoggedSelector = createSelector(
  authSelector,
  ({ isLogged }) => isLogged,
);
