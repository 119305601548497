import { createSlice } from '@reduxjs/toolkit';
import { LoadMoreAction, SearchAction, SearchState, SetResultAction } from '$store/search/interface';
import { RootState } from '$store/rootReducer';

const initialState: SearchState = {
  isLoading: true,
  isLoadingMore: false,
  query: '',
  result: [],
  nextFrom: null,
};

export const { actions, reducer } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search(state, action: SearchAction) {
      state.isLoading = true;
      state.query = action.payload.query;
    },
    reset(state) {
      state.nextFrom = null;
      state.result = [];
    },
    setResult(state, action: SetResultAction) {
      state.nextFrom = action.payload.nextFrom;
      state.result = action.payload.items;
      state.isLoading = false;
      state.isLoadingMore = false;
    },
    loadMore(state, __: LoadMoreAction) {
      state.isLoadingMore = true;
    },
  }
});

export { reducer as searchReducer, actions as searchActions };

export const searchSelector = (state: RootState) => state.search;
