import React, { FC } from 'react';
import { Helmet } from "react-helmet";
import { MetatagsProps } from '$shared/components/Metatags/Metatags.interface';

export const Metatags: FC<MetatagsProps> = ({ title, description = '', image = null, canonical }) => {
  if (!description) {
    description = 'Аниме.фанс - социальная сеть для любителей аниме. Создавайте сообщества для фанатов аниме или для людей из вашего города, находите новых друзей и смотрите аниме онлайн без рекламы. Присоединяйтесь к глобальному сообществу фанатов аниме.';
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={location.href} />
      <meta name="twitter:title" content={title} />
      <meta property="og:site_name" content="Anime.Fans" />
      <meta property="og:type" content="website"/>
      {image && [
        <meta key="og" property="og:image" content={image} />,
        <meta key="twitter" name="twitter:image" content={image} />,
      ]}
      {!!canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};
