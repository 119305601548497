import './EditAnimeTranslation.sass';

import React, { FC } from 'react';

import { Header } from '$uikit';
import { EditAnimeTranslationProps } from './EditAnimeTranslation.interface';

export const EditAnimeTranslation: FC<EditAnimeTranslationProps> = ({ translationId, isEdit = true }) => {

  return (
    <>
      <Header showCloseButton>{isEdit ? 'Ред. озвучку' : 'Новая озвучка'}</Header>

    </>
  );
};
