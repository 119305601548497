import { detect } from 'detect-browser';

export function getDeviceId() {
  // @ts-ignore
  const prefix = window.isNative ? 'native_' : '';
  let device: string | null = '';
  try {
    device = localStorage.getItem('device_id');
  } catch (e) {}

  if (!device) {
    try {
      // @ts-ignore
      if (window.isNative) {
        device = 'native_android_' + Date.now();
      } else {
        const browser = detect();
        device = (browser?.name ?? 'unknown').toLowerCase() + '_' + Date.now();
      }
    } catch (e) {
      device = 'unknown';
    }

    try {
      localStorage.setItem('device_id', device);
    } catch (e) {}
  }

  return prefix + device;
}
