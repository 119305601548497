import './All.sass';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loader, Metatags } from '$shared/components';
import { Api } from '$api';
import { useRoute } from 'react-router5';
import { ScrollView } from '$uikit';
import { classNames } from '$utils';


export const All: FC<{ type: string }> = (props) => {
  const { route } = useRoute();

  const { type } = props;
  const offset = +route.params?.offset ?? 0;
  const count = 500;

  const [loadingStatus, setLoading] = useState('loading');
  const [info, setInfo] = useState<{
    items: {
      id: number;
      title: string;
      link: string;
    }[];
    total: number;
  } | null>(null);

  const loadData = useCallback(() => {
    setLoading('loading');
    Api
      .post<any, any>('/all', {
        type: props.type,
        offset: +offset,
        count,
      })
      .then((res) => {
        setInfo({
          items: res.items,
          total: res.total,
        });
        setLoading('loaded');
      })
      .catch(() => {
        setTimeout(() => loadData(), 3000);
      });
  }, [offset, count]);

  useEffect(() => {
    loadData();
  }, []);

  const data = useMemo(() => {
    if (type === 'users') {
      return {
        title: 'Пользователи',
        description: 'Список всех пользователей',
      };
    } else if (type === 'posts') {
      return {
        title: 'Посты',
        description: 'Список всех постов',
      };
    } else if (type === 'groups') {
      return {
        title: 'Группы',
        description: 'Список всех групп',
      };
    } else {
      return {
        title: 'Все',
        description: 'Список всех',
      };
    }
  }, [type]);

  if (loadingStatus === 'loading' || !info) {
    return <Loader isWrapped />;
  }

  function renderPagination() {
    const pagesCount = Math.ceil(info!.total / count);
    const pages: number[] = [];
    for (let i = 0; i < pagesCount; i++) {
      pages.push(i);
    }

    return (
      <div className="All__pagination">
        {pages.map((page) => {
          const p = page * count;
          return (
            <a className={classNames({
              active: p === +offset,
            })} href={`/all/${type}?offset=${p}`} key={page}>
              {page + 1}
            </a>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <ScrollView>
        <Metatags title={data.title} description={data.description} />
        <div className="All__head">
          <a href="/all/users" target="_blank">Пользователи</a>
          <a href="/all/posts" target="_blank">Посты</a>
          <a href="/all/groups" target="_blank">Группы</a>
        </div>
        {renderPagination()}
        <div className="All">
          <h1>{data.title}</h1>
          {info.items.map((item) => (
            <div key={item.id} className="All__item">
              <a href={item.link} target="_blank">
                {item.id}: {item.title || 'Post'}
              </a>
            </div>
          ))}
        </div>
        {renderPagination()}
      </ScrollView>
    </>
  );
};
