

export function getTopOnScrollView(elem: HTMLDivElement) {
  let result = elem.offsetTop;

  // let next: HTMLElement | null = elem;
  // while ((next = next.parentElement)) {
  //   if (next.classList.contains('.ScrollView')) {
  //     break;
  //   }
  //
  //   result += next.offsetTop;
  // }

  return result;
}
