import './PullToRefresh.sass';

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactPullToRefresh from 'react-simple-pull-to-refresh';

import { PullToRefreshProps } from './PullToRefresh.interface';

export const PullToRefresh: FC<PullToRefreshProps> = (props) => {
  const {
    children,
    isRefreshing,
    onRefresh
  } = props;

  const promise = useRef<any>(null);
  const [isEnabled, setEnabled] = useState(true);

  useEffect(() => {
    if (!isRefreshing) {
      promise.current && promise.current();
      promise.current = null;
      console.log('resolve promise');
    }
  }, [isRefreshing]);

  const handleScroll = useCallback(({ target }) => {
    setEnabled(target.scrollTop === 0);
  }, []);

  useEffect(() => {
    const scrollNode = document.querySelector('.ScrollView');
    scrollNode && scrollNode.addEventListener('scroll', handleScroll);
    return () => {
      scrollNode && scrollNode.removeEventListener('scroll', handleScroll);
    };
  }, [children]);

  const handleRefresh = useCallback(() => {
    return new Promise<any>((resolve) => {
      promise.current = resolve;
      onRefresh();
    });
  }, [promise, onRefresh]);

  return (
    <ReactPullToRefresh
      onRefresh={handleRefresh}
      className="PullToRefresh"
      isPullable={isEnabled}
      pullingContent={(
        <div className="PullToRefresh__label">Потяните вниз, чтобы обновить</div>
      )}
    >
      {/* @ts-ignore */}
      {children}
    </ReactPullToRefresh>
  );
};
