import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListenAction, RealTimeState, SetConnectingAction } from '$store/realtime/interface';
import { RootState } from '$store/rootReducer';

const initialState: RealTimeState = {
  lastEventId: 0,
  isConnecting: false,
  events: [],
};

export const { actions, reducer } = createSlice({
  name: 'realtime',
  initialState,
  reducers: {
    setConnecting(state, action: SetConnectingAction) {
      state.isConnecting = action.payload;
    },
    listen(_, __: ListenAction) {},
    unListen() {},
    resetRealtime: () => initialState,
    pushEvent(state, action: PayloadAction<any>) {
      state.events.push(action.payload);
    },
  },
});


export { actions as realtimeActions, reducer as realtimeReducer };

export const realtimeSelector = (state: RootState) => state.realtime;
