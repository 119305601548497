import { createSlice } from '@reduxjs/toolkit';

import {
  FeedState,
  HideNotificationAction,
  LoadFeedAction,
  LoadMoreAction,
  SetFeedAction,
  SetMoreFeedAction
} from './interface';
import { RootState } from 'store/rootReducer';

const initialState: FeedState = {
  isLoading: true,
  isLoadingMore: false,
  feed: [],
  nextFrom: '',
  suggestions: [],
  notifications: [],
};

export const { actions, reducer } = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    loadFeed(state, __: LoadFeedAction) {
      state.isLoading = true;
      state.suggestions = [];
      state.feed = [];
      state.isLoadingMore = false;
      state.nextFrom = '';
    },
    setFeed(state, action: SetFeedAction) {
      const { items, nextFrom, suggestions, notifications } = action.payload;

      state.feed = items;
      state.nextFrom = nextFrom;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.suggestions = suggestions;
      state.notifications = notifications;
    },

    loadMore(state, _: LoadMoreAction) {
      state.isLoadingMore = true;
    },
    setMoreFeed(state, action: SetMoreFeedAction) {
      const { items, nextFrom } = action.payload;

      const old = state.feed;
      const exist = {};
      for (let item of old) {
        exist[`${item.type}_${item.object}`] = true;
      }

      state.feed = [...old].concat(
        items.filter((item) => !exist[`${item.type}_${item.object}`])
      );
      state.nextFrom = nextFrom;
      state.isLoadingMore = false;
    },
    hideNotification(_, action: HideNotificationAction) {},
    hideNotificationFromUI(state, action: HideNotificationAction) {
      const notifications = [...state.notifications];
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].id === action.payload) {
          notifications.splice(i, 1);
          break;
        }
      }

      state.notifications = notifications;
    },
  },
});

export { reducer as feedReducer, actions as feedActions };

export const feedSelector = (state: RootState) => state.feed;
