import { all, call, put, takeLatest } from 'redux-saga/effects';
import { blacklistActions } from '$store/blacklist/index';
import { toastActions } from '$store/toast';
import { BanAction, GroupBanAction } from '$store/blacklist/interface';
import { Api } from '$api';

function* banWorker(action: BanAction) {
  try {
    const userId = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/blacklist/${userId}/add`);
    yield put(blacklistActions.setBanFromMe({
      userId,
      isBanned: true,
    }));
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* unbanWorker(action: BanAction) {
  try {
    const userId = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/blacklist/${userId}/del`);
    yield put(blacklistActions.setBanFromMe({
      userId,
      isBanned: false,
    }));
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* groupBanWorker(action: GroupBanAction) {
  try {
    const { userId, groupId } = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/group/${groupId}/blacklist/${userId}/add`);
    yield put(blacklistActions.setGroupBan({
      userId,
      groupId,
      isBanned: true,
    }));
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* groupUnbanWorker(action: GroupBanAction) {
  try {
    const { userId, groupId } = action.payload;

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/group/${groupId}/blacklist/${userId}/del`);
    yield put(blacklistActions.setGroupBan({
      userId,
      groupId,
      isBanned: false,
    }));
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* blacklistSaga() {
  yield all([
    takeLatest(blacklistActions.ban, banWorker),
    takeLatest(blacklistActions.unban, unbanWorker),
    takeLatest(blacklistActions.groupBan, groupBanWorker),
    takeLatest(blacklistActions.groupUnban, groupUnbanWorker),
  ]);
}
