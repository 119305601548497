import './FindFriendItem.sass';

import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router5';

import { FindFriendItemProps } from './FindFriendItem.interface';
import { useUserInfo } from '$hooks';
import { Online } from '$shared/components';
import { gram } from '$utils';
import { IcAdd28, IcClose28 } from '$assets';
import { Button } from '$uikit';
import { followersActions, followersSelector } from '$store/followers';
import { PROFILE } from '$shared/constants/pages';

const MutualFriend: FC<{ friendId: number }> = ({ friendId }) => {
  const user = useUserInfo(friendId);

  return (
    <div className="FindFriendItem__mutual_friend" style={{ backgroundImage: `url(${user.photo})` }} />
  );
};

export const FindFriendItem: FC<FindFriendItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const user = useUserInfo(item.userId);
  const { following } = useSelector(followersSelector);
  const isFollowed = following[item.userId];

  const handleClick = useCallback((e) => {
    if (e.target.closest('.FindFriendItem__follow')) {
      e.preventDefault();

      if (!isFollowed) {
        dispatch(followersActions.follow(item.userId));
      } else {
        dispatch(followersActions.unfollow(item.userId));
      }

      return false;
    }
  }, [isFollowed, dispatch, item.userId]);

  function renderCaption() {
    if (item.mutualFriends > 0) {
      return (
        <div className="FindFriendItem__followers">
          <div className="FindFriendItem__mutual_friends">
            {item.friends.map((friendId) => <MutualFriend key={friendId} friendId={friendId} />)}
          </div>
          {gram(item.mutualFriends, ['подписан', 'подписаны', 'подписано'], true)}
          {` `}
          {gram(item.mutualFriends, ['друг', 'друга', 'друзей'])}
        </div>
      );
    } else {
      return (
        <div className="FindFriendItem__followers">
          {gram(user.followers, ['подписчик', 'подписчика', 'подписчиков'])}
        </div>
      );
    }
  }

  return (
    <Link
      className="FindFriendItem"
      routeName={PROFILE}
      routeParams={{ id: item.userId }}
      onClick={handleClick}
    >
      <div className="FindFriendItem__photo" style={{ backgroundImage: `url(${user.photo})` }} />
      <div className="FindFriendItem__cont">
        <div className="FindFriendItem__name_wrap">
          <div className="FindFriendItem__name">{user.firstName} {user.lastName}</div>
          <Online userId={item.userId} />
        </div>
        {renderCaption()}
        <div className="FindFriendItem__follow">
          <Button
            icon={isFollowed ? <IcClose28 /> : <IcAdd28 />}
            size="small"
            mode={isFollowed ? 'secondary' : 'default'}
          >
            {isFollowed ? 'Отписаться' : 'Подписаться'}
          </Button>
        </div>
      </div>
    </Link>
  );
};
