import './WriteCell.sass';

import React, { FC, useCallback } from 'react';
import { Link, useRoute } from 'react-router5';

import { WriteCellProps } from '$core/Write/WriteCell/WriteCell.interface';
import { useUserInfo } from '$hooks';
import { Online } from '$shared/components';
import { CHAT } from '$shared/constants/pages';
import { IcChevron16 } from '$assets';
import { useDispatch } from 'react-redux';
import { attachActions } from '$store/attach';
import { store } from '$store';

export const WriteCell: FC<WriteCellProps> = ({ userId }) => {
  const user = useUserInfo(userId);
  const { route } = useRoute();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (route.params.share) {
      //const matches = route.params.share.match(/^(\w+)(\d+)$/);
      dispatch(attachActions.loadLink({
        pickerId: `im_${userId}`,
        link: `https://anime.fans/feed?w=${route.params.share}`,
      }))
    }
  }, [route]);

  return (
    <Link
      routeName={CHAT}
      routeParams={{ peerId: userId }}
      routeOptions={{ replace: true }}
      className="WriteCell"
      onClick={handleClick}
    >
      <div
        className="WriteCell__photo"
        style={{ backgroundImage: `url(${user.photo})` }}
      />
      <div className="WriteCell__cont">
        <div className="WriteCell__name_wrap">
          <div className="WriteCell__name">{user.firstName} {user.lastName}</div>
          <Online userId={userId} />
        </div>
      </div>
      <div className="WriteCell__arrow">
        <IcChevron16 />
      </div>
    </Link>
  );
};
