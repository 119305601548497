import './AnimeList.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRoute } from 'react-router5';

import { ActionSheet, Button, Header, InlineHeader, Placeholder, ScrollView } from '$uikit';
import { animeActions, animeSelector } from '$store/anime';
import { Loader, Metatags } from '$shared/components';
import { useCurrentUserInfo, useUserInfo } from '$hooks';
import { PROFILE, RESOLVE } from '$shared/constants/pages';
import { AnimeItem } from '$core/Anime/AnimeItem/AnimeItem';
import { mainSelector } from '$store/main';
import { IcKey28, IcMore24 } from '$assets';
import { showModal } from '$navigation/helper';
import copyToClipboard from 'copy-text-to-clipboard';
import { toastActions } from '$store/toast';

export const AnimeList: FC = () => {
  const dispatch = useDispatch();
  const { route } = useRoute();
  const listId = +route.params.listId;

  const curUser = useCurrentUserInfo();
  const { isListLoading, isListFailed, listInfo } = useSelector(animeSelector);
  const isOwner = listInfo && listInfo.id === listId && listInfo.ownerId === curUser.id;
  const user = useUserInfo(listInfo ? listInfo.ownerId : 0);
  const { anime } = useSelector(mainSelector);
  const [isActionsShown, setActionsShown] = useState(false);

  useEffect(() => {
    dispatch(animeActions.loadList(listId));
  }, []);

  const handleRetry = useCallback(() => {
    dispatch(animeActions.loadList(listId));
  }, [dispatch, listId]);

  const handleActions = useCallback(() => {
    setActionsShown(!isActionsShown);
  }, [isActionsShown]);

  const handleMenu = useCallback((value) => {
    setActionsShown(false);

    if (value === 'delete') {
      dispatch(animeActions.deleteList(listId));
    } else if (value === 'edit') {
      showModal(`edit_list${listId}`);
    }
  }, [dispatch, listId, setActionsShown]);

  const handleShare = useCallback(() => {
    /* @ts-ignore */
    if (navigator.canShare) {
      navigator.share({
        url: location.href,
        title: `${listInfo?.title ?? ''} — список аниме`,
        text: listInfo?.description ?? '',
      })
    } else {
      copyToClipboard(location.href);
      dispatch(toastActions.setToastSuccess('Ссылка скопирована!'));
    }
  }, []);

  function renderContent() {
    if (isListLoading && (!listInfo || listInfo.id !== listId)) {
      return (
        <Loader isWrapped />
      );
    }

    if (isListFailed) {
      return (
        <Placeholder
          title="Произошла ошибка"
          actions={<Button size="small" onClick={handleRetry}>Попробовать снова</Button>}
          centered
        >
          Попробуйте еще раз позже
        </Placeholder>
      );
    }

    return (
      <>
        <ActionSheet
          isOpened={isActionsShown}
          options={[
            {
              label: 'Редактировать',
              value: 'edit',
            },
            {
              label: 'Удалить',
              value: 'delete',
            }
          ]}
          onChange={handleMenu}
        />
        <div className="AnimeList__info">
          <div className="AnimeList__title">
            {listInfo?.title ?? ''}
            {listInfo?.privacy === 1 && <IcKey28 />}
          </div>
          <div className="AnimeList__description">
            {listInfo?.description ?? ''}
          </div>
          <Link className="AnimeList__author" routeName={user.domain ? RESOLVE : PROFILE} routeParams={user.domain ? { domain: user.domain } : { id: user.id }}>
            <div className="AnimeList__author__photo" style={{ backgroundImage: `url(${user.photo})` }} />
            <div className="AnimeList__author__cont">
              <div className="AnimeList__author__name">{user.firstName} {user.lastName}</div>
              <div className="AnimeList__author__label">Автор списка</div>
            </div>
          </Link>
        </div>
        <div className="AnimeLists__share">
          <Button onClick={handleShare}>Поделиться списком</Button>
        </div>
        <InlineHeader>Аниме</InlineHeader>
        {listInfo!.animeIds.map((animeId) => <AnimeItem key={animeId} anime={anime[animeId]} />)}
      </>
    );
  }

  return (
    <>
      <Metatags
        title={`${listInfo?.title ?? ''} — список аниме | ${user?.firstName} ${user?.lastName}`}
        description={`${listInfo?.description ?? ''}`}
      />
      <Header
        showBackButton
        rightIcon={isOwner && <IcMore24 />}
        rightOnClick={isOwner && handleActions}
      >
        Список аниме
      </Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
