import './NotifyRequest.sass';

import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '$uikit';
import { mainSelector } from '$store/main';
import { subscribe } from '../../../services/push';
import { isNative, isNotificationsSupported } from '$utils';
import { sendNativeEvent } from '$services';

export const NotifyRequest:  FC = () => {
  const { isNotifyEnabled } = useSelector(mainSelector);

  const handleClick = useCallback(() => {
    if (isNative(2)) {
      sendNativeEvent('register_push_notifications');
    } else {
      if (Notification.permission === 'denied') {
        alert('Вы запретили уведомления, зайдите в настройки браузера и включите их там');
      } else {
        subscribe();
      }
    }
  }, []);

  if (isNotifyEnabled || !isNotificationsSupported()) {
    return null;
  }

  return (
    <div className="NotifyRequest" onClick={handleClick}>
      <div className="NotifyRequest__in">
        <div className="NotifyRequest__cont">
          <div className="NotifyRequest__title">Push-уведомления</div>
          <div className="NotifyRequest__caption">Включите, чтобы моментально узнавать о&nbsp;новых событиях</div>
        </div>
        <Button size="small">Вкл.</Button>
      </div>
    </div>
  );
};
