import './BottomSheet.sass';

import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDispatch } from 'react-redux';

import { IcClose16 } from '$assets';
import { mainActions } from '$store/main';
import { BottomSheetProps } from './BottomSheet.interface';

export const BottomSheet: FC<BottomSheetProps> = ({ children, title }) => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const [{ y }, api] = useSpring(() => ({ y: 0 }));
  const [{ opacity }, opacityApi] = useSpring(() => ({ opacity: 0 }));

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    api.start({ y: -contentRef.current.offsetHeight, immediate: false });
    opacityApi.start({ opacity: 1, immediate: false });
  }, [contentRef]);

  const handleClose = useCallback(() => {
    opacityApi.start({ opacity: 0, immediate: false });
    api.start({
      y: 0,
      onRest: () => {
        dispatch(mainActions.setPopup(null));
      }
    });
  }, [dispatch]);

  return (
    <div className="BottomSheet">
      <animated.div className="BottomSheet__close_helper" onClick={handleClose} style={{ opacity }} />
      <div className="BottomSheet__sheet_wrap">
        <animated.div className="BottomSheet__sheet" ref={contentRef} style={{ y }}>
          <div className="BottomSheet__header">
            <div className="BottomSheet__header__title">{title}</div>
            <div className="BottomSheet__header__close_wrap" onClick={handleClose}>
              <div className="BottomSheet__header__close">
                <IcClose16 />
              </div>
            </div>
          </div>
          <div className="BottomSheet__content">
            {children}
          </div>
        </animated.div>
      </div>
    </div>
  );
};
