import { AnimeGenreModel, AnimeThemeModel } from '$store/models';

const Genres = [
  {
    "id": 1,
    "title": "Приключения"
  },
  {
    "id": 2,
    "title": "Боевик"
  },
  {
    "id": 3,
    "title": "Комедия"
  },
  {
    "id": 4,
    "title": "Повседневность"
  },
  {
    "id": 5,
    "title": "Романтика"
  },
  {
    "id": 6,
    "title": "Драма"
  },
  {
    "id": 7,
    "title": "Фантастика"
  },
  {
    "id": 8,
    "title": "Фэнтези"
  },
  // {
  //   "id": 9,
  //   "title": "Мистика"
  // },
  {
    "id": 10,
    "title": "Детектив"
  },
  {
    "id": 11,
    "title": "Мистика"
  },
  // {
  //   "id": 12,
  //   "title": "Трилер"
  // },
  {
    "id": 13,
    "title": "Психология"
  }
];

const Themes = [
  // {
  //   "id": 1,
  //   "title": "Боевые исскуства"
  // },
  // {
  //   "id": 2,
  //   "title": "История"
  // },
  {
    "id": 3,
    "title": "Меха"
  },
  {
    "id": 4,
    "title": "Полиция"
  },
  {
    "id": 5,
    "title": "Сёнен"
  },
  {
    "id": 6,
    "title": "Ужасы"
  },
  {
    "id": 7,
    "title": "Вампиры"
  },
  {
    "id": 8,
    "title": "Демоны"
  },
  {
    "id": 9,
    "title": "Космос"
  },
  {
    "id": 10,
    "title": "Музыка"
  },
  {
    "id": 11,
    "title": "Самураи"
  },
  {
    "id": 12,
    "title": "Спорт"
  },
  {
    "id": 13,
    "title": "Школа"
  },
  {
    "id": 14,
    "title": "Военное"
  },
  {
    "id": 15,
    "title": "Игры"
  },
  {
    "id": 16,
    "title": "Магия"
  },
  {
    "id": 17,
    "title": "Пародия"
  },
  {
    "id": 18,
    "title": "Сёдзё"
  },
  {
    "id": 19,
    "title": "Суперсила"
  }
];

export function getAnimeGenresByIds(ids: string): AnimeGenreModel[] {
  let result: AnimeGenreModel[] = [];

  const map: { [index: number]: AnimeGenreModel } = {};
  for (let item of Genres) {
    map[item.id] = item;
  }

  const exp = ids.split(',');
  for (const item of exp) {
    if (map[+item]) {
      result.push(map[+item]);
    }
  }

  return result;
}

export function getAnimeThemesByIds(ids: string): AnimeThemeModel[] {
  let result: AnimeThemeModel[] = [];

  const map: { [index: number]: AnimeThemeModel } = {};
  for (let item of Themes) {
    map[item.id] = item;
  }

  const exp = ids.split(',');
  for (const item of exp) {
    if (map[+item]) {
      result.push(map[+item]);
    }
  }

  return result;
}

export function getAnimeGenres() {
  return Genres;
}

export function getAnimeThemes() {
  return Themes;
}
