import './Message.sass';

import React, { FC, useCallback, useMemo, useState } from 'react';
import copyToClipboard from 'copy-text-to-clipboard';
import { useDispatch } from 'react-redux';

import { MessageProps } from '$core/Chat/Message/Message.interface';
import { useCurrentUserInfo, useTranslator } from '$hooks';
import { classNames, format } from '$utils';
import { Attachments, Linkify } from '$shared/components';
import { IcError28, IcStatusRead16, IcStatusSending16, IcStatusSent16 } from '$assets';
import { imActions } from '$store/im';
import { ActionSheet } from '$uikit';
import { ActionSheetPropsOption } from '$uikit/ActionSheet/ActionSheet.interface';
import { toastActions } from '$store/toast';
import { showModal } from '$navigation/helper';

export const Message: FC<MessageProps> = (props) => {
  const { message, peerId, isGroupingBefore, isGroupingAfter } = props;

  const [isActionShow, setActionsShown] = useState(false);
  const time = useMemo(() => format(message.createdAt * 1000, 'HH:mm'), [message.createdAt]);
  const curUser = useCurrentUserInfo();
  const isInbox = message.fromId !== curUser.id;
  const hasAttachments = message.attachments && message.attachments.length > 0;
  const dispatch = useDispatch();
  const t = useTranslator();

  function renderSendIndicator() {
    if (message.isFailed || message.isSending) {
      return <IcStatusSending16/>;
    } else if (message.isUnread) {
      return <IcStatusSent16 />;
    } else {
      return <IcStatusRead16 />;
    }
  }

  const date = (
    <div className="Message__meta">
      <div className="Message__time">
        {time}
      </div>
      {!message.isInbox && (
        <div className="Message__send_indicator">
          {renderSendIndicator()}
        </div>
      )}
    </div>
  );

  const text = useMemo(() => {
    return message.text.trim();
  }, []);

  const attachWidth = useMemo(() => {
    return Math.min(600, window.innerWidth) - 84 - 16 - 12 * 2; // 12 is inner bubble padding
  }, []);

  const actions = useMemo(() => {
    const items: ActionSheetPropsOption[] = [];

    if (message.text.trim().length > 0) {
      items.push({ label: t('message_copy_text'), value: 'copy' });
    }

    if (!message.isInbox) {
      items.push({ label: t('message_delete'), value: 'delete' });
    }

    return items;
  }, [message, t]);

  const handleClick = useCallback(({ target }) => {
    if (message.isFailed) {
      dispatch(imActions.retrySend({ peerId, message }));
    } else {
      if (target.closest('.Collage__item')) {
        const photoId = target.closest('.Collage__item').dataset.id;
        showModal(`photos+history${peerId}+${photoId}`);
        return;
      }

      if (actions.length > 0) {
        setActionsShown(true);
      }
    }
  }, [message, dispatch, actions]);

  const handleAction = useCallback((item) => {
    setActionsShown(false);

    if (item === 'copy') {
      copyToClipboard(message.text);
      dispatch(toastActions.setToastSuccess(t('copied')));
    } else if (item === 'delete') {
      dispatch(imActions.deleteMessage({
        id: message.id,
        peerId,
      }));
    }
  }, [dispatch, peerId, message, t]);

  return (
    <>
      <div
        className={classNames({
          Message: true,
          inbox: isInbox,
          outbox: !isInbox,
          withAttach: hasAttachments,
          isGroupingBefore,
          isGroupingAfter
        })}
        onClick={handleClick}
      >
        {message.isFailed && (
          <div className="Message__failed">
            <IcError28 />
          </div>
        )}
        <div className="Message__in">
          <div>
            {text.length > 0 && <div className="Message__text">
              <Linkify>{text}</Linkify>
              {!hasAttachments && date}
            </div>}
            <div className="Message__attachments">
              <Attachments attachments={message.attachments} photosWidth={attachWidth} parentObject={`message_${message.id}`} />
            </div>
            {date}
          </div>
        </div>
      </div>
      <ActionSheet
        isOpened={isActionShow}
        options={actions}
        onChange={handleAction}
      />
    </>
  );
};
