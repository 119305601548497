import { useCallback, useEffect, useMemo, useRef } from 'react';
import { isDesktop } from '$utils';
import { useRoute } from 'react-router5';

interface UseLoadMoreProps {
  handler: () => any | void,
  enabled: boolean;
  opts?: {
    isPreload?: boolean;
    isHorizontal?: boolean;
    node?: HTMLDivElement | null;
  }
}

export function useLoadMore(props: UseLoadMoreProps) {
  const {
    handler,
    enabled,
    opts = { isPreload: false, isHorizontal: false, node: null }
  } = props;

  const lastHandle = useRef(0);
  const { route } = useRoute();

  const defaultNode = useMemo(() => {
    if (isDesktop() && route.params?.w) {
      return document.querySelector('.Modal__cont') as HTMLDivElement;
    }

    return document;
  }, [route.params, handler, enabled]);


  const handleScroll = useCallback(() => {
    if (!enabled) {
      return;
    }

    const now = Date.now();
    if (now - lastHandle.current < 1000) {
      return;
    }

    let node = opts.node;
    if (!node && defaultNode != document) {
      // @ts-ignore
      node = defaultNode;
    }

    let scrollOffset, size, scrollSize;
    if (opts.isHorizontal) {
      scrollOffset = node ? node.scrollLeft : window.scrollX;
      size = node ? node.offsetWidth : window.innerWidth;
      scrollSize = node ? node.scrollWidth : document.body.scrollWidth;
    } else {
      scrollOffset = node ? node.scrollTop : window.scrollY;
      size = node ? node.offsetHeight : window.innerHeight;
      scrollSize = node ? node.scrollHeight : document.body.scrollHeight;
    }

    if (opts.isPreload) {
      if (scrollOffset < size) {
        lastHandle.current = now;
        handler();
      }
    } else {
      if (scrollOffset + size * 2 >= scrollSize) {
        lastHandle.current = now;
        handler();
      }
    }
  }, [handler, enabled, opts, lastHandle]);

  useEffect(() => {
    const node = opts.node || defaultNode;
    enabled && node && node.addEventListener('scroll', handleScroll);
    return () => node && node.removeEventListener('scroll', handleScroll);
  }, [enabled, opts.node, handler, defaultNode]);
}
