import './Collage.sass';

import React, { useMemo, FC, useCallback } from 'react';
import { CollageProps } from './Collage.interface';
import { IcPlay28 } from '$assets';
import { InlineVideo } from '$shared/components';
import { classNames } from '$utils';

const sum = (items) => items.reduce((item, value) => value + item, 0);
const multiThumbsHeight = (ratios, width, margin) => Math.floor((width - (ratios.length - 1) * margin) / sum(ratios));
const compile = (photo, width, height) => ({
  id: photo.photoId || photo.videoId,
  width: parseInt(width),
  height: parseInt(height),
  image: {
    src: photo.photoMedium,
    width: photo.width,
    height: photo.height,
  },
  orig: photo,
});
const getMaxHeight = (thumbs, maxHeight) => {
  const max = thumbs
    .map((thumb) => thumb.height)
    .reduce((item, current) => {
      if (item > current) {
        return item;
      } else {
        return current;
      }
    }, 0);

  return Math.min(maxHeight, max);
};

export const Collage: FC<CollageProps> = ({ items, renderExtra = null, maxWidth = 300 }) => {
  const collage = useMemo(() => {
    let result: any[] = [];
    let thumbs = items.map((item) => item[item.type]);

    const maxHeight = Math.ceil(maxWidth * 1.333);
    const margin = 0;
    const maxRatio = maxWidth / maxHeight;
    const cnt = thumbs.length;

    const ratios = thumbs.map((thumb) => thumb.width / thumb.height);
    const avgRatio = ratios.length > 0 ? sum(ratios) / ratios.length : 1.0;
    let width = 0, height = 0;
    if (cnt === 1) {
      let tHeight, tWidth;
      if (ratios[0] >= maxRatio) {
        tWidth = Math.round(Math.min(maxWidth, thumbs[0].width));
        tHeight = Math.round(Math.min(tWidth / ratios[0], maxHeight));
      } else {
        tHeight = Math.round(Math.min(maxHeight, thumbs[0].height));
        tWidth = Math.round(Math.min(tHeight * ratios[0], maxWidth));
      }
      result[0] = compile(thumbs[0], tWidth, tHeight);

      width = tWidth + margin;
      height = tHeight + margin;
    } else if (cnt === 2) {
      const w0 = Math.ceil((maxWidth - margin) / ratios[1] / (1 / ratios[0] + 1 / ratios[1]));
      const w1 = maxWidth - w0 - margin;
      const h = Math.ceil(Math.min(maxHeight, w0 / ratios[0], w1 / ratios[1]));
      result[0] = compile(thumbs[0], w0, h);
      result[1] = compile(thumbs[1], w1, h);

      width = maxWidth + margin * 2;
      height = h + margin * 2;
    } else if (cnt === 3) {
      let h = getMaxHeight(thumbs, maxHeight);
      const coverWidth = Math.floor(Math.min(h * ratios[0], (maxWidth - margin) * 0.75));
      result[0] = compile(thumbs[0], coverWidth, h);

      const h1 = Math.floor(ratios[1] * (maxHeight - margin) / (ratios[2] + ratios[1]));
      const h0 = maxHeight - h1 - margin;
      const w = Math.floor(Math.min(maxWidth - coverWidth - margin, h1 * ratios[2], h0 * ratios[1]));

      result[1] = compile(thumbs[1], w, h0);
      result[2] = compile(thumbs[2], w, h1);

      width = coverWidth + w + margin * 2;
      height = h + margin * 2;
    } else if (cnt === 4) {
      let h = getMaxHeight(thumbs, maxHeight);
      const coverWidth = Math.floor(Math.min(h * ratios[0], (maxWidth - margin) * 0.66));
      result[0] = compile(thumbs[0], coverWidth, h);

      let w = Math.floor((maxHeight - 2 * margin) / (1 / ratios[1] + 1 / ratios[2] + 1 / ratios[3]));
      const h0 = Math.floor(w / ratios[1]);
      const h1 = Math.floor(w / ratios[2]);
      const h2 = Math.floor(h - h0 - h1 - 2 * margin);
      w = Math.floor(Math.min(maxWidth - coverWidth - margin, w));

      result[1] = compile(thumbs[1], w, h0);
      result[2] = compile(thumbs[2], w, h1);
      result[3] = compile(thumbs[3], w, h2);

      width = coverWidth + w + margin * 2;
      height = h + margin * 2;
    } else {
      let ratiosCropped;
      if (avgRatio > 1.1) {
        ratiosCropped = ratios.map((ratio) => Math.max(1.0, ratio));
      } else {
        ratiosCropped = ratios.map((ratio) => Math.min(1.0, ratio));
      }

      let tries = {};
      let firstLine, secondLine, thirdLine;
      tries[(firstLine = cnt) + ''] = [multiThumbsHeight(ratiosCropped, maxWidth, margin)];

      for (firstLine = 1; firstLine <= cnt - 1; firstLine++) {
        tries[firstLine + ',' + (secondLine = cnt - firstLine)] = [
          multiThumbsHeight(ratiosCropped.slice(0, firstLine), maxWidth, margin),
          multiThumbsHeight(ratiosCropped.slice(firstLine), maxWidth, margin)
        ];
      }

      for (firstLine = 1; firstLine <= cnt - 2; firstLine++) {
        for (secondLine = 1; secondLine <= cnt - firstLine - 1; secondLine++) {
          tries[firstLine + ',' + secondLine + ',' + (thirdLine = cnt - firstLine - secondLine)] = [
            multiThumbsHeight(ratiosCropped.slice(0, firstLine), maxWidth, margin),
            multiThumbsHeight(ratiosCropped.slice(firstLine, firstLine + secondLine), maxWidth, margin),
            multiThumbsHeight(ratiosCropped.slice(firstLine + secondLine), maxWidth, margin)
          ];
        }
      }

      let optConf: string | null = null;
      let optDiff = 0;
      let optHeight = 0;
      let optH;

      for (let conf in tries) {
        const heights = tries[conf];
        const confH = sum(heights) + margin * (heights.length - 1);
        let confDiff = Math.abs(confH - maxHeight);

        if (conf.indexOf(',') != -1) {
          let confNums = conf.split(',').map((item) => +item);
          if (confNums[0] > confNums[1] || confNums[2] && confNums[1] > confNums[2]) {
            confDiff += 50;
            confDiff *= 1.5;
          }
        }
        if (optConf == null || confDiff < optDiff) {
          optConf = conf;
          optDiff = confDiff;
          optH = confH;
        }
      }

      const thumbsRemain = [...thumbs];
      const ratiosRemain = [...ratiosCropped];
      const chunks = optConf!.split(',').map((item) => +item);
      const optHeights = tries[optConf!];
      const lastRow = chunks.length - 1;

      for (let i = 0; i < chunks.length; i++) {
        const lineChunksNum = chunks[i];
        const lineThumbs = thumbsRemain.splice(0, lineChunksNum);
        const lineHeight = optHeights.shift();
        const lastColumn = lineThumbs.length - 1;
        let widthRemains = maxWidth;

        let opts: any = {};
        if (lastRow == i) {
          opts.lastRow = true;
        }
        for (let j = 0; j < lineThumbs.length; j++) {
          const thumb = lineThumbs[j];
          const thumbRatio = ratiosRemain.shift();
          const thumbOpts = opts;
          let thumbWidth;
          if (lastColumn === j) {
            thumbWidth = Math.ceil(widthRemains);
            thumbOpts.lastColumn = true;
          } else {
            thumbWidth = Math.floor(thumbRatio * lineHeight);
            widthRemains -= thumbWidth + margin;
          }
          result[result.length] = compile(thumb, thumbWidth, lineHeight);
        }
      }

      width = maxWidth;
      height = optH;
    }

    result = result.map((item) => {
      item.width = (item.width * 100) / width;
      item.height = (item.height * 100) / height;

      return item;
    });

    return {
      width,
      thumbs: result,
      height: height / width * 100,
    };
  }, [items]);

  function renderItemCont(item: any) {
    if (item.videoId > 0 || item.isVideo) {
      return (
        <div className="ExternalVideo__iframe">
          <div className="ExternalVideo__play_btn">
            <IcPlay28/>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="Collage_wrap">
      <div
        className="Collage__helper"
        style={{ width: collage.width, paddingTop: `${collage.height}%` }}
      />
      <div className="Collage">
        { /* @ts-ignore */}
        {collage.thumbs.map((item, i) => {
          return (
            <div
              key={i}
              className={classNames({
                Collage__item: true,
                [(item.orig.videoId > 0 || item.orig.isVideo) ? 'video' : 'photo']: true,
              })}
              style={{
                width: `${item.width}%`,
                height: `${item.height}%`,
              }}
              /* @ts-ignore */
              data-id={item.id}
            >
              <div
                className="Collage__item__image"
                style={{
                  backgroundImage: `url(${item.image.src})`,
                }}
              >
                {renderItemCont(item.orig)}
                {renderExtra && renderExtra(i)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}
