import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { Loader } from '$shared/components';
import { Api } from '$api';
import { getDeviceId, setRegistrationToken, setToken, statReachGoal } from '$utils';
import router from '$navigation/router';
import { REGISTRATION } from '$shared/constants/pages';
import { toastActions } from '$store/toast';

export const VkAuth: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    if (!window.VkAuthAccessToken) {
      location.href = '/';
      return;
    }

    Api.post(`/auth/vk`, {
      // @ts-ignore
      accessToken: window.VkAuthAccessToken,
      test: location.hostname === 'localhost' ? 1 : 0,
      device: getDeviceId(),
    })
      .then((data: any) => {
        if (data.is_new) {
          setRegistrationToken(data.access_token);
          router.navigate(REGISTRATION);
        } else {
          setToken(+data.user_id, data.access_token);
          setTimeout(() => {
            location.href = '/feed';
          }, 300);
        }

        statReachGoal('success_vk_auth');
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
        setTimeout(() => {
          location.href = '/';
        }, 1000);

        statReachGoal('fail_vk_auth');
      });
  }, []);

  return (
    <>
      <Loader isWrapped />
    </>
  );
};
