import './Badge.sass';

import React, { FC, useMemo } from 'react';
import { BadgeProps } from './Badge.interface';

export const Badge: FC<BadgeProps> = ({ count }) => {

  const prepared = useMemo(() => {
    let result = `${count}`;

    if (result.length > 3) {
      result = `..${result.substr(-2)}`;
    }

    return result;
  }, [count]);

  return (
    <div className="Badge">
      {prepared}
    </div>
  );
};
