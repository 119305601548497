import './FeedNotification.sass';

import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FeedNotificationProps } from './FeedNotification.interface';
import { Button } from '$uikit';
import router from '$navigation/router';
import { feedActions } from '$store/feed';
import { requestAddToHomeScreen } from '$services';

export const FeedNotification: FC<FeedNotificationProps> = ({ notification }) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(({ target }) => {
    if (target.closest('.Button.inline')) {
      dispatch(feedActions.hideNotification(notification.id));
    } else {
      if (notification.action === 'home_screen') {
        requestAddToHomeScreen();
      } else {
        const parsed = router.matchUrl(notification.actionUrl);
        if (parsed) {
          router.navigate(parsed.name, parsed.params);
        } else {
          window.open(notification.actionUrl, '_blank');
        }
      }
    }
  }, [notification]);

  return (
    <div className="FeedNotification" onClick={handleClick}>
      <div className="FeedNotification__icon" style={{ backgroundImage: `url(${notification.iconUrl})` }} />
      <div className="FeedNotification__cont">
        <div className="FeedNotification__title">{notification.title}</div>
        <div className="FeedNotification__description">{notification.description}</div>
        <div className="FeedNotification__action">
          <Button size="small">{notification.actionText}</Button>
          <Button size="small" mode="inline">Скрыть</Button>
        </div>
      </div>
    </div>
  );
};
