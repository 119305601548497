import './Birthday.sass';

import React, { useCallback, useMemo } from 'react';
import { ily } from '../../../utils';
import { Select } from '$uikit';

const Months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

export default function Birthday({ birthday, onChange }) {
  const years = useMemo(() => {
    const curYear = new Date().getFullYear();
    let result: number[] = [];
    for (let i = curYear - 8; i >= curYear - 90; i--) {
      result.push(i);
    }
    return result;
  }, []);

  const bdate = useMemo(() => {
    const exp = birthday.split('.');
    return {
      day: +exp[0] || 0,
      month: +exp[1] || 0,
      year: +exp[2] || 0,
    }
  }, [birthday, years]);

  const daysCount = useMemo(() => {
    let daysForMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (bdate.year > 0 && ily(bdate.year)) {
      daysForMonth[1] = 29;
    }

    const month = bdate.month > 0 ? bdate.month - 1 : 0;
    const result = daysForMonth[month];
    if (bdate.day > result) {
      onChange(`${result}.${bdate.month}.${bdate.year}`);
    }

    return result;
  }, [bdate.month, bdate.year]);

  const days = useMemo(() => {
    let result: number[] = [];
    for (let i = 0; i < daysCount; i++) {
      result.push(i + 1);
    }
    return result;
  }, [daysCount]);

  const handleDay = useCallback(({ target: { value } }) => {
    onChange(`${+value}.${bdate.month}.${bdate.year}`);
  }, [bdate]);

  const handleMonth = useCallback(({ target: { value } }) => {
    onChange(`${bdate.day}.${+value}.${bdate.year}`);
  }, [bdate]);

  const handleYear = useCallback(({ target: { value } }) => {
    onChange(`${bdate.day}.${bdate.month}.${+value}`);
  }, [bdate]);

  return (
    <div className="Birthday">
      <Select
        onChange={handleDay}
        value={bdate.day}
      >
        <option value={0}>День</option>
        {days.map((day) => <option value={day} key={day}>{day}</option>)}
      </Select>
      <Select
        value={bdate.month}
        onChange={handleMonth}
      >
        <option value={0}>Месяц</option>
        {Months.map((month, i) => <option value={i + 1} key={i}>{month}</option>)}
      </Select>
      <Select
        style={{ width: 112, flex: '0 0 auto' }}
        value={bdate.year}
        onChange={handleYear}
      >
        <option value={0}>Год</option>
        {years.map((year) => <option key={year} value={year}>{year}</option>)}
      </Select>
    </div>
  )
}
