import './NewPost.sass';

import React, { useCallback, useRef, useState } from 'react';
import { Button, Header, ModalWrapper, ScrollView } from '$uikit';
import { Input } from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import { createPostFormSelector, postsActions } from '../../store/posts';
import { RootState } from '$store/rootReducer';
import { IcGallery28, IcStatusSending16, IcVideo28 } from '../../assets';
import { AttachPickerManagerRef } from 'shared/components/AttachPickerManager/AttachPickerManager.interface';
import AttachPickerManager from 'shared/components/AttachPickerManager/AttachPickerManager';
import { BottomBar } from '$uikit';
import { attachTimerSelector } from '$store/attach';

export function NewPost({ ownerId }) {
  const attachManager = useRef<AttachPickerManagerRef | null>(null);
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => createPostFormSelector(state, `post_${ownerId}`));
  const [photoInputKey, setPhotoInputKey] = useState(0);
  const [videoInputKey, setVideoInputKey] = useState(0);
  const timer = useSelector((state: RootState) => attachTimerSelector(state, `post_${ownerId}`));

  const handleChange = useCallback(({ target }) => {
    dispatch(postsActions.updateFormText({ formId: `post_${ownerId}`, text: target.value }));
    attachManager.current?.findLinks(target.value);
  }, [dispatch, ownerId, attachManager]);

  const handlePublish = useCallback(() => {
    dispatch(postsActions.publish(ownerId));
  }, [ownerId]);

  const handlePhotoChange = useCallback(({ target }) => {
    attachManager.current?.uploadImages(target.files);
    setPhotoInputKey(Date.now());
  }, [attachManager, setPhotoInputKey]);

  const handleVideoChange = useCallback(({ target }) => {
    if (!target.files.length) {
      return;
    }

    attachManager.current?.uploadVideo(target.files[0]);
    setVideoInputKey(Date.now());
  }, [attachManager]);

  const handleToggleTimer = useCallback(() => {
    attachManager.current?.toggleTimer();
  }, []);

  return (
    <ModalWrapper>
      <Header
        showCloseButton
      >Новая запись</Header>
      <ScrollView>
        <div className="NewPost">
          <Input
            multiline
            placeholder="Введите текст..."
            value={form.text}
            onChange={handleChange}
            maxLength={1000}
          />
          <AttachPickerManager
            ref={attachManager}
            pickerId={`post_${ownerId}`}
          />
          <div className="NewPost__attachments">
            <div className="NewPost__attachments__photo_wrap">
              <Button
                mode="secondary"
                icon={<IcGallery28 />}
                size="small"
              >Фото</Button>
              <input
                key={photoInputKey}
                type="file"
                onChange={handlePhotoChange}
                multiple
                accept="image/*"
              />
            </div>
            <div className="NewPost__attachments__photo_wrap">
              <Button
                mode="secondary"
                icon={<IcVideo28 />}
                size="small"
              >Видео</Button>
              <input
                key={videoInputKey}
                type="file"
                onChange={handleVideoChange}
                accept="video/mp4"
              />
            </div>
            <Button
              mode={timer ? 'light' : 'secondary'}
              size="small"
              icon={<IcStatusSending16 width={28} height={28} />}
              onClick={handleToggleTimer}
            >
              Таймер
            </Button>
          </div>
        </div>
      </ScrollView>
      <BottomBar fixed>
        <Button
          stretched
          onClick={handlePublish}
        >Опубликовать</Button>
      </BottomBar>
    </ModalWrapper>
  );
}
