import { all, put, call, takeEvery, select, delay } from 'redux-saga/effects';
import * as loadImage from 'blueimp-load-image';

import { attachActions, attachLinksSelector, attachPhotosSelector } from './index';
import { LoadLinkAction, UploadPhotosAction, UploadVideoAction } from './interface';
import { toastActions } from '../toast';
import { RootState } from '../rootReducer';
import { Api } from '$api';
import { canvasToBlob } from '$utils';
import { batchActions } from '$store';

function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function() {
      resolve(reader.result);
    };

    reader.onerror = function() {
      reject(reader.error);
    };
  });
}

function loadImageByUrl(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      reject();
    };

    img.src = url;
  });
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

function* uploadPhotosWorker(action: UploadPhotosAction) {
  try {
    const { pickerId, files } = action.payload;
    const photos = yield select((state: RootState) => attachPhotosSelector(state, pickerId));

    let preparedPhotos: {
      photoId: number;
      base64: string;
      blob: Blob;
      width: number;
      height: number;
    }[] = [];

    if (photos.length + files.length > 10) {
      return yield put(toastActions.setToastFail('Вы можете прикрепить максимум 10 фотографий'));
    }

    let startId = Date.now();
    for (let file of files) {
      if (file.type.substr(0, 6) !== 'image/') {
        return yield put(toastActions.setToastFail('Это не фото — нужно фото'));
      }

      let width, height, blob, base64;
      if (file.type === 'image/gif') {
        const dataUrl = yield call(readFile, file);
        const imgData = yield call(loadImageByUrl, dataUrl);

        base64 = dataUrl;
        width = imgData.width;
        height = imgData.height;
        blob = yield call(dataURLtoBlob, dataUrl);
      } else {
        const data = yield call(loadImage, file, {
          maxWidth: 1200,
          maxHeight: 1200,
          meta: true,
          canvas: true,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
          orientation: true,
        });

        const quality = 0.8;
        base64 = data.image.toDataURL('image/jpeg', quality);
        blob = yield call(canvasToBlob, data.image, quality);
        width = data.image.width;
        height = data.image.height;
      }

      preparedPhotos.push({
        photoId: -(++startId),
        base64,
        blob,
        width,
        height,
      });
    }

    yield put(attachActions.pushPhotos({
      pickerId,
      photos: preparedPhotos.map((photo) => ({
        photoMedium: photo.base64,
        photoLarge: photo.base64,
        width: photo.width,
        height: photo.height,
        photoId: photo.photoId,
      })),
    }));

    for (let photo of preparedPhotos) {
      const form = new FormData();
      form.append('file', photo.blob);
      form.append('album_id', `-3`); // -3 is attachments album_id
      try {
        const data = yield call(Api.post, '/photos/upload', form);
        yield put(attachActions.updatePhoto({
          pickerId,
          photoId: photo.photoId,
          updated: {
            photoId: +data.photo_id,
          },
        }));
      } catch (e) {
        yield put(toastActions.setToastFail(e.message));
        yield put(attachActions.removePhoto({
          pickerId,
          photoId: photo.photoId,
        }));
      }
    }
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadLinkWorker(action: LoadLinkAction) {
  const { pickerId, link } = action.payload;
  try {
    const attachedLink = yield select((state: RootState) => attachLinksSelector(state, pickerId));
    if (attachedLink) {
      return;
    }

    const data = yield call(Api.post, '/attach/link', {
      link,
    });

    yield put(
      batchActions(
        attachActions.setLink({
          pickerId,
          link: data,
        }),
        attachActions.setLoadedLink(pickerId),
      )
    );
  } catch (e) {
    yield put(
      batchActions(
        attachActions.setLoadedLink(pickerId),
        attachActions.setLinkFailed(link),
      ),
    );
  }
}

function* uploadVideoWorker(action: UploadVideoAction) {
  try {
    const { pickerId, file } = action.payload;

    if (file.type !== 'video/mp4') {
      return yield put(toastActions.setToastFail('Выберите видео в формате mp4'));
    }

    const maxSize = 200 << 20;
    if (file.size > maxSize) {
      return yield put(toastActions.setToastFail('Размер видео не более 200МБ'));
    }

    const form = new FormData();
    form.append('file', file);

    const id = -Date.now();
    yield put(attachActions.pushPhotos({
      pickerId,
      photos: [
        {
          isVideo: true,
          photoMedium: require('$assets/video_placeholder.svg').default,
          photoLarge: require('$assets/video_placeholder.svg').default,
          width: 500,
          height: 400,
          photoId: id,
        }
      ],
    }));

    try {
      const data = yield call(Api.post, '/video/upload', form);
      yield put(attachActions.updatePhoto({
        pickerId,
        photoId: id,
        updated: {
          photoId: +data.videoId,
          photoMedium: data.poster,
          photoLarge: data.poster,
        },
      }));
    } catch (e) {
      yield put(toastActions.setToastFail(e.message));
      yield put(attachActions.removePhoto({
        pickerId,
        photoId: id,
      }));
    }
  } catch (e) {

  }
}

export function* attachSaga() {
  yield all([
    takeEvery(attachActions.uploadPhotos, uploadPhotosWorker),
    takeEvery(attachActions.loadLink, loadLinkWorker),
    takeEvery(attachActions.uploadVideo, uploadVideoWorker),
  ]);
}
