import { all, take, takeLatest, call, put, select } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { realtimeActions } from './';
import { API_URL_PRODUCTION } from '$shared/constants';
import { getApiUrl, isNative, scrollToBottomWithCheck } from '$utils';
import { batchActions } from '$store';
import { mainActions } from '$store/main';
import { imActions, imHistorySelector } from '$store/im';
import router from '$navigation/router';
import { CHAT, IM } from '$shared/constants/pages';
import { MessageModel } from '$store/models';
import { showNotify } from '../../services/notifications';
import { blacklistActions } from '$store/blacklist';

let baseUrl: string | null = null;
let Channel: EventChannel<any> | null = null;

async function initWebsocket(token: string) {
  if (!baseUrl) {
    baseUrl = ((await getApiUrl()) ?? API_URL_PRODUCTION).slice(0, -7);
  }

  Channel = eventChannel((emitter) => {
    let socket: WebSocket | null = null;
    let reconnectTimer: any = 0;
    let currentConnId = 0;
    let forceClosed = false;
    let lastEventId = 0;

    function connect() {
      clearTimeout(reconnectTimer);

      const connId = ++currentConnId;

      let params: { [index: string]: any } = {
        access_token: token,
        last_event_id: lastEventId,
      };

      let paramsArr = [];
      for (const i in params) {
        // @ts-ignore
        paramsArr.push(`${i}=${encodeURIComponent(params[i])}`);
      }

      if (socket) {
        try {
          socket.close();
          socket = null;
        } catch (e) {}
      }

      socket = new WebSocket(`wss://api.anime.fans/ws?${paramsArr.join('&')}`);
      socket.onopen = () => {
        //console.log('ws connected');
      };

      socket.onclose = (err) => {
        console.log('closed', err);
        if (connId === currentConnId) {
          connectionDidClose();
        }
      };
      socket.onmessage = (e) => {
        const messages = e.data.split('\n');
        for (let i = 0; i < messages.length; i++) {
          let event;
          try {
            event = JSON.parse(messages[i]);
          } catch (err) {
            console.log('err', err.message);
            continue;
          }

          console.log('event', event);
          lastEventId = event.id;
          emitter(event);
        }
      };

      socket.onerror = (err) => {
        console.log('err', err);
        if (connId === currentConnId) {
          connectionDidClose();
        }
      };
    }

    function connectionDidClose() {
      if (forceClosed) {
        return;
      }

      clearTimeout(reconnectTimer);
      reconnectTimer = setTimeout(connect, 3000);
    }

    connect();

    return () => {
      clearTimeout(reconnectTimer);
      forceClosed = true;
      socket?.close();
      socket = null;
    };
  });

  return Channel;
}

// async function initWebsocket(token: string) {
//   if (!baseUrl) {
//     baseUrl = ((await getApiUrl()) ?? API_URL_PRODUCTION).slice(0, -7);
//   }
//
//   Channel = eventChannel((emitter) => {
//     const es = new EventSource(`https://api.anime.fans/es?access_token=${token}`);
//     es.onopen = () => {
//       console.log('es connected');
//     };
//
//     es.onmessage = (e) => {
//       console.log('es onmessage', e.data);
//       try {
//         const parsed = JSON.parse(e.data);
//         emitter(parsed);
//       } catch (e) {
//         console.log('cant parse event', e.message);
//       }
//     };
//
//     es.onerror = (e) => {
//       console.log('es onerror', e);
//     };
//
//     return () => {
//       es.close();
//     };
//   });
//
//   return Channel;
// }

function* handleEvent(event: any) {
  try {
    const { type, body } = event;

    switch (type) {
      case 'connect': {
        console.log('ws connected');
        break;
      }

      case 'batch': {
        for (const item of body) {
          try {
            yield call(handleEvent, JSON.parse(item));
          } catch (e) {}
        }
        break;
      }

      case 'message': {
        yield put(
          batchActions(
            mainActions.setUser(body.user),
            imActions.setMessage({
              peerId: body.dialog.peerId,
              messageId: body.message.isInbox ? body.message.id : body.randomId,
              message: body.message,
            }),
            imActions.setDialogs({
              dialogs: [body.dialog],
              messages: {},
            })
          )
        );
        const routerState = router.getState();
        if (body.message.isInbox) {
          const isInChat = routerState.name === CHAT && +routerState.params.peerId === body.dialog.peerId;
          if (isInChat) {
            yield call(scrollToBottomWithCheck, 'chat_history');

            if (!document.hidden) {
              yield put(imActions.readHistory(body.dialog.peerId));
            }
          }

          if (!isNative(2) && (routerState.name !== IM && !isInChat) || document.hidden) {
            let text = `${body.user.firstName} ${body.user.lastName}: ${body.message.text ?? ''}`;

            if (body.message.attachments && body.message.attachments.length > 0) {
              if (body.message.attachments.length > 1) {
                text += ' Вложения';
              } else if (body.message.attachments[0].type === 'photo') {
                text += ' Фото';
              }
            }

            yield call(
              showNotify,
              'Новое сообщение',
              text,
              () => {
                if (routerState.name === CHAT && +routerState.params.peerId === body.dialog.peerId) {
                  // already in chat
                } else {
                  router.navigate(CHAT, { peerId: body.dialog.peerId });
                }
              },
              { image: body.user.photo }
            );
          }
        }

        if (body.message.isInbox && routerState.name === CHAT && +routerState.params.peerId === body.dialog.peerId) {
          yield call(scrollToBottomWithCheck, 'chat_history');
          yield put(imActions.readHistory(body.dialog.peerId));
        }
        break;
      }

      case 'read_history': {
        const history = yield select(imHistorySelector, body.peerId);
        if (history) {
          const updatedMessages: { [index: number]: MessageModel } = [];
          for (const msgId in history) {
            const message = history[msgId];
            if (!message.isInbox && message.isUnread) {
              updatedMessages[message.id] = { ...message, isUnread: false };
            }
          }

          if (Object.keys(updatedMessages).length > 0) {
            yield put(
              imActions.setHistory({
                peerId: body.peerId,
                history: updatedMessages,
              }),
            );
          }
        }

        break;
      }

      case 'dialogs_counter': {
        yield put(mainActions.setNewDialogs(body.count));
        break;
      }

      case 'ban_from_me': {
        yield put(
          blacklistActions.setBanFromMe({
            userId: body.userId,
            isBanned: true,
          })
        );
        break;
      }

      case 'ban_to_me': {
        yield put(
          blacklistActions.setBanToMe({
            userId: body.userId,
            isBanned: true,
          })
        );
        break;
      }

      case 'unban_from_me': {
        yield put(
          blacklistActions.setBanFromMe({
            userId: body.userId,
            isBanned: false,
          })
        );
        break;
      }

      case 'unban_to_me': {
        yield put(
          blacklistActions.setBanToMe({
            userId: body.userId,
            isBanned: false,
          })
        );
        break;
      }

      case 'has_notify': {
        yield put(
          mainActions.setHasNotify(body),
        );
        break;
      }

      case 'delete_dialog': {
        yield put(
          imActions.removeDialog(body.peerId),
        );
        break;
      }

      case 'delete_message': {
        yield put(
          imActions.removeMessage({
            id: body.id,
            peerId: body.peerId,
          }),
        );
        break;
      }

      default:
        console.log('Unknown event', body.event);
    }
  } catch (e) {}
}

function* listenWorker(action: PayloadAction<string>) {
  const chan = yield call(initWebsocket, action.payload);
  try {
    while (true) {
      const body = yield take(chan);
      yield call(handleEvent, body);
    }
  } finally {
    Channel = null;
  }
}

function* unlistenWorker() {
  if (Channel) {
    yield call(Channel.close);
    Channel = null;
  }
}

export function* realtimeSaga() {
  yield all([
    takeLatest(realtimeActions.listen, listenWorker),
    takeLatest(realtimeActions.unListen, unlistenWorker),
  ]);
}
