import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

import {
  FEED,
  INVITE,
  MAIN,
  NOTIFY,
  OTHER,
  PROFILE,
  GROUP,
  REGISTRATION,
  SEARCH,
  VIDEOS,
  IM,
  GROUPS,
  CHAT,
  AUTH_REQUIRED,
  FIND_FRIENDS,
  VIEW_ANIME,
  RESOLVE,
  VK_AUTH,
  SETTINGS,
  ANIME_LISTS,
  ANIME_LIST,
  PROMO,
  RATING,
  BIRTHDAYS,
  POST_VIEW,
  TESTS_VIEW,
  TESTS_RESULT,
  ALL,
  PAYMENT_SUCCESS,
} from 'shared/constants/pages';
import { isDesktop } from '$utils';
import { store } from '$store';
import { postsActions } from '$store/posts';

const routes = [
  { name: MAIN, path: '/' },
  { name: REGISTRATION, path: '/registration' },
  { name: FEED, path: '/feed' },
  { name: PROFILE, path: '/id:id<\\d+>' },
  { name: GROUP, path: '/group:id<\\d+>' },
  { name: SEARCH, path: '/search' },
  { name: NOTIFY, path: '/notify' },
  { name: POST_VIEW, path: '/post:id' },
  { name: OTHER, path: '/other' },
  { name: VIDEOS, path: '/explore' },
  { name: INVITE, path: '/invite' },
  { name: IM, path: '/im' },
  { name: CHAT, path: '/im:peerId' },
  { name: GROUPS, path: '/groups?:section' },
  { name: AUTH_REQUIRED, path: '/auth_required' },
  { name: FIND_FRIENDS, path: '/find_friends' },
  { name: VIEW_ANIME, path: '/anime/:id' },
  { name: VK_AUTH, path: '/vkauth' },
  { name: SETTINGS, path: '/settings' },
  { name: ANIME_LISTS, path: '/anime/:ownerId/lists' },
  { name: ANIME_LIST, path: '/anime/list/:listId' },
  { name: PROMO, path: '/promo' },
  { name: RATING, path: '/rating' },
  { name: BIRTHDAYS, path: '/birthdays' },
  { name: PAYMENT_SUCCESS, path: '/payment/success' },

  { name: TESTS_VIEW, path: '/tests/:testId' },
  { name: TESTS_RESULT, path: '/tests/:id/result' },

  { name: ALL, path: '/all/:type' },

  { name: RESOLVE, path: '/:domain' },
];

const router = createRouter(routes, {
  allowNotFound: false,
  autoCleanUp: false,
  defaultRoute: 'home',
  defaultParams: {},
  queryParamsMode: 'loose',
  trailingSlashMode: 'default',
  strictTrailingSlash: false,
  caseSensitive: false,
  urlParamsEncoding: 'default'
})
router.usePlugin(browserPlugin());
router.usePlugin(listenersPlugin());

let history: any = [];
let isBack = false;
let lastScrollTop = 0;

export function isBackNavigation() {
  return isBack;
}

export function getLastScrollTop() {
  return lastScrollTop;
}

function parseRouteName(route) {
  if (!route) {
    return 'none';
  }
  //
  // if (route.params && route.params.w) {
  //   return route.params.w;
  // }

  return route.path;
}

/* @ts-ignore */
router.addListener((from, to) => {
  const fromName = parseRouteName(from);
  const toName = parseRouteName(to);

  const lastHistory = history.length > 0 ? history[history.length - 1] : null;

  if (lastHistory && lastHistory.from === toName && lastHistory.to === fromName) {
    history.pop();
    isBack = true;
    lastScrollTop = lastHistory.scrollTop;
  } else {
    let scrollNode = document.querySelector('.Modal__cont ._ScrollView');
    if (!scrollNode) {
      scrollNode = document.querySelector('._ScrollView');
    }

    history.push({
      from: fromName,
      to: toName,
      scrollTop: scrollNode?.scrollTop ?? 0,
    });
    isBack = false;
  }

  if ((to?.path ?? '').match(/w\=play/) && (from?.path ?? '').match(/w\=play/)) {
     // skip scroll
  } else {
    //window.scrollTo(0, 0);
  }
});

export default router;
