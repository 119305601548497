import './ScrollView.sass';

import React, { FC, useCallback, useEffect, useRef } from 'react';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { getLastScrollTop, isBackNavigation } from '$navigation/router';
import { ScrollViewProps, ScrollViewVirtualizedProps } from './ScrollView.interface';
import { useLoadMore } from '$hooks';
import { useRoute } from 'react-router5';

export const ScrollView: FC<ScrollViewProps> = (props) => {
  const { id, children, canLoadMore = false, onLoadMore = null } = props;
  const { route } = useRoute();

  const ref = useRef<HTMLDivElement>(null);

  const isBack = isBackNavigation();
  const st = getLastScrollTop();

  useEffect(() => {
    if (isBack) {
      if (ref.current) {
        ref.current.scrollTop = st;
      }
    }
  }, [route]);

  useLoadMore({
    handler: () => onLoadMore && onLoadMore(),
    enabled: canLoadMore && !!onLoadMore,
    opts: {
      node: ref.current,
    },
  });

  return (
    <div className="ScrollView _ScrollView" ref={ref} id={id}>
      {children}
    </div>
  );
};

export const ScrollViewVirtualized: FC<ScrollViewVirtualizedProps> = (props) => {
  const { canLoadMore = false, onLoadMore = null, rowCount, rowHeight, renderRow } = props;
  const { route } = useRoute();

  const ref = useRef<any>(null);

  const isBack = isBackNavigation();
  const st = getLastScrollTop();

  useEffect(() => {
    if (isBack) {
      if (ref.current) {
        ref.current.scrollToPosition(st);
      }
    }
  }, [route]);

  function renderRowWrapper({index, key, style}) {
    return (
      <div key={key} style={style}>
        {renderRow(index)}
      </div>
    );
  }

  const handleScroll = useCallback(({ clientHeight, scrollHeight, scrollTop }) => {
    if (scrollTop + clientHeight * 2 >= scrollHeight) {
      onLoadMore && onLoadMore();
    }
  }, [onLoadMore]);

  const listProps: any = {};
  if (!!onLoadMore && canLoadMore) {
    listProps.onScroll = handleScroll;
  }

  return (
    <div style={{ height: rowHeight * rowCount }}>
      <AutoSizer>
        {({width, height}) => (
          <List
            className="_ScrollView"
            ref={ref}
            rowCount={rowCount}
            rowHeight={rowHeight}
            height={height}
            width={width}
            rowRenderer={renderRowWrapper}
            {...listProps}
          />
        )}
      </AutoSizer>
    </div>
  )
};
