import './LinkAttach.sass';

import React, { FC, useMemo } from 'react';
import { LinkAttachProps } from './LinkAttach.interface';
import { classNames, parseLinkData } from '$utils';
import { Button } from '$uikit';
import { ExternalVideo } from '$shared/components';

export const LinkAttach: FC<LinkAttachProps> = ({ link, mini, parentObject }) => {
  const data = useMemo(() => {
    return parseLinkData(link);
  }, [link]);

  if (!mini && link && link.type === 'external_video') {
    return <ExternalVideo link={link} parentObject={parentObject} />;
  }

  const LinkComponent = data.linkComponent;

  return (
    <LinkComponent
      className={classNames({
        LinkAttach: true,
        [link.type]: true,
      })}
      {...data.linksOptions}
    >
      <div className="LinkAttach__type">{data.type}</div>
      <div className="LinkAttach__body">
        <div className="LinkAttach__image" style={{ backgroundImage: `url(${data.image})` }} />
        <div className="LinkAttach__cont">
          <div className="LinkAttach__title">{data.title}</div>
          <div className="LinkAttach__description">{data.description}</div>
          <div className="LinkAttach__button">
            <Button size="small">{data.buttonText}</Button>
          </div>
        </div>
      </div>
    </LinkComponent>
  )
};
