import './Anime.sass';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import { useRoute } from 'react-router5';

import {
  Button,
  CellButton,
  Form,
  FormItem,
  Header,
  HorizontalScroll,
  InlineHeader,
  ScrollView,
  SearchInput
} from '$uikit';
import { animeActions, animeSelector } from '$store/anime';
import { Loader, Metatags } from '$shared/components';
import { AnimeItem } from '$core/Anime/AnimeItem/AnimeItem';
import { AnimeCard } from '$core/Anime/AnimeCard/AnimeCard';
import { IcDropdown16, IcFilters28, IcList28 } from '$assets';
import { useCurrentUserInfo } from '$hooks';
import { getAnimeGenres, getAnimeGenresByIds, getAnimeThemes, getAnimeThemesByIds } from '$utils';
import router from '$navigation/router';
import { ANIME_LISTS, VIDEOS } from '$shared/constants/pages';
import { authSelector } from '$store/auth';

export function Anime() {
  const dispatch = useDispatch();
  const {
    isLoading,
    list,
    recent,
    likes,
    suggestions,
    searchQuery,
    isSearching,
    searchResult,
    isLoadingMore,
    nextFrom,
    searchNextFrom,
  } = useSelector(animeSelector);

  const { route } = useRoute();
  const user = useCurrentUserInfo();
  const { isLogged } = useSelector(authSelector)

  const [isFiltersShown, setFiltersShown] = useState(route.params.genre || route.params.theme);
  const [genre, setGenre] = useState(route.params.genre ? getAnimeGenresByIds(route.params.genre).map((item) => ({ value: item.id, label: item.title })) : null);
  const [theme, setTheme] = useState(route.params.theme ? getAnimeThemesByIds(route.params.theme).map((item) => ({ value: item.id, label: item.title })) : null);

  useEffect(() => {
    dispatch(animeActions.loadExplore());
  }, []);

  const isSearch = route.params.genre || route.params.theme || searchQuery.trim().length > 0;

  useEffect(() => {
    const params: any = { ...route.params };
    if (genre && genre.length > 0) {
      params.genre = genre.map((item) => item.value).join(',');
    } else {
      delete params.genre;
    }

    if (theme && theme.length > 0) {
      params.theme = theme.map((item) => item.value).join(',');
    } else {
      delete params.theme;
    }

    router.navigate(VIDEOS, params, { replace: true, urlParamsEncoding: false });

    if (params.genre || params.theme) {
      dispatch(animeActions.search(searchQuery));
    }
  }, [genre, theme]);

  const handleSearchChange = useCallback((query) => {
    dispatch(animeActions.search(query));
  }, [dispatch]);

  const handleFiltersToggle = useCallback(() => {
    setFiltersShown(!isFiltersShown);
  }, [isFiltersShown, setFiltersShown]);

  const handleGenreChange = useCallback((value) => {
    setGenre(value);
  }, [setGenre]);

  const genreOptions = useMemo(() => {
    return getAnimeGenres().map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, []);

  const handleThemeChange = useCallback((value) => {
    setTheme(value);
  }, [setTheme]);

  const themesOptions = useMemo(() => {
    return getAnimeThemes().map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, []);

  function renderContent() {
    if (isLoading && !list.length) {
      return (
        <Loader isWrapped />
      );
    }

    if (isSearch) {
      if (isSearching) {
        return (
          <Loader isWrapped />
        );
      }

      if (!searchResult.length) {
        return (
          <div className="Anime_not_found">
            Ничего не найденно
          </div>
        )
      }

      return (
        <>
          <InlineHeader>Результаты поиска</InlineHeader>
          <div>
            {searchResult.map((anime) => <AnimeItem key={anime.id} anime={anime} />)}
          </div>
        </>
      )
    }

    return <>
      {isLogged && (
        <div className="Anime__buttons">
          <CellButton icon={<IcList28 />} route={ANIME_LISTS} routeParams={{ ownerId: user.id }}>Мои списки</CellButton>
        </div>
      )}
      {recent.length > 0 && (
        <>
          <InlineHeader>Продолжить просмотр</InlineHeader>
          <div className="Anime__cards">
            <HorizontalScroll>
              {recent.map((item) => <AnimeCard key={item.anime.id} item={item} />)}
            </HorizontalScroll>
          </div>
        </>
      )}
      {likes.length > 0 && (
        <>
          <InlineHeader>Понравилось</InlineHeader>
          <div className="Anime__cards">
            <HorizontalScroll>
              {likes.map((anime) => <AnimeCard key={anime.id} item={{ anime }} />)}
            </HorizontalScroll>
          </div>
        </>
      )}
      {suggestions.length > 0 && (
        <>
          <InlineHeader>Для вас</InlineHeader>
          <div className="Anime__cards">
            <HorizontalScroll>
              {suggestions.map((anime) => <AnimeCard key={anime.id} item={{ anime }} />)}
            </HorizontalScroll>
          </div>
        </>
      )}
      <InlineHeader>Популярное</InlineHeader>
      <div>
        {list.map((anime) => <AnimeItem key={anime.id} anime={anime} />)}
      </div>
    </>
  }

  function renderFilters() {
    if (!isFiltersShown) {
      return null;
    }

    return (
      <div className="Anime__filters">
        <Form>
          <FormItem title="Жанр">
            <ReactSelect
              classNamePrefix="Select"
              placeholder="Выберите жанр"
              menuPlacement="bottom"
              isMulti
              value={genre}
              onChange={handleGenreChange}
              options={genreOptions}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
          <FormItem title="Тип">
            <ReactSelect
              classNamePrefix="Select"
              placeholder="Выберите тип"
              menuPlacement="bottom"
              isMulti
              value={theme}
              onChange={handleThemeChange}
              options={themesOptions}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
        </Form>
      </div>
    );
  }

  return (
    <>
      <Metatags
        title={`Смотреть аниме на Anime.Fans`}
        description="Более 200 аниме доступно для просмотра без рекламы и в хорошем качестве"
      />
      <Header isSearch>
        <SearchInput
          placeholder="Поиск аниме"
          query={searchQuery}
          onChange={handleSearchChange}
          rightButtons={(
            <Button
              icon={<IcFilters28 />}
              mode={isFiltersShown ? 'light' : 'secondary'}
              onClick={handleFiltersToggle}
            />
          )}
        />
      </Header>
      <ScrollView
        onLoadMore={() => dispatch(animeActions.loadMore())}
        canLoadMore={!isLoadingMore && !!(searchQuery.trim().length > 0 ? searchNextFrom : nextFrom)}
      >
        {renderFilters()}
        {renderContent()}
      </ScrollView>
    </>
  );
}
