import './Online.sass';

import React, { FC, useMemo } from 'react';

import { useLastActivity } from '$hooks/useLastActivity';
import { OnlineProps } from '$shared/components/Online/Online.interface';
import { useDate, useUserInfo } from '$hooks';
import { classNames, genderText } from '$utils';

export const Online: FC<OnlineProps> = ({ userId, extended = false, inPhoto = false }) => {
  const { online, seenRecently } = useLastActivity(userId);
  const user = useUserInfo(userId);
  const date = useDate(user?.lastUpdate ?? 0);

  const label = useMemo(() => {
    return genderText(user?.sex ?? 0, [
      'был',
      'была'
    ]) + ' ' + date;
  }, [user?.sex, date]);

  if (userId < 0) {
    return null;
  }

  const wrapStyle = classNames({
    Online__wrapper: true,
    inPhoto,
  })

  if (online) {
    return (
      <div className={wrapStyle}>
        <div className="Online active" />
        {extended && <div className="Online__label">онлайн</div>}
      </div>
    );
  }

  if (seenRecently) {
    return (
      <div className={wrapStyle}>
        <div className="Online">
          <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.993 4.243A3 3 0 013.758.008a4 4 0 104.235 4.235z" fill="#45E588"/></svg>
        </div>
        {extended && <div className="Online__label">{label}</div>}
      </div>
    );
  }

  if (extended) {
    return <div className="Online__label skipMargin">{label}</div>;
  }

  return null;
}
