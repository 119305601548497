import './HorizontalScroll.sass';

import React, { FC, forwardRef } from 'react';

export const HorizontalScroll = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div className="HorizontalScroll">
      <div className="HorizontalScroll__content" ref={ref}>
        {props.children}
      </div>
    </div>
  );
});
