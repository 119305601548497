import './EditPost.sass';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Header, ScrollView } from '$uikit';
import { Input } from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import { createPostFormSelector, postsActions } from '../../store/posts';
import { RootState } from '$store/rootReducer';
import { IcGallery28, IcStatusSending16 } from '../../assets';
import { AttachPickerManagerRef } from 'shared/components/AttachPickerManager/AttachPickerManager.interface';
import AttachPickerManager from 'shared/components/AttachPickerManager/AttachPickerManager';
import { BottomBar } from '$uikit';
import { attachTimerSelector } from '$store/attach';
import { usePost } from '$hooks';

export function EditPost({ postId }) {
  const attachManager = useRef<AttachPickerManagerRef | null>(null);
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => createPostFormSelector(state, `edit_post_${postId}`));
  const [photoInputKey, setPhotoInputKey] = useState(0);
  const timer = useSelector((state: RootState) => attachTimerSelector(state, `edit_post_${postId}`));
  const post = usePost(postId);
  const isScheduled = post && post.createdAt * 1000 > Date.now();

  useEffect(() => {
    dispatch(postsActions.loadEditPost(postId));
  }, []);

  const handleChange = useCallback(({ target }) => {
    dispatch(postsActions.updateFormText({ formId: `edit_post_${postId}`, text: target.value }));
    attachManager.current?.findLinks(target.value);
  }, [dispatch, postId, attachManager]);

  const handleSave = useCallback(() => {
    dispatch(postsActions.savePost(postId));
  }, [postId]);

  const handlePhotoChange = useCallback(({ target }) => {
    attachManager.current?.uploadImages(target.files);
    setPhotoInputKey(Date.now());
  }, [attachManager, setPhotoInputKey]);

  const handleToggleTimer = useCallback(() => {
    attachManager.current?.toggleTimer();
  }, []);

  return (
    <>
      <Header
        showCloseButton
      >Ред. записи</Header>
      <ScrollView>
        <div className="NewPost">
          <Input
            multiline
            placeholder="Введите текст..."
            value={form.text}
            onChange={handleChange}
            maxLength={1000}
          />
          <AttachPickerManager
            ref={attachManager}
            pickerId={`edit_post_${postId}`}
          />
          <div className="NewPost__attachments">
            <div className="NewPost__attachments__photo_wrap">
              <Button
                mode="secondary"
                icon={<IcGallery28 />}
                size="small"
              >Фото</Button>
              <input
                key={photoInputKey}
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                multiple
              />
            </div>
            {isScheduled && (
              <Button
                mode={timer ? 'light' : 'secondary'}
                size="small"
                icon={<IcStatusSending16 width={28} height={28} />}
                onClick={handleToggleTimer}
              >
                Таймер
              </Button>
            )}
          </div>
        </div>
      </ScrollView>
      <BottomBar>
        <Button
          onClick={handleSave}
          stretched
        >Сохранить</Button>
      </BottomBar>
    </>
  );
}
