import './Linkify.sass';

import React, { useMemo } from 'react';
import linkifyIt from 'linkify-it';

function internalLink(link) {
  return !!link.match(/^(https?:\/\/)?(anime)\.(fans)/i);
}

function findLink(children) {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-ignore
        children: findLink(child.props.children),
      });
    }

    if (!child) {
      return child;
    }

    const matches = linkifyIt().match(child);
    if (!matches) {
      return child;
    }

    let result: any[] = [];
    let lastIndex = 0;
    for (let match of matches) {
      result.push(child.substr(lastIndex, match.index));

      const isInternal = internalLink(match.url);

      if (isInternal) {
        result.push(
          <a href={match.url}>
            {match.text}
          </a>
        );
      } else {
        result.push(
          <a href={match.url} target="_blank">
            {match.text}
          </a>
        );
      }
      lastIndex = match.lastIndex;
    }
    result.push(child.substr(lastIndex));

    return result;
  });
}

export function Linkify({ children }) {
  const prepared = useMemo(() => {
    return findLink(children);
  }, [children]);

  return (
    <div className="Linkify">
      {prepared}
    </div>
  );
}
