import { useCallback, useEffect, useState } from 'react';
import { getTopOnScrollView } from '$utils';

export function useVisibleElement(elem) {
  const [visible, setVisible] = useState(false);

  const handleScroll = useCallback(() => {
    if (!elem.current) {
      return;
    }

    const scrollEl = document.querySelector('.ScrollView') as HTMLDivElement;
    if (!scrollEl) {
      return;
    }

    const st = scrollEl.scrollTop;
    const top = getTopOnScrollView(elem.current);
    const th = 100; // threshold
    const isVisible = st + scrollEl.offsetHeight - th > top && st < top + elem.current.offsetHeight - th;

    if (isVisible !== visible) {
      setVisible(isVisible);
    }
  }, [elem, visible, setVisible]);

  useEffect(() => {
    const scrollEl = document.querySelector('.ScrollView');
    if (!scrollEl) {
      return;
    }

    scrollEl.addEventListener('scroll', handleScroll, true);
    const timer = setTimeout(handleScroll, 200);

    return () => {
      clearTimeout(timer);
      scrollEl.removeEventListener('scroll', handleScroll, true);
    };
  }, [elem, handleScroll]);

  return {
    visible
  };
}
