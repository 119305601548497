import { useSelector } from 'react-redux';

import { AnimeModel } from '$store/models';
import { RootState } from '$store/rootReducer';
import { animeInfoSelector } from '$store/main';

export const useAnime = (userId: number): AnimeModel => {
  const info = useSelector((state: RootState) =>
    animeInfoSelector(state, userId),
  );
  return info as AnimeModel;
};
