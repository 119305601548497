import { all } from 'redux-saga/effects';

import { authSaga } from './auth/sagas';
import { profileSaga } from './profile/sagas';
import { postsSaga } from './posts/sagas';
import { attachSaga } from './attach/sagas';
import { feedSaga } from './feed/sagas';
import { animeSaga } from './anime/sagas';
import { searchSaga } from './search/sagas';
import { followersSaga } from './followers/sagas';
import { groupsSaga } from './groups/sagas';
import { imSaga } from '$store/im/sagas';
import { realtimeSaga } from '$store/realtime/sagas';
import { blacklistSaga } from '$store/blacklist/sagas';
import { notifySaga } from '$store/notify/sagas';
import { inviteSaga } from '$store/invite/sagas';
import { editGroupSaga } from '$store/editGroup/sagas';
import { resolverSaga } from '$store/resolver/sagas';
import { mainSaga } from '$store/main/sagas';
import { likesSaga } from '$store/likes/sagas';

export function* rootSaga() {
  yield all([
    authSaga(),
    profileSaga(),
    postsSaga(),
    attachSaga(),
    feedSaga(),
    animeSaga(),
    searchSaga(),
    followersSaga(),
    groupsSaga(),
    imSaga(),
    realtimeSaga(),
    blacklistSaga(),
    notifySaga(),
    inviteSaga(),
    editGroupSaga(),
    resolverSaga(),
    mainSaga(),
    likesSaga(),
  ]);
}
