import './Edit.sass';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { default as ReactSelect } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux';

import { Button, Form, FormItem, Header, Input, ScrollView, SegmentedControl } from '$uikit';
import { BottomBar } from '$uikit';
import Birthday from '$shared/components/Birthday/Birthday';
import { promiseCities, promiseCountries } from '../../services/selectDataFetcher';
import { IcDropdown16 } from '$assets';
import { useCurrentUserInfo, useTranslator } from '$hooks';
import { toastActions } from '$store/toast';
import { USER_NAME_REGEX } from '$shared/constants';
import { profileActions } from '$store/profile';

export function Edit() {
  const user = useCurrentUserInfo();
  const dispatch = useDispatch();
  const t = useTranslator();

  const [activeTab, setActiveTab] = useState('general');
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [sex, setSex] = useState(user.sex);
  const [birthday, setBirthday] = useState(user.bDate);
  const [country, setCountry] = useState(user.countryId > 0 ? {
    label: user.countryName,
    value: user.countryId,
  } : null);
  const [city, setCity] = useState(user.cityId > 0 ? {
    label: user.cityName,
    value: user.cityId,
  } : null);
  const searchTimer = useRef<any | null>(null);
  const [cities, setCities] = useState<any[]>([]);
  const [isCitiesLoading, setCitiesLoading] = useState(false);

  useEffect(() => {
    if (country) {
      handleCitiesInputChange('', { action: 'input-change' }, country.value);
    }
  }, []);

  const handleFirstName = useCallback(({ target }) => {
    setFirstName(target.value);
  }, [setFirstName]);

  const handleLastName = useCallback(({ target }) => {
    setLastName(target.value);
  }, [setLastName]);

  const handleSex = useCallback((value: any) => {
    setSex(value);
  }, [setSex]);

  const handleBirthday = useCallback((newBirthday) => {
    setBirthday(newBirthday);
  }, [setBirthday]);

  const handleCountryChange = useCallback((value) => {
    if (country?.value !== value.value) {
      setCity(null);
    }
    setCountry(value);
    handleCitiesInputChange('', { action: 'input-change' }, value);
  }, [setCountry, country]);

  const handleCitiesInputChange = useCallback((query, { action }, newCountry = null) => {
    if (action !== 'input-change') {
      return;
    }

    searchTimer.current && clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setCitiesLoading(true);
      promiseCities(query, newCountry?.value ?? country!.value).then((options) => {
        setCities(options);
        setCitiesLoading(false);
      });
    }, newCountry ? 0 : 1000);
  }, [setCitiesLoading, promiseCities, country, searchTimer]);

  const handleCityChange = useCallback((value) => {
    setCity(value);
  }, [setCity]);

  const handleSave = useCallback(() => {
    if (firstName.trim().length < 2) {
      return dispatch(toastActions.setToastFail(t('reg_name_short')));
    }

    if (firstName.trim().length > 16) {
      return dispatch(toastActions.setToastFail(t('reg_name_long')));
    }

    if (!firstName.trim().match(USER_NAME_REGEX)) {
      return dispatch(toastActions.setToastFail(t('reg_name_wrong')));
    }

    if (lastName.trim().length < 2) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_short')));
    }

    if (lastName.trim().length > 16) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_long')));
    }

    if (!lastName.trim().match(USER_NAME_REGEX)) {
      return dispatch(toastActions.setToastFail(t('reg_last_name_wrong')));
    }

    if (!sex) {
      return dispatch(toastActions.setToastFail(t('reg_select_gender')));
    }

    dispatch(profileActions.editProfile({
      firstName,
      lastName,
      sex,
      birthday,
      countryId: country?.value ?? 0,
      cityId: city?.value ?? 0,
    }));
  }, [dispatch, firstName, lastName, sex, birthday, country, city, t]);

  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
  }, []);

  function renderContent() {
    if (activeTab === 'general') {
      return (
        <Form>
          <FormItem title={t('reg_name')}>
            <Input
              placeholder={t('reg_enter_name')}
              value={firstName}
              onChange={handleFirstName}
            />
          </FormItem>
          <FormItem title={t('reg_last_name')}>
            <Input
              placeholder={t('reg_enter_last_name')}
              value={lastName}
              onChange={handleLastName}
            />
          </FormItem>
          <FormItem title={t('reg_gender')}>
            <SegmentedControl
              options={[
                { label: t('reg_gender_female'), value: 1 },
                { label: t('reg_gender_male'), value: 2 },
              ]}
              defaultValue={sex}
              onChange={handleSex}
            />
          </FormItem>
          <FormItem title={t('reg_birthday')}>
            <Birthday birthday={birthday} onChange={handleBirthday} />
          </FormItem>
          <FormItem title={t('reg_country')}>
            <AsyncSelect
              classNamePrefix="Select"
              menuPlacement="top"
              value={country}
              onChange={handleCountryChange}
              cacheOptions
              defaultOptions
              loadOptions={promiseCountries}
              loadingMessage={() => t('loading')}
              noOptionsMessage={() => t('reg_select_empty')}
              placeholder={t('reg_select_country')}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>
          {!!country && <FormItem title={t('reg_city')}>
            <ReactSelect
              classNamePrefix="Select"
              placeholder={t('reg_select_city')}
              menuPlacement="top"
              isSearchable
              value={city}
              onChange={handleCityChange}
              options={cities}
              onInputChange={handleCitiesInputChange}
              isLoading={isCitiesLoading}
              loadingMessage={() => t('loading')}
              noOptionsMessage={() => t('reg_select_empty')}
              components={{
                DropdownIndicator: () => <IcDropdown16 />,
              }}
            />
          </FormItem>}
        </Form>
      );
    } else if (activeTab === 'interests') {
      return (
        <Form>
          <FormItem title="Деятельность">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Интересы">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимая музыка">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимые фильмы">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимые телешоу">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимые книги">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимые игры">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="Любимые цитаты">
            <Input
              multiline
            />
          </FormItem>
          <FormItem title="О себе">
            <Input
              multiline
            />
          </FormItem>
        </Form>
      );
    }
  }

  return (
    <>
      <Header showCloseButton>{t('other_edit')}</Header>
      <ScrollView>
        {/*<Tabs selected={activeTab} onChange={handleTabChange}>*/}
        {/*  <TabsItem value="general">Основное</TabsItem>*/}
        {/*  <TabsItem value="interests">Интересы</TabsItem>*/}
        {/*</Tabs>*/}
        {renderContent()}
      </ScrollView>
      <BottomBar>
        <Button stretched onClick={handleSave}>{t('save')}</Button>
      </BottomBar>
    </>
  );
}
