import { createSelector, createSlice } from '@reduxjs/toolkit';


import { RootState } from '$store/rootReducer';
import {
  EditGroupState,
  LoadAdminsAction,
  LoadAdminsDoneAction,
  PromoteAdminAction,
  RemoveAdminAction, SetAdminsAction
} from '$store/editGroup/interface';

const initialState: EditGroupState = {
  isAdminsLoading: true,
  isAdminsFailed: false,
  admins: [],
};

export const { actions, reducer } = createSlice({
  name: 'editGroup',
  initialState,
  reducers: {
    loadAdmins(state, __: LoadAdminsAction) {
      state.isAdminsLoading = true;
      state.isAdminsFailed = false;
    },
    loadAdminsFailed(state) {
      state.isAdminsLoading = false;
      state.isAdminsFailed = true;
    },
    loadAdminsDone(state, action: LoadAdminsDoneAction) {
      state.isAdminsLoading = false;
      state.admins = action.payload;
    },
    promoteAdmin(_, __: PromoteAdminAction) {},
    removeAdmin(_, __: RemoveAdminAction) {},
    setAdmins(state, action: SetAdminsAction) {
      state.admins = action.payload;
    },
  },
});

export { reducer as editGroupReducer, actions as editGroupActions };

export const editGroupSelector = (state: RootState) => state.editGroup;
