import './SendForm.sass';

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SendFormProps } from '$core/Chat/SendForm/SendForm.interface';
import { Input } from '$uikit';
import { IcGallery28, IcSend28 } from '$assets';
import { AttachPickerManagerRef } from '$shared/components/AttachPickerManager/AttachPickerManager.interface';
import AttachPickerManager from '$shared/components/AttachPickerManager/AttachPickerManager';
import { imActions } from '$store/im';
import { useChatInfo, useEvent, useTranslator, useUserInfo } from '$hooks';
import { RootState } from '$store/rootReducer';
import { blacklistHasBanFromMeSelector, blacklistHasBanToMeSelector } from '$store/blacklist';
import { genderText, scrollToBottom } from '$utils';
import { stopWatch, watchOpen } from '../../../services/keyboard';

export const SendForm: FC<SendFormProps> = ({ peerId }) => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const attachManager = useRef<AttachPickerManagerRef | null>(null);
  const info = useChatInfo(peerId);
  const sendBtn = useRef<HTMLDivElement | null>(null);
  const [photoInputKey, setPhotoInputKey] = useState(0);
  const peer = useUserInfo(peerId);
  const inputRef = useRef<any>(null);
  const [keyboardWatcher, setKeyboardWatcher] = useState(0);

  useEffect(() => {
    return () => {
      stopWatch(keyboardWatcher);
    }
  }, []);

  const isBannedFromMe = useSelector((root: RootState) => blacklistHasBanFromMeSelector(root, peerId));
  const isBannedToMe = useSelector((root: RootState) => blacklistHasBanToMeSelector(root, peerId));

  const handlePhotoChange = useCallback(({ target }) => {
    attachManager.current?.uploadImages(target.files);
    setPhotoInputKey(Date.now());
  }, [attachManager, setPhotoInputKey]);

  const handleTextChange = useCallback(({ target }) => {
    dispatch(imActions.chatSetFormText({ peerId, text: target.value }));
  }, [dispatch, peerId]);

  const handleSend = useCallback((e) => {
    try {
      e && e.preventDefault();
    } catch (err) {}
    dispatch(imActions.sendMessage(peerId));
    inputRef.current?.resetHeight();
  }, [dispatch, inputRef]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend(e);
      setTimeout(() => scrollToBottom('chat_history'), 10);
    }
  }, [handleSend]);

  const handleFocus = useCallback(() => {
    // @ts-ignore
    if (window.isNative) {
      setTimeout(() => scrollToBottom('chat_history'));

      setKeyboardWatcher(watchOpen(() => {
        scrollToBottom('chat_history');
      }));
    } else {
      setTimeout(() => scrollToBottom('chat_history'));
      setTimeout(() => scrollToBottom('chat_history'), 500);
    }
  }, [setKeyboardWatcher]);

  const handleBlur = useCallback(() => {
    stopWatch(keyboardWatcher);
  }, [setKeyboardWatcher]);

  // @ts-ignore
  if (window.isNative) {
    useEvent('touchstart', handleSend, sendBtn);
  } else {
    useEvent('touchstart', handleSend, sendBtn);
    useEvent('mousedown', handleSend, sendBtn);
  }

  function renderContent() {
    if (isBannedToMe) {
      return (
        <div className="SendForm__banned">
          {genderText(peer?.sex, [
            t('send_form_block_you_male', { name: peer?.firstName ?? '' }),
            t('send_form_block_you_female', { name: peer?.firstName ?? '' }),
          ])}
        </div>
      );
    }

    if (isBannedFromMe) {
      return (
        <div className="SendForm__banned">
          {t('send_from_blocked')}
        </div>
      );
    }

    return (
      <>
        <AttachPickerManager
          mini
          ref={attachManager}
          pickerId={`im_${peerId}`}
        />
        <div className="SendForm__cont">
          <div className="SendForm__button">
            <IcGallery28 />
            <input
              key={photoInputKey}
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              multiple
            />
          </div>
          <div className="SendForm__input_wrap">
            <Input
              multiline
              minHeight={40}
              padding={8}
              maxLines={4}
              placeholder={t('send_from_placeholder')}
              onChange={handleTextChange}
              value={info.text}
              ref={inputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="SendForm__button" ref={sendBtn}>
            <IcSend28 />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="SendForm__helper" />
      <div className="SendForm">
        <div className="SendForm__cont_wrap">
          {renderContent()}
        </div>
      </div>
    </>
  );
};
