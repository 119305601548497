import './ViewVideo.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ViewVideoProps } from '$core/ViewVideo/ViewVideo.interface';
import { Api } from '$api';
import { VideoModel } from '$store/models';
import { toastActions } from '$store/toast';
import { Loader } from '$shared/components';
import { Button, Header, Placeholder } from '$uikit';
import { mainActions } from '$store/main';

export const ViewVideo: FC<ViewVideoProps> = (props) => {
  const { object, videoId } = props;

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isFailed, setFailed] = useState(false);
  const [video, setVideo] = useState<VideoModel>();

  const handleLoad = useCallback(() => {
    setLoading(true);
    setFailed(false);

    Api.post('/video/get', {
      videoId,
      object,
    })
      .then((data) => {
        // @ts-ignore
        setVideo(data.video);
        setLoading(false);
      })
      .catch((e) => {
        setFailed(true);
        setLoading(false);
        dispatch(toastActions.setToastFail(e.message));
      });
  }, [dispatch, videoId, object]);

  useEffect(() => {
    handleLoad();
    dispatch(mainActions.setPlayingVideo('view_video_modal'));
  }, [object]);

  function renderContent() {
    if (isLoading) {
      return (
        <Loader isWrapped />
      );
    }

    if (isFailed) {
      return (
        <Placeholder
          title="Ошибка"
          actions={<Button onClick={handleLoad} size="small">Попробовать снова</Button>}
        >
          Произошла ошибка, попробуйте позже
        </Placeholder>
      )
    }

    return (
      <div className="ViewVideo">
        <video
          autoPlay
          src={video!.source[0].path}
          poster={video!.poster}
          controls
        />
      </div>
    )
  }

  return (
    <>
      <Header showCloseButton>Видео</Header>
      {renderContent()}
    </>
  );
};
