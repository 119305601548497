import { createSelector, createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';

import {
  DeletePostAction,
  LoadEditPost,
  LoadMoreCommentsAction,
  LoadPostAction,
  PostsState,
  PublishAction,
  PushToListAction,
  ShareAction,
  RestorePostAction,
  SavePostAction,
  SetCommentsNextFromAction,
  SetListAction,
  SetPostAction,
  SetPostLoading,
  SetPostsAction,
  ToggleLikeAction,
  UnshiftToListAction,
  UpdateFormTextAction,
  ReplyCommentAction,
  LoadRepliesAction,
  SetRepliesLoadedAction,
  ToggleRepliesAction,
  SetSenderAction,
  LoadScheduledAction,
  SetScheduledAction,
  PublishScheduledAction,
  ReadPostAction,
  ReadPostMultiAction, PinPostAction, UnpinPostAction
} from './interface';
import { RootState } from 'store/rootReducer';
import { PostModel } from '$store/models';

const initialState: PostsState = {
  forms: {},
  posts: {},
  lists: {},
  isPostLoading: {},
  isCommentsLoadMore: {},
  commentsNextFrom: {},
  replies: {},

  isScheduledLoading: true,
  isScheduledFailed: false,
  scheduled: [],
  readQueue: [],
};

function findPostsInAttachments(posts: PostModel[]) {
  const found: { [index: number]: PostModel } = {};

  for (const post of posts) {
    if (!post.attachments) {
      continue;
    }

    for (const item of post.attachments) {
      if (item.type === 'link' && item.link!.type === 'post') {
        found[item.link!.object.id] = item.link!.object;
      }
    }
  }

  return found;
}

export const { actions, reducer } = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    updateFormText(state, action: UpdateFormTextAction) {
      const { formId, text } = action.payload;

      state.forms[formId] = {
        ...(state.forms[formId] || {}),
        text,
      };
    },

    setSender(state, action: SetSenderAction) {
      const { formId, sender } = action.payload;

      state.forms[formId] = {
        ...(state.forms[formId] || {}),
        sender,
      };
    },

    publish(_, action: PublishAction) {},

    publishComment(_, action: PublishAction) {},

    setPosts(state, action: SetPostsAction) {
      const result = {
        ...findPostsInAttachments(action.payload),
      };
      for (let post of action.payload) {
        result[post.id] = post;
      }

      state.posts = {
        ...state.posts,
        ...result,
      };
    },

    setPost(state, action: SetPostAction) {
      const { id, updated } = action.payload;

      state.posts = {
        ...state.posts,
        [id]: {
          ...(state.posts[id] || {}),
          ...updated,
        },
        ...findPostsInAttachments([updated])
      };
    },

    setList(state, action: SetListAction) {
      state.lists[action.payload.listId] = action.payload.items;
    },

    unshiftToList(state, action: UnshiftToListAction) {
      state.lists[action.payload.listId] = _.uniq([
        ...action.payload.items,
        ...(state.lists[action.payload.listId] || []),
      ]);
    },

    pushToList(state, action: PushToListAction) {
      state.lists[action.payload.listId] = _.uniq([
        ...(state.lists[action.payload.listId] || []),
        ...action.payload.items,
      ]);
    },

    toggleLike(_, action: ToggleLikeAction) {},

    loadPost(state, action: LoadPostAction) {
      state.isPostLoading[action.payload] = true;
    },

    setPostLoading(state, action: SetPostLoading) {
      state.isPostLoading[action.payload.postId] = action.payload.isLoading;
    },

    deletePost(_, action: DeletePostAction) {},

    restorePost(_, action: RestorePostAction) {},

    loadEditPost(_, action: LoadEditPost) {},

    savePost(_, action: SavePostAction) {},

    loadMoreComments(state, action: LoadMoreCommentsAction) {
      state.isCommentsLoadMore[action.payload] = true;
    },

    setCommentsNextFrom(state, action: SetCommentsNextFromAction) {
      const { postId, nextFrom } = action.payload;

      state.commentsNextFrom[postId] = nextFrom;
      delete state.isCommentsLoadMore[postId];
    },

    share(_, __: ShareAction) {},

    replyComment(state, action: ReplyCommentAction) {
      const { parentId, comment } = action.payload;

      const key = `comment_${parentId}`;
      state.forms[key] = {
        ...(state.forms[key] || {}),
        replyTo: comment,
      };
    },

    loadReplies(state, action: LoadRepliesAction) {
      const { postId } = action.payload;

      state.replies[postId] = {
        ...(state.replies[postId] || {}),
        isLoading: true,
        isShown: true,
      };
    },

    setRepliesLoaded(state, action: SetRepliesLoadedAction) {
      const { postId, nextFrom } = action.payload;

      state.replies[postId] = {
        ...(state.replies[postId] || {}),
        isLoading: false,
        nextFrom,
      };
    },

    toggleReplies(state, action: ToggleRepliesAction) {
      const { postId, isShown } = action.payload;

      state.replies[postId] = {
        ...(state.replies[postId] || {}),
        isShown,
      };
    },

    loadScheduled(state, action: LoadScheduledAction) {
      state.isScheduledLoading = true;
      state.isScheduledFailed = false;
      state.scheduled = [];
    },

    scheduledFailed(state) {
      state.isScheduledLoading = false;
      state.isScheduledFailed = true;
    },

    setScheduled(state, action: SetScheduledAction) {
      state.isScheduledLoading = false;
      state.isScheduledFailed = false;
      state.scheduled = action.payload;
    },

    readPost(state, action: ReadPostAction) {
      state.readQueue.push(action.payload);
    },

    readPostMulti(state, action: ReadPostMultiAction) {
      state.readQueue.push(...action.payload);
    },

    sendSeenPosts() {},

    clearReadPostsList(state) {
      state.readQueue = [];
    },

    publishScheduled(_, __: PublishScheduledAction) {},

    pinPost(_, __: PinPostAction) {},

    unpinPost(_, __: UnpinPostAction) {},
  },
});

export { reducer as postsReducer, actions as postsActions };

export const postsSelector = (state: RootState) => state.posts;

export const createPostFormSelector = createSelector(
  postsSelector,
  (_, formId: string) => formId,
  (posts, formId) => {
    return posts.forms[formId] || { text: '' };
  },
);

export const postsListSelector = createSelector(
  postsSelector,
  (_, params: { listId: string }) => params,
  (questions, { listId }) => {
    return questions.lists[listId] || [];
  },
);

export const postSelector = createSelector(
  postsSelector,
  (_, postId: number ) => postId,
  (questions, postId) => {
    return questions.posts[postId];
  },
);

