import React from 'react';

export function rnbr(text) {
  if (!text) {
    return '';
  }

  return text.split(`\n`).map((item, i) => {
    return (
      <React.Fragment key={i}>
        {i > 0 && <br/>}
        {item}
      </React.Fragment>
    );
  });
}
