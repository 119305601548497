import './Im.sass';

import React, { useEffect, useMemo } from 'react';
import { Header, Placeholder, ScrollView } from '$uikit';
import { Loader, Metatags, NotifyRequest } from '$shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { imActions, imSelector } from '$store/im';
import { IcWrite28 } from '$assets';
import { DialogModel } from '$store/models';
import { Dialog } from '$core/Im/Dialog/Dialog';
import { useTranslator } from '$hooks';

export default function Im() {
  const { isDialogsLoading, dialogs, history } = useSelector(imSelector);
  const dispatch = useDispatch();
  const t = useTranslator();

  useEffect(() => {
    dispatch(imActions.loadDialogs());
  }, []);

  const dialogsPrepared = useMemo(() => {
    return Object.values(dialogs).sort((a: DialogModel, b: DialogModel) => {
      const aMsg = (history[a.peerId] || {})[a.lastMsgId];
      const bMsg = (history[b.peerId] || {})[b.lastMsgId];

      const aTs = aMsg?.createdAt ?? a.createdAt;
      const bTs = bMsg?.createdAt ?? b.createdAt;

      return aTs > bTs ? -1 : 1;
    });
  }, [dialogs, history]);

  function renderContent() {
    if (isDialogsLoading && !dialogsPrepared.length) {
      return (
        <Loader isWrapped />
      );
    }

    if (!dialogsPrepared.length) {
      return (
        <Placeholder>
          {t('im_empty')}
        </Placeholder>
      );
    }

    return (
      <div className="Im">
        <NotifyRequest />
        {dialogsPrepared.map((dialog) => <Dialog key={dialog.peerId} dialog={dialog} />)}
      </div>
    );
  }

  return (
    <>
      <Metatags title={t('im_title')} />
      <Header
        rightIcon={<IcWrite28 />}
        rightModal="write"
      >{t('im_title')}</Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
}
