import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { postsListSelector } from 'store/posts';

export const usePosts = (listId: string): number[] => {
  return useSelector((state: RootState) =>
    postsListSelector(state, { listId }),
  );
};
