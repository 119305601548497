import './AnimeEpisodes.sass';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { AnimeEpisodesProps } from '$shared/components/AnimeEpisodes/AnimeEposodes.interface';
import { ActionSheet, InlineHeader, Placeholder } from '$uikit';
import { EpisodeItem } from '$core/ViewAnime/EpisodeItem/EpisodeItem';
import { useAnimeFull } from '$hooks';
import { IcDropdown16 } from '$assets';
import { animeActions } from '$store/anime';
import { useDispatch } from 'react-redux';

export const AnimeEpisodes: FC<AnimeEpisodesProps> = ({ animeId, isReplaceLocation = false, selectedSeason = 0, selectedEpisode = 0 }) => {
  const anime = useAnimeFull(animeId);
  const dispatch = useDispatch();
  const [isSeasonSelectorShown, setSeasonSelectorShown] = useState(false);

  const handleSeasonsChange = useCallback((value: any) => {
    setSeasonSelectorShown(false);
    dispatch(animeActions.setSelectedSeason({ animeId, season: value }));
  }, [setSeasonSelectorShown]);

  const seasons = useMemo(() => {
    let result: any[] = [];
    for (let i = 0; i < (anime?.seasons ?? []).length; i++) {
      result.push({
        label: `Сезон ${i + 1}`,
        value: i
      });
    }
    return result;
  }, [anime]);

  const handleShowSeasonsSelector = useCallback(() => {
    if (seasons.length > 1) {
      setSeasonSelectorShown(true);
    }
  }, [setSeasonSelectorShown, seasons]);

  if (!anime) {
    return null;
  }

  const episodes = anime.seasons[anime.seasonSelected]?.episodes ?? [];
  if (!episodes.length) {
    return (
      <Placeholder
        title="Недоступно для просмотра"
      >
        Пока не добавлена ни одна серия этого аниме, возможно это изменится позже.
      </Placeholder>
    );
  }

  return (
    <>
      <InlineHeader>
        <div className="ViewAnime__season_select" onClick={handleShowSeasonsSelector}>
          Сезон {anime.seasonSelected + 1}
          {anime?.seasons.length > 1 && <IcDropdown16 />}
        </div>
      </InlineHeader>
      {episodes.map((item, i) => (
        <EpisodeItem
          key={item.number}
          episode={item}
          animeId={animeId}
          isSelected={selectedEpisode === i + 1 && selectedSeason === anime.seasonSelected + 1}
          isReplaceLocation={isReplaceLocation}
        />
      ))}
      <ActionSheet
        isOpened={isSeasonSelectorShown}
        selected={anime?.seasonSelected ?? 0}
        options={seasons}
        onChange={handleSeasonsChange}
      />
    </>
  );
};
