import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';
import {
  NotifyState, SetItemsAction,
} from './interface';

const initialState: NotifyState = {
  isLoading: true,
  items: [],
};

export const { actions, reducer } = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    load(state) {
      state.isLoading = true;
    },
    setItems(state, action: SetItemsAction) {
      state.isLoading = false;
      state.items = action.payload;
    }
  },
});

export { reducer as notifyReducer, actions as notifyActions };

export const notifySelector = (state: RootState) => state.notify;
