import { all, takeLatest, call, put, delay, select } from 'redux-saga/effects';
import { resolverActions } from '$store/resolver/index';
import { ResolveAction } from '$store/resolver/interface';
import { Api } from '$api';
import { handleProfileResult } from '$store/profile/sagas';
import { handleGroupInfo } from '$store/groups/sagas';

function* resolveWorker(action: ResolveAction) {
  const { mode, domain } = action.payload;

  try {
    const data = yield call(Api.post, '/resolve', {
      mode,
      domain,
    });

    if (data.type === 'user') {
      yield call(handleProfileResult, data.id, data.info);
    } else if (data.type === 'group') {
      yield call(handleGroupInfo, data.id, data.info);
    }

    yield delay(10);
    yield put(resolverActions.setResult({
      mode,
      domain,
      result: data,
    }));
  } catch (e) {
    if (e.code === 'not_found') {
      yield put(resolverActions.setNotFound({ mode, domain }));
    } else {
      yield put(resolverActions.setFailed({ mode, domain }));
    }
  }
}

export function* resolverSaga() {
  yield all([
    takeLatest(resolverActions.resolve, resolveWorker),
  ]);
}
