import './Loader.sass';

import React from 'react';
import { classNames } from '$utils';

export function Loader({ mode = 'default', isWrapped = false }) {

  const loader = (
    <div className={classNames({
      Loader: true,
      [mode]: true,
    })} />
  );

  if (isWrapped) {
    return (
      <div className="Loader__wrap">
        {loader}
      </div>
    )
  }

  return loader;
}
