import './AnimeCard.sass';

import React, { FC, useMemo } from 'react';

import { AnimeCardProps } from '$core/Anime/AnimeCard/interface';
import { ModalLink } from '$shared/components';

export const AnimeCard: FC<AnimeCardProps> = ({ item }) => {
  const { anime, info } = item;

  const route = useMemo(() => {
    if (info) {
      return `play${anime.id}_${info.translationId}_${info.season}_${info.episode}`;
    } else {
      return `anime${anime.id}`;
    }
  }, [anime.id, info]);

  return (
    <ModalLink name={route}>
      <div className="AnimeCard">
        <div
          className="AnimeCard__poster"
          style={{ backgroundImage: `url(${anime.photos[0]})` }}
        />
      </div>
    </ModalLink>
  );
};
