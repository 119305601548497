import './NotifyItem.sass';

import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router5';

import { NotifyItemProps } from '$shared/components/NotifyItem/NotifyItem.interface';
import { postsSelector } from '$store/posts';
import { mainSelector } from '$store/main';
import { classNames, genderText, gram } from '$utils';
import { useDate } from '$hooks';
import { ModalLink } from '$shared/components';
import { PROFILE } from '$shared/constants/pages';
import { PostModel } from '$store/models';
import { Button } from '$uikit';
import { IcAdd28, IcClose28 } from '$assets';
import { followersActions, followersSelector } from '$store/followers';

export const NotifyItem: FC<NotifyItemProps> = ({ notify }) => {
  const dispatch = useDispatch();
  const { posts } = useSelector(postsSelector);
  const { users } = useSelector(mainSelector);
  const { following } = useSelector(followersSelector);

  const date = useDate(notify.createdAt);

  const handleFollow = useCallback((isFollowed, userId) => () => {
    if (!isFollowed) {
      dispatch(followersActions.follow(userId));
    } else {
      dispatch(followersActions.unfollow(userId));
    }
  }, [dispatch]);

  const data = useMemo(() => {
    let matches;
    let objectType = '';
    let label = '';
    let names: string[] = [];
    let LinkComponent: any = Link;
    let linkParams: any = {};
    let avatars: string[] = [];
    let comment = '';
    let post: PostModel | null = null;
    let indicator: any = null;

    if ((matches = notify.group.match(/post([\-0-9]+)/))) {
      post = posts[matches[1]];
      objectType = 'post';

      linkParams.name = `post${matches[1]}`;
      LinkComponent = ModalLink;
    } else if (notify.type === 'follow') {
      linkParams.routeName = PROFILE;
      linkParams.routeParams = { id: notify.extra };
    }

    if (notify.extra && notify.extra.userIds && notify.type === 'like') {
      for (let uid of notify.extra.userIds) {
        const user = users[uid];
        if (user) {
          names.push(`${user.firstName} ${user.lastName}`);
          avatars.push(user.photo);
        }
      }

      if (names.length === 1) {
        label = names[0];
      } else if (names.length === 2) {
        label = names.join(' и ');
      } else if (names.length === 3) {
        label = names.slice(0, 2).join(', ') + ' и ' + names[2];
      } else {
        label = names.slice(0, 2).join(', ') + ' и еще ' + gram(notify.extra.count - 2, [
          'человек',
          'человека',
          'человек'
        ]);
      }
    }

    if (notify.type === 'like') {
      if (names.length === 3) {
        label += ' оценили';
      } else {
        label += ' ' + gram(notify.extra.count, [
          'оценил(а)',
          'оценили',
          'оценило'
        ], true);
      }
    } else if (notify.type === 'follow') {
      const user = users[notify.extra];
      label = `${user.firstName} ${user.lastName} ` + genderText(user.sex, [
        'подписался на вас',
        'подписалась на вас',
      ]);
      avatars.push(user.photo);

      const isFollowed = following[user.id];
      indicator = (
        <div className="NotifyItem__follow">
          <Button
            icon={isFollowed ? <IcClose28 /> : <IcAdd28 />}
            size="small"
            mode={isFollowed ? 'secondary' : 'default'}
            onClick={handleFollow(isFollowed, user.id)}
          />
        </div>
      );
    } else if (notify.type === 'comment') {
      const user = users[notify.extra.userId];
      label += `${user.firstName} ${user.lastName} ${post?.level === 0 ? 'комментирует' : genderText(user.sex, [
        'ответил',
        'ответила'
      ])}`;
      avatars.push(user.photo);
      comment = notify.extra.text;
    }

    if (objectType === 'post') {
      if (post?.level === 0) {
        label += ' вашу запись';
      } else {
        label += ' на ваш комментарий';
      }
    }

    return {
      label,
      linkParams,
      LinkComponent,
      avatars,
      comment,
      indicator,
    };
  }, [notify, posts, users, following]);

  const handleClick = useCallback((e) => {
    if (e.target.closest('.NotifyItem__follow')) {
      e.preventDefault();
      return false;
    }
  }, []);

  const LinkComponent = data.LinkComponent;

  return (
    <LinkComponent className="NotifyItem" {...data.linkParams} onClick={handleClick}>
      {data.avatars.length > 0 && (
        <div className={classNames({
          NotifyItem__avatars: true,
          multi: data.avatars.length > 1,
          single: data.avatars.length == 1,
        })}>
          {data.avatars.slice(0, 2).map((photo) => (
            <div
              className="NotifyItem__avatars__item"
              style={{ backgroundImage: `url(${photo})` }}
              key={photo}
            />
          ))}
        </div>
      )}
      <div className="NotifyItem__cont">
        <div className="NotifyItem__label">{data.label}</div>
        {data.comment && <div className="NotifyItem__comment">{data.comment}</div>}
        <div className="NotifyItem__date">{date}</div>
      </div>
      {data.indicator}
    </LinkComponent>
  );
};
