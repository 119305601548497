import { all, takeLatest, put, call, retry, delay, select, takeEvery } from 'redux-saga/effects';
import * as loadImage from 'blueimp-load-image';

import { groupInfoSelector, groupsActions } from '$store/groups/index';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { CreateGroupAction, LoadGroupInfoAction, SaveGroupAction, UploadPhotoAction } from '$store/groups/interface';
import router  from '$navigation/router';
import { mainActions } from '$store/main';
import { GROUP } from '$shared/constants/pages';
import { postsActions } from '$store/posts';
import { FollowAction, UnfollowAction } from '$store/groups/interface';
import { canvasToBlob, statReachGoal } from '$utils';
import { goBack } from '$navigation/helper';
import { batchActions } from '$store';
import { followersActions } from '$store/followers';
import { RootState } from '$store/rootReducer';
import { LoadMorePostsAction } from '$store/profile/interface';

function* createGroupWorker(action: CreateGroupAction) {
  try {
    const { name, description } = action.payload;

    if (name.length < 2) {
      yield put(toastActions.setToastFail('Название должно быть длинее 2 символов'))
      return;
    }

    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, '/groups/create', {
      name: name.trim(),
      description: description.trim(),
    });

    yield put(mainActions.setGroup(data.group));
    yield call(goBack);
    yield put(toastActions.setToastSuccess());
    yield delay(100);
    /* @ts-ignore */
    yield call(router.navigate, GROUP, {
      id: data.group.id
    });
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* saveGroupWorker(action: SaveGroupAction) {
  try {
    const { groupId, name, description, link, isAdultOnly } = action.payload;

    if (name.length < 2) {
      yield put(toastActions.setToastFail('Название должно быть длинее 2 символов'))
      return;
    }

    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, `/group/${groupId}/edit`, {
      name: name.trim(),
      description: description.trim(),
      link: link.trim(),
      adultOnly: isAdultOnly,
    });

    yield put(mainActions.setGroup(data.group));
    yield call(goBack);
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadGroupsWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.get, '/groups');
    yield put(mainActions.setGroups(data.groups));
    yield put(groupsActions.setGroups({
      groups: Object.keys(data.groups).map((id) => +id),
      managed: data.managed,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadGroupInfoWorker(action: LoadGroupInfoAction) {
  const groupId = action.payload;
  try {
    const data = yield call(Api.get, `/group/${groupId}`);
    yield call(handleGroupInfo, groupId, data);
  } catch (e) {
    yield put(groupsActions.setGroupFailed({
      groupId,
      reason: e.message,
    }))
  }
}

export function* handleGroupInfo(groupId: number, data: any) {
  yield put(
    batchActions(
      mainActions.setGroups(data.groups),
      mainActions.setUsers(data.users),
      postsActions.setPosts(data.posts),
      postsActions.setList({
        listId: `-${groupId}`,
        items: data.posts.map((post) => post.id),
      }),
      groupsActions.setGroupInfo({
        groupId,
        isFollowed: data.isFollowed,
        canPost: data.canPost,
        nextFrom: data.postsNextFrom,
        scheduledCount: data.scheduledCount,
      }),
    ),
  );
}

function* followWorker(action: FollowAction) {
  const groupId = action.payload;
  try {
    yield put(groupsActions.setFollowStatus({ groupId, isFollowed: true }));
    const resp = yield call(Api.post, `/follow/-${groupId}/add`);
    yield put(
      batchActions(
        mainActions.setGroup(resp.group),
        followersActions.setFollowing({
          [-groupId]: true,
        }),
      ),
    );
    yield call(statReachGoal, 'follow');
  } catch (e) {
    yield put(groupsActions.setFollowStatus({ groupId, isFollowed: false }));
  }
}

function* unfollowWorker(action: UnfollowAction) {
  const groupId = action.payload;
  try {
    yield put(groupsActions.setFollowStatus({ groupId, isFollowed: false }));
    const resp = yield call(Api.post, `/follow/-${groupId}/del`);
    yield put(
      batchActions(
        mainActions.setGroup(resp.group),
        followersActions.setFollowing({
          [-groupId]: false,
        }),
      ),
    );
  } catch (e) {
    yield put(groupsActions.setFollowStatus({ groupId, isFollowed: true }));
  }
}

function* uploadPhotoWorker(action: UploadPhotoAction) {
  try {
    const { groupId, file } = action.payload;

    if (file.type.substr(0, 6) !== 'image/' || file.type === 'image/gif') {
      return yield put(toastActions.setToastFail('Это не фото — нужно фото'));
    }

    yield put(toastActions.setToastLoading());

    const img = yield call(loadImage, file, {
      maxWidth: 1200,
      maxHeight: 1200,
      meta: true,
      canvas: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      orientation: true,
    });

    const quality = 0.8;
    const blob = yield call(canvasToBlob, img.image, quality);

    const form = new FormData();
    form.append('file', blob);
    const data = yield call(Api.post, `/group/${groupId}/photo`, form);
    yield put(mainActions.setGroup(data.group));
    yield put(toastActions.setToastSuccess());
    yield put(groupsActions.loadGroupInfo(groupId));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadMorePostsWorker(action: LoadMorePostsAction) {
  try {
    const groupId = action.payload;
    const { postsNextFrom } = yield select((state: RootState) => groupInfoSelector(state, groupId));
    const data = yield call(Api.post, `/profile/${-groupId}/posts`, {
      startFrom: +postsNextFrom,
    });

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        postsActions.setPosts(data.posts),
        postsActions.pushToList({
          listId: String(-groupId),
          items: data.posts.map((post) => post.id),
        }),
        groupsActions.setLoadMorePosts({
          groupId,
          nextFrom: data.nextFrom,
        }),
      ),
    );
  } catch (e) {}
}

export function* groupsSaga() {
  yield all([
    takeLatest(groupsActions.createGroup, createGroupWorker),
    takeLatest(groupsActions.loadGroups, loadGroupsWorker),
    takeEvery(groupsActions.loadGroupInfo, loadGroupInfoWorker),
    takeLatest(groupsActions.follow, followWorker),
    takeLatest(groupsActions.unfollow, unfollowWorker),
    takeLatest(groupsActions.saveGroup, saveGroupWorker),
    takeLatest(groupsActions.uploadPhoto, uploadPhotoWorker),
    takeLatest(groupsActions.loadMorePosts, loadMorePostsWorker),
  ]);
}
