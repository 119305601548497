import './Status.sass';

import React, { useCallback, useMemo, useState } from 'react';
import { Button, Checkbox, Form, FormItem, Header, Input, ScrollView } from '$uikit';
import { BottomBar } from '$uikit';
import { useCurrentUserInfo, useGroupInfo } from '$hooks';
import { gram } from '$utils';
import { useDispatch } from 'react-redux';
import { profileActions } from '$store/profile';

const MAX_LEN = 120;

export function Status({ groupId = 0 }) {
  const user = useCurrentUserInfo();
  const group = useGroupInfo(groupId);
  const [text, setText] = useState(groupId ? group?.status ?? '' : user.status);
  const [isPublish, setPublish] = useState(true);
  const dispatch = useDispatch();

  const availLen = useMemo(() => {
    return MAX_LEN - text.length;
  }, [text]);

  const handleChange = useCallback(({ target }) => {
    setText(target.value.slice(0, MAX_LEN));
  }, [setText]);

  const handleSave = useCallback(() => {
    dispatch(profileActions.setStatus({ text, isPublish, groupId }));
  }, [text, dispatch, isPublish, groupId]);

  const handlePublish = useCallback(() => {
    setPublish(!isPublish);
  }, [setPublish, isPublish]);

  return (
    <>
      <Header showCloseButton>Новый статус</Header>
      <ScrollView>
        <Form>
          <FormItem
            description={(
              <>
                {gram(availLen, ['остался', 'осталось', 'осталось'], true)}
                {' '}
                {gram(availLen, ['символ', 'символа', 'символов'])}
              </>
            )}
          >
            <Input
              placeholder="Введите статус.."
              value={text}
              onChange={handleChange}
              maxLength={MAX_LEN}
            />
          </FormItem>
          <FormItem>
            <Checkbox
              checked={isPublish}
              onClick={handlePublish}
            >
              Опубликовать запись
            </Checkbox>
          </FormItem>
        </Form>
      </ScrollView>
      <BottomBar>
        <Button stretched onClick={handleSave}>Сохранить</Button>
      </BottomBar>
    </>
  )
}
