import { AnimeModel, AttachLink, GroupModel, PostModel, UserModel } from '$store/models';
import { ModalLink } from '$shared/components';

export function parseLinkData(link: AttachLink) {
  let title = '';
  let description = '';
  let image = '';
  let type = 'Ссылка';
  let buttonText = 'Открыть';
  let linkComponent: any = 'a';
  let linksOptions: any = {
    href: '',
    target: '_blank',
  };

  switch (link.type) {
    case 'anime': {
      const anime = link.object as AnimeModel;
      title = anime.title;
      description = anime.description;
      image = anime.photos[0];
      type = 'Аниме';
      buttonText = 'Смотреть';
      linkComponent = ModalLink;
      linksOptions = {
        name: `anime${anime.id}`,
      };
      break;
    }

    case 'post': {
      const post = link.object as PostModel;
      title = '';
      description = post.text || '[Вложения]';
      type = 'Запись на стене';
      linkComponent = ModalLink;
      linksOptions = {
        name: `post${post.id}`,
      };

      if (post.ownerId > 0) {
        const user = link.extra as UserModel;
        title = `${user.firstName} ${user.lastName}`;
        image = user.photo;
      } else {
        const group = link.extra as GroupModel;
        title = `${group.name}`;
        image = group.photo;
      }
      break;
    }

    case 'external_video': {
      title = link.object.title;
      description = link.object.description;
      image = link.object.imageUrl;
      buttonText = 'Смотреть';
      linksOptions.href = link.object.url;

      if (link.object.platfrom === 'youtube') {
        type = 'Видео на YouTube';
      } else if (link.object.platfrom === 'tiktok') {
        type = 'Видео на TikTok';
      } else {
        type = 'Видео';
      }
    }
      break;
  }

  return {
    title,
    description,
    image,
    type,
    buttonText,
    linkComponent,
    linksOptions,
  }
}
