
export function showNotify(title, body, onClick, options: NotificationOptions = {}) {
  if (!window.Notification) {
    return;
  }

  if (Notification.permission !== 'granted') {
    return
  }
  const notification = new Notification(title, {
    ...options,
    body,
  });
  notification.onclick = () => {
    onClick();
    window.focus();
    notification.close();
  };
}
