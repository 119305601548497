import './Dialog.sass';

import React, { FC, useMemo } from 'react';
import * as fns from 'date-fns';
import { Link } from 'react-router5';
import { useSelector } from 'react-redux';

import { DialogProps } from '$core/Im/Dialog/Dialog.interface';
import { useCurrentUserInfo, useUserInfo } from '$hooks';
import { imSelector } from '$store/im';
import { CHAT } from '$shared/constants/pages';
import { Badge } from '$uikit';
import { Online, Verified } from '$shared/components';

export const Dialog: FC<DialogProps> = ({ dialog }) => {
  const { history } = useSelector(imSelector);
  const peer = useUserInfo(dialog.peerId);
  const message = history[dialog.peerId] ? history[dialog.peerId][dialog.lastMsgId] : null;

  const time = useMemo(() => {
    const now = new Date();
    const date = new Date((message?.createdAt ?? dialog.createdAt) * 1000);

    const seconds = fns.differenceInSeconds(now, date);
    const minutes = fns.differenceInMinutes(now, date);
    const hours = fns.differenceInHours(now, date);
    const days = fns.differenceInDays(now, date);

    if (seconds <= 60) {
      return `${seconds}с`;
    } else if (minutes <= 60) {
      return `${minutes}м`;
    } else if (hours <= 24) {
      return `${hours}ч`;
    } else {
      return `${days}д`;
    }
  }, [dialog.createdAt, message]);

  const text = useMemo(() => {
    let result = message?.text ?? '';

    if (message && message.attachments && message.attachments.length > 0) {
      if (message.attachments.length > 1) {
        result += ' Вложения';
      } else if (message.attachments[0].type === 'photo') {
        result += ' Фото';
      }
    }

    const prefix = !message?.isInbox ? 'Вы: ' : '';
    return prefix + result;
  }, [message?.text, message?.attachments]);

  return (
    <Link routeName={CHAT} routeParams={{ peerId: dialog.peerId }} className="Dialog">
      <div className="Dialog__photo" style={{ backgroundImage: `url(${peer.photo})` }}>
        <Online userId={peer.id} inPhoto />
      </div>
      <div className="Dialog__cont">
        <div className="Dialog__name_wrap">
          <div className="Dialog__name">{peer?.firstName ?? ''} {peer?.lastName ?? ''}</div>
          <Verified objectId={peer?.id} />
        </div>
        <div className="Dialog__message">
          <div className="Dialog__text">{text}</div>
          <div className="Dialog__date"> · {time}</div>
        </div>
      </div>
      {dialog.unread > 0 && (
        <div className="Dialog__unread">
          <Badge count={dialog.unread} />
        </div>
      )}
    </Link>
  );
};
