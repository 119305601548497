import './BottomBar.sass';

import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '$utils';

export function BottomBar({ children, fixed = false, direction = 'row' }) {
  return (
    <div className={classNames({
      BottomBar: true,
      fixed,
      [direction]: true,
    })}>
      <div className="BottomBar__in">
        <div className="BottomBar__content">
          {children}
        </div>
      </div>
    </div>
  )
}

BottomBar.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']),
};
