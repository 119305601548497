import './ShareSheet.sass';

import React, { FC, useCallback, useMemo } from 'react';
import copyToClipboard from 'copy-text-to-clipboard';
import { useDispatch } from 'react-redux';

import { ShareSheetProps } from './ShareSheet.interface';
import { CellButton, HorizontalScroll } from '$uikit';
import { toastActions } from '$store/toast';
import { postsActions } from '$store/posts';
import { showModal } from '$navigation/helper';
import { BottomSheet } from '$uikit/BottomSheet/BottomSheet';
import { mainActions } from '$store/main';
import { IcCopy28, IcMessage28, IcShare24, IcWrite28 } from '$assets';

export const ShareSheet: FC<ShareSheetProps> = (props) => {
  const { link, title } = props;
  const dispatch = useDispatch();

  const handleWall = useCallback(() => {
    dispatch(mainActions.setPopup(null));
    dispatch(postsActions.share(link!));
  }, [dispatch, link]);

  const handleMessage = useCallback(() => {
    dispatch(mainActions.setPopup(null));
    showModal('write', false, {
      share: `${link!.type}${link!.object.id}`,
    });
  }, [dispatch, link]);

  const url = useMemo(() => {
    return `https://anime.fans${location.pathname}?w=${link!.type}${link!.object.id}`;
  }, [link]);

  const urlEncoded = useMemo(() => {
    return encodeURIComponent(url);
  }, [url]);

  const handleOther = useCallback(() => {
    /* @ts-ignore */
    if (navigator.canShare) {
      navigator.share({
        url,
      });
    } else {
      copyToClipboard(url);
      dispatch(toastActions.setToastSuccess('Ссылка скопирована!'));
    }
  }, [dispatch, url]);

  function renderOtherButton() {
    /* @ts-ignore */
    if (navigator.canShare) {
      return <CellButton icon={<IcShare24 />} onClick={handleOther}>Другой способ</CellButton>;
    } else {
      return <CellButton icon={<IcCopy28 />} onClick={handleOther}>Копировать ссылку</CellButton>;
    }
  }

  return (
    <BottomSheet title={title}>
      <CellButton icon={<IcWrite28 />} onClick={handleWall}>Поделиться на стене</CellButton>
      <CellButton icon={<IcMessage28 />} onClick={handleMessage}>Отправить сообщением</CellButton>
      {renderOtherButton()}
      <HorizontalScroll>
        <div className="Invite__buttons ShareSheet__socials">
          <a href={`https://t.me/share/url?url=${urlEncoded}`} target="_blank" className="Invite__button telegram">
            Telegram
          </a>
          <a href={`https://vk.com/share.php?url=${urlEncoded}`} target="_blank" className="Invite__button vk">
            ВКонтакте
          </a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${urlEncoded}`} className="Invite__button fb" target="_blank">
            Facebook
          </a>
          <a href={`https://twitter.com/share?url=${urlEncoded}`} target="_blank" className="Invite__button twitter">
            Twitter
          </a>
        </div>
      </HorizontalScroll>
    </BottomSheet>
  );
};
