import './Scheduled.sass';

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScheduledProps } from './Scheduled.interface';
import { Button, Header, Placeholder, ScrollView } from '$uikit';
import { Loader, Post } from '$shared/components';
import { postsActions, postsSelector } from '$store/posts';

export const Scheduled: FC<ScheduledProps> = ({ ownerId }) => {
  const dispatch = useDispatch();
  const { isScheduledLoading, isScheduledFailed, scheduled } = useSelector(postsSelector);

  useEffect(() => {
    dispatch(postsActions.loadScheduled({ ownerId }));
  }, [ownerId]);

  const handleRetry = useCallback(() => {
    dispatch(postsActions.loadScheduled({ ownerId }));
  }, []);

  function renderContent() {
    if (isScheduledLoading) {
      return <Loader isWrapped />;
    }

    if (isScheduledFailed) {
      return (
        <Placeholder
          actions={<Button size="small" onClick={handleRetry}>Попробовать еще раз</Button>}
        >
          Произошла ошибка, попробуйте позже
        </Placeholder>
      );
    }

    if (!scheduled.length) {
      return (
        <Placeholder>
          Нет запланированных записей
        </Placeholder>
      );
    }

    return scheduled.map((postId) => <Post key={postId} postId={postId} />);
  }

  return (
    <>
      <Header
        showCloseButton
      >
        Запланированно
      </Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
