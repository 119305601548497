import './GroupAdmins.sass';

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GroupAdminsProps } from './GroupAdmins.interface';
import { Button, Header, Placeholder, ScrollView } from '$uikit';
import { editGroupActions, editGroupSelector } from '$store/editGroup';
import { Loader } from '$shared/components';
import { IcAdd28 } from '$assets';
import { showModal } from '$navigation/helper';
import { GroupAdminsItem } from '$core/GroupAdmins/GroupAdminsItem/GroupAdminsItem';

export const GroupAdmins: FC<GroupAdminsProps> = ({ groupId }) => {
  const dispatch = useDispatch();
  const { isAdminsLoading, isAdminsFailed, admins } = useSelector(editGroupSelector);

  useEffect(() => {
    dispatch(editGroupActions.loadAdmins(groupId));
  }, []);

  const handleRetry = useCallback(() => {
    dispatch(editGroupActions.loadAdmins(groupId));
  }, [dispatch, groupId]);

  const handleAdd = useCallback(() => {
    showModal(`followers-${groupId}`, false, {
      promote: 1,
    });
  }, [dispatch, groupId]);

  function renderContent() {
    if (isAdminsLoading) {
      return <Loader isWrapped />;
    }

    if (isAdminsFailed) {
      return (
        <Placeholder
          actions={<Button size="small" onClick={handleRetry}>Попробовать еще раз</Button>}
        >
          Произошла ошибка, попробуйте позже
        </Placeholder>
      );
    }

    if (!admins.length) {
      return (
        <Placeholder
          actions={<Button size="small" onClick={handleAdd}>Добавить</Button>}
        >
          Администраторов нет
        </Placeholder>
      );
    }

    return admins.map((item) => <GroupAdminsItem key={item.userId} userId={item.userId} groupId={groupId} />);
  }

  return (
    <>
      <Header
        showCloseButton
        rightIcon={<IcAdd28 />}
        rightOnClick={handleAdd}
      >
        Администраторы
      </Header>
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  );
};
