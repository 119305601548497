import './Verified.sass';

import React, { useCallback } from 'react';
import { useGroupInfo, useUserInfo } from '$hooks';
import { IcVerified16 } from '$assets';
import { classNames } from '$utils';
import { showModal } from '$navigation/helper';

export function Verified({ objectId, large = false }) {
  const user = useUserInfo(objectId);
  const group = useGroupInfo(-objectId);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    showModal('post2945');
  }, []);

  if (objectId > 0 && !user?.verified) {
    return null;
  } else if (objectId < 0 && !group?.verified) {
    return null;
  }

  return (
    <div
      onClick={handleClick}
      className={classNames({
        Verified: true,
        large,
      })}
    >
      <IcVerified16 />
    </div>
  )
}
