import { all, takeLatest, put, retry, takeEvery, select, call } from 'redux-saga/effects';
import { imActions, imChatInfoSelector, imHistorySelector, imSelector } from '$store/im/index';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { mainActions } from '$store/main';
import {
  DeleteDialogAction, DeleteMessageAction,
  LoadChatAction,
  ReadHistoryAction,
  RetrySendAction,
  SendMessageAction
} from '$store/im/interface';
import { RootState } from '$store/rootReducer';
import { attachActions, attachItemsSelector } from '$store/attach';
import { MessageModel } from '$store/models';
import { authSelector } from '$store/auth';
import { scrollToBottom, statReachGoal } from '$utils';
import { batchActions } from '$store';
import { blacklistActions } from '$store/blacklist';
import { goBack } from '$navigation/helper';
import router from '$navigation/router';

let MsgId = 0;

function* loadDialogsWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.get, '/im');

    const messages: { [index: number]: MessageModel } = {};
    for (const message of data.messages) {
      messages[message.id] = message;
    }

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        imActions.setDialogs({ dialogs: data.dialogs, messages }),
        mainActions.setNewDialogs(data.newDialogs),
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* sendMessageWorker(action: SendMessageAction) {
  const messageId = --MsgId;
  const peerId = action.payload;

  try {
    const { currentUserId } = yield select(authSelector);
    const info = yield select((state: RootState) => imChatInfoSelector(state, peerId));
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `im_${peerId}`));
    const text = info.text || '';

    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    if (text.length === 0 && !attachments.length) {
      return yield put(toastActions.setToastFail('Напишите текст или прикрепите вложение'));
    }

    const message: MessageModel = {
      id: messageId,
      fromId: currentUserId,
      toId: peerId,
      text,
      attachments,
      createdAt: Math.floor(Date.now() / 1000),
      isSending: true,
      isInbox: false,
      isUnread: true,
    };

    yield put(
      batchActions(
        imActions.chatSetFormText({
          peerId,
          text: '',
        }),
        attachActions.clear(`im_${peerId}`),
        imActions.setMessage({
          peerId,
          messageId: message.id,
          message,
        }),
      ),
    );

    yield call(scrollToBottom, 'chat_history');

    const data = yield call(Api.post, `/im/${peerId}/send`, {
      text,
      attachments,
      randomId: message.id,
    });

    yield put(imActions.setMessage({
      peerId,
      messageId: message.id,
      message: data.message,
    }));

    yield call(statReachGoal, 'message');
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));

    const history = yield select(imHistorySelector, peerId);
    const message = { ...history[messageId], isFailed: true, isSending: false };
    yield put(imActions.setMessage({
      peerId,
      messageId,
      message,
    }));
  }
}

function* retrySendWorker(action: RetrySendAction) {
  const { peerId, message } = action.payload;

  try {
    yield put(imActions.setMessage({
      peerId,
      messageId: message.id,
      message: {
        ...message,
        isFailed: false,
        isSending: true,
      },
    }));

    const data = yield call(Api.post, `/im/${peerId}/send`, {
      text: message.text,
      attachments: message.attachments,
      randomId: message.id,
    });

    yield put(imActions.setMessage({
      peerId,
      messageId: message.id,
      message: data.message,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
    yield put(imActions.setMessage({
      peerId,
      messageId: message.id,
      message,
    }));
  }
}

function* loadChatWorker(action: LoadChatAction) {
  const peerId = action.payload;

  try {
    const data = yield call(Api.get, `/im/${peerId}/history`);

    const history: { [index: number]: MessageModel } = {};
    for (const message of data.history) {
      history[message.id] = message;
    }

    yield put(
      batchActions(
        mainActions.setUser(data.user),
        imActions.setHistory({ peerId, history }),
        blacklistActions.setBanFromMe({
          userId: peerId,
          isBanned: data.banFromMe,
        }),
        blacklistActions.setBanToMe({
          userId: peerId,
          isBanned: data.banToMe,
        }),
      ),
    );

    yield call(scrollToBottom, 'chat_history');
    yield put(imActions.readHistory(peerId));
  } catch (e) {
    yield put(imActions.setChatFailed({ peerId, reason: e.message }));
  }
}

function* readHistoryWorker(action: ReadHistoryAction) {
  try {
    const peerId = action.payload;
    yield call(Api.post, `/im/${peerId}/read`);

    const { dialogs } = yield select(imSelector);
    if (dialogs[peerId]) {
      yield put(imActions.setDialogs({
        dialogs: [{ ...dialogs[peerId], unread: 0 }],
        messages: {},
      }));
    }
  } catch (e) {}
}

function* loadWriteWorker() {
  try {
    const query = router.getState().params?.query ?? '';
    const data = yield retry(Infinity, 3000, Api.post, '/im/write', {
      query,
    });
    yield put(batchActions(
      mainActions.setUsers(data.users),
      imActions.setWriteIds(data.items),
    ));
  } catch (e) {}
}

function* deleteDialogWorker(action: DeleteDialogAction) {
  try {
    const userId = action.payload;
    const isConfirmed = yield call(confirm, 'Вы действительно хотите удалить диалог? Отменить действие будет невозможно!');
    if (!isConfirmed) {
      return;
    }

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/im/${userId}/delete`);
    yield put(imActions.removeDialog(userId));
    yield put(toastActions.setToastSuccess());
    yield call(goBack);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* deleteMessageWorker(action: DeleteMessageAction) {
  try {
    const { id, peerId } = action.payload;
    const isConfirmed = yield call(confirm, 'Вы действительно хотите удалить сообщение? Отменить действие будет невозможно!');
    if (!isConfirmed) {
      return;
    }

    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/im/message/${id}/delete`);
    yield put(imActions.removeMessage({ id, peerId }));
    yield put(toastActions.hideToast());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* imSaga() {
  yield all([
    takeLatest(imActions.loadDialogs, loadDialogsWorker),
    takeEvery(imActions.sendMessage, sendMessageWorker),
    takeLatest(imActions.retrySend, retrySendWorker),
    takeLatest(imActions.loadChat, loadChatWorker),
    takeLatest(imActions.readHistory, readHistoryWorker),
    takeLatest(imActions.loadWrite, loadWriteWorker),
    takeLatest(imActions.deleteDialog, deleteDialogWorker),
    takeLatest(imActions.deleteMessage, deleteMessageWorker),
  ]);
}
