import './Other.sass';

import React, { useCallback } from 'react';
import { Link } from 'react-router5';

import { Header, CellButton, ScrollView } from 'uikit';
import { BIRTHDAYS, FIND_FRIENDS, GROUPS, PROFILE, RESOLVE, SETTINGS } from 'shared/constants/pages';
import { useCurrentUserInfo } from 'hooks/useUserInfo';
import AuthRequired from '$core/AuthRequired/AuthRequired';
import { useSelector } from 'react-redux';
import { authSelector } from '$store/auth';
import {
  IcChevron16,
  IcEdit28, IcGift28, IcGoogle24,
  IcSearch28,
  IcSettings28,
  IcUsers28
} from '$assets';
import { Metatags } from '$shared/components';
import { useTranslator } from '$hooks';
import { isAndroid, isNative, isPWA } from '$utils';
import { requestAddToHomeScreen } from '$services';

export function Other() {
  const user = useCurrentUserInfo();
  const { isLogged } = useSelector(authSelector);
  const t = useTranslator();

  const handleHomeScreen = useCallback(() => {
    requestAddToHomeScreen();
  }, []);

  if (!isLogged) {
    return <AuthRequired />;
  }

  return (
    <>
      <Metatags title={t('other_title')} />
      <Header>{t('other_title')}</Header>
      <ScrollView>
        <Link routeName={user.domain ? RESOLVE : PROFILE} routeParams={user.domain ? { domain: user.domain } : { id: user!.id }} className="Other__profile">
          <div
            className="Other__profile__photo"
            style={{ backgroundImage: `url(${user!.photo})` }}
          />
          <div className="Other__profile__cont">
            <div className="Other__profile__name">{user!.firstName} {user!.lastName}</div>
            <div className="Other__profile__caption">
              {t('other_go_to_profile')}
              <IcChevron16 />
            </div>
          </div>
        </Link>
        <div className="Other__actions">
          <CellButton icon={<IcEdit28 />} modal="edit">{t('other_edit')}</CellButton>
          <CellButton icon={<IcUsers28 />} route={GROUPS}>{t('other_groups')}</CellButton>
          <CellButton icon={<IcGift28 />} route={BIRTHDAYS}>Дни рождения</CellButton>
          <CellButton icon={<IcSearch28 />} route={FIND_FRIENDS}>{t('other_find_friends')}</CellButton>
          <CellButton icon={<IcSettings28 />} route={SETTINGS}>{t('other_settings')}</CellButton>
          {!isNative() && !isPWA() && isAndroid() && (
            <CellButton icon={<IcGoogle24 width={28} height={28} />} onClick={handleHomeScreen}>Добавить на рабочий стол</CellButton>
          )}
        </div>
      </ScrollView>
    </>
  );
}
