import router from '$navigation/router';
import { isNative } from '$utils';
import { sendNativeEvent } from '$services';

export function showModal(name, isReplace = false, params = {}) {
  const route = router.getState();

  router.navigate(route.name, {
    ...route.params,
    ...params,
    w: name,
  }, {
    replace: isReplace,
  });
}

export function hideModal() {
  const route = router.getState();
  if (route.params.w) {
    const params = { ...route.params };
    delete params.w;
    router.navigate(route.name, params);
  }
}

export const goBack = () => {
  if (isNative(3)) {
    sendNativeEvent('go_back');
  } else {
    window.history.back();
  }
};
