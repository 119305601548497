import './Domain.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { BottomBar, Button, Form, FormItem, Header, Input, ScrollView } from '$uikit';
import { Link } from 'react-router5';
import { RESOLVE } from '$shared/constants/pages';
import { useCurrentUserInfo, useTranslator } from '$hooks';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '$store/profile';
import { authSelector } from '$store/auth';
import AuthRequired from '$core/AuthRequired/AuthRequired';

export const Domain: FC = () => {
  const { isLogged } = useSelector(authSelector);
  const user = useCurrentUserInfo();
  const dispatch = useDispatch();
  const t = useTranslator();
  const [domain, setDomain] = useState(user.domain || '');
  const [error, setError] = useState('');

  const handleChange = useCallback(({ target }) => {
    setDomain(target.value.trim());
  }, []);

  useEffect(() => {
    let timer: any = 0;
    if (domain) {
      if (domain === user.domain) {
        setError('');
        return;
      }
      timer = setTimeout(() => {
        dispatch(profileActions.checkDomain({
          domain,
          onDone: () => setError('ok'),
          onFail: (msg) => setError(msg),
        }));
      }, 1000);
    } else {
      setError(user.domain ? 'ok' : '');
    }
    return () => clearTimeout(timer);
  }, [domain]);

  const handleSave = useCallback(() => {
    dispatch(profileActions.changeDomain(domain));
  }, [dispatch, domain]);

  function renderError() {
    if (error === 'ok') {
      if (domain.length === 0) {
        return null;
      }
      return t('domain_available');
    } else if (error) {
      return <div className="Domain__error">{error}</div>;
    } else {
      return null;
    }
  }

  if (!isLogged) {
    return <AuthRequired />;
  }

  return (
    <>
      <Header showCloseButton>{t('domain_title')}</Header>
      <ScrollView>
        <div className="Domain__message">{t('domain_caption')}{` `}<Link routeName={RESOLVE} routeParams={{ domain: 'goglev' }}>anime.fans/goglev</Link>.</div>
        <div>
          <ul>
            <li>{t('domain_req1')};</li>
            <li>{t('domain_req2')};</li>
            <li>{t('domain_req3')};</li>
            <li>{t('domain_req4')};</li>
          </ul>
        </div>
        <Form>
          <FormItem title="Адрес" description={renderError()}>
            <div className="Domain__input">
              <div className="Domain__host">anime.fans/</div>
              <Input
                placeholder={t('domain_enter_username')}
                value={domain}
                onChange={handleChange}
                maxLength={15}
              />
            </div>
          </FormItem>
        </Form>
      </ScrollView>
      <BottomBar fixed direction="column">
        <Button onClick={handleSave} disabled={error !== 'ok'}>{t('save')}</Button>
      </BottomBar>
    </>
  );
};
