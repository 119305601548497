export const MAIN = 'main';
export const REGISTRATION = 'registration';
export const FEED = 'feed';
export const PROFILE = 'profile';
export const SEARCH = 'search';
export const NOTIFY = 'notify';
export const OTHER = 'other';
export const VIDEOS = 'VIDEOS';
export const INVITE = 'INVITE';
export const GROUP = 'GROUP';
export const GROUPS = 'GROUPS';
export const IM = 'IM';
export const CHAT = 'CHAT';
export const AUTH_REQUIRED = 'auth_required';
export const FIND_FRIENDS = 'FIND_FRIENDS';
export const VIEW_ANIME = 'VIEW_ANIME';
export const RESOLVE = 'RESOLVE';
export const VK_AUTH = 'VK_AUTH';
export const SETTINGS = 'SETTINGS';
export const ANIME_LISTS = 'ANIME_LISTS';
export const ANIME_LIST = 'ANIME_LIST';
export const PROMO = 'PROMO';
export const RATING = 'RATING';
export const BIRTHDAYS = 'BIRTHDAYS';
export const LIKES = 'LIKES';
export const POST_VIEW = 'POST_VIEW';

export const TESTS_VIEW = 'TESTS_VIEW';

export const TESTS_RESULT = 'TESTS_RESULT';

export const ALL = 'ALL';

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
