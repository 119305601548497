import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';
import { InviteState, SetSuggestionsAction } from '$store/invite/interface';

const initialState: InviteState = {
  isSuggestionsLoading: true,
  isSuggestionsLoadingMore: false,
  suggestionsNextFrom: '',
  suggestions: [],
};

export const { actions, reducer } = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    loadSuggestions(state) {
      state.isSuggestionsLoading = true;
      state.isSuggestionsLoadingMore = true;
    },
    setSuggestions(state, action: SetSuggestionsAction) {
      const { suggestions, nextFrom } = action.payload;

      state.suggestions = suggestions;
      state.isSuggestionsLoading = false;
      state.isSuggestionsLoadingMore = false;
      state.suggestionsNextFrom = nextFrom;
    },
    loadMoreSuggestions(state) {
      state.isSuggestionsLoadingMore = true;
    },
    setLoadedMoreSuggestions(state, action: SetSuggestionsAction) {
      const { suggestions, nextFrom } = action.payload;

      const suggestionsExist: { [index: number]: boolean } = {};
      for (let item of state.suggestions) {
        suggestionsExist[item.userId] = true;
      }

      const newSuggestions = [...state.suggestions];
      for (let item of suggestions) {
        if (!suggestionsExist[item.userId]) {
          newSuggestions.push(item);
        }
      }

      state.suggestions = newSuggestions;
      state.isSuggestionsLoadingMore = false;
      state.suggestionsNextFrom = nextFrom;
    },
  },
});

export { reducer as inviteReducer, actions as inviteActions };

export const inviteSelector = (state: RootState) => state.invite;
