import { useSelector } from 'react-redux';

import { PostModel } from 'store/models';
import { RootState } from 'store/rootReducer';
import { postSelector } from 'store/posts';

export const usePost = (postId: number): PostModel => {
  return useSelector((state: RootState) =>
    postSelector(state, postId),
  );
};
