import './NotFound.sass';

import React from 'react';

export function NotFound() {
  return (
    <div className="NotFound">
      <div className="NotFound__title">404 Not Found</div>
      <div className="NotFound__caption">Страница еще не создана или временно недоступна</div>
    </div>
  );
}
