import './TestsResult.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api } from '$api';
import { toastActions } from '$store/toast';
import { Button, Placeholder, ScrollView } from '$uikit';
import { Loader } from '$shared/components';
import { classNames } from '$utils';
import { TESTS_VIEW } from '$shared/constants/pages';
import router from '$navigation/router';
import copyToClipboard from 'copy-text-to-clipboard';
import { IcChevron16 } from '$assets';

export const TestsResult: FC<{ id: number }> = (props) => {
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [info, setInfo] = useState<{
    score: number;
    test_id: number;
    name: string;
    description: string;
  } | null>(null);

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get<any, any>(`/tests/${props.id}/result`)
      .then((data) => {
        setInfo(data);
        setLoadingStatus('');
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
        setLoadingStatus('failed');
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handleCopy = useCallback(() => {
    copyToClipboard(location.href);
    dispatch(toastActions.setToastSuccess('Ссылка скопирована, отправьте ее друзьям!'))
  }, []);

  function renderContent() {
    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          title="Произошла ошибка"
          actions={<Button size="small" onClick={loadData}>Попробовать снова</Button>}
          centered
        >
          Попробуйте еще раз позже
        </Placeholder>
      );
    }

    if (loadingStatus === 'loading' || !info) {
      return <Loader isWrapped/>;
    }

    let style = 'low';
    let label = 'Низкий уровень';
    if (info.score >= 50) {
      style = 'medium';
      label = 'Средний уровень';
    } else if (info.score >= 70) {
      style = 'high';
      label = 'Высокий уровень';
    }

    return (
      <div className="Tests__intro">
        <div className="TestsResult__title">Результат #{props.id}</div>
        <div className="Tests__intro__cont">
          <div className="TestsResult__score__label">{label}</div>
          <div className={classNames({
            TestsResult__score: true,
            [style]: true,
          })}>
            {parseInt(info.score + '', 10)}%
          </div>
          <Button onClick={handleCopy}>Поделиться</Button>
          <div className="TestsResult__separator"></div>
          <div className="TestsResult__score__label">Тест</div>
          <div className="TestsResult__test_name">{info.name}</div>
          <Button onClick={() => router.navigate(TESTS_VIEW, { testId: info.test_id })}>Открыть тест</Button>
        </div>
      </div>
    );
  }

  return (
    <ScrollView>
      <a href="https://anime.fans/promo" className="Tests_copyright" target="_blank">
        <div className="Tests_copyright__photo" />
        <div className="Tests_copyright__info"><b>Anime.Fans</b> - социальная сеть для любителей аниме</div>
        <div className="Tests_copyright__chevron">
          <IcChevron16 />
        </div>
      </a>
      {renderContent()}
    </ScrollView>
  );
};
