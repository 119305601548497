import 'react-virtualized/styles.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRoute } from 'react-router5';

import { useDispatch, useSelector } from 'react-redux';
import { authActions, authSelector } from '$store/auth';
import { Tabbar, TabbarItem } from '$uikit';
import router  from 'navigation/router';
import { Loader, Navbar, Toast } from 'shared/components';
import {
  FEED,
  IM,
  INVITE,
  MAIN,
  OTHER,
  PROFILE,
  GROUP,
  REGISTRATION,
  SEARCH,
  VIDEOS,
  GROUPS,
  CHAT,
  NOTIFY,
  AUTH_REQUIRED,
  FIND_FRIENDS,
  VIEW_ANIME,
  RESOLVE,
  VK_AUTH,
  SETTINGS,
  ANIME_LISTS,
  ANIME_LIST,
  PROMO,
  RATING,
  BIRTHDAYS,
  POST_VIEW,
  TESTS_VIEW,
  TESTS_RESULT,
  ALL,
  PAYMENT_SUCCESS,
} from '$shared/constants/pages';
import { IcHome28, IcDiscover28, IcMenu28, IcMessage28, IcNotifications28 } from '$assets';
import {
  Profile,
  Feed,
  Registration,
  Main,
  Other,
  Anime,
  NewPost,
  NotFound,
  ViewComment,
  ViewPost,
  EditAnime,
  ViewAnime,
  PlayAnime,
  EditPost,
  Edit,
  Status,
  Search,
  Followers,
  Invite,
  Group,
  Groups,
  NewGroup,
  EditGroup,
  Chat,
  Write,
  Notify,
  FindFriends,
  PhotoView,
  GroupAdmins,
  Scheduled,
  Resolver,
  VkAuth,
  Settings,
  Domain,
  AnimeLists,
  CreateAnimeList,
  AnimeList,
  Promo,
  Rating,
  ViewVideo,
  Birthdays,
  Likes,
  TestsView,
  TestsResult,
  All,
  PaymentSuccess,
} from '$core';
import { useCurrentUserInfo, useDesktop, useTranslator } from '$hooks';
import Im from '$core/Im/Im';
import { mainSelector } from '$store/main';
import AuthRequired from '$core/AuthRequired/AuthRequired';
import { initPush } from '../services/push';
import { EditAnimeTranslation } from '$core/EditAnimeTranslation/EditAnimeTranslation';
import { hideModal } from '$navigation/helper';
import { classNames, isNative } from '$utils';

function getTabs(t: any) {
  return [
    {
      icon: <IcHome28 />,
      name: FEED,
      label: t('navbar_news'),
    },
    {
      icon: <IcDiscover28 />,
      name: VIDEOS,
      label: t('navbar_anime'),
    },
    {
      icon: <IcMessage28 />,
      name: IM,
      label: t('navbar_im'),
    },
    {
      icon: <IcNotifications28 />,
      name: NOTIFY,
      label: t('navbar_notify'),
    },
    {
      icon: <IcMenu28 />,
      name: OTHER,
      label: t('tabbar_profile'),
    }
  ];
}

function App() {
  const { route } = useRoute();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(OTHER);
  const isDesktop = useDesktop();
  const user = useCurrentUserInfo();
  const t = useTranslator();

  const { isLoading, isLogged } = useSelector(authSelector);
  const { newDialogs, hasNotify, popup } = useSelector(mainSelector);

  useEffect(() => {
    dispatch(authActions.init());
  }, []);

  useEffect(() => {
    if (isLogged) {
      initPush();
    }
  }, [isLogged]);

  useEffect(() => {
    let names = getTabs(t).map((tab) => tab.name);
    if (names.indexOf(route.name) > -1) {
      setActiveTab(route.name);
    }
  }, [route.name, t]);

  const handleTab = useCallback((name) => () => {
    if (name === route.name) {
      const scrollNode = document.querySelector('.ScrollView');
      if (scrollNode) {
        scrollNode.scrollTop = 0;
      }
    } else {
      router.navigate(name, {}, { replace: true });
    }
  }, [activeTab, route]);

  const modal = useMemo(() => {
    const name = route.params?.w ?? '';

    let matches: any = null;
    if (name === 'post') {
      return <NewPost ownerId={user.id} />;
    } else if (name === 'status') {
      return <Status />;
    } else if (name === 'edit') {
      return <Edit/>;
    } else if (name === 'group') {
      return <NewGroup/>;
    } else if (name === 'write') {
      return <Write/>;
    } else if (name === 'domain') {
      return <Domain/>;
    } else if (name === 'new_list') {
      return <CreateAnimeList />;
    } else if ((matches = name.match(/^edit_list(\d+)$/))) {
      return <CreateAnimeList listId={+matches[1]} />;
    } else if ((matches = name.match(/^status(\d+)$/))) {
      return <Status groupId={+matches[1]} />;
    } else if ((matches = name.match(/^new_post([0-9\-]+)$/))) {
      return <NewPost ownerId={+matches[1]} />;
    } else if ((matches = name.match(/^post(\d+)$/))) {
      return <ViewPost postId={+matches[1]} />;
    } else if ((matches = name.match(/^edit_post(\d+)$/))) {
      return <EditPost postId={+matches[1]} />;
    } else if ((matches = name.match(/^comment(\d+)$/))) {
      return <ViewComment commentId={+matches[1]} />;
    } else if ((matches = name.match(/^anime(\d+)$/))) {
      return <ViewAnime animeId={+matches[1]} />;
    } else if ((matches = name.match(/^play(\d+)_(\d+)_(\d+)_(\d+)$/))) {
      return (
        <PlayAnime
          animeId={+matches[1]}
          translationId={+matches[2]}
          season={+matches[3]}
          number={+matches[4]}
        />
      );
    } else if ((matches = name.match(/^add_anime$/))) {
      return <EditAnime animeId={0} isEdit={false} />;
    } else if ((matches = name.match(/^edit_anime(\d+)$/))) {
      return <EditAnime animeId={+matches[1]}/>;
    } else if ((matches = name.match(/^followers([0-9\-]+)$/))) {
      return <Followers userId={+matches[1]} />;
    } else if ((matches = name.match(/^followed(\d+)$/))) {
      return <Followers userId={+matches[1]} isOutbox={true} />;
    } else if ((matches = name.match(/^edit(\d+)$/))) {
      return <EditGroup groupId={+matches[1]} />;
    } else if ((matches = name.match(/^edit_anime_translation(\d+)$/))) {
      return <EditAnimeTranslation translationId={+matches[1]} isEdit />;
    } else if ((matches = name.match(/^photos\+([a-z0-9]+)(\+(\d+))?$/))) {
      return <PhotoView object={matches[1]} selectedId={+matches[3]} />;
    } else if ((matches = name.match(/^group_admins(\d+)$/))) {
      return <GroupAdmins groupId={+matches[1]} />;
    } else if ((matches = name.match(/^scheduled([0-9\-]+)$/))) {
      return <Scheduled ownerId={+matches[1]} />;
    } else if ((matches = name.match(/^video\+([a-z0-9]+)(\+(\d+))?$/))) {
      return <ViewVideo object={matches[1]} videoId={+matches[3]} />;
    } else if ((matches = name.match(/^likes_([a-z]+)(\d+)$/))) {
      return <Likes objectType={matches[1]} objectId={+matches[2]} />;
    }

    return null;
  }, [route.params?.w, user.id]);

  const filteredTabs = useMemo(() => {
    if (!isNative(2) || true) {
      return getTabs(t);
    }

    return getTabs(t).filter((item) => {
      return item.name !== VIDEOS;
    });
  }, [t]);

  useEffect(() => {
    document.body.classList.toggle('desktop', isDesktop);
    document.body.classList.toggle('mobile', !isDesktop);
    document.body.classList.toggle('modal', !!modal);
    document.body.classList.toggle('fullWidth', route?.name === MAIN);

    const whiteMode = route?.name === TESTS_VIEW || route?.name === TESTS_RESULT;
    document.body.classList.toggle('white', whiteMode);
    document.body.setAttribute('scheme', whiteMode ? 'light' : 'dark');
  }, [isDesktop, modal, route?.name]);

  const handleModalOverlay = useCallback(({ target }) => {
    if (target.closest('.Modal__cont')) {
      return;
    }

    hideModal();
  }, []);

  function renderPage() {
    switch (route?.name) {
      case MAIN:
        return <Main />;
      case REGISTRATION:
        return <Registration />;
      case FEED:
        return <Feed />;
      case PROFILE:
        return <Profile />;
      case VIDEOS:
        return <Anime />;
      case OTHER:
        return <Other />;
      case SEARCH:
        return <Search />;
      case INVITE:
        return <Invite />;
      case GROUP:
        return <Group />;
      case GROUPS:
        return <Groups />;
      case IM:
        return <Im />;
      case CHAT:
        return <Chat />;
      case NOTIFY:
        return <Notify />;
      case AUTH_REQUIRED:
        return <AuthRequired />;
      case FIND_FRIENDS:
        return <FindFriends />;
      case VIEW_ANIME:
        return <ViewAnime animeId={route.params.id} />;
      case RESOLVE:
        return <Resolver mode="address" domain={route.params.domain} />;
      case ALL:
        return <All type={route.params.type} />;
      case VK_AUTH:
        return <VkAuth />;
      case SETTINGS:
        return <Settings />;
      case ANIME_LISTS:
        return <AnimeLists />;
      case ANIME_LIST:
        return <AnimeList />;
      case PROMO:
        return <Promo />;
      case RATING:
        return <Rating />;
      case BIRTHDAYS:
        return <Birthdays />;
      case POST_VIEW:
        return <ViewPost postId={+route.params.id} />;
      case TESTS_VIEW:
        return <TestsView testId={route.params.testId} />;
      case TESTS_RESULT:
        return <TestsResult id={+route.params.id} />;
      case PAYMENT_SUCCESS:
        return <PaymentSuccess />;
    }

    return <NotFound />;
  }

  function renderModal() {
    if (!modal) {
      return null;
    }

    const name = route.params?.w ?? '';
    return (
      <div className={classNames({
        Modal__wrap: true,
        isPhotos: name.substr(0, 6) === 'photos',
      })} onClick={handleModalOverlay}>
        <div className="Modal__cont">
          {modal}
        </div>
      </div>
    );
  }

  function renderTabbar() {
    if (isDesktop) {
      return null;
    }

    if (!isLogged || route?.name === CHAT) {
      return null;
    }

    return (
      <Tabbar>
        {filteredTabs.map((tab) => (
          <TabbarItem
            key={tab.name}
            isActive={tab.name === activeTab}
            onClick={handleTab(tab.name)}
            counter={tab.name === IM ? newDialogs : 0}
            badge={tab.name === NOTIFY ? hasNotify : false}
            label={tab.label}
          >
            {tab.icon}
          </TabbarItem>
        ))}
      </Tabbar>
    );
  }

  if (isLoading) {
    return (
      <div className="App">
        <Loader isWrapped />
      </div>
    );
  }

  const result = (
    <div className="App">
      <div className="Content__wrap">
        {renderPage()}
      </div>
      {renderModal()}
      {renderTabbar()}
      <Toast />
      {popup}
      {/*{!isLogged && route?.name !== MAIN && route?.name !== AUTH_REQUIRED && route?.name !== REGISTRATION && (*/}
      {/*  <CreateAccountPopover />*/}
      {/*)}*/}
    </div>
  );

  if (isDesktop && isLogged) {
    return (
      <div className="App__desktop_wrap">
        <div className="App__desktop_menu">
          <Navbar activeTab={activeTab} />
        </div>
        <div className="App__desktop_cont">
          {result}
        </div>
      </div>
    );
  }

  return result;
}

export default App;
