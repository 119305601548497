import './PhoneInput.sass';

import React, { FC, useMemo } from 'react';
import InputMask from 'react-input-mask';

import { PhoneInputProps } from './PhoneInput.interface';

const countries = [
  {
    prefix: '7',
    flag: '🇷🇺',
  },
  {
    prefix: '380',
    flag: '🇺🇦',
  },
  {
    prefix: '375',
    flag: '🇧🇾',
  },
  {
    prefix: '81',
    flag: '🇯🇵',
  }
];

export const PhoneInput: FC<PhoneInputProps> = ({ code, value, onChange, onChangeCode }) => {

  const mask = useMemo(() => {
    if (+code === 380) {
      return `(99) 99-99-999`;
    } else if (+code === 375) {
      return '(99) 999-99-999';
    } else {
      return `(999) 999-99-99`;
    }
  }, [code]);

  return (
    <div className="PhoneInput Input">
      <select className="PhoneInput__country_code" onChange={onChangeCode}>
        {countries.map((item) => <option key={item.flag} value={item.prefix} selected={+code === +item.prefix}>{item.flag} +{item.prefix}</option>)}
      </select>
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        alwaysShowMask
      />
    </div>
  );
};
