import './Group.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRoute } from 'react-router5';
import { useDispatch, useSelector } from 'react-redux';
import copyToClipboard from 'copy-text-to-clipboard';

import { ActionSheet, Button, Header, Placeholder, ScrollView } from '$uikit';
import { useCurrentUserInfo, useDesktop, useGroupInfo, usePosts } from '$hooks';
import { groupInfoSelector, groupsActions } from '$store/groups';
import { RootState } from '$store/rootReducer';
import { DownloadAppBlock, Loader, Metatags, ModalLink, Post, Verified } from '$shared/components';
import { CreatePostForm } from '$shared/components';
import { IcAdd28, IcChevron16, IcDone28, IcMessage28, IcMore24, IcStatusSending16 } from '$assets';
import { birthdayToAge, classNames, gram, rnbr } from '$utils';
import { toastActions } from '$store/toast';
import { showModal } from '$navigation/helper';
import { ActionSheetPropsOption } from '$uikit/ActionSheet/ActionSheet.interface';
import { GroupProps } from './Group.interface';

export const Group: FC<GroupProps> = (props) => {
  const { route } = useRoute();
  const groupId = props.groupId ? props.groupId : +route.params.id;
  const group = useGroupInfo(groupId);
  const dispatch = useDispatch();
  const info = useSelector((root: RootState) => groupInfoSelector(root, groupId));
  const posts = usePosts(`-${groupId}`);
  const isDesktop = useDesktop();
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [isMoreShown, setMoreShown] = useState(false);
  const user = useCurrentUserInfo();

  useEffect(() => {
    dispatch(groupsActions.loadGroupInfo(groupId));
  }, [groupId]);

  const handleExpand = useCallback(() => {
    setDescriptionExpanded(!descriptionExpanded);
  }, [descriptionExpanded, setDescriptionExpanded]);

  const handleFollow = useCallback(() => {
    dispatch(groupsActions.follow(groupId));
  }, [dispatch, groupId]);

  const handleUnfollow = useCallback(() => {
    dispatch(groupsActions.unfollow(groupId));
  }, [dispatch, groupId]);

  const handleMoreAction = useCallback((item) => {
    console.log(item);
    setMoreShown(false);

    if (item === 'share') {
      navigator.share({
        url: location.href,
        title: group?.name,
        text: `Сообщество на anime.fans`,
      })
    } else if (item === 'copyLink') {
      copyToClipboard(location.href);
      dispatch(toastActions.setToastSuccess('Скопировано!'));
    } else if (item === 'edit') {
      showModal(`edit${groupId}`);
    }
  }, [setMoreShown, dispatch, group]);

  const handleShowMoreAction = useCallback(() => {
    setMoreShown(true);
  }, [setMoreShown, group]);

  const handlePhotoSelect = useCallback(({ target }) => {
    setMoreShown(false);
    dispatch(groupsActions.uploadPhoto({ groupId, file: target.files[0] }));
  }, [groupId, setMoreShown]);

  const moreActions = useMemo(() => {
    const items: ActionSheetPropsOption[]  = [
      { label: 'Копировать ссылку', value: 'copyLink' },
    ];

    /* @ts-ignore */
    if (navigator.canShare) {
      items.push({ label: 'Поделиться', value: 'share' });
    }

    if (group?.isAdmin) {
      items.push(
        { label: 'Редактировать', value: 'edit' },
        {
          label: 'Изменить фото',
          value: 'changePhoto',
          disabled: true,
          children: (
            <input
              type="file"
              className="Profile__upload_ava_input"
              onChange={handlePhotoSelect}
            />
          )
        }
      );
    }

    return items;
  }, [group, handlePhotoSelect]);

  const ageRestriction = useMemo(() => {
    if (!group) {
      return 'allow';
    }

    if (group.adultOnly && !group.isModer) {
      const age = birthdayToAge(user.bDate);
      if (age <= 0) {
        return 'require_bdate';
      } else if (age >= 18) {
        return 'allow';
      } else {
        return 'denied';
      }
    } else {
      return 'allow';
    }
  }, [group, user.bDate]);

  const metaDescription = useMemo(() => {
    const result: string[] = [];

    if (!group) {
      return '';
    }

    if (group.description) {
      result.push(`Описание: ${group.description}`);
    }

    if (group.followers > 0) {
      result.push(`Подписчики: ${group.followers}`);
    }


    return result.filter(item => item.length > 0).join('. ');
  }, [group]);

  function renderPosts() {
    if (posts.length === 0) {
      return <Placeholder>Нет записей</Placeholder>;
    }

    return (
      <div>
        {posts.map((post) => <Post key={post} postId={post}/>)}
      </div>
    );
  }

  function renderStatus() {
    if (group.isModer) {
      return (
        <ModalLink
          className="Profile__common_info__status"
          name={`status${groupId}`}
        >
          <IcMessage28 className="Profile__common_info__status__icon" />
          {group.status || 'Изменить статус'}
          <IcChevron16 className="Profile__common_info__status__chevron" />
        </ModalLink>
      );
    }

    if (group.status) {
      return null;
    }

    return (
      <div className="Profile__common_info__status">
        {group.status}
      </div>
    );
  }

  function renderContent() {
    if (!info || info.isLoading && !group) {
      return (
        <Loader isWrapped/>
      );
    }

    if (info.isFailed) {
      return (
        <Placeholder>
          {info.failedReason}
        </Placeholder>
      );
    }

    if (ageRestriction === 'require_bdate') {
      return (
        <Placeholder
          title="Нет доступа"
          actions={(
            <ModalLink name="edit">
              <Button size="small">Ред. профиль</Button>
            </ModalLink>
          )}
        >
          Чтобы смотреть содержимое данного сообщества укажите вашу дату рождения.
        </Placeholder>
      );
    } else if (ageRestriction === 'denied') {
      return (
        <Placeholder title="Нет доступа">
          Данное сообщество доступно для лиц от 18 лет и старше.
        </Placeholder>
      );
    }

    return (
      <div className="Group">
        <div className="Profile__common_info">
          <div
            className="Profile__common_info__photo"
            style={{ backgroundImage: `url(${group.photo})` }}
          />
          <div className="Profile__common_info__cont">
            <div className="Profile__common_info__name_wrap">
              <div className="Profile__common_info__name">
                {group.name}
              </div>
              <Verified objectId={-groupId} large />
              {group.adultOnly && (
                <div className="Badge orange">18+</div>
              )}
            </div>
            {renderStatus()}
          </div>
        </div>

        <div>
          {group.description && (
            <div className="Group__info_row">
              <div className="Group__info_label">Описание:</div>
              <div className="Group__info_cont">
                <div className={classNames({
                  Group__description: true,
                  expanded: descriptionExpanded,
                })}>
                  {rnbr(group.description)}
                  <div className="Group__description__expand_btn"
                       onClick={handleExpand}>{descriptionExpanded ? 'Свернуть' : 'Показать полностью..'}</div>
                </div>
              </div>
            </div>
          )}
          {!!group.link && (
            <div className="Group__info_row">
              <div className="Group__info_label">Сайт:</div>
              <div className="Group__info_cont">
                <a href={group.link} target="_blank">{group.link}</a>
              </div>
            </div>
          )}
        </div>

        <div className="Profile__follow_wrap">
          {info.isFollowed ? (
            <Button
              icon={<IcDone28/>}
              mode="secondary"
              onClick={handleUnfollow}
            >Вы подписаны</Button>
          ) : (
            <Button
              icon={<IcAdd28/>}
              onClick={handleFollow}
            >Подписаться</Button>
          )}
        </div>
        <ModalLink className="Group__followers" name={`followers-${groupId}`}>
          {gram(group.followers, ['подписчик', 'подписчика', 'подписчиков'])}
        </ModalLink>
        {info.canPost && <CreatePostForm ownerId={-groupId}/>}
        {info.scheduledCount > 0 && (
          <ModalLink className="Group__scheduled_posts" name={`scheduled-${groupId}`}>
            <IcStatusSending16 width={28} height={28} />
            {gram(info.scheduledCount, ['запланирована', 'запланированы', 'запланировано'])}
          </ModalLink>
        )}
        {renderPosts()}
      </div>
    );
  }

  return (
    <>
      <Metatags
        title={`${group?.name ?? ''} (@${group?.domain || 'group' + group?.id}) | Anime.Fans`}
        description={metaDescription}
        image={group?.photo ?? ''}
        canonical={`https://anime.fans/${group?.domain ? group.domain : `group${group?.id}`}`}
      />
      <Header
        showBackButton={!isDesktop}
        rightIcon={<IcMore24/>}
        rightOnClick={handleShowMoreAction}
      />
      <ScrollView
        onLoadMore={() => dispatch(groupsActions.loadMorePosts(groupId))}
        canLoadMore={info && !info.isLoading && !info.isPostsLoadingMore && !!info.postsNextFrom}
      >
        <DownloadAppBlock />
        {renderContent()}
      </ScrollView>
      <ActionSheet
        isOpened={isMoreShown}
        options={moreActions}
        onChange={handleMoreAction}
      />
    </>
  );
};
