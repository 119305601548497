import './ViewAnime.sass';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Header, InlineHeader, ScrollView } from '$uikit';
import { animeActions } from '$store/anime';
import { useAnimeFull, useCurrentUserInfo } from '$hooks';
import { classNames } from '$utils';
import { AnimeEpisodes, Loader, Metatags, ModalLink, ShareSheet } from '$shared/components';
import { IcEdit28, IcLike24, IcShare24 } from '$assets';
import { showModal } from '$navigation/helper';
import { mainActions } from '$store/main';

export function ViewAnime({ animeId }) {
  const dispatch = useDispatch();
  const anime = useAnimeFull(animeId);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const curUser = useCurrentUserInfo();

  useEffect(() => {
    dispatch(animeActions.loadAnimeInfo({ animeId, season: 0 }));
  }, []);

  const handleDescriptionExpand = useCallback(() => {
    setDescriptionExpanded(!isDescriptionExpanded);
  }, [setDescriptionExpanded, isDescriptionExpanded]);

  const handleLike = useCallback(() => {
    dispatch(animeActions.toggleLike(animeId));
  }, [dispatch, animeId]);

  const handleShare = useCallback(() => {
    dispatch(mainActions.setPopup(
      <ShareSheet
        title="Поделиться аниме"
        link={{
          type: 'anime',
          object: anime.info,
        }}
      />
    ));
  }, [anime, dispatch]);

  const handleEdit = useCallback(() => {
    showModal(`edit_anime${animeId}`);
  }, [animeId]);

  const metaDescription = useMemo(() => {
    if (!anime) {
      return '';
    }

    const result: string[] = [];

    result.push(anime.info.description);
    result.push(`Год выпуска: ${anime.info.years.join(', ')}`);
    result.push(`Жанр: ${anime.info.genres.map((item) => item.title).join(', ')}`);
    result.push(`Тема: ${anime.info.themes.map((item) => item.title).join(', ')}`);

    return result.filter(item => item.length > 0).join('. ');
  }, [anime]);

  function renderContent() {
    if (!anime) {
      return (
        <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Loader />
        </div>
      );
    }

    const info = [
      {
        label: 'Год выпуска',
        value: anime.info.years.join(', '),
      },
      {
        label: 'Жанр',
        value: anime.info.genres.map((item) => item.title).join(', '),
      },
      {
        label: 'Тема',
        value: anime.info.themes.map((item) => item.title).join(', '),
      }
    ];

    if (anime.info.ageRestriction > 0) {
      info.push({
        label: 'Возрастной рейтинг',
        value: `${anime.info.ageRestriction}+`
      });
    }

    return (
      <>
        <Metatags
          title={`Смотреть аниме ${anime?.info.title ?? ''} все серии | Anime.Fans`}
          description={metaDescription}
          image={anime?.info.photos[0] ?? ''}
          canonical={`https://anime.fans/anime/${animeId}`}
        />
        <InlineHeader>{anime.info.title}</InlineHeader>
        <div className="ViewAnime__orig_name">{anime.info.originalTitle}</div>
        <div className="ViewAnime__info">
          <div
            className="ViewAnime__info__poster"
            style={{ backgroundImage: `url(${anime.info.photos[0]})` }}
          />
          <div className="ViewAnime__info_cont">
            <div className="ViewAnime__info__rows">
              {info.filter((item) => item.value.trim().length > 0).map((item) => (
                <div className="ViewAnime__info__row" key={item.label}>
                  <div className="ViewAnime__info__row__label">{item.label}:</div>
                  <div className="ViewAnime__info__row__value">{item.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {anime.state && (
          <div className="ViewAnime__continue">
            <ModalLink
              name={`play${anime.info.id}_${anime.state.translationId}_${anime.state.season}_${anime.state.episode}`}
            >
              <Button>Продолжить просмотр</Button>
            </ModalLink>
          </div>
        )}
        <div className="ViewAnime__description_wrap">
          <div className={classNames({
            ViewAnime__description: true,
            expanded: isDescriptionExpanded,
          })}>
            {anime.info.description}
          </div>
          <Button
            size="small"
            mode="inline"
            onClick={handleDescriptionExpand}
          >{isDescriptionExpanded ? 'Свернуть' : 'Показать полностью..'}</Button>
        </div>
        <div className="ViewAnime__like_wrap">
          <Button
            size="small"
            mode={anime.info.isLiked ? 'red' : 'secondary'}
            icon={<IcLike24 />}
            onClick={handleLike}
          >
            Нравится
            {anime.info.likes > 0 ? <span>{anime.info.likes}</span> : null}
          </Button>
          <Button
            size="small"
            mode="secondary"
            icon={<IcShare24 />}
            onClick={handleShare}
          />
          {curUser.isAnimeEditor && <Button
            size="small"
            mode="secondary"
            icon={<IcEdit28 />}
            onClick={handleEdit}
          />}
        </div>
        <AnimeEpisodes animeId={animeId} />
      </>
    );
  }

  return (
    <>
      <Header showBackButton />
      <ScrollView>
        {renderContent()}
      </ScrollView>
    </>
  )
}
