import React, { useCallback } from 'react';
import { Loader, Collage } from '$shared/components';
import { attachActions } from '../../../store/attach';
import { IcClose16 } from '$assets';
import { useDispatch } from 'react-redux';

export default function Photos({ pickerId, photos, mini }) {
  const dispatch = useDispatch();

  const handleRemovePhoto = useCallback((photoId: number) => () => {
    dispatch(attachActions.removePhoto({
      pickerId,
      photoId,
    }));
  }, [dispatch, pickerId]);

  if (!photos.length) {
    return null;
  }

  function renderControls(photo) {
    return (
      <div className="AttachPickerManager__photo__controls">
        <div className="AttachPickerManager__photo__remove" onClick={handleRemovePhoto(photo.photoId)}>
          <IcClose16 />
        </div>
        {photo.photoId < 0 && <div className="AttachPickerManager__photo__loader">
          <Loader />
        </div>}
      </div>
    );
  }

  if (mini) {
    return (
      <div className="AttachPickerManager__mini_photos">
        {photos.map((photo) => (
          <div
            className="AttachPickerManager__mini_photo"
            key={photo.photoId}
          >
            <div
              className="AttachPickerManager__mini_photo__image"
              style={{ backgroundImage: `url(${photo.photoMedium})` }}
            >
              {renderControls(photo)}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Collage
      renderExtra={(index) => renderControls(photos[index])}
      items={photos.map((photo) => ({
        type: 'photo',
        photo: {
          isVideo: photo.isVideo,
          photoId: photo.photoId,
          photoMedium: photo.photoMedium,
          width: photo.width,
          height: photo.height,
        },
      }))}
    />
  );
}
