import './Write.sass';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Placeholder, ScrollView, SearchInput } from '$uikit';
import { imActions, imSelector } from '$store/im';
import { Loader } from '$shared/components';
import { WriteCell } from '$core/Write/WriteCell/WriteCell';
import { useRoute } from 'react-router5';
import router, { isBackNavigation } from '$navigation/router';

export const Write: FC = () => {
  const dispatch = useDispatch();
  const { isWriteLoading, writeIds } = useSelector(imSelector);
  const { route } = useRoute();
  const isShare = !!route.params.share;
  const isBack = isBackNavigation();
  const [query, setQuery] = useState(route.params?.query ?? '');

  useMemo(() => {
    if (!isBack) {
      dispatch(imActions.loadWrite());
    }
  }, []);

  useEffect(() => {
    const params: any = { ...route.params };
    if (query && query.length > 0) {
      if (params.query === query) {
        return;
      }

      params.query = query;
    } else {
      delete params.query;
    }

    router.navigate(route.name, params, { replace: true, urlParamsEncoding: false });

    let timer = setTimeout(() => {
      dispatch(imActions.loadWrite());
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const handleSearchChange = useCallback((newQuery) => {
    setQuery(newQuery);
  }, []);

  function renderContent() {
    if (isWriteLoading && !writeIds.length) {
      return (
        <Loader isWrapped />
      );
    }

    if (!writeIds.length) {
      if (query.length > 0) {
        return (
          <Placeholder>
            По вашему запросу ничего не найдено.
          </Placeholder>
        );
      }

      return (
        <Placeholder>
          Здесь будут выводиться пользователи, на которых вы подписались.
        </Placeholder>
      );
    }

    return writeIds.map((userId) => <WriteCell key={userId} userId={userId} />);
  }

  return (
    <>
      <Header showCloseButton>{isShare ? 'Поделиться' : 'Новое сообщение'}</Header>
      <ScrollView>
        <SearchInput
          query={query}
          onChange={handleSearchChange}
          placeholder="Поиск"
        />
        <div className="Write__content">
          {renderContent()}
        </div>
      </ScrollView>
    </>
  );
};
