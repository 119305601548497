import './TestsView.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { Api } from '$api';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import { Loader } from '$shared/components';
import { Button, Placeholder, ScrollView } from '$uikit';
import router from '$navigation/router';
import { TESTS_RESULT } from '$shared/constants/pages';
import { IcChevron16 } from '$assets';

export const TestsView: FC<{ testId: string }> = (props) => {
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [info, setInfo] = useState<{
    name: string;
    description: string;
    rows: {
      question: string;
      answers: string[];
      index: number;
    }[];
  } | null>(null);
  const [index, setIndex] = useState(-1);
  const [answers, setAnswers] = useState<number[]>([]);

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get<any, any>(`/tests/${props.testId}/info`)
      .then((data) => {
        setInfo(data);
        setLoadingStatus('');
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
        setLoadingStatus('failed');
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(toastActions.setToastLoading());
   Api.post<any, any>(`/tests/${props.testId}/calc`, {
     answers,
     indexes: info!.rows.map((row) => row.index),
   })
     .then((data) => {
       dispatch(toastActions.hideToast());
       router.navigate(TESTS_RESULT, {
         id: data.resultId,
       })
     })
     .catch((e) => {
       dispatch(toastActions.setToastFail(e.message));
     })
  }, [answers, props.testId]);

  function renderContent() {
    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          title="Произошла ошибка"
          actions={<Button size="small" onClick={loadData}>Попробовать снова</Button>}
          centered
        >
          Попробуйте еще раз позже
        </Placeholder>
      );
    }

    if (loadingStatus === 'loading' || !info) {
      return <Loader isWrapped />;
    }

    if (index === -1) {
      return (
        <div className="Tests__intro">
          <div className="Tests__intro__cont">
            <div className="Tests__intro__name">{info.name}</div>
            <div className="Tests__intro__description">{info.description}</div>
            <div className="Tests__intro__action">
              <Button onClick={() => setIndex(0)}>
                Начать тест
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="Tests__intro">
        <div className="Tests__intro__cont test">
          <div className="Tests__question">{info.rows[index].question}</div>
          <div className="Tests__answers">
            {info.rows[index].answers.map((answer, i) => (
              <div
                key={i}
                className="Tests__answer"
                onClick={() => {
                  setAnswers((prev) => {
                    const next = [...prev];
                    next[index] = i;
                    return next;
                  });
                  if (index + 1 === info.rows.length) {
                    setTimeout(() => handleSubmit(), 0);
                  } else {
                    setIndex(index + 1);
                  }
                }}
              >
                {answer}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <ScrollView>
      <a href="https://anime.fans/promo" className="Tests_copyright" target="_blank">
        <div className="Tests_copyright__photo" />
        <div className="Tests_copyright__info"><b>Anime.Fans</b> - социальная сеть для любителей аниме</div>
        <div className="Tests_copyright__chevron">
          <IcChevron16 />
        </div>
      </a>
      {renderContent()}
    </ScrollView>
  );
};
