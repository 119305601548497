import { all, takeLatest, call, put, retry, select } from 'redux-saga/effects';
import { feedActions, feedSelector } from '$store/feed/index';
import { Api } from '$api';
import { mainActions } from '$store/main';
import { postsActions } from '$store/posts';
import { HideNotificationAction, LoadFeedAction, LoadMoreAction } from '$store/feed/interface';
import { toastActions } from '$store/toast';
import { isAndroid, isIOS, isNative, isPWA } from '$utils';

function* loadFeedWorker(action: LoadFeedAction) {
  try {
    const { section } = action.payload;

    const data = yield retry(Infinity, 3000, Api.post, '/feed', {
      from: '',
      section,
      suggestions: section === 'suggestions',
    });
    yield put(mainActions.setUsers(data.users));
    yield put(mainActions.setGroups(data.groups));
    yield put(postsActions.setPosts(Object.values(data.posts)))
    yield put(feedActions.setFeed({
      nextFrom: data.nextFrom,
      items: data.feed,
      suggestions: data.suggestions,
      notifications: data.notifications.filter((item) => {
        if (item.id === 'home_screen' && (isNative() || isPWA() || !isAndroid())) {
          return false;
        }

        return true;
      }),
    }));

    if (data.feed.length < 5 && section === 'following') {
      yield put(feedActions.loadMore({ section }));
    }
  } catch (e) {}
}

function* loadMoreWorker(action: LoadMoreAction) {
  try {
    const { nextFrom } = yield select(feedSelector);
    const data = yield call(Api.post, '/feed', {
      from: nextFrom,
      section: action.payload.section,
    });
    yield put(mainActions.setUsers(data.users));
    yield put(mainActions.setGroups(data.groups));
    yield put(postsActions.setPosts(Object.values(data.posts)))
    yield put(feedActions.setMoreFeed({
      nextFrom: data.nextFrom,
      items: data.feed
    }));
  } catch (e) {}
}

function* hideNotificationWorker(action: HideNotificationAction) {
  try {
    yield put(toastActions.setToastLoading());
    yield call(Api.post, '/feed/hide_notify', {
      id: action.payload,
    });
    yield put(feedActions.hideNotificationFromUI(action.payload));
    yield put(toastActions.hideToast());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* feedSaga() {
  yield all([
    takeLatest(feedActions.loadFeed, loadFeedWorker),
    takeLatest(feedActions.loadMore, loadMoreWorker),
    takeLatest(feedActions.hideNotification, hideNotificationWorker),
  ]);
}
