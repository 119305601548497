export default {
  "ru": {
    "cancel": "Отмена",
    "back": "Назад",
    "loading": "Загрузка..",
    "continue": "Продолжить",
    "done": "Готово",
    "save": "Сохранить",
    "copied": "Скопировано!",
    "show_more": "Показать больше",
    "and": "и",
    "m": "м",
    "d": "д",
    "y": "г",
    "user-age": {
      "other": "%{count} лет",
      "many": "%{count} лет",
      "few": "%{count} года",
      "one": "%{count} год"
    },
    "today": "Сегодня",
    "yesterday": "Вчера",
    "feed_title": "Новости",
    "feed_empty_title": "Записей больше нет",
    "feed_empty_caption": "Подписывайтесь на других пользователей и группы, чтобы видеть больше записей",
    "feed_show_peoples": "Показать людей",
    "feed_onboarding_title": "Лента новостей",
    "feed_onboarding_caption": "Здесь будут выводиться записи людей и сообществ, на которые вы подписаны",
    "find_friends_btn": "Найти друзей",
    "feed_suggestions_title": "Вы посмотрели все записи от подписок",
    "feed_suggestions_caption": "Ниже показаны записи от рекомендованных групп и людей",
    "feed_likes_empty_title": "Лайков нет",
    "feed_likes_empty_caption": "Здесь будут выводиться записи, на которые вы поставили лайк.",
    "navbar_my_page": "Моя страница",
    "navbar_news": "Новости",
    "navbar_anime": "Аниме",
    "navbar_im": "Сообщения",
    "navbar_notify": "Обновления",
    "navbar_groups": "Сообщества",
    "navbar_search": "Поиск",
    "navbar_find_friends": "Поиск друзей",
    "navbar_more": "Ещё",
    "tabbar_profile": "Профиль",
    "main_caption": "Социальная сеть для любителей аниме",
    "main_link_anime": "Аниме",
    "main_link_peoples": "Люди",
    "main_link_groups": "Сообщества",
    "auth_title": "Вход на сайт",
    "auth_button": "Войти",
    "auth_sms_code_title": "Введите код из смс",
    "auth_sms_code_description": "Код отправлен на: %{phone}.",
    "auth_change_number": "Изменить номер",
    "auth_enter_code": "Введите код",
    "auth_sms": "Войти через SMS",
    "auth_vk": "Войти через ВКонтакте",
    "auth_terms_pref": "Продолжая, вы соглашаетесь c",
    "auth_terms": "Правилами",
    "auth_privacy": "Конфиденциальностью",
    "reg_step1_title": "Давайте знакомиться",
    "reg_step2_title": "Откуда вы?",
    "reg_step3_title": "Теперь давайте загрузим аватарку",
    "reg_name_short": "Введите имя не короче 2 символов",
    "reg_name_long": "Введите имя не длинее 16 символов",
    "reg_name_wrong": "Введите корректное имя",
    "reg_last_name_short": "Введите фамилию не короче 2 символов",
    "reg_code_required_error":"Введите пригласительный код",
    "reg_last_name_long": "Введите фамилию не длинее 16 символов",
    "reg_last_name_wrong": "Введите корректную фамилию",
    "reg_invite_code": "Пригласительный код",
    "reg_enter_invite_code": "Введите пригласительный код...",
    "reg_invite_code_description": "Оставьте поле пустым, если у вас нет кода.",
    "reg_name": "Ваше имя",
    "reg_enter_name": "Введите имя",
    "reg_last_name": "Ваша фамилия",
    "reg_enter_last_name": "Введите фамилию",
    "reg_gender": "Ваш пол",
    "reg_select_gender": "Выберите пол",
    "reg_gender_not_selected": "Не выбрано",
    "reg_gender_male": "Мужчина",
    "reg_gender_female": "Женщина",
    "reg_birthday": "Дата рождения",
    "reg_country": "Страна",
    "reg_select_country": "Выберите страну",
    "reg_select_empty": "Ничего нет",
    "reg_city": "Город",
    "reg_select_city": "Выберите город",
    "bad_photo_err": "Это не фото — нужно фото",
    "photo_uploading_err": "Дождитесь загрузки фото",
    "reg_delete_photo": "Удалить фото",
    "other_title": "Ещё",
    "other_go_to_profile": "Перейти в профиль",
    "other_edit": "Ред. профиль",
    "other_groups": "Сообщества",
    "other_find_friends": "Найти друзей",
    "other_settings": "Настройки",
    "settings_title": "Настройки",
    "settings_username": "Адрес страницы",
    "settings_terms": "Пользовательское соглашение",
    "settings_privacy": "Политика конфиденциальности",
    "settings_logout": "Выход",
    "settings_logout_confirm": "Вы действительно хотите выйти?",
    "domain_title": "Адрес страницы",
    "domain_caption": "Вы можете указать короткий адрес до вашей страницы, например:",
    "domain_req1": "Не менее 5 символов",
    "domain_req2": "Не более 15 символов",
    "domain_req3": "Латинские буквы и цифры",
    "domain_req4": "Не более 3-х нижних подчеркиваний (_)",
    "domain_enter_username": "Введите адрес",
    "domain_available": "Адрес свободен",
    "groups_title": "Сообщества",
    "groups_tab_all": "Все",
    "groups_tab_manage": "Управление",
    "groups_empty": "Нет ни одного сообщества",
    "find_friends_title": "Поиск друзей",
    "find_friends_search": "Поиск по имени",
    "find_friends_invite": "Пригласить друзей",
    "find_friends_suggestions": "Рекомендации",
    "find_friends_empty": "Подписывайтесь на других людей и здесь будут показаны рекомендации",
    "im_empty": "Нет диалогов",
    "im_title": "Сообщения",
    "chat_title": "Сообщения",
    "chat_unblock": "Разблокировать",
    "chat_block": "Заблокировать",
    "chat_delete": "Удалить диалог",
    "history_empty": "Сообщений нет",
    "send_from_blocked": "Нельзя написать человеку из черного списка",
    "send_form_block_you_male": "%{name} заблокировал вас",
    "send_form_block_you_female": "%{name} заблокировала вас",
    "send_from_placeholder": "Ваше сообщение..",
    "message_copy_text": "Копировать текст",
    "message_delete": "Удалить сообщение",
    "settings_legal": "Правообладателям",
    "search_filters_sort": "Сортировка",
    "search_filters_select_sort": "Выберите сортировку",
    "search_filters_city": "Город",
    "search_filters_select_city": "Выберите город",
    "search_empty": "Ничего не найдено",
    "search_section_peoples": "Люди",
    "search_section_groups": "Сообщества",
    "search_all_placeholder": "Найти человека или группу",
    "search_groups_placeholder": "Введите название",
    "search_peoples_placeholder": "Введите имя или фамилию",
    "search_meta_title": "Поиск людей и сообществ",
    "search_meta_description": "Ищите пользователей и сообщества созданные на Anime.Fans",
    "search_tab_all": "Все",
    "search_tab_peoples": "Люди",
    "search_tab_groups": "Сообщества",
    "search_sort_popular": "Популярные",
    "search_sort_new": "Новые пользователи",
    "search_sort_online": "Последние активные",
  },
  "en": {
    "cancel": "Cancel",
    "back": "Back",
    "loading": "Loading..",
    "continue": "Continue",
    "and": "and",
    "done": "Done",
    "copied": "Copied!",
    "show_more": "Show more",
    "save": "Save",
    "m": "m",
    "d": "d",
    "y": "y",
    "user-age": {
      "other": "%{count} years",
      "many": "%{count} years",
      "few": "%{count} years",
      "one": "%{count} year"
    },
    "today": "Today",
    "yesterday": "Yesterday",
    "feed_title": "News",
    "feed_empty_title": "No posts yet",
    "feed_empty_caption": "Follow other peoples or groups to see more posts",
    "feed_show_peoples": "Show peoples",
    "feed_onboarding_title": "Newsfeed",
    "feed_onboarding_caption": "Here will be appears posts from peoples and groups you following",
    "find_friends_btn": "Find friends",
    "feed_suggestions_title": "You seen all posts from you followed",
    "feed_suggestions_caption": "For next you can see recommendations",
    "navbar_my_page": "My Profile",
    "navbar_news": "News",
    "navbar_anime": "Anime",
    "navbar_im": "Messages",
    "navbar_notify": "Updates",
    "navbar_groups": "Groups",
    "navbar_search": "Search",
    "navbar_find_friends": "Find friends",
    "navbar_more": "More",
    "tabbar_profile": "Profile",
    "main_caption": "Social network for anime lovers",
    "main_link_anime": "Anime",
    "main_link_peoples": "Peoples",
    "main_link_groups": "Groups",
    "auth_title": "Authorization",
    "auth_button": "Sign in",
    "auth_sms_code_title": "Enter code from sms",
    "auth_sms_code_description": "Code was sent to: %{phone}.",
    "auth_change_number": "Change phone",
    "auth_enter_code": "Enter code",
    "auth_sms": "Continue via SMS",
    "auth_vk": "Continue via VK",
    "auth_terms_pref": "By continue you agree to our",
    "auth_terms": "Terms",
    "auth_privacy": "Policy",
    "reg_step1_title": "Fill basic information",
    "reg_step2_title": "Where you from?",
    "reg_step3_title": "Finally, add photo",
    "reg_name_short": "First name must contain at least 2 chars",
    "reg_name_long": "First name must contain at greatest 16 chars",
    "reg_name_wrong": "Incorrect name",
    "reg_last_name_short": "Last name must contain at least 2 chars",
    "reg_code_required_error": "Enter invite code",
    "reg_last_name_long": "Last name must contain at greatest 16 chars",
    "reg_last_name_wrong":  "Incorrect last name",
    "reg_invite_code": "Invite code",
    "reg_enter_invite_code": "Enter invite code...",
    "reg_invite_code_description": "Leave field empty if you don't have code.",
    "reg_name": "Your first name",
    "reg_enter_name": "Enter first name",
    "reg_last_name": "Your last name",
    "reg_enter_last_name": "Enter last name",
    "reg_gender": "Your gender",
    "reg_select_gender": "Select gender",
    "reg_gender_not_selected": "Not selected",
    "reg_gender_male": "Male",
    "reg_gender_female": "Female",
    "reg_birthday": "Date of birth",
    "reg_country": "Country",
    "reg_select_country": "Select country",
    "reg_select_empty": "Nothing yet",
    "reg_city": "City",
    "reg_select_city": "Select city",
    "bad_photo_err": "It's not a photo - need a photo",
    "photo_uploading_err": "Wait for uploading photo",
    "reg_delete_photo": "Delete photo",
    "other_title": "More",
    "other_go_to_profile": "Go to profile",
    "other_edit": "Edit profile",
    "other_groups": "Groups",
    "other_find_friends": "Find friends",
    "other_settings": "Settings",
    "settings_title": "Settings",
    "settings_username": "Username",
    "settings_terms": "Terms of use",
    "settings_privacy": "Privacy policy",
    "settings_logout": "Logout",
    "settings_logout_confirm": "Do you wanna logout?",
    "domain_title": "Username",
    "domain_caption": "You can create short link to your profile, e.g:",
    "domain_req1": "Contain at least 5 symbols",
    "domain_req2": "Contain at greatest 15 symbols",
    "domain_req3": "Latin symbols and numbers",
    "domain_req4": "Maximum 3 underscores (_)",
    "domain_enter_username": "Enter username",
    "domain_available": "Username is available",
    "groups_title": "Groups",
    "groups_tab_all": "All",
    "groups_tab_manage": "Managed",
    "groups_empty": "No groups yet",
    "find_friends_title": "Find friends",
    "find_friends_search": "Search by name",
    "find_friends_invite": "Invite friends",
    "find_friends_suggestions": "Suggestions",
    "find_friends_empty": "Follow others peoples and suggestions will be appear here",
    "im_empty": "No dialogs yet",
    "im_title": "Dialogs",
    "chat_title": "Messages",
    "chat_unblock": "Unblock",
    "chat_block": "Block",
    "chat_delete": "Delete dialog",
    "history_empty": "No messages yet",
    "send_from_blocked": "You can't text with user from blacklist",
    "send_form_block_you_male": "%{name} blocked you",
    "send_form_block_you_female": "%{name} blocked you",
    "send_from_placeholder": "Message..",
    "message_copy_text": "Copy text",
    "message_delete": "Delete message",
    "settings_legal": "Claims to Content Placement",
    "search_filters_sort": "Sort by",
    "search_filters_select_sort": "Select sorting",
    "search_filters_city": "City",
    "search_filters_select_city": "Select city",
    "search_empty": "Nothing found",
    "search_section_peoples": "Peoples",
    "search_section_groups": "Groups",
    "search_all_placeholder": "Find people or group",
    "search_groups_placeholder": "Enter name",
    "search_peoples_placeholder": "Enter name",
    "search_meta_title": "Find people or group",
    "search_meta_description": "Find people or group on Anime.Fans",
    "search_tab_all": "All",
    "search_tab_peoples": "Peoples",
    "search_tab_groups": "Groups",
    "search_sort_popular": "Popular",
    "search_sort_new": "New users",
    "search_sort_online": "Recently active",
  }
}
