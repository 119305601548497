import './Likes.sass';

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LikesProps } from '$core/Likes/Likes.interface';
import { Button, Header, Placeholder, ScrollViewVirtualized } from '$uikit';
import { likesActions, likesSelector } from '$store/likes';
import { Loader, UserCell } from '$shared/components';
import { isBackNavigation } from '$navigation/router';

const ROW_HEIGHT = 68;

export const Likes: FC<LikesProps> = ({ objectId, objectType }) => {
  const dispatch = useDispatch();
  const isBack = isBackNavigation();
  const { isLoading, isFailed, likes, nextFrom } = useSelector(likesSelector);

  useEffect(() => {
    if (!isBack) {
      dispatch(likesActions.loadLikes({
        objectId,
        objectType,
      }));
    }
  }, []);

  const handleRetry = useCallback(() => {
    dispatch(likesActions.loadLikes({
      objectId,
      objectType,
    }));
  }, [dispatch, objectId, objectType]);

  const handleLoadMore = useCallback(() => {
    dispatch(likesActions.loadMoreLikes({
      objectId,
      objectType,
      nextFrom: nextFrom!,
    }));
  }, [dispatch, nextFrom]);

  function renderRow(index: number) {
    return <UserCell objectId={likes[index]} />;
  }

  function renderContent() {
    if (isLoading && !likes.length) {
      return <Loader isWrapped />;
    }

    if (isFailed) {
      return (
        <Placeholder
          title="Произошла ошибка"
          actions={<Button onClick={handleRetry}>Попробовать снова</Button>}
        >
          Попробуйте повторить позже.
        </Placeholder>
      );
    }

    return (
      <ScrollViewVirtualized
        rowCount={likes.length}
        rowHeight={ROW_HEIGHT}
        renderRow={renderRow}
        canLoadMore={!isLoading && !!nextFrom}
        onLoadMore={handleLoadMore}
      />
    );
  }

  return (
    <>
      <Header showCloseButton>Лайки</Header>
      {renderContent()}
    </>
  );
};
