import './Notify.sass';

import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header, Placeholder, PullToRefresh, ScrollView } from '$uikit';
import { notifyActions, notifySelector } from '$store/notify';
import { Loader, Metatags, NotifyItem, NotifyRequest } from '$shared/components';
import { isBackNavigation } from '$navigation/router';

export const Notify: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, items } = useSelector(notifySelector);
  const isBack = isBackNavigation();

  useEffect(() => {
    if (!isBack) {
      dispatch(notifyActions.load());
    }
  }, []);

  const handleRefresh = useCallback(() => {
    dispatch(notifyActions.load());
  }, [dispatch]);

  function renderContent() {
    if (isLoading && !items.length) {
      return (
        <Loader isWrapped />
      );
    }

    if (!items.length) {
      return (
        <Placeholder centered>
          Пока нет уведомлений 📭
        </Placeholder>
      );
    }

    return (
      <>
        {items.map((item) => <NotifyItem notify={item} key={item.id} />)}
      </>
    );
  }

  return (
    <>
      <Metatags title="Обновления" />
      <Header>Обновления</Header>
      <ScrollView>
        <PullToRefresh
          isRefreshing={isLoading}
          onRefresh={handleRefresh}
        >
          <NotifyRequest />
          {renderContent()}
        </PullToRefresh>
      </ScrollView>
    </>
  );
};
