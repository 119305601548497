import './PlayAnime.sass';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { AnimeEpisodes, VideoPlayer, Loader, ModalLink, Metatags } from '$shared/components';
import { useAnimeFull } from '$hooks';
import { animeActions } from '$store/anime';
import { Button, Header, Placeholder, ScrollView } from '$uikit';
import { isNative } from '$utils';

export function PlayAnime({ animeId, translationId, season, number }) {
  const dispatch = useDispatch();
  const anime = useAnimeFull(animeId);

  useEffect(() => {
    dispatch(animeActions.loadAnimeInfo({ animeId, season: season - 1 }));
  }, []);

  const episodes = useMemo(() => {
    if (!anime) {
      return [];
    }

    return anime.seasons[season - 1]?.episodes ?? [];
  }, [anime, season]);

  const episode = useMemo(() => {
    if (!anime) {
      return null;
    }

    return episodes[number - 1];
  }, [anime, episodes, number]);

  const video = useMemo(() => {
    if (!anime) {
      return null;
    }

    for (let item of episodes) {
      if (item.number === number) {
        return anime.videos[item.videoId];
      }
    }

  }, [number, anime, episodes]);

  const prevParams = useMemo(() => {
    if (!anime) {
      return null;
    }

    if (season === 1 && number === 1) {
      return null;
    }

    let newSeason = season;
    let newNumber = number - 1;
    if (newNumber === 0) {
      newSeason = season - 1;
      newNumber = anime.seasons[newSeason - 1]?.episodes.length;
    }

    return {
      id: animeId,
      translation: anime.translationSelected,
      season: newSeason,
      number: newNumber,
    };
  }, [anime, animeId, season, number]);

  const nextParams = useMemo(() => {
    if (!anime) {
      return null;
    }

    const episodesLen = anime.seasons[season - 1]?.episodes.length;
    if (season === anime.seasons.length && number === episodesLen) {
      return null;
    }

    let newSeason = season;
    let newNumber = number + 1;
    if (newNumber > episodesLen) {
      newSeason++;
      newNumber = 1;
    }

    return {
      id: animeId,
      translation: anime.translationSelected,
      season: newSeason,
      number: newNumber,
    };
  }, [anime, animeId, season, number]);

  if (!video) {
    return (
      <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Loader />
      </div>
    );
  }

  if (isNative(4)) {
    return (
      <>
        <Header showBackButton />
        <Placeholder
          title="Просмотр недоступен"
          actions={<Button target="_blank" href="https://anime.fans" size="small">Открыть веб версию</Button>}
        >
          Смотреть аниме можно только в веб версии приложения (через браузер), заходите на <a href="https://anime.fans" target="_blank">https://anime.fans</a>
          <div style={{ marginTop: 24 }}>
            О причинах можете прочитать в этой записи: <ModalLink name="post14549">Открыть запись</ModalLink>
          </div>
        </Placeholder>
      </>
    );
  }

  return (
    <>
      <Metatags
        title={`Смотреть аниме ${anime?.info.title ?? ''} ${number} серия ${season} сезон`}
        description={anime?.info.description ?? ''}
        image={video?.poster ?? ''}
      />
      <Header showBackButton>Серия {number} {episode?.isFiller ? <sup className="PlayAnime__filler">F</sup> : null}</Header>
      <ScrollView>
        <VideoPlayer
          key={`${number}_${season}`}
          video={video}
          animeId={animeId}
          episodeId={episode!.id}
          season={season}
          episode={number}
          translationId={translationId}
          nextParams={nextParams}
        />
        <div className="PlayAnime__info">
          <div className="PlayAnime__title">{anime?.info.title ?? ''}</div>
          <div className="PlayAnime__nav">
            <div>
              {!!prevParams && (
                <ModalLink
                  name={`play${animeId}_${prevParams.translation}_${prevParams.season}_${prevParams.number}`}
                  routeOptions={{ replace: true }}
                >
                  <Button mode="secondary" size="small">← Серия {prevParams.number}{prevParams.season !== season ? ` (Сезон ${prevParams.season})` : ''}</Button>
                </ModalLink>
              )}
            </div>
            <div>
              {!!nextParams && (
                <ModalLink
                  name={`play${animeId}_${nextParams.translation}_${nextParams.season}_${nextParams.number}`}
                  routeOptions={{ replace: true }}
                >
                  <Button mode="secondary" size="small">Серия {nextParams.number}{nextParams.season !== season ? ` (Сезон ${nextParams.season})` : ''} →</Button>
                </ModalLink>
              )}
            </div>
          </div>
        </div>
        <AnimeEpisodes
          animeId={animeId}
          selectedSeason={season}
          selectedEpisode={number}
          isReplaceLocation
        />
      </ScrollView>
    </>
  );
}
