import i18n from 'i18n-js';
import { enUS, ru, id } from 'date-fns/locale';
import { differenceInYears, format as dateFnsFormat, parse as dateFnsParse } from 'date-fns';
import { TranslatorFunc } from '$translation';

const dateFnsLocale: Record<string, any> = {
  ru,
  id,
  en: enUS,
};

const getLocale = () => {
  const locale = i18n.currentLocale();
  return dateFnsLocale[locale] ?? enUS;
};

export const format = (date: number | Date, formatString: string, options?: any) =>
  dateFnsFormat(date, formatString, {
    locale: getLocale(),
    ...options,
  });

export const parse = (dateString: string, formatString: string, fallbackDate: Date) =>
  dateFnsParse(dateString, formatString, fallbackDate, {
    locale: getLocale(),
  });

export const birthdayToAge = (bDate: string) => {
  if (!bDate) {
    return 0;
  }

  try {
    const ageTs = parse(bDate, 'd.m.yyyy', new Date()).getTime() || 0;
    if (!ageTs) {
      return 0;
    }
    return getUserAge(ageTs) || 0;
  } catch (e) {
    return 0;
  }
};

export const getUserAge = (ts: number) => {
  const birthday = new Date(ts);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function formatDate(date: Date, t: TranslatorFunc) {
  const now = new Date();
  const daysDiff = Math.abs(date.getDate() - now.getDate());
  if (daysDiff < 1) {
    return t('today');
  } else if (daysDiff < 2) {
    return t('yesterday');
  } else {
    if (differenceInYears(now, date) < 1) {
      return format(date, 'd MMMM');
    } else {
      return format(date, 'd MMMM yyyy');
    }
  }
}
