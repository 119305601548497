import { all, takeLatest, retry, put } from 'redux-saga/effects';
import { notifyActions } from '$store/notify/index';
import { Api } from '$api';
import { batchActions } from '$store';
import { mainActions } from '$store/main';
import { postsActions } from '$store/posts';
import { followersActions } from '$store/followers';

function* loadWorker() {
  try {
    const data = yield retry(Infinity, 3000, Api.get, '/notify');

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        postsActions.setPosts(data.posts),
        notifyActions.setItems(data.items),
        mainActions.setHasNotify(false),
        followersActions.setFollowing(data.followed),
      )
    );
  } catch (e) {}
}

export function* notifySaga() {
  yield all([
    takeLatest(notifyActions.load, loadWorker),
  ]);
}
