import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';

import { createPostFormSelector, postsActions, postSelector, postsSelector } from './index';
import { toastActions } from '../toast';
import { Api } from '$api';
import {
  DeletePostAction,
  LoadMoreCommentsAction,
  LoadPostAction,
  PublishAction,
  PublishCommentAction,
  ShareAction,
  SavePostAction,
  ToggleLikeAction,
  LoadRepliesAction,
  LoadScheduledAction,
  PublishScheduledAction,
  ReadPostAction,
  PinPostAction
} from './interface';
import { RootState } from '../rootReducer';
import { attachActions, attachItemsSelector, attachSelector } from '../attach';
import { mainActions } from '../main';
import { LoadEditDataAction } from '$store/anime/interface';
import { showModal } from '$navigation/helper';
import { authSelector } from '$store/auth';
import { batchActions, store } from '$store';
import { statReachGoal } from '$utils';

function* publishWorker(action: PublishAction) {
  try {
    const ownerId = action.payload;
    const form = yield select((state: RootState) => createPostFormSelector(state, `post_${ownerId}`));
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `post_${ownerId}`));
    const { links, timers } = yield select(attachSelector);

    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    if (form.text.length === 0 && !attachments.length) {
      return yield put(toastActions.setToastFail('Напишите текст или прикрепите вложение'));
    }

    let text = form.text;
    const link = links[`post_${ownerId}`];
    const timer = timers[`post_${ownerId}`];
    if (link && link.object && link.object.originalLink === text.trim()) {
      text = '';
    }

    yield put(toastActions.setToastLoading('Публикация...'));
    const data = yield call(Api.post, '/posts/new', {
      ownerId: ownerId,
      text,
      parentId: 0,
      attachments,
      timer: timer > 0 ? Math.floor(timer / 1000) : 0,
    });
    yield put(toastActions.setToastSuccess('Успешно!'));
    yield put(postsActions.updateFormText({
      formId: `post_${ownerId}`,
      text: '',
    }));
    yield put(postsActions.setPost({
      id: data.post.id,
      updated: data.post,
    }));
    yield put(postsActions.unshiftToList({
      listId: String(ownerId),
      items: [data.post.id],
    }));
    yield put(attachActions.clear(`post_${ownerId}`));
    yield call(statReachGoal, 'wall_post');
    history.back();
  } catch(e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* toggleLikeWorker(action: ToggleLikeAction) {
  const postId = action.payload;
  const post = yield select((state: RootState) =>
    postSelector(state, postId),
  );

  try {
    const isAddLike = !post.isLiked;

    yield put(postsActions.setPost({
      id: postId,
      updated: {
        isLiked: isAddLike,
        likes: isAddLike ? post.likes + 1 : Math.max(0, post.likes - 1),
      },
    }));

    const data = yield call(Api.post, `/likes/${postId}/post/${isAddLike ? 'add' : 'del'}`);
    yield put(postsActions.setPost({
      id: postId,
      updated: {
        likes: +data.likes_count,
      },
    }));

    if (isAddLike) {
      yield call(statReachGoal, 'like');
    }
  } catch (e) {
    if (e.message === 'already_liked') {
      return;
    }

    yield put(toastActions.setToastFail(e.message));
    yield put(postsActions.setPost({
      id: postId,
      updated: {
        isLiked: post.isLiked,
        likes: post.likes,
      },
    }));
  }
}

function* loadPostWorker(action: LoadPostAction) {
  try {
    const postId = action.payload;
    const data = yield call(Api.get, `/posts/${postId}`);
    yield put(mainActions.setUsers(data.users));
    yield put(mainActions.setGroups(data.groups));
    yield put(postsActions.setPosts([data.post, ...data.comments]));
    yield put(postsActions.setList({
      listId: `comments_${postId}`,
      items: data.comments.map((comment) => comment.id),
    }));
    yield put(postsActions.setPostLoading({
      postId,
      isLoading: false,
    }));
    yield put(postsActions.setCommentsNextFrom({
      postId,
      nextFrom: data.commentsNextFrom,
    }));
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* publishCommentWorker(action: PublishCommentAction) {
  try {
    const originalParent = action.payload;
    let parentId = action.payload;
    const form = yield select((state: RootState) => createPostFormSelector(state, `comment_${originalParent}`));
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `comment_${originalParent}`));
    const replyTo = form.replyTo?.id ?? 0;
    const post = yield select((state: RootState) => postSelector(state, originalParent));

    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    if (form.text.length === 0 && !attachments.length) {
      return yield put(toastActions.setToastFail('Напишите текст или прикрепите вложение'));
    }

    if (form.replyTo) {
      if (form.replyTo.level === 1) {
        parentId = form.replyTo.id;
      } else {
        parentId = form.replyTo.parentId;
      }
    }

    let groupId = 0;
    if (form.sender === 'group' && post && post.ownerId < 0) {
      groupId = Math.abs(post.ownerId);
    }

    yield put(toastActions.setToastLoading('Публикация...'));
    const data = yield call(Api.post, '/posts/new', {
      parentId,
      replyTo,
      text: form.text,
      attachments,
      groupId,
    });

    const actions: any[] = [
      toastActions.hideToast(),
      postsActions.updateFormText({
        formId: `comment_${originalParent}`,
        text: '',
      }),
      postsActions.setPost({
        id: data.post.id,
        updated: data.post,
      }),
      attachActions.clear(`comment_${originalParent}`),
      postsActions.replyComment({ parentId: originalParent, comment: null }),
    ];

    if (form.replyTo && form.replyTo.level > 1) {
      actions.push(
        postsActions.pushToList({
          listId: `comments_${parentId}`,
          items: [data.post.id],
        }),
      );
    } else {
      actions.push(
        postsActions.unshiftToList({
          listId: `comments_${parentId}`,
          items: [data.post.id],
        }),
      );
    }

    if (data.parent) {
      actions.push(
        postsActions.setPost({
          id: data.parent.id,
          updated: data.parent,
        })
      );
    }

    yield put(batchActions(...actions));
    yield call(statReachGoal, 'comment');
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* deletePostWorker(action: DeletePostAction) {
  const postId = action.payload;
  try {
    yield put(postsActions.setPost({
      id: postId,
      updated: { isDeleted: true },
    }));
    yield call(Api.post, `/posts/${postId}/delete`);
  } catch (e) {
    yield put(postsActions.setPost({
      id: postId,
      updated: { isDeleted: false },
    }));
    yield put(toastActions.setToastFail(e.message));
  }
}

function* restorePostWorker(action: DeletePostAction) {
  const postId = action.payload;
  try {
    yield put(postsActions.setPost({
      id: postId,
      updated: { isDeleted: false },
    }));
    yield call(Api.post, `/posts/${postId}/restore`);
  } catch (e) {
    yield put(postsActions.setPost({
      id: postId,
      updated: { isDeleted: true },
    }));
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadEditPostWorker(action: LoadEditDataAction) {
  try {
    const postId = action.payload;
    const form = yield select((state: RootState) => createPostFormSelector(state, `edit_post_${postId}`));
    if (form.hasOwnProperty('attachments')) {
      return;
    }

    let post = yield select((state: RootState) => postSelector(state, postId));
    if (!post) {
      const data = yield call(Api.get, `/posts/${postId}`);
      post = data.post;
    }

    yield put(postsActions.updateFormText({
      formId: `edit_post_${postId}`,
      text: post.text,
    }));
    yield put(attachActions.initFromAttachments({
      pickerId: `edit_post_${postId}`,
      attachments: post.attachments,
    }));

    if (post.createdAt * 1000 > Date.now()) {
      yield put(attachActions.setTimer({
        pickerId: `edit_post_${postId}`,
        time: post.createdAt * 1000,
      }));
    }
  } catch (e) {

  }
}

function* savePostWorker(action: SavePostAction) {
  try {
    const postId = action.payload;
    const form = yield select((state: RootState) => createPostFormSelector(state, `edit_post_${postId}`));
    const attachments = yield select((state: RootState) => attachItemsSelector(state, `edit_post_${postId}`));
    const { timers } = yield select(attachSelector);
    const timer = timers[`edit_post_${postId}`];
    if (attachments === 'photo_uploading') {
      return yield put(toastActions.setToastFail('Дождитесь загрузки фото'));
    }

    if (form.text.length === 0 && !attachments.length) {
      return yield put(toastActions.setToastFail('Напишите текст или прикрепите вложение'));
    }

    yield put(toastActions.setToastLoading('Сохранение...'));
    const data = yield call(Api.post, `/posts/${postId}/edit`, {
      text: form.text,
      attachments,
      timer: timer > 0 ? Math.floor(timer / 1000) : 0,
    });
    yield put(toastActions.setToastSuccess('Успешно!'));
    yield put(postsActions.updateFormText({
      formId: `edit_post_${postId}`,
      text: '',
    }));
    yield put(postsActions.setPost({
      id: data.post.id,
      updated: data.post,
    }));
    yield put(attachActions.clear(`edit_post_${postId}`));
    history.back();
  } catch (e) {
    console.log('e', e);
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadMoreCommentsWorker(action: LoadMoreCommentsAction) {
  try {
    const postId = action.payload;
    const { commentsNextFrom } = yield select(postsSelector);
    const data = yield call(Api.post, `/posts/${postId}/comments`, {
      startFrom: +commentsNextFrom[postId],
      sort: 'popular',
    });
    yield put(mainActions.setUsers(data.users));
    yield put(postsActions.setPosts(data.comments));
    yield put(postsActions.pushToList({
      listId: `comments_${postId}`,
      items: data.comments.map((comment) => comment.id),
    }));
    yield put(postsActions.setCommentsNextFrom({
      postId,
      nextFrom: data.nextFrom,
    }));
  } catch (e) {}
}

function* shareWorker(action: ShareAction) {
  try {
    const link = action.payload;
    const { currentUserId } = yield select(authSelector);

    yield call(showModal, 'post');
    yield put(
      batchActions(
        attachActions.clear(`post_${currentUserId}`),
        attachActions.setLink({
          pickerId: `post_${currentUserId}`,
          link,
        })
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadRepliesWorker(action: LoadRepliesAction) {
  try {
    const { postId } = action.payload;
    const { replies } = yield select(postsSelector);
    const repliesState = replies[postId] || {};

    const data = yield call(Api.post, `/posts/${postId}/comments`, {
      startFrom: +repliesState.nextFrom,
      sort: 'recent',
      limit: 5,
    });

    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        postsActions.setPosts(data.comments),
        postsActions.pushToList({
          listId: `comments_${postId}`,
          items: data.comments.map((comment) => comment.id),
        }),
        postsActions.setRepliesLoaded({
          postId,
          nextFrom: data.nextFrom,
        }),
      ),
    );
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* loadScheduledWorker(action: LoadScheduledAction) {
  try {
    const { ownerId } = action.payload;

    const data = yield call(Api.get, `/posts/${ownerId}/scheduled`);
    yield put(
      batchActions(
        mainActions.setUsers(data.users),
        mainActions.setGroups(data.groups),
        postsActions.setPosts(data.posts),
        postsActions.setScheduled(data.scheduled),
      ),
    );
  } catch (e) {
    yield put(postsActions.scheduledFailed());
  }
}

function* publishScheduledWorker(action: PublishScheduledAction) {
  try {
    const postId = action.payload;
    yield put(toastActions.setToastLoading());

    yield call(Api.post, `/posts/scheduled/${postId}/publish`);

    const { scheduled } = yield select(postsSelector);
    const scheduledCopy = [...scheduled];
    for (let i = 0; i < scheduled.length; i++) {
      if (scheduledCopy[i] === postId) {
        scheduledCopy.splice(i, 1);
        break;
      }
    }

    yield put(postsActions.setScheduled(scheduledCopy));
    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

const SeenPosts: { [index: number]: true } = {};
let ReadTimer: any = 0;
function* readPostWorker(action: ReadPostAction) {
  try {
    const { isLogged } = yield select(authSelector);
    if (!isLogged) {
      return;
    }

    if (SeenPosts[action.payload]) {
      return;
    }

    SeenPosts[action.payload] = true;
    clearTimeout(ReadTimer);
    ReadTimer = setTimeout(() => {
      store.dispatch(postsActions.sendSeenPosts());
    }, 2000);
  } catch (e) {}
}

function* sendSeenPostsWorker() {
  clearTimeout(ReadTimer);

  const { isLogged } = yield select(authSelector);
  if (!isLogged) {
    return;
  }

  const { readQueue } = yield select(postsSelector);

  if (!readQueue.length) {
    return;
  }

  const posts: number[] = _.uniq(readQueue);
  try {
    yield put(postsActions.clearReadPostsList());
    yield call(Api.post, '/posts/read', {
      posts,
    });
  } catch (e) {
    yield put(postsActions.readPostMulti(posts));
  }
}

function* pinPostWorker(action: PinPostAction) {
  try {
    const postId = action.payload;
    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/posts/${postId}/pin`);

    const post = yield select(postSelector, postId);
    console.log('post', post);
    if (post) {
      yield put(postsActions.setPost({
        id: postId,
        updated: {
          pinned: true,
        }
      }));
    }

    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* unpinPostWorker(action: PinPostAction) {
  try {
    const postId = action.payload;
    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/posts/${postId}/unpin`);

    const post = yield select(postSelector, postId);
    if (post) {
      yield put(postsActions.setPost({
        id: postId,
        updated: {
          pinned: false,
        }
      }));
    }

    yield put(toastActions.setToastSuccess());
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* postsSaga() {
  yield all([
    takeLatest(postsActions.publish, publishWorker),
    takeLatest(postsActions.toggleLike, toggleLikeWorker),
    takeEvery(postsActions.loadPost, loadPostWorker),
    takeLatest(postsActions.publishComment, publishCommentWorker),
    takeLatest(postsActions.deletePost, deletePostWorker),
    takeLatest(postsActions.restorePost, restorePostWorker),
    takeLatest(postsActions.loadEditPost, loadEditPostWorker),
    takeLatest(postsActions.savePost, savePostWorker),
    takeLatest(postsActions.loadMoreComments, loadMoreCommentsWorker),
    takeLatest(postsActions.share, shareWorker),
    takeEvery(postsActions.loadReplies, loadRepliesWorker),
    takeLatest(postsActions.loadScheduled, loadScheduledWorker),
    takeLatest(postsActions.publishScheduled, publishScheduledWorker),
    takeLatest(postsActions.readPost, readPostWorker),
    takeLatest(postsActions.sendSeenPosts, sendSeenPostsWorker),
    takeLatest(postsActions.pinPost, pinPostWorker),
    takeLatest(postsActions.unpinPost, unpinPostWorker),
  ]);
}
