import './Form.sass';

import React, { FC } from 'react';
import { FormItemProps } from '$uikit/Form/interface';
import { classNames } from '$utils';

export function Form({ children }) {
  return (
    <div className="Form">
      {children}
    </div>
  );
}

export const FormItem: FC<FormItemProps> = (props) => {
  const { children, title = null, description = null, skipPadding = false } = props;

  return (
    <div className={classNames({
      Form__item: true,
      skipPadding,
    })}>
      {title && <div className="Form__item__title">{title}</div>}
      <div className="Form__item__cont">
        {children}
      </div>
      {description && <div className="Form__item__description">{description}</div>}
    </div>
  );
};

export const FormItemColumn: FC = ({ children }) => {
  return (
    <div className="Form__item__column">
      {children}
    </div>
  )
}
